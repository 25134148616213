export const TRAZAS = ['Tronco', 'Ramal', 'Enlace']

export const UNIDADES = [
  { text: 'm', value: 'M' },
  { text: 'm²', value: 'M2' },
  { text: 'm³', value: 'M3' },
  { text: 'Ud', value: 'Ud' }
]

export const SITUACIONES = [
  { text: 'Mal', value: 'mal' },
  { text: 'Regular', value: 'regular' },
  { text: 'Limpia', value: 'limpia' }
]

export const SENTIDOS = [
  { text: 'Creciente', value: 'creciente' },
  { text: 'Decreciente', value: 'decreciente' },
  { text: 'Reversible', value: 'reversible' },
  { text: 'Mediana', value: 'mediana' }
]

export const DIRECCIONES = ['N', 'S', 'M']

export const SI_NO = [{ text: 'Sí', value: 'si' }, { text: 'No', value: 'no' }]

export const TIPO_ACTUACION_JARDINERIA = [
  { text: 'Siegas', value: 'siegas' },
  { text: 'Desbroce', value: 'desbroce' },
  { text: 'Podas', value: 'podas' },
  { text: 'despejes', value: 'despejes' },
  { text: 'Tratamientos fitosanitarios', value: 'tratamientos-fitosanitarios' },
  { text: 'Fertilización', value: 'fertilización' },
  { text: 'Entrecavas', value: 'entrecavas' },
  { text: 'Riego', value: 'riego' }
]

export const TIPOLOGIA_ELEMENTO_HIDRAULICO_DRENAJE_SUPERFICIAL = [
  { text: 'Cuneta lateral revestida', value: 'cuneta lateral revestida' },
  { text: 'Cuneta mediana revestida', value: 'cuneta mediana revestida' },
  { text: 'Cuneta desmonte revestida', value: 'cuneta desmonte revestida' },
  { text: 'Berma revestida', value: 'berma revestida' },
  { text: 'Cuneta terraplén revestida', value: 'cuneta terraplén revestida' },
  { text: 'Cuneta lateral en tierras', value: 'cuneta lateral en tierras' },
  { text: 'Cuneta mediana en tierras', value: 'cuneta mediana en tierras' },
  { text: 'Cuneta desmonte en tierras', value: 'cuneta desmonte en tierras' },
  { text: 'Cuneta terraplén en tierras', value: 'cuneta terraplén en tierras' },
  { text: 'Berma en tierras', value: 'berma en tierras' },
  { text: 'Bajante', value: 'bajante' },
  { text: 'Caz', value: 'caz' },
  { text: 'Pozos de drenaje', value: 'pozos de drenaje' },
  { text: 'Arquetas', value: 'arquetas' }
]

export const TIPOLOGIA_ELEMENTO_HIDRAULICO_DRENAJE_PROFUNDO = [
  { text: 'Colector d50cm', value: 'colector d50cm' },
  { text: 'Colector d60cm', value: 'colector d60cm' },
  { text: 'Colector d80cm', value: 'colector d80cm' },
  { text: 'Colector d100cm', value: 'colector d100cm' },
  { text: 'Pozo', value: 'pozo' }
]

export const TIPO_INSPECCION_VALLA_CIERRE = [
  { text: 'Puntual', value: 'puntual' },
  { text: 'Cuatrimestral 1º', value: 'cuatrimestral 1º' },
  { text: 'Cuatrimestral 2º', value: 'cuatrimestral 2º' },
  { text: 'Cuatrimestral 3º', value: 'cuatrimestral 3º' },
  { text: 'Cuatrimestral 4º', value: 'cuatrimestral 4º' }
]

export const LOCALIZACION_JARDINERIA_TRONCO = [
  { text: 'Lateral tronco', value: 'lateral-tronco' },
  { text: 'Mediana', value: 'mediana' },
  { text: 'Pantallas', value: 'pantallas' },
  { text: 'Áreas desc. / mant. / peaj.', value: 'areas-desc-mant-peaj' }
]

export const LOCALIZACION_LIMPIEZA_PISTA = [
  { text: 'Arcén derecho', value: 'arcén derecho' },
  { text: 'Arcén izquierdo', value: 'arcén izquierdo' },
  { text: 'Plataformas', value: 'plataformas' },
  { text: 'Berma', value: 'berma' },
  { text: 'Ranuras en calzada', value: 'ranuras en calzada' },
  { text: 'Graffitis', value: 'graffitis' }
]

export const ACTUACION_LIMPIEZA_PISTA = [
  { text: 'Barrido', value: 'barrido' },
  { text: 'Limpieza', value: 'limpieza' }
]

export const ACTUACION_REPARACION_FIRMES_ESTRUCTURAS = [
  {
    text: 'Reparación asfalto en frío',
    value: 'reparación asfalto en frío'
  },
  {
    text: 'Reparación asfalto en caliente',
    value: 'reparación asfalto en caliente'
  },
  {
    text: 'Reparación del firme por raíces',
    value: 'reparación del firme x raíces'
  },
  {
    text: 'Reparación juntas de dilatación',
    value: 'reparación juntas de dilatación'
  }
]

export const ACTUACION_ELEMENTOS_SEÑALIZACION_BALIZAMIENTO_CONTENCION = [
  { text: 'Recrecido', value: 'recrecido' },
  { text: 'Reposición', value: 'reposición' },
  { text: 'Limpieza', value: 'limpieza' },
  { text: 'Repintado', value: 'repintado' }
]

export const ELEMENTOS_SEÑALIZACION_BALIZAMIENTO = [
  { text: 'Hitos cilíndricos', value: 'hitos cilíndricos' },
  { text: 'Hitos divergentes', value: 'hitos divergentes' },
  { text: 'Captafaros', value: 'captafaros' },
  { text: 'Postes guías', value: 'postes guías' },
  { text: 'Losetas reflectantes', value: 'losetas reflectantes' },
  { text: 'Marcas S.O.S', value: 'marcas S.O.S' }
]

export const ELEMENTOS_CONTENCION = [
  { text: 'Metálica simple', value: 'metálica simple' },
  { text: 'Metálica doble', value: 'metálica doble' },
  { text: 'Metálica superpuesta', value: 'metálica superpuesta' },
  { text: 'Hormigón', value: 'hormigón' },
  { text: 'Pretil', value: 'pretil' }
]

export const INSPECCION_CIMENTACION = [
  {
    text: 'Movimiento de tierras o grietas en el terreno',
    value: 'movimiento de tierras o grietas en el terreno'
  },
  {
    text: 'Descalce o socavón del terreno',
    value: 'descalce o socavón del terreno'
  },
  {
    text: 'Parte superior zapata visible',
    value: 'parte superior zapata visible'
  },
  {
    text: 'Movimiento o cabeceo de zapata',
    value: 'movimiento o cabeceo de zapata'
  },
  {
    text: 'Fisura superficial en hormigón',
    value: 'fisura superficial en hormigón'
  },
  {
    text: 'Grietas o roturas profundas en hormigón',
    value: 'grietas o roturas profundas en hormigón'
  },
  {
    text: 'Desconche superficial del hormigón',
    value: 'desconche superficial del hormigón'
  },
  { text: 'Otros', value: 'otros' }
]

export const INSPECCION_ESPACIO_CIMIENTO = [
  {
    text: 'Falta de mortero de relleno (espárragos o tuercas visibles)',
    value: 'falta de mortero de relleno (espárragos o tuercas visibles)'
  }
]

export const INSPECCION_PLACAS_ANCLAJE_Y_CARTELAS_EN_BASES = [
  {
    text: 'Envejecimiento desgaste pintura protección',
    value: 'envejecimiento desgaste pintura protección'
  },
  {
    text: 'Placas o cartelas oxidadas o corroídas',
    value: 'placas o cartelas oxidadas o corroídas'
  },
  {
    text: 'Soldaduras defectuosas por inspección visual',
    value: 'soldaduras defectuosas por inspección visual'
  },
  { text: 'Soldadura oxidada', value: 'soldadura oxidada' },
  {
    text: 'Pernos anclaje y tornillos sueltos o rotos',
    value: 'pernos anclaje y tornillos sueltos o rotos'
  },
  {
    text: 'Oxidación en pernos o tuercas',
    value: 'oxidación en pernos o tuercas'
  },
  { text: 'Otros', value: 'otros' }
]

export const INSPECCION_BACULO_COLUMNA = [
  {
    text: 'Acumula agua en su interior o mal drenaje',
    value: 'acumula agua en su interior o mal drenaje'
  },
  {
    text: 'Desplomado o nivelación deficiente',
    value: 'desplomado o nivelación deficiente'
  },
  { text: 'Elemento pandeado o doblado', value: 'elemento pandeado o doblado' },
  {
    text: 'Tiene impactos, golpes o abolladuras',
    value: 'tiene impactos, golpes o abolladuras'
  },
  { text: 'Desgaste pintura protección', value: 'desgaste pintura protección' },
  {
    text: 'Existe oxidación o corrosión',
    value: 'existe oxidación o corrosión'
  },
  {
    text: 'Soldaduras defectuosas por inspección visual',
    value: 'soldaduras defectuosas por inspección visual'
  },
  { text: 'Otros', value: 'otros' }
]

export const INSPECCION_TAPAS_INTERIOR_BACULO = [
  { text: 'Falta de la tapa', value: 'falta de la tapa' },
  { text: 'Falta toma de tierra', value: 'falta toma de tierra' },
  { text: 'Falta numeración', value: 'falta numeración' },
  { text: 'Otros', value: 'otros' }
]

export const EJECUCION_CIMENTACION = [
  { text: 'Pintura', value: 'pintura' },
  { text: 'Reparación hormigón', value: 'reparación hormigón' },
  { text: 'Otros', value: 'otros' }
]

export const EJECUCION_ESPACIO_CIMIENTO = [
  { text: 'Relleno mortero', value: 'relleno mortero' },
  { text: 'Otros', value: 'otros' }
]

export const EJECUCION_BACULO_COLUMNA = [
  { text: 'Pintura', value: 'pintura' },
  { text: 'Apriete tuercas', value: 'apriete tuercas' },
  { text: 'Engrase', value: 'engrase' },
  { text: 'Reparación hormigón', value: 'reparación hormigón' },
  { text: 'Otros', value: 'otros' }
]

export const EJECUCION_TAPAS_INTERIOR_BACULO = [
  { text: 'Pintura', value: 'pintura' },
  { text: 'Apriete tuercas', value: 'apriete tuercas' },
  { text: 'Nueva tapa', value: 'nueva tapa' },
  { text: 'Otros', value: 'otros' }
]
