const MODULE = 'CECOS'

export const FETCH_CECOS_REQUEST = `${MODULE}/FETCH_CECOS_REQUEST`
export const FETCH_CECOS_SUCCESS = `${MODULE}/FETCH_CECOS_SUCCESS`
export const FETCH_CECOS_FAILED = `${MODULE}/FETCH_CECOS_FAILED`
export const SET_EDITING = `${MODULE}/SET_EDITING`
export const SET_CREATING = `${MODULE}/SET_CREATING`
export const SET_CURRENT_PAGE = `${MODULE}/SET_CURRENT_PAGE`
export const SET_SEARCH_VALUE = `${MODULE}/SET_SEARCH_VALUE`
export const SET_MODAL_VALUES = `${MODULE}/SET_MODAL_VALUES`
