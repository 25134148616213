import {
  getPKFamily,
  getStartEndPKFields,
  getAvgHeightGrassField,
  getStartEndPKExecFields,
  // getStartEndCoordExecFields,
  getWayField,
  getMedicUnidExecFields,
  getCommonInspectionFields,
  getCommonExecFields,
  getCommonValidationFields
} from './commonFields'

const limpiezasDesbrocesODsFields = [
  ...getPKFamily('limpiezasDesbrocesODs'),
  ...getStartEndPKFields('limpiezasDesbrocesODs'),
  ...getAvgHeightGrassField('limpiezasDesbrocesODs'),
  ...getCommonInspectionFields('limpiezasDesbrocesODs'),
  ...getStartEndPKExecFields('limpiezasDesbrocesODs'),
  // ...getStartEndCoordExecFields('limpiezasDesbrocesODs'),
  ...getWayField('limpiezasDesbrocesODs'),
  ...getMedicUnidExecFields('limpiezasDesbrocesODs'),
  ...getCommonExecFields('limpiezasDesbrocesODs'),
  ...getCommonValidationFields('limpiezasDesbrocesODs')
]

export const getLimpiezaDesbroceODFields = () => limpiezasDesbrocesODsFields
