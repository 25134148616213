import { combineReducers } from 'redux'
import { buildReducer } from '../utils'
import * as ActionTypes from './actionTypes'
export * from './selectors'
export * from './actions'

const asset = buildReducer(
  {
    isSaving: false,
    lastSaveError: null
  },
  {
    [ActionTypes.SAVE_ASSET_REQUEST]: (state, action) => {
      return {
        isSaving: true,
        lastSaveError: null
      }
    },
    [ActionTypes.UPDATE_ASSET_REQUEST]: (state, action) => {
      return {
        isSaving: true,
        lastSaveError: null
      }
    },
    [ActionTypes.SAVE_ASSET_SUCCESS]: (state, action) => {
      return {
        isSaving: false,
        lastSaveError: null
      }
    },
    [ActionTypes.UPDATE_ASSET_SUCCESS]: (state, action) => {
      return {
        isSaving: false,
        lastSaveError: null
      }
    },
    [ActionTypes.SAVE_ASSET_FAILED]: (state, action) => {
      return {
        isSaving: false,
        lastSaveError:
          action.payload.message || action.payload.toString() || 'error'
      }
    },
    [ActionTypes.UPDATE_ASSET_FAILED]: (state, action) => {
      return {
        isSaving: false,
        lastSaveError:
          action.payload.message || action.payload.toString() || 'error'
      }
    }
  }
)

const ordinaryOperation = buildReducer(
  {
    isSaving: false,
    lastSaveError: null,
    createFromAssetError: null,
    ordinaryOperationOptions: null
  },
  {
    [ActionTypes.SAVE_ORDINARY_OPERATION_REQUEST]: (state, action) => {
      return {
        ...state,
        isSaving: true,
        lastSaveError: null
      }
    },
    [ActionTypes.UPDATE_ORDINARY_OPERATION_REQUEST]: (state, action) => {
      return {
        ...state,
        isSaving: true,
        lastSaveError: null
      }
    },
    [ActionTypes.SAVE_ORDINARY_OPERATION_SUCCESS]: (state, action) => {
      return {
        ...state,
        isSaving: false,
        lastSaveError: null
      }
    },
    [ActionTypes.UPDATE_ORDINARY_OPERATION_SUCCESS]: (state, action) => {
      return {
        ...state,
        isSaving: false,
        lastSaveError: null
      }
    },
    [ActionTypes.SAVE_ORDINARY_OPERATION_FAILED]: (state, action) => {
      return {
        ...state,
        isSaving: false,
        lastSaveError:
          action.payload.message || action.payload.toString() || 'error'
      }
    },
    [ActionTypes.UPDATE_ORDINARY_OPERATION_FAILED]: (state, action) => {
      return {
        ...state,
        isSaving: false,
        lastSaveError:
          action.payload.message || action.payload.toString() || 'error'
      }
    },
    [ActionTypes.REMOVE_ORDINARY_OPERATION_ERROR]: state => {
      return {
        ...state,
        isSaving: false,
        lastSaveError: null
      }
    },
    [ActionTypes.CREATE_WORK_REPORT_FROM_ASSET_REQUEST]: state => {
      return {
        ...state,
        createFromAssetError: null,
        ordinaryOperationOptions: null
      }
    },
    [ActionTypes.CREATE_WORK_REPORT_FROM_ASSET_SUCCESS]: state => {
      return {
        ...state,
        createFromAssetError: null,
        ordinaryOperationOptions: null
      }
    },
    [ActionTypes.CREATE_WORK_REPORT_FROM_ASSET_FAILED]: (state, action) => {
      return {
        ...state,
        createFromAssetError: action.payload.message,
        ordinaryOperationOptions: null
      }
    },
    [ActionTypes.CREATE_WORK_REPORT_FROM_ASSET_SET_OPTIONS]: (
      state,
      action
    ) => {
      return {
        ...state,
        createFromAssetError: null,
        ordinaryOperationOptions: action.payload
      }
    },
    [ActionTypes.CREATE_WORK_REPORT_FROM_ASSET_ACKNOWLEDGEMENT]: state => {
      return {
        ...state,
        createFromAssetError: null,
        ordinaryOperationOptions: null
      }
    }
  }
)

const extraordinaryOperation = buildReducer(
  {
    isSaving: false,
    lastSaveError: null
  },
  {
    [ActionTypes.SAVE_EXTRAORDINARY_OPERATION_REQUEST]: (state, action) => {
      return {
        isSaving: true,
        lastSaveError: null
      }
    },
    [ActionTypes.UPDATE_EXTRAORDINARY_OPERATION_REQUEST]: (state, action) => {
      return {
        isSaving: true,
        lastSaveError: null
      }
    },
    [ActionTypes.SAVE_EXTRAORDINARY_OPERATION_SUCCESS]: (state, action) => {
      return {
        isSaving: false,
        lastSaveError: null
      }
    },
    [ActionTypes.UPDATE_EXTRAORDINARY_OPERATION_SUCCESS]: (state, action) => {
      return {
        isSaving: false,
        lastSaveError: null
      }
    },
    [ActionTypes.SAVE_EXTRAORDINARY_OPERATION_FAILED]: (state, action) => {
      return {
        isSaving: false,
        lastSaveError:
          action.payload.message || action.payload.toString() || 'error'
      }
    },
    [ActionTypes.UPDATE_EXTRAORDINARY_OPERATION_FAILED]: (state, action) => {
      return {
        isSaving: false,
        lastSaveError:
          action.payload.message || action.payload.toString() || 'error'
      }
    },
    [ActionTypes.REMOVE_EXTRAORDINARY_OPERATION_ERROR]: () => {
      return {
        isSaving: false,
        lastSaveError: null
      }
    }
  }
)

const workOperation = buildReducer(
  {
    isSaving: false,
    lastSaveError: null
  },
  {
    [ActionTypes.SAVE_WORK_OPERATION_REQUEST]: (state, action) => {
      return {
        isSaving: true,
        lastSaveError: null
      }
    },
    [ActionTypes.UPDATE_WORK_OPERATION_REQUEST]: (state, action) => {
      return {
        isSaving: true,
        lastSaveError: null
      }
    },
    [ActionTypes.SAVE_WORK_OPERATION_SUCCESS]: (state, action) => {
      return {
        isSaving: false,
        lastSaveError: null
      }
    },
    [ActionTypes.UPDATE_WORK_OPERATION_SUCCESS]: (state, action) => {
      return {
        isSaving: false,
        lastSaveError: null
      }
    },
    [ActionTypes.SAVE_WORK_OPERATION_FAILED]: (state, action) => {
      return {
        isSaving: false,
        lastSaveError:
          action.payload.message || action.payload.toString() || 'error'
      }
    },
    [ActionTypes.UPDATE_WORK_OPERATION_FAILED]: (state, action) => {
      return {
        isSaving: false,
        lastSaveError:
          action.payload.message || action.payload.toString() || 'error'
      }
    },
    [ActionTypes.REMOVE_WORK_OPERATION_ERROR]: () => {
      return {
        isSaving: false,
        lastSaveError: null
      }
    }
  }
)

const initialDrawingState = {
  isDrawing: false,
  assetType: null,
  newGeometry: null,
  isMenuOpen: false
}

const drawingState = buildReducer(initialDrawingState, {
  [ActionTypes.SET_MENU_OPEN]: (state, action) => {
    const { isOpen } = action.payload
    return {
      ...state,
      isMenuOpen: isOpen
    }
  },
  [ActionTypes.SET_CREATE_MODE]: (state, action) => {
    const { isUserDrawing, assetType } = action.payload
    return {
      ...state,
      isDrawing: isUserDrawing,
      isMenuOpen: false,
      assetType: isUserDrawing ? assetType : null
    }
  },
  [ActionTypes.STORE_GEOMETRY]: (state, action) => {
    return {
      ...state,
      newGeometry: action.payload
    }
  },
  [ActionTypes.END_CREATION]: (state, action) => {
    return initialDrawingState
  }
})

export default combineReducers({
  asset,
  ordinaryOperation,
  extraordinaryOperation,
  workOperation,
  drawingState
})
