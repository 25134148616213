import * as ActionTypes from './actionTypes'
import { getListItems } from './api'
import { getRequestStatus, isListCached } from './selectors'
import { REQUEST_STATUS } from '../constants'

export function fetchRequest(listName) {
  return {
    type: ActionTypes.FETCH_REQUESTED,
    payload: {
      listName
    }
  }
}

export function fetchSuccess(listName, data) {
  return {
    type: ActionTypes.FETCH_SUCCEEDED,
    payload: {
      listName,
      data
    }
  }
}

export function fetchFailed(listName, error) {
  return {
    type: ActionTypes.FETCH_FAILED,
    payload: {
      listName,
      error
    }
  }
}

export function fetchList(listName) {
  return async (dispatch, getState) => {
    const reqStatus = getRequestStatus(listName, getState())
    if (reqStatus === REQUEST_STATUS.Loading) {
      //console.log('Data for ' + listName + ' is already loading')
      return
    }
    const isDataCached = isListCached(listName, getState())
    if (isDataCached) {
      //console.log('Data for ' + listName + ' is cached')
      return
    }

    dispatch(fetchRequest(listName))
    try {
      const listData = await getListItems(listName)
      dispatch(fetchSuccess(listName, listData))
    } catch (err) {
      console.warn('Error fetching simple list ' + listName, err)
      dispatch(fetchFailed(listName, err))
    }
  }
}
