//@ts-check
import { pathOr } from 'lodash/fp'

// CONCESIONARIAS /	AUTOPISTA /	COMUNIDAD /	PROVINCIA /	MUNICIPIOS
export const MunicipalityMap = {
  AUCAT: {
    C32A: {
      Cataluña: {
        Tarragona: ['El Vendrell', 'CalaFell', 'Cunit'],
        Barcelona: [
          'Cubelles',
          ' Vilanova i la Geltrú',
          'Sant Pere de Ribes',
          'Sitges',
          'CastellDefels',
          'Gavá',
          'Viladecans',
          'Sant Boi de Llobregat'
        ]
      }
    }
  },
  CASTELLANA: {
    AP51: {
      'Castilla y León': {
        Segovia: ['Villacastin'],
        Ávila: [
          'Sanata María del Cubillo',
          'Blascoeles',
          'Ojos Albos',
          'Mediana de Voltoya',
          'Berrocalejo de Aragona',
          'Ávila'
        ]
      }
    },
    AP06: {
      'Comunidad de Madrid': {
        Madrid: [
          'Collado Villalba',
          'Alpedrete',
          'Guadarrama',
          'San Lorenzo del Escorial'
        ]
      },
      'Castilla y León': {
        Segovia: [
          'El Espinar',
          'Navas de San Antonio',
          'Villacastin',
          'Muñopedro',
          'Labajos'
        ],
        Ávila: ['Maello', 'Sanchidrián', 'Adanero']
      }
    },
    AP61: {
      'Castilla y León': {
        Segovia: [
          'El Espinar',
          'Otero de herreros',
          'Ortigosa del Monte',
          'La Losa',
          'Navas de Riofrio',
          'Revenga',
          'Segovia'
        ]
      }
    }
  },
  AULESA: {
    AP71: {
      'Castilla y León': {
        León: [
          'Valverde de la Virgen',
          'Chozas de Abajo',
          'Villadangos del Paramo',
          'Santa Marina del Rey',
          'Hospital de Órbigo',
          'Vilarejo de Órbigo',
          'Villares de  Órbigo',
          'San Justo de la Vega',
          'Bustillo del Páramo'
        ]
      }
    }
  },
  AVASA: {
    AP68: {
      'País Vasco': {
        Vizcaya: [
          'Bilbao',
          'Arrigorriaga',
          'Ugao-Miraballes',
          'Arrankudiaga',
          'Arakaldo',
          'Orozco'
        ],
        Álava: [
          'Amurrio',
          'Zuia',
          'Urkabustaiz',
          'Kuartango',
          'Rivera alta',
          'Rivera baja',
          'Armiñón',
          'Berantevilla',
          'Zambrana'
        ]
      },
      'Castilla y León': { Burgos: ['Miranda de Ebro'] },
      'La Rioja': {
        Logroño: [
          'Haro',
          'Rodezno',
          'Ollauri',
          'Gimileo',
          'Briones',
          'San asensio',
          'Cenicero',
          'Fuenmayor',
          'Navarrete',
          'Logroño',
          'Lardero',
          'Villamediana de Iregua',
          'Agoncillo',
          'Murillo de Rio Leza',
          'Galilea',
          'Alcanadre',
          'Ausejo',
          'Pradejón',
          'Calahorra',
          'Aldeanueva de Ebro',
          'Rincón de Soto',
          'Alfaro'
        ]
      },
      Navarra: {
        Navarra: [
          'Lodosa',
          'Corella',
          'Tudela',
          'Murchante',
          'Cascante',
          'Fontellas',
          'Ablitas',
          'Ribaforada',
          'Cortes'
        ]
      },
      Aragón: {
        Zaragoza: [
          'Mallen',
          'Bisimbre',
          'Magallon',
          'Gallur',
          'Boquiñeni',
          'Luceni',
          'Pedrola',
          'Figueruelas',
          'Alagón',
          'Torres de Berrellen',
          'La joyosa',
          'Sobradiel',
          'Zaragoza',
          'Utebo'
        ]
      }
    }
  },
  TÚNELS: {
    '0C16': {
      Cataluña: {
        Barcelona: [
          'Barcelona',
          'San cugat del Valles',
          'Bagá',
          'Guardiola de Berguedá'
        ],
        Lleida: ['Bellver de Cerdanya', 'Riu de Cerdanya'],
        Gerona: ['Urús', 'Das', 'Alp', 'Fontanals de Cerdanya']
      }
    }
  },
  TRADOS45: {
    '0M45': {
      Madrid: { Madrid: ['Madrid'] }
    }
  }
}

/**
 *
 * @param {import('./concesionarias_autopistas').CompanySpec} spec
 */
export const getMunicipality = spec => {
  if (spec.company === 'TRADOS45') return ['Madrid']
  return pathOr(
    [],
    [spec.company, spec.highway, spec.state, spec.province],
    MunicipalityMap
  )
}
