/**
 * Conerts a literal lat lng bounds from Google Map into
 * a GeoJson polygon geometry
 * @param {Object} LatLngBoundsLiteral from Google Maps
 * @returns [[[Number]]] GeoJSON Polygon geometry
 */
export function boundsToPolygon({ south, west, north, east }) {
  const res = []
  res.push([east, north])
  res.push([east, south])
  res.push([west, south])
  res.push([west, north])
  // last point same as first to "close" the polygon
  res.push([east, north])
  // single line polygon, no inner hole
  return [res]
}
