import { buildReducer } from '../utils'
import * as ActionTypes from './actionTypes'
export * from './actions'
export * from './selectors'

const initialState = {
  assetList: [],
  selectedAssetType: '',
  isLoading: false,
  hasError: false,
  filter: {
    code: '',
    initialPK: '',
    finalPK: '',
    highway: ''
  }
}

export default buildReducer(initialState, {
  [ActionTypes.FETCH_REQUEST]: (state, action) => {
    return {
      ...state,
      isLoading: true,
      hasError: false,
      selectedAssetType: action.assetType,
      assetList: []
    }
  },
  [ActionTypes.FETCH_FAILED]: (state, action) => {
    return {
      ...state,
      isLoading: false,
      hasError: true,
      lastErrorMessage: action.error
    }
  },
  [ActionTypes.FETCH_SUCCESS]: (state, action) => {
    const { assets, assetType } = action.payload
    if (assetType !== state.selectedAssetType) return state
    return {
      ...state,
      hasError: false,
      isLoading: false,
      assetList: assets
    }
  },
  [ActionTypes.CLEAR_LIST]: (state, action) => {
    if (action.assetType === state.selectedAssetType) {
      return {
        ...state,
        assetList: [],
        filter: {
          code: '',
          initialPK: '',
          finalPK: '',
          highway: ''
        }
      }
    }
    return state
  },
  [ActionTypes.STORE_ASSET_LIST]: (state, action) => {
    return {
      ...state,
      assetList: action.payload
    }
  },
  [ActionTypes.SET_FILTER_VALUE]: (state, action) => {
    const { field, value } = action.payload
    return {
      ...state,
      filter: {
        ...state.filter,
        [field]: value
      }
    }
  },
  [ActionTypes.CLEAR_FILTERS]: (state, action) => {
    if (action.assetType !== state.selectedAssetType) {
      return state
    }
    return {
      ...state,
      filter: initialState.filter
    }
  }
})
