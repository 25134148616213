import { set } from 'lodash/fp'
import { buildReducer } from '../utils'
import * as ActionTypes from './actionTypes'
import { REQUEST_STATUS } from '../constants'

export * from './actions'
export * from './selectors'

const initialState = {
  connections: [],
  connectionsRequest: REQUEST_STATUS.NotRequested,
  connectionsLastFetched: 0,
  pks: [],
  filters: {
    network: '',
    management: '',
    highway: '',
    connectionCode: '',
    connectionName: '',
    pk: '',
    lng: '',
    lat: ''
  },
  coords: {
    lat: null,
    lng: null
  },
  modal: {
    show: false,
    text: ''
  },
  favLocations: [
    /* { title, filters, lat, lng } */
  ],
  favLocationsRequest: REQUEST_STATUS.NotRequested,
  favLocationsLastFetched: 0,
  newFavLocName: '',
  showDeleteFavLocConfirm: false,
  favLocToDelete: null
}

export default buildReducer(initialState, {
  [ActionTypes.SAVE_CONNECTIONS]: (state, action) => ({
    ...state,
    connections: action.payload.connections,
    connectionsRequest: REQUEST_STATUS.Success,
    connectionsLastFetched: Date.now()
  }),
  [ActionTypes.SAVE_FAV_LOCATIONS]: (state, action) => ({
    ...state,
    favLocations: action.payload.favLocations,
    favLocationsRequest: REQUEST_STATUS.Success,
    favLocationsLastFetched: Date.now()
  }),
  [ActionTypes.SAVE_NEW_FAV_LOC_NAME]: (state, action) => ({
    ...state,
    newFavLocName: action.payload.newFavLocName
  }),
  [ActionTypes.SHOW_DELETE_FAV_LOCATIONS_CONFIRM]: (state, action) => ({
    ...state,
    showDeleteFavLocConfirm: action.payload.showDeleteFavLocConfirm
  }),
  [ActionTypes.SET_FAV_LOC_TO_DELETE]: (state, action) => ({
    ...state,
    favLocToDelete: action.payload.favLocToDelete
  }),
  [ActionTypes.SAVE_HIGHWAY_PKS]: (state, action) => ({
    ...state,
    pks: action.payload
  }),
  [ActionTypes.CLEAR_FILTERS]: state => ({
    ...initialState,
    connections: state.connections,
    favLocations: state.favLocations
  }),
  [ActionTypes.SET_FILTER_VALUE]: (state, action) => {
    const { filter, value } = action.payload
    return {
      ...state,
      filters: {
        ...state.filters,
        [filter]: value
      }
    }
  },
  [ActionTypes.SAVE_POSITION]: (state, action) => {
    const [lng, lat] = action.payload.coordinates
    return {
      ...state,
      filters: {
        ...state.filters,
        lng,
        lat
      },
      coords: { lng, lat }
    }
  },
  [ActionTypes.SET_MODAL_VALUES]: (state, action) => {
    const { show, text } = action.payload
    return set('modal', { show, text }, state)
    /* return {
      ...state,
      modal: { show, text }
    } */
  },
  [ActionTypes.SET_FILTERS_FROM_SELECTED_CONN]: (state, action) => {
    const { network, management, highway, _id } = action.payload
    return {
      ...state,
      filters: {
        ...state.filters,
        network,
        management,
        highway,
        connectionCode: _id,
        connectionName: _id
      }
    }
  },
  [ActionTypes.SET_FILTERS]: (state, action) => {
    const { filters } = action.payload
    return {
      ...state,
      filters
    }
  },
  [ActionTypes.LOCATION_ADDED]: (state, action) => ({
    ...state,
    favLocations: [...state.favLocations, action.payload]
  }),
  [ActionTypes.LOCATION_REMOVED]: (state, action) => ({
    ...state,
    favLocations: state.favLocations.filter(x => x._id !== action.payload.id)
  })
})
