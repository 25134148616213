import * as ActionTypes from './actionTypes'
import * as API from './api'
import { dateToString } from '../../core/autoform/formFieldDataFromDB'
import { isISODateString } from '../../core/dates/index'

export const setCreating = value => ({
  type: ActionTypes.SET_CREATING,
  payload: value
})

export const setCECOToEdit = value => ({
  type: ActionTypes.SET_EDITING,
  payload: value
})

export const fetchCECOsRequest = () => ({
  type: ActionTypes.FETCH_CECOS_REQUEST
})

export const fetchCECOsSuccess = cecos => ({
  type: ActionTypes.FETCH_CECOS_SUCCESS,
  payload: cecos
})

export const fetchCECOsFailed = () => ({
  type: ActionTypes.FETCH_CECOS_FAILED
})

export const setCurrentPage = currentPage => ({
  type: ActionTypes.SET_CURRENT_PAGE,
  currentPage
})

export const setSearchValue = searchValue => ({
  type: ActionTypes.SET_SEARCH_VALUE,
  searchValue
})

export const setModalValues = (show, text) => ({
  type: ActionTypes.SET_MODAL_VALUES,
  payload: {
    show,
    text
  }
})

const formatCECOs = cecos => {
  const formattedCECOs = cecos.map(ceco => {
    if (isISODateString(ceco.validoDesde))
      ceco.validoDesde = dateToString(ceco.validoDesde)
    if (isISODateString(ceco.validoHasta))
      ceco.validoHasta = dateToString(ceco.validoHasta)

    return ceco
  })

  return formattedCECOs
}

export const fetchCECOs = () => {
  return async function(dispatch) {
    dispatch(fetchCECOsRequest())
    try {
      const cecos = await API.fetchCECOs()
      dispatch(fetchCECOsSuccess(formatCECOs(cecos)))
      return cecos
    } catch (err) {
      console.warn('Error fetching CECOs', err)
      dispatch(fetchCECOsFailed())
      return null
    }
  }
}

export const deleteCECO = cecoId => {
  return async function(dispatch) {
    API.removeCECO(cecoId)
      .then(s => {
        dispatch(fetchCECOs())
        dispatch(setCECOToEdit(false))
        return true
      })
      .catch(err => {
        console.error('Error removing CECO', err)
        dispatch(setCECOToEdit(false))
        dispatch(
          setModalValues(true, 'No se pudo eliminar el ceco. ' + err.message)
        )
      })
  }
}
