import { propOr } from 'lodash/fp'
const fields = {
  // ID
  codigo: {
    name: 'codigo',
    label: 'Código',
    type: 'String'
  },
  codigoi3met: {
    name: 'codigoi3met',
    label: 'Código i3met',
    type: 'String'
  },
  concesionaria: {
    name: 'concesionaria',
    label: 'Concesionaria',
    type: 'String'
  },
  autopista: {
    name: 'autopista',
    label: 'Autopista',
    type: 'String'
  },
  eje: {
    name: 'eje',
    label: 'Eje',
    type: 'String'
  },
  sentido: {
    name: 'sentido',
    label: 'Sentido',
    type: 'String'
  },
  nombre: {
    name: 'nombre',
    label: 'Nombre',
    type: 'String'
  },
  pk: {
    name: 'pk',
    label: 'PK',
    type: 'Number'
  },
  pkInicial: {
    name: 'pkInicial',
    label: 'PK Inicial',
    type: 'Number'
  },
  pkFinal: {
    name: 'pkFinal',
    label: 'PK Final',
    type: 'Number'
  },
  lado: {
    name: 'lado',
    label: 'Lado',
    type: 'String'
  },
  distanciaEje: {
    name: 'distanciaEje',
    label: 'Distancia a eje (m)',
    type: 'Number'
  },
  X: {
    name: 'X',
    label: 'Longitud',
    type: 'Number'
  },
  Y: {
    name: 'Y',
    label: 'Latitud',
    type: 'Number'
  },
  ccaa: {
    name: 'ccaa',
    label: 'Comunidad Autónoma',
    type: 'String'
  },
  provincia: {
    name: 'provincia',
    label: 'Provincia',
    type: 'String'
  },
  municipio: {
    name: 'municipio',
    label: 'Municipio (BBDD Origen)',
    type: 'String'
  },
  municipioSelect: {
    name: 'municipioSelect',
    label: 'Municipio',
    type: 'String'
  },
  fechaAlta: {
    name: 'fechaAlta',
    label: 'Fecha de alta',
    type: 'Date'
  },
  fechaBaja: {
    name: 'fechaBaja',
    label: 'Fecha de baja',
    type: 'Date'
  },

  // DATA
  cronoConstruccion: {
    name: 'cronoConstruccion',
    label: 'Cronología (años) construcción',
    type: 'Number'
  },
  tipologiaGeneral: {
    name: 'tipologiaGeneral',
    label: 'Tipología general',
    type: 'String'
  },
  longitudTotal: {
    name: 'longitudTotal',
    label: 'Longitud total (m)',
    type: 'Number'
  },
  anchuraTotal: {
    name: 'anchuraTotal',
    label: 'Anchura total (m)',
    type: 'Number'
  },
  superficieTotal: {
    name: 'superficieTotal',
    label: 'Superficie total (m2)',
    type: 'Number'
  },
  numVanos: {
    name: 'numVanos',
    label: 'Número de vanos / tubos',
    type: 'Number'
  },
  longitud: {
    name: 'longitud',
    label: 'Longitud (m)',
    type: 'Number'
  },
  altura: {
    name: 'altura',
    label: 'Altura (m)',
    type: 'Number'
  },
  anchura: {
    name: 'anchura',
    label: 'Anchura (cm)',
    type: 'Number'
  },
  anchuraCm: {
    name: 'anchuraCm',
    label: 'Anchura (cm)',
    type: 'Number'
  },

  materialElemento: {
    name: 'materialElemento',
    label: 'Material del elemento',
    type: 'String'
  },
  pendiente: {
    name: 'pendiente',
    label: 'Pendiente (%)',
    type: 'Number'
  },
  diametro: {
    name: 'diametro',
    label: 'Diámetro (m)',
    type: 'Number'
  },
  descripcion: {
    name: 'descripcion',
    label: 'Descripción',
    type: 'String'
  },
  tipologiaElemento: {
    name: 'tipologiaElemento',
    label: 'Tipología de elemento',
    type: 'String'
  },
  codigoElemento: {
    name: 'codigoElemento',
    label: 'Código de elemento',
    type: 'String'
  },
  dimensionesPlanta: {
    name: 'dimensionesPlanta',
    label: 'Dimensiones en planta',
    type: 'String'
  },
  ubicacion: {
    name: 'ubicacion',
    label: 'Ubicación',
    type: 'String'
  },
  anoColocacion: {
    name: 'anoColocacion',
    label: 'Año de colocación',
    type: 'Number'
  },
  elementosProtegidos: {
    name: 'elementosProtegidos',
    label: 'Elementos protegidos',
    type: 'String'
  },
  fabricanteElemento: {
    name: 'fabricanteElemento',
    label: 'Fabricante del elemento',
    type: 'String'
  },
  nivelContencion: {
    name: 'nivelContencion',
    label: 'Nivel de contención',
    type: 'String'
  },
  soporteBarrera: {
    name: 'soporteBarrera',
    label: 'Soporte barrera',
    type: 'String'
  },
  tipoBarrera: {
    name: 'tipoBarrera',
    label: 'Tipo de barrera',
    type: 'String'
  },
  tipoPretil: {
    name: 'tipoPretil',
    label: 'Tipo Pretil',
    type: 'String'
  },
  tipoPantalla: {
    name: 'tipoPantalla',
    label: 'Tipo pantalla',
    type: 'String'
  },
  tipoSoporte: {
    name: 'tipoSoporte',
    label: 'Tipo de Soporte',
    type: 'String'
  },
  cimentacion: {
    name: 'cimentacion',
    label: 'Cimentación',
    type: 'String'
  },
  equipo: {
    name: 'equipo',
    label: 'Equipo',
    type: 'String'
  },
  numEquipo: {
    name: 'numEquipo',
    label: 'Número de equipo',
    type: 'String'
  },
  emplazamiento: {
    name: 'emplazamiento',
    label: 'Emplazamiento',
    type: 'String'
  },
  ubicacionTecnica: {
    name: 'ubicacionTecnica',
    label: 'Ubicación técnica',
    type: 'String'
  },
  denominacion: {
    name: 'denominacion',
    label: 'Denominación',
    type: 'String'
  },
  sistema: {
    name: 'sistema',
    label: 'Sistema',
    type: 'String'
  },
  numViasEntrada: {
    name: 'numViasEntrada',
    label: 'Nº de vías de entrada',
    type: 'Number'
  },
  numViasSalida: {
    name: 'numViasSalida',
    label: 'Nº de vías de salida',
    type: 'Number'
  },
  numViasReversibles: {
    name: 'numViasReversibles',
    label: 'Nº de vías reversibles',
    type: 'Number'
  },
  ancho: {
    name: 'ancho',
    label: 'Ancho (m)',
    type: 'Number'
  },
  anchoVias: {
    name: 'anchoVias',
    label: 'Ancho de vías (m)',
    type: 'Number'
  },
  anchoViasMixtas: {
    name: 'anchoViasMixtas',
    label: 'Ancho de vías mixtas (m)',
    type: 'Number'
  },
  discontinuidad: {
    name: 'discontinuidad',
    label: 'Discontinuidad',
    type: 'String'
  },
  discontinuidadExterior: {
    name: 'discontinuidadExterior',
    label: 'Discontinuidad Exterior',
    type: 'String'
  },
  discontinuidadInterior: {
    name: 'discontinuidadInterior',
    label: 'Discontinuidad Interior',
    type: 'String'
  },
  discontinuidadEje: {
    name: 'discontinuidadEje',
    label: 'Discontinuidad Eje',
    type: 'String'
  },
  tipoPintura: {
    name: 'tipoPintura',
    label: 'Tipo pintura',
    type: 'String'
  },
  resalte: {
    name: 'resalte',
    label: 'Resalte',
    type: 'String'
  },
  fechaRepintadoExterior: {
    name: 'fechaRepintadoExterior',
    label: 'Fecha repintado exterior',
    type: 'Date'
  },
  fechaRepintadoInterior: {
    name: 'fechaRepintadoInterior',
    label: 'Fecha repintado interior',
    type: 'Date'
  },
  fechaRepintadoEje: {
    name: 'fechaRepintadoEje',
    label: 'Fecha repintado eje',
    type: 'Date'
  },
  superficie: {
    name: 'superficie',
    label: 'Superficie (m2)',
    type: 'Number'
  },
  grupoSenalizacion: {
    name: 'grupoSenalizacion',
    label: 'Grupo de señalización',
    type: 'String'
  },
  codigoSenal: {
    name: 'codigoSenal',
    label: 'Código de Señal',
    type: 'String'
  },
  formaSenal: {
    name: 'formaSenal',
    label: 'Forma de Señal',
    type: 'String'
  },
  tipoSenal: {
    name: 'tipoSenal',
    label: 'Tipo de Señal',
    type: 'String'
  },
  dimensiones: {
    name: 'dimensiones',
    label: 'Dimensiones',
    type: 'String'
  },
  leyenda: {
    name: 'leyenda',
    label: 'Leyenda',
    type: 'String'
  },
  soporte: {
    name: 'soporte',
    label: 'Soporte',
    type: 'String'
  },
  reflectancia: {
    name: 'reflectancia',
    label: 'Reflectancia',
    type: 'String'
  },
  geometria: {
    name: 'geometria',
    label: 'Geometría',
    type: 'String'
  },
  superficieSeccionTunel: {
    name: 'superficieSeccionTunel',
    label: 'Superficie sección del túnel',
    type: 'Number'
  },
  tipoEquipo: {
    name: 'tipoEquipo',
    label: 'Tipo de equipo',
    type: 'String'
  },
  tipoObjeto: {
    name: 'tipoObjeto',
    label: 'Tipo de objeto',
    type: 'String'
  },
  elementoPEP: {
    name: 'elementoPEP',
    label: 'Elemento PEP',
    type: 'String'
  },
  cuadroMandoReferencia: {
    name: 'cuadroMandoReferencia',
    label: 'Cuadro de mando / Punto de suministro',
    type: 'String'
  },
  tipoSoporteAlumbrado: {
    name: 'tipoSoporteAlumbrado',
    label: 'Tipo de soporte',
    type: 'String'
  },
  elementoSustentado: {
    name: 'elementoSustentado',
    label: 'Elemento sustentado',
    type: 'String'
  },

  // longitudCm: {
  //   name: 'longitudCm',
  //   label: 'Longitud (cm)',
  //   type: 'Number'
  // },

  // alturaMm: {
  //   name: 'alturaMm',
  //   label: 'Altura (mm)',
  //   type: 'Number'
  // },
  // anchuraMm: {
  //   name: 'anchuraMm',
  //   label: 'Anchura (mm)',
  //   type: 'Number'
  // },

  // AUSCULTATIONS
  ultimInspeccionPpal: {
    name: 'ultimInspeccionPpal',
    label: 'Ultima inspección principal',
    type: 'Date'
  },
  califUltimInspeccionPpal: {
    name: 'califUltimInspeccionPpal',
    label: 'Calificación última inspección principal',
    type: 'Number'
  },
  ultimInspeccionBasica: {
    name: 'ultimInspeccionBasica',
    label: 'Ultima inspección básica',
    type: 'Date'
  }
}

export const ORDERED_FIELDS = [
  // ID
  fields.codigo.name,
  fields.codigoi3met.name,
  fields.concesionaria.name,
  fields.autopista.name,
  fields.eje.name,
  fields.sentido.name,
  fields.nombre.name,
  fields.pk.name,
  fields.pkInicial.name,
  fields.pkFinal.name,
  fields.lado.name,
  fields.distanciaEje.name,
  fields.X.name,
  fields.Y.name,
  fields.ccaa.name,
  fields.provincia.name,
  fields.municipio.name,
  fields.municipioSelect.name,
  fields.fechaAlta.name,
  fields.fechaBaja.name,

  // DATA
  fields.cronoConstruccion.name,
  fields.tipologiaGeneral.name,
  fields.longitudTotal.name,
  fields.anchuraTotal.name,
  fields.superficieTotal.name,
  fields.numVanos.name,
  fields.longitud.name,
  fields.altura.name,
  fields.anchura.name,
  fields.anchuraCm.name,

  fields.materialElemento.name,
  fields.pendiente.name,
  fields.diametro.name,
  fields.descripcion.name,
  fields.tipologiaElemento.name,
  fields.codigoElemento.name,
  fields.dimensionesPlanta.name,
  fields.ubicacion.name,
  fields.anoColocacion.name,
  fields.elementosProtegidos.name,
  fields.fabricanteElemento.name,
  fields.nivelContencion.name,
  fields.soporteBarrera.name,
  fields.tipoBarrera.name,
  fields.tipoPretil.name,
  fields.tipoPantalla.name,
  fields.tipoSoporte.name,
  fields.cimentacion.name,
  fields.equipo.name,
  fields.numEquipo.name,
  fields.emplazamiento.name,
  fields.ubicacionTecnica.name,
  fields.denominacion.name,
  fields.sistema.name,
  fields.numViasEntrada.name,
  fields.numViasSalida.name,
  fields.numViasReversibles.name,
  fields.ancho.name,
  fields.anchoVias.name,
  fields.anchoViasMixtas.name,
  fields.discontinuidad.name,
  fields.discontinuidadExterior.name,
  fields.discontinuidadInterior.name,
  fields.discontinuidadEje.name,
  fields.tipoPintura.name,
  fields.resalte.name,
  fields.fechaRepintadoExterior.name,
  fields.fechaRepintadoInterior.name,
  fields.fechaRepintadoEje.name,
  fields.superficie.name,
  fields.grupoSenalizacion.name,
  fields.codigoSenal.name,
  fields.formaSenal.name,
  fields.tipoSenal.name,
  fields.dimensiones.name,
  fields.leyenda.name,
  fields.soporte.name,
  fields.reflectancia.name,
  fields.geometria.name,
  fields.superficieSeccionTunel.name,

  // longitudCm.name,
  // alturaMm.name,
  // anchuraMm.name,

  // AUSCULTATIONS
  fields.ultimInspeccionPpal.name,
  fields.califUltimInspeccionPpal,
  fields.ultimInspeccionBasica
]

export const getAssetField = fieldName => fields[fieldName]
export const getAssetFieldType = fieldName =>
  propOr(null, [fieldName, 'type'], fields)
export const getAssetFieldLabel = fieldName =>
  propOr('', [fieldName, 'label'], fields)
