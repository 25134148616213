import {
  getPKFamily,
  getNumOFfield,
  getEntryOutputInpecStatusField,
  getClearingField,
  getMedUnidClearingFields,
  getCleaningField,
  getMedUnidCleaningFields,
  getEntryOutputValStatusField,
  getCommonDrenajeFields,
  getCommonInspectionFields,
  getCommonExecFields,
  getCommonValidationFields
} from './commonFields'

const drenajesTransversalesFields = [
  ...getPKFamily('drenajesTransversales'),
  ...getNumOFfield('drenajesTransversales'),
  ...getCommonDrenajeFields('drenajesTransversales'),
  ...getEntryOutputInpecStatusField('drenajesTransversales'),
  ...getCommonInspectionFields('drenajesTransversales'),
  ...getClearingField('drenajesTransversales'),
  ...getMedUnidClearingFields('drenajesTransversales'),
  ...getCleaningField('drenajesTransversales'),
  ...getMedUnidCleaningFields('drenajesTransversales'),
  ...getCommonExecFields('drenajesTransversales'),
  ...getEntryOutputValStatusField('drenajesTransversales'),
  ...getCommonValidationFields('drenajesTransversales')
]

export const getDrenajeTransversalFields = () => drenajesTransversalesFields
