const MODULE = 'CREATE_ASSET'

// toggles menu open on/off
export const SET_MENU_OPEN = `${MODULE}/SET_MENU_OPEN`
// toggles create mode that allows drawing in the map a specific asset type
export const SET_CREATE_MODE = `${MODULE}/SET_CREATE_MODE`
// store new created geometry
export const STORE_GEOMETRY = `${MODULE}/STORE_GEOMETRY`

export const END_CREATION = `${MODULE}/END_CREATION`

// save asset in backend request, success, failed
export const SAVE_ASSET_REQUEST = `${MODULE}/SAVE_ASSET_REQUEST`
export const SAVE_ASSET_SUCCESS = `${MODULE}/SAVE_ASSET_SUCCESS`
export const SAVE_ASSET_FAILED = `${MODULE}/SAVE_ASSET_FAILED`

export const UPDATE_ASSET_REQUEST = `${MODULE}/UPDATE_ASSET_REQUEST`
export const UPDATE_ASSET_SUCCESS = `${MODULE}/UPDATE_ASSET_SUCCESS`
export const UPDATE_ASSET_FAILED = `${MODULE}/UPDATE_ASSET_FAILED`

// save ordinary operation in backend request, success, failed
export const SAVE_ORDINARY_OPERATION_REQUEST = `${MODULE}/SAVE_ORDINARY_OPERATION_REQUEST`
export const SAVE_ORDINARY_OPERATION_SUCCESS = `${MODULE}/SAVE_ORDINARY_OPERATION_SUCCESS`
export const SAVE_ORDINARY_OPERATION_FAILED = `${MODULE}/SAVE_ORDINARY_OPERATION_FAILED`
export const REMOVE_ORDINARY_OPERATION_ERROR = `${MODULE}/REMOVE_ORDINARY_OPERATION_ERROR`

export const UPDATE_ORDINARY_OPERATION_REQUEST = `${MODULE}/UPDATE_ORDINARY_OPERATION_REQUEST`
export const UPDATE_ORDINARY_OPERATION_SUCCESS = `${MODULE}/UPDATE_ORDINARY_OPERATION_SUCCESS`
export const UPDATE_ORDINARY_OPERATION_FAILED = `${MODULE}/UPDATE_ORDINARY_OPERATION_FAILED`

// save extraordinary operation in backend request, success, failed
export const SAVE_EXTRAORDINARY_OPERATION_REQUEST = `${MODULE}/SAVE_EXTRAORDINARY_OPERATION_REQUEST`
export const SAVE_EXTRAORDINARY_OPERATION_SUCCESS = `${MODULE}/SAVE_EXTRAORDINARY_OPERATION_SUCCESS`
export const SAVE_EXTRAORDINARY_OPERATION_FAILED = `${MODULE}/SAVE_EXTRAORDINARY_OPERATION_FAILED`
export const REMOVE_EXTRAORDINARY_OPERATION_ERROR = `${MODULE}/REMOVE_EXTRAORDINARY_OPERATION_ERROR`

export const UPDATE_EXTRAORDINARY_OPERATION_REQUEST = `${MODULE}/UPDATE_EXTRAORDINARY_OPERATION_REQUEST`
export const UPDATE_EXTRAORDINARY_OPERATION_SUCCESS = `${MODULE}/UPDATE_EXTRAORDINARY_OPERATION_SUCCESS`
export const UPDATE_EXTRAORDINARY_OPERATION_FAILED = `${MODULE}/UPDATE_EXTRAORDINARY_OPERATION_FAILED`

// save work operation in backend request, success, failed
export const SAVE_WORK_OPERATION_REQUEST = `${MODULE}/SAVE_WORK_OPERATION_REQUEST`
export const SAVE_WORK_OPERATION_SUCCESS = `${MODULE}/SAVE_WORK_OPERATION_SUCCESS`
export const SAVE_WORK_OPERATION_FAILED = `${MODULE}/SAVE_WORK_OPERATION_FAILED`
export const REMOVE_WORK_OPERATION_ERROR = `${MODULE}/REMOVE_WORK_OPERATION_ERROR`

export const UPDATE_WORK_OPERATION_REQUEST = `${MODULE}/UPDATE_WORK_OPERATION_REQUEST`
export const UPDATE_WORK_OPERATION_SUCCESS = `${MODULE}/UPDATE_WORK_OPERATION_SUCCESS`
export const UPDATE_WORK_OPERATION_FAILED = `${MODULE}/UPDATE_WORK_OPERATION_FAILED`

// create work report directly from an asset shortcut request, success, failed
export const CREATE_WORK_REPORT_FROM_ASSET_REQUEST = `${MODULE}/CREATE_WORK_REPORT_FROM_ASSET_REQUEST`
export const CREATE_WORK_REPORT_FROM_ASSET_SUCCESS = `${MODULE}/CREATE_WORK_REPORT_FROM_ASSET_SUCCESS`
export const CREATE_WORK_REPORT_FROM_ASSET_FAILED = `${MODULE}/CREATE_WORK_REPORT_FROM_ASSET_FAILED`
export const CREATE_WORK_REPORT_FROM_ASSET_SET_OPTIONS = `${MODULE}/CREATE_WORK_REPORT_FROM_ASSET_SET_OPTIONS`
export const CREATE_WORK_REPORT_FROM_ASSET_ACKNOWLEDGEMENT = `${MODULE}/CREATE_WORK_REPORT_FROM_ASSET_ACKNOWLEDGEMENT`
