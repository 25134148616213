import db from 'localforage'
const cacheName = 'sga'

db.config({
  driver: db.INDEXEDDB,
  name: cacheName
})

export const Expiration = {
  OneHour: 60 * 60 * 1000,
  OneDay: 24 * 60 * 60 * 1000,
  OneWeek: 7 * 24 * 60 * 60 * 1000,
  OneMonth: 30 * 7 * 24 * 60 * 60 * 1000
}

const isItemFresh = ({ ts }) => ts >= Date.now()

export async function getFromCache(key) {
  if (!key) {
    throw new Error('getFromCache requires a key (String)')
  }
  const item = await db.getItem(key)
  if (item && isItemFresh(item)) {
    return item.data
  }
  return null
}

export async function saveInCache(key, data, maxAge = Expiration.OneDay) {
  if (!key) {
    throw new Error('saveInCache requires key, data, and optional expiration')
  }
  const item = await db.setItem(key, {
    data: data,
    ts: Date.now() + maxAge
  })

  return item
}

export async function removeFromCache(key) {
  if (!key) {
    throw new Error('removeFromCache requires key')
  }
  await db.removeItem(key)
  return true
}
