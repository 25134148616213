import {
  getPKFamily,
  getAppearanceField,
  getClearStartEndPKFields,
  getMedUnidClearingFields,
  getCleanStartEndPKFields,
  getMedUnidCleaningFields,
  getEntryValStatusField,
  getCommonDrenajeFields,
  getCommonInspectionFields,
  getCommonExecFields,
  getCommonValidationFields
} from './commonFields'

const drenajesSuperficialesFields = [
  ...getPKFamily('drenajesSuperficiales'),
  ...getCommonDrenajeFields('drenajesSuperficiales'),
  ...getAppearanceField('drenajesSuperficiales'),
  ...getCommonInspectionFields('drenajesSuperficiales'),
  ...getClearStartEndPKFields('drenajesSuperficiales'),
  ...getMedUnidClearingFields('drenajesSuperficiales'),
  ...getCleanStartEndPKFields('drenajesSuperficiales'),
  ...getMedUnidCleaningFields('drenajesSuperficiales'),
  ...getCommonExecFields('drenajesSuperficiales'),
  ...getEntryValStatusField('drenajesSuperficiales'),
  ...getCommonValidationFields('drenajesSuperficiales')
]

export const getDrenajeSuperficialFields = () => drenajesSuperficialesFields
