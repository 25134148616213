import * as drenajeTransversalFormFields from './drenajeTransversalFormFields'
import * as drenajeSuperficialFormFields from './drenajeSuperficialFormFields'
import * as drenajeProfundoFormFields from './drenajeProfundoFormFields'
import * as intervencionesVallaCierreFormFields from './intervencionesVallaCierreFormFields'
import * as limpiezasDesbrocesODsFormFields from './limpiezasDesbrocesODsFormFields'
import * as jardineriasTroncoFormFields from './jardineriasTroncoFormFields'
import * as jardineriasEnlacesFormFields from './jardineriasEnlacesFormFields'
import * as desbrocesTaludesFormFields from './desbrocesTaludesFormFields'
import * as limpiezasPistaFormFields from './limpiezasPistaFormFields'
import * as reparacionesFirmesEstructurasFormFields from './reparacionesFirmesEstructurasFormFields'
import * as elementosSeñalizacionBalizamientoFormFields from './elementosSeñalizacionBalizamientoFormFields'
import * as elementosContencionFormFields from './elementosContencionFormFields'
import * as alumbradoFormFields from './alumbradoFormFields'

export const FAMILY_TYPES = {
  DRENAJES_TRANSVERSALES: 'drenajesTransversales',
  DRENAJES_SUPERFICIALES: 'drenajesSuperficiales',
  DRENAJES_PROFUNDOS: 'drenajesProfundos',
  INTERV_VALLA_CIERRE: 'intervencionesVallaCierre',
  LIMPIEZAS_DESBROCES_ODS: 'limpiezasDesbrocesODs',
  JARDINERIAS_TRONCO: 'jardineriasTronco',
  JARDINERIAS_ENLACE: 'jardineriasEnlaces',
  DESBROCES_TALUDES: 'desbrocesTaludes',
  LIMPIEZAS_PISTA: 'limpiezasPista',
  REPARAC_FIRMES_ESTRUCTURAS: 'reparacionesFirmesEstructuras',
  ELEM_SENALIZ_BALIZAMIENTO: 'elementosSeñalizacionBalizamiento',
  ELEM_CONTENCION: 'elementosContencion',
  ALUMBRADO: 'alumbrado'
}
export const FAMILIES = {
  [FAMILY_TYPES.DRENAJES_TRANSVERSALES]: {
    id: 'drenaje-transversal',
    label: 'Drenaje transversal',
    labelSingular: 'drenaje transversal',
    labelPlural: 'drenajes transversales',
    formFields: drenajeTransversalFormFields
  },

  [FAMILY_TYPES.DRENAJES_SUPERFICIALES]: {
    id: 'drenaje-superficial',
    label: 'Drenaje superficial',
    labelSingular: 'drenaje superficial',
    labelPlural: 'drenajes superficiales',
    formFields: drenajeSuperficialFormFields
  },

  [FAMILY_TYPES.DRENAJES_PROFUNDOS]: {
    id: 'drenaje-profundo',
    label: 'Drenaje profundo',
    labelSingular: 'drenaje profundo',
    labelPlural: 'drenajes profundos',
    formFields: drenajeProfundoFormFields
  },

  [FAMILY_TYPES.INTERV_VALLA_CIERRE]: {
    id: 'valla-cierre',
    label: 'Valla cierre',
    labelSingular: 'intervención valla cierre',
    labelPlural: 'intervenciones valla cierre',
    formFields: intervencionesVallaCierreFormFields
  },

  [FAMILY_TYPES.LIMPIEZAS_DESBROCES_ODS]: {
    id: 'limpieza-desbroce-ods',
    label: 'Limpieza y desbroce O.D.s',
    labelSingular: 'limpieza y desbroce O.D.s',
    labelPlural: 'limpiezas y desbroces O.D.s',
    formFields: limpiezasDesbrocesODsFormFields
  },

  [FAMILY_TYPES.JARDINERIAS_TRONCO]: {
    id: 'jardineria-tronco',
    label: 'Jardinería en tronco',
    labelSingular: 'jardinería en tronco',
    labelPlural: 'jardinerías en tronco',
    formFields: jardineriasTroncoFormFields
  },

  [FAMILY_TYPES.JARDINERIAS_ENLACE]: {
    id: 'jardineria-enlaces',
    label: 'Jardinería en enlaces',
    labelSingular: 'jardinería en enlaces',
    labelPlural: 'jardinerías en enlaces',
    formFields: jardineriasEnlacesFormFields
  },

  [FAMILY_TYPES.DESBROCES_TALUDES]: {
    id: 'desbroce-taludes',
    label: 'Desbroce en taludes',
    labelSingular: 'desbroce en taludes',
    labelPlural: 'desbroces en taludes',
    formFields: desbrocesTaludesFormFields
  },

  [FAMILY_TYPES.LIMPIEZAS_PISTA]: {
    id: 'limpieza-pista',
    label: 'Limpieza de pista',
    labelSingular: 'limpieza de pista',
    labelPlural: 'limpiezas de pista',
    formFields: limpiezasPistaFormFields
  },

  [FAMILY_TYPES.REPARAC_FIRMES_ESTRUCTURAS]: {
    id: 'reparacion-firmes-estructuras',
    label: 'Rep. firmes y estruct.',
    labelSingular: 'rep. firmes y estruct.',
    labelPlural: 'rep. firmes y estruct.',
    formFields: reparacionesFirmesEstructurasFormFields
  },

  [FAMILY_TYPES.ELEM_SENALIZ_BALIZAMIENTO]: {
    id: 'elementos-senalizacion-balizamiento',
    label: 'Elementos señalización y balizamiento',
    labelSingular: 'elementos señalización y balizamiento',
    labelPlural: 'elementos señalización y balizamiento',
    formFields: elementosSeñalizacionBalizamientoFormFields
  },

  [FAMILY_TYPES.ELEM_CONTENCION]: {
    id: 'elementos-contencion',
    label: 'Elementos contención',
    labelSingular: 'elementos contención',
    labelPlural: 'elementos contención',
    formFields: elementosContencionFormFields
  },

  [FAMILY_TYPES.ALUMBRADO]: {
    id: 'alumbrado',
    label: 'Alumbrado',
    labelSingular: 'alumbrado',
    labelPlural: 'alumbrado',
    formFields: alumbradoFormFields
  }
}

export default FAMILIES

export const getFamilyTypeByLabel = label =>
  Object.keys(FAMILIES).filter(family => FAMILIES[family].label === label)
