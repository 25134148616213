import dayjs from 'dayjs'
import moment from 'moment'
import { isUndefined } from 'util'

export const isISODateString = dateString =>
  dateString && Boolean(~[...`${dateString}`].indexOf('T'))

export function dayJSFromString(date = '') {
  if (date === null) date = ''
  if (isISODateString(date)) {
    return dayjs(date)
  }
  const [day, month, year] = date.split('/')
  if (!day || !month || !year) {
    //console.warn('Unknown date format', typeof date, date)
    return date
  }
  return dayjs()
    .set('date', Number(day))
    .set('month', Number(month) - 1)
    .set('year', Number(year))
}

export function parseDateToForInput(date = '', formatString = 'YYYY-MM-DD') {
  if (date === '') return ''
  if (typeof date === 'number') return dayjs(date).format(formatString)
  //TODO: this works in Chrome, test in other browsers
  const parsedDate = dayJSFromString(date)
  return typeof parsedDate === 'object'
    ? parsedDate.format(formatString)
    : parsedDate
}

export const matchDateDDMMYYYY = str =>
  str && (str.match(/^(\d{4})-(\d{2})-(\d{2})/) || str.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/))

export const formatIfIsDate = (value = '') => {
  if (isISODateString(value)) {
    const fieldParsedToDate = parseDateToForInput(value, 'DD/MM/YYYY')
    return matchDateDDMMYYYY(fieldParsedToDate) ? fieldParsedToDate : value
  } else return value.toString()
}

export const getNowDateDDMMYYYY = () =>
  new Date().toLocaleDateString('es-ES').replace(/\//g, '-')

export const dateToLocale = (date, fallbackText, format, locale = 'es') => {
  if (isUndefined(date)) {
    return fallbackText
  }

  const parsed = moment.utc(date)

  if (!parsed.isValid()) {
    return fallbackText
  }

  return parsed.locale(locale).format(format)
}

export const stringDateToLocale = (
  stringDate,
  fallbackText,
  format,
  locale = 'es'
) => {
  const parsed = moment.utc(stringDate, 'DD/MM/YYYY', true)

  if (!parsed.isValid()) {
    return fallbackText
  }

  return parsed.locale(locale).format(format)
}

export const dateToLocaleShort = (date, fallbackText, locale = 'es') =>
  dateToLocale(date, fallbackText, 'L', locale)

export const stringDateToLocaleShort = (
  stringDate,
  fallbackText,
  locale = 'es'
) => stringDateToLocale(stringDate, fallbackText, 'L', locale)

export const dateToLocaleLong = (date, fallbackText, locale = 'es') =>
  dateToLocale(date, fallbackText, 'LL', locale)

export const stringDateToLocaleLong = (
  stringDate,
  fallbackText,
  locale = 'es'
) => stringDateToLocale(stringDate, fallbackText, 'LL', locale)

/**
 * Format input month year date format: YYYY/MM -> MM/YYYY
 * @param {String} date
 */
export const formatMonthYear = date => {
  const year = date.split('/')[1]
  const month = date.split('/')[0]
  return `${year}/${month}`
}

export const matchDateYYYYMM = str => str && str.match(/^(\d{4})\/(\d{2})$/)
