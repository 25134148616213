const getXYFromPointCoords = points => {
  return {
    X: points[0],
    Y: points[1]
  }
}

export function extractXYFromGeometry(geometry) {
  switch (geometry.type) {
    case 'LineString':
      // get X,Y from first line
      return getXYFromPointCoords(geometry.coordinates[0])
    case 'Polygon':
      // get X,Y from first line in first polygon (could have holes)
      return getXYFromPointCoords(geometry.coordinates[0][0])
    default:
      // Point
      return getXYFromPointCoords(geometry.coordinates)
  }
}
