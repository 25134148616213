import * as Sentry from '@sentry/browser'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { unregister } from './registerServiceWorker'
import './styles/styles.scss'
import './styles/print.scss'
import configureStore from './configureStore'
import app from './feathers'
import * as Session from './modules/session'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN
})

const store = configureStore()
const root = document.getElementById('root')
// give Feather hooks access to Redux store
app.set('__reduxStore', store)

if (process.env.NODE_ENV === 'development') {
  window.app = app
  window.store = store
}

const renderApp = Component =>
  ReactDOM.render(<Component store={store} />, root)

store.dispatch(Session.tryAuthenticate()).then(isAuthenticated => {
  if (!isAuthenticated) {
    // Not standard SAML, can't open Autopistas SAML in iframe, simply redirect to their site
    Session.redirectToLogin()
  } else {
    renderApp(App)
  }
})

//ReactDOM.render(<App store={store} />, root)
//registerServiceWorker()
unregister()

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    renderApp(NextApp)
  })
}
