import React from 'react'
import Cube from './Cube'

const fullStyles = {
  width: '100%',
  height: '100%',
  margin: '0 auto',
  marginTop: '40%'
}

const FullPageCube = () => (
  <div style={fullStyles}>
    <Cube />
  </div>
)

export default FullPageCube
