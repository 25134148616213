import { buildReducer } from '../../utils'
import * as ActionTypes from './actionTypes'

const initialState = {
  currentView: ActionTypes.SCREEN.EDITOR
}

export default buildReducer(initialState, {
  [ActionTypes.SCREEN_CHANGED]: (state, action) => {
    return {
      ...state,
      currentView: action.payload
    }
  }
})
