import * as ActionTypes from './actionTypes'
import TYPES from '../../core/assets/assetTypes'
import app from '../../feathers'

export function fetchSpecificAssetRequest(assetType) {
  return {
    type: ActionTypes.FETCH_REQUEST,
    assetType
  }
}

export function fetchSpecificAssetSuccess(assetType, assets) {
  return {
    type: ActionTypes.FETCH_SUCCESS,
    payload: {
      assets,
      assetType
    }
  }
}

export function clearSpecificAssetList(assetType) {
  return {
    type: ActionTypes.CLEAR_LIST,
    assetType
  }
}

export function clearFilters(assetType) {
  return {
    type: ActionTypes.CLEAR_FILTERS,
    assetType
  }
}

export function fetchSpecificAssetFailed(assetType, error) {
  return {
    type: ActionTypes.FETCH_FAILED,
    assetType,
    error: error.message || error.toString()
  }
}

async function fetchAssetsByAssetType(assetType) {
  switch (assetType) {
    case TYPES.CONSERVACION_ORDINARIA:
      // Fetch ordinary operations
      return app.service('/operations/ordinary').find({
        query: {
          $select: [
            'registroGeneral.claveProyecto',
            'registroGeneral.autopistas',
            'parteTrabajos.pkInicio',
            'parteTrabajos.pkFin'
          ],
          $sort: { createdAt: -1, 'registoGeneral.claveProyecto': 1 },
          isDeleted: { $ne: true }
        }
      })

    case TYPES.CONSERVACION_EXTRAORDINARIA:
      // Fetch extraordinary operations
      return app.service('/operations/extraordinary').find({
        query: {
          $select: [
            'registroGeneral.claveProyecto',
            'registroGeneral.autopistas',
            'seguimientoObra.activos.pk'
          ],
          $sort: { createdAt: -1, 'registoGeneral.claveProyecto': 1 },
          isDeleted: { $ne: true }
        }
      })

    case TYPES.OBRAS:
      // Fetch work operations
      return app.service('/operations/work').find({
        query: {
          $select: [
            'registroGeneral.claveProyecto',
            'registroGeneral.ubicacion.autopistas',
            'registroGeneral.ubicacion.pkInicio'
          ],
          $sort: { createdAt: -1, 'registoGeneral.claveProyecto': 1 },
          isDeleted: { $ne: true }
        }
      })

    default:
      // Fetch normal asset data
      return app.service('/assets').find({
        query: {
          $select: [
            'codigo',
            'pkInicial',
            'pkFinal',
            'autopista',
            'denominacion'
          ],
          $sort: { codigo: 1 },
          isDeleted: { $ne: true },
          assetType: assetType
        }
      })
  }
}

export function fetchCodesByAssetType(assetType) {
  return async function(dispatch, getState) {
    dispatch(fetchSpecificAssetRequest(assetType))
    try {
      const assets = await fetchAssetsByAssetType(assetType)
      dispatch(fetchSpecificAssetSuccess(assetType, assets))
    } catch (err) {
      dispatch(fetchSpecificAssetFailed(assetType, err))
    }
  }
}

export function setFilterValue(field, value) {
  return {
    type: ActionTypes.SET_FILTER_VALUE,
    payload: {
      field,
      value
    }
  }
}

// used in tests!
export const storeAssetList = assetsList => ({
  type: ActionTypes.STORE_ASSET_LIST,
  payload: assetsList
})
