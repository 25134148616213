import feathers from '../../feathers'

export const fetchCECOs = (app = feathers) =>
  app.service('cecos').find({ query: { $sort: { codigo: 1 } } })

export const updateCECO = (id, cecoData, app = feathers) =>
  app.service('cecos').patch(id, cecoData)

export const createCECO = (cecoData, app = feathers) =>
  app.service('cecos').create(cecoData)

export const removeCECO = (id, app = feathers) =>
  app.service('cecos').remove(id)

export async function fetchCecoByCode(code, app = feathers) {
  return app.service('cecos').find({ query: { codigo: code } })
}
