export const SignalingGroups = {
  Balizamiento: 'Balizamiento',
  Fluorescentes: 'Fluorescentes',
  Genericas: 'Genéricas',
  Iluminadas: 'Iluminadas',
  Peligro: 'Advertencia de Peligro',
  ReglamentacionPrioridad: 'Reglamentación y Prioridad',
  ProhibicionEntrada: 'Prohibición de Entrada',
  RestriccionDePaso: 'Restricción de Paso',
  OtrasSenalesProhibicionRestriccion:
    'Otras Señales de Prohibición o Restricción',
  Obligacion: 'Obligación',
  FinProhibicionRestriccion: 'Fin de Prohibición o Restricción',
  IndicacionesGenerales: 'Indicaciones Generales',
  Carriles: 'Carriles',
  Servicios: 'Servicios',
  IdentificacionCarreteras: 'Identificación de Carreteras',
  OrientacionPresenalizacion: 'Orientación Preseñalización',
  OrientacionDireccion: 'Orientación Dirección',
  OrientacionLocalizacion: 'Orientación Localización',
  OrientacionConfirmacion: 'Orientación Confirmación',
  OrientacionPoblado: 'Uso específico en Poblado',
  PanelesComplementarios: 'Paneles Complementarios',
  Obras: 'Obras',
  Otras: 'Otras Señales'
}

export const FormSignal = {
  Circulo: 'Círculo',
  Cuadrado: 'Cuadrado',
  Octogono: 'Octógono',
  Rectangulo: 'Rectángulo',
  Triangulo: 'Triángulo',
  Rombo: 'Rombo',
  Panel: 'Panel'
}
