import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'
import { isUserAdmin } from '../session'
import { toggleNavigation, isNavOpen } from './index'
import * as AppRoutes from '../../core/routes'

const withNavMenu = connect(
  state => ({
    isMenuOpen: isNavOpen(state),
    isUserAdmin: isUserAdmin(state)
  }),
  {
    onMenuToggle: toggleNavigation
  }
)

const createMenuItemsForUser = withPropsOnChange(['isUserAdmin'], props => {
  const items = [
    // HACK somwhere: first element is NOT DISPLAYED in Desktop
    //{ text: 'Mis búsquedas', url: '#', icon: 'search' },
    {
      text: 'Inicio',
      url: AppRoutes.ROOT,
      aliasURLs: [
        AppRoutes.makeFilterByType(),
        // HACK! We cannot build these URLs for no `id` (or it throws) so we set
        // a fake `id` and replace it, since we're only interested in the parent
        // path
        AppRoutes.makeAssetDetails('HACK').replace('HACK', ''),
        AppRoutes.makeAssetEdit('HACK').replace('HACK', ''),
        AppRoutes.makeOrdinaryOperationDetails('HACK').replace('HACK', ''),
        AppRoutes.makeOrdinaryOperationEdit('HACK').replace('HACK', ''),
        AppRoutes.makeExtraordinaryOperationDetails('HACK').replace('HACK', ''),
        AppRoutes.makeExtraordinaryOperationEdit('HACK').replace('HACK', ''),
        AppRoutes.makeWorkOperationDetails('HACK').replace('HACK', ''),
        AppRoutes.makeWorkOperationEdit('HACK').replace('HACK', '')
      ],
      icon: 'home'
    },
    { text: 'Consultas', url: AppRoutes.QUERIES, icon: 'data' },
    { text: 'Contratos', url: AppRoutes.DOCS, icon: 'contracts' }
  ]

  if (props.isUserAdmin) {
    items.push(
      /* { text: 'Ejes', url: '#', icon: 'road' },*/
      { text: 'Configuración', url: '/config/users', icon: 'configuration' }
    )
  }

  return { menuItems: items }
})

export default compose(withNavMenu, createMenuItemsForUser)
