import { parseDateToForInput } from '../../core/dates'

export function changeLabelsIfNotExitsFinalPK(asset) {
  if (!asset.pkFinal) {
    asset.pk = asset.pkInicial
  }

  if (!asset.utmFinalX) {
    asset.utmX = asset.utmInicialX
  }

  if (!asset.utmFinalY) {
    asset.utmY = asset.utmInicialY
  }

  return asset
}

export function changeCodeIfIsAnStructure(asset) {
  if (asset.source === 'i3met') asset.codigoi3met = asset.codigo
  return asset
}

export function niceDate(dateString) {
  if (dateString === null) return ''
  return parseDateToForInput(dateString, 'DD/MM/YYYY')
}

const dateFields = ['fechaAlta', 'fechaBaja', 'ultimInspeccionPpal', 'ultimInspeccionBasica']
export function beautifyDates(asset) {
  return dateFields.reduce((acc, dateField) => {
    if (acc[dateField]) {
      acc[dateField] = niceDate(acc[dateField])
    }
    return acc
  }, asset)
}
