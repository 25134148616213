import React, { lazy, Component, Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom'
import * as ROUTES from './core/routes'
// spinner for lazy loading
import FullPageCube from './ui/spinners//FullPageCube'
import AppLayout from './ui/views/AppLayout'
import withNavMenu from './modules/layout/withNavMenu'
import NotAuthorizedModal from './modules/session/components/NotAuthorizedModal'

const MapViewContainer = lazy(() => import('./modules/mapview/MapView'))
const AdminContainer = lazy(() => import('./modules/admin/AdminRouter'))
const QueryContainer = lazy(() =>
  import('./modules/queries/components/QueryView')
)
const DocumentsViewContainer = lazy(() =>
  import('./modules/documents/DocumentsView')
)
const AppLayoutWithMenu = withRouter(withNavMenu(AppLayout))

class App extends Component {
  render() {
    return (
      <Provider store={this.props.store}>
        <BrowserRouter>
          <AppLayoutWithMenu>
            <Suspense fallback={<FullPageCube />}>
              <Switch>
                <Route
                  path={ROUTES.QUERIES}
                  render={props => <QueryContainer {...props} />}
                />
                <Route
                  path={ROUTES.DOCS}
                  render={props => <DocumentsViewContainer {...props} />}
                />
                <Route
                  path='/config'
                  render={props => <AdminContainer {...props} />}
                />
                <Route
                  path={ROUTES.ROOT}
                  render={props => <MapViewContainer {...props} />}
                />
              </Switch>
            </Suspense>
            <NotAuthorizedModal />
          </AppLayoutWithMenu>
        </BrowserRouter>
      </Provider>
    )
  }
}

export default App
