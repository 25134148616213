import TYPES from './assetTypes'

const GEOJSON_TYPES = {
  Point: 'Point',
  LineString: 'LineString',
  Polygon: 'Polygon'
}

// only geometries for asset types created by SGA, not 3rd party applications like i3met
const GEOMETRIES = {
  [TYPES.ARQUETAS_POZOS]: GEOJSON_TYPES.Point,
  [TYPES.CUNETAS]: GEOJSON_TYPES.LineString,
  [TYPES.CAZ]: GEOJSON_TYPES.LineString,
  [TYPES.BORDILLOS]: GEOJSON_TYPES.LineString,
  [TYPES.BAJANTES]: GEOJSON_TYPES.Point,
  [TYPES.VALLA_CERRAMIENTO]: GEOJSON_TYPES.LineString,
  [TYPES.BARRERA_METALICA]: GEOJSON_TYPES.LineString,
  [TYPES.BARRERA_HORMIGON]: GEOJSON_TYPES.LineString,
  [TYPES.PRETIL]: GEOJSON_TYPES.LineString,
  [TYPES.PANTALLAS_ANTIRRUIDO]: GEOJSON_TYPES.LineString,
  [TYPES.SENALIZACION_HORIZONTAL]: GEOJSON_TYPES.LineString,
  [TYPES.SENALIZACION_VERTICAL]: GEOJSON_TYPES.Point,
  [TYPES.BALIZAMIENTO]: GEOJSON_TYPES.Point,
  [TYPES.PASOS_MEDIANA]: GEOJSON_TYPES.LineString,
  [TYPES.VIAS_PEAJES]: GEOJSON_TYPES.Polygon,
  [TYPES.FIRMES]: GEOJSON_TYPES.LineString,
  [TYPES.CAMARAS]: GEOJSON_TYPES.Point,
  [TYPES.ESTACION_METEO]: GEOJSON_TYPES.Point,
  [TYPES.PMV]: GEOJSON_TYPES.Point,
  [TYPES.POSTES_SOS]: GEOJSON_TYPES.Point,
  [TYPES.BACULOS]: GEOJSON_TYPES.Point,
}

export function getGeoJSONTypeForAssetType(assetType) {
  return GEOMETRIES[assetType] || null
}
