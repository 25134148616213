import * as ActionTypes from './actionTypes'
import app from '../../feathers'
import { getMapZoom, getMapBoundsGeoJSON } from '../mapview'
import {
  getSelectedAssetTypes,
  getAssetTypeGroup,
  isChecked,
  getDraggableOriginalGeometry,
  getDraggableAssetId,
  getDraggableUpdatedGeometry,
  getLastFetchBounds
} from './selectors'
import { MIN_ZOOM_LEVEL } from './constants'
import { extractXYFromGeometry } from '../../core/assets'
import booleanContains from '@turf/boolean-contains'
import { setMapCenter } from '../mapview/googleMapMiddleware'

// used in tests!
export const storeAssetTree = tree => ({
  type: ActionTypes.STORE_ASSET_TREE,
  payload: tree
})

// used in tests!
export const clearSelectedTypes = () => ({
  type: ActionTypes.CLEAR_SELECTED_TYPES
})

export const setCheckedTo = (itemIds, isChecked) => ({
  type: ActionTypes.SET_ITEM_CHECKED,
  payload: {
    itemIds,
    isChecked
  }
})

export const manageChecked = id => {
  return function(dispatch, getState) {
    const state = getState()
    const assetTypeGroup = getAssetTypeGroup(state, id)
    const isItemChecked = isChecked(state, id)
    const idsToCheck = assetTypeGroup || [id]
    dispatch(setCheckedTo(idsToCheck, !isItemChecked))
  }
}

export function storeAssets(data, bounds) {
  return {
    type: ActionTypes.FETCH_ASSETS_SUCCESS,
    payload: {
      data,
      bounds
    }
  }
}

export function addAsset(asset, entityType) {
  const assetData = {
    _id: asset._id,
    geometry: asset.geometry,
    codigo: asset.codigo,
    assetType: entityType || asset.assetType
  }
  return {
    type: ActionTypes.ASSET_ADDED,
    payload: assetData
  }
}

export function fetchRequest() {
  return {
    type: ActionTypes.FETCH_ASSETS_REQUEST
  }
}

export function fetchFailed(error) {
  return {
    type: ActionTypes.FETCH_ASSETS_FAILED,
    error: error.message || error.toString()
  }
}

let lastFetchedAssetTypes = []
export function fetchAssets(query = {}, force = false, feathers = app) {
  return async function(dispatch, getState) {
    const state = getState()
    // geojson
    const currentBounds = getMapBoundsGeoJSON(state)
    // geojson
    const lastFetchBounds = getLastFetchBounds(state)
    const currentZoomLevel = getMapZoom(state)
    const selectedAssetTypes = getSelectedAssetTypes(state)
    const isMovingAsset = Boolean(getDraggableAssetId(state))
    // avoid fetching if too zoom out, or nothing to fetch, or user is moving
    // a feature by dragging the map
    if (!force) {
      if (
        currentZoomLevel < MIN_ZOOM_LEVEL ||
        selectedAssetTypes.length === 0 ||
        isMovingAsset
      ) {
        return
      }
      // check if this new area is already fetched
      if (
        lastFetchBounds &&
        booleanContains(lastFetchBounds, currentBounds) &&
        lastFetchedAssetTypes === selectedAssetTypes
      ) {
        return
      }
    }
    dispatch(fetchRequest())
    try {
      const assets = await feathers.service('assets').find({
        query: {
          ...query,
          assetType: {
            $in: selectedAssetTypes
          },
          isDeleted: { $ne: true },
          geometry: currentBounds.geometry.coordinates
        }
      })
      // keep track of what we fetched last
      lastFetchedAssetTypes = selectedAssetTypes
      dispatch(storeAssets(assets, currentBounds))
    } catch (err) {
      console.warn('Error fetching assets', err)
      dispatch(fetchFailed(err))
    }
  }
}

export const removeAsset = assetId => ({
  type: ActionTypes.ASSET_REMOVED,
  assetId
})

export function deleteAsset(assetId) {
  return async function(dispatch) {
    try {
      const softDeletedAsset = await app.service('assets').remove(assetId)
      dispatch(removeAsset(softDeletedAsset._id))
      return softDeletedAsset
    } catch (error) {
      console.warn('Error deleting asset', error)
      return false
    }
  }
}

export function setAssetFocus(assetId) {
  return {
    type: ActionTypes.SET_ASSET_FOCUS,
    id: assetId || null
  }
}
export const beginAssetMove = asset => ({
  type: ActionTypes.ASSET_DRAG_START,
  payload: {
    assetId: asset._id,
    geometry: Object.assign({}, asset.geometry)
  }
})

export const updateMovingAssetGeometry = (assetId, newGeometry) => ({
  type: ActionTypes.ASSET_DRAG_UPDATE,
  payload: {
    assetId,
    geometry: newGeometry
  }
})

export const endAssetMove = (assetId, { newGeometry, X, Y }) => ({
  type: ActionTypes.ASSET_DRAG_END,
  payload: {
    assetId,
    newGeometry,
    X,
    Y
  }
})
export const confirmAssetMove = assetId => async (dispatch, getState) => {
  const state = getState()
  const draggableAssetId = getDraggableAssetId(state)

  if (draggableAssetId !== assetId) {
    return
  }

  try {
    const newGeometry = getDraggableUpdatedGeometry(state)
    const { X, Y } = extractXYFromGeometry(newGeometry)
    await app.service('assets').patch(assetId, {
      geometry: newGeometry,
      X,
      Y
    })
    dispatch(endAssetMove(assetId, { newGeometry, X, Y }))
    dispatch(setMapCenter({ lat: Y, lng: X }))
    return true
  } catch (err) {
    console.warn('Error updating asset geometry', err)
    //TODO: move this to a proper modal message
    alert(err.message || 'No se pudo desplazar el activo')
    dispatch(cancelAssetMove(assetId))
    return false
  }
}

export const cancelAssetMove = assetId => (dispatch, getState) => {
  const originalGeometry = getDraggableOriginalGeometry(getState())
  dispatch(endAssetMove(assetId, originalGeometry))
}
