import TYPES from './assetTypes'
import { assetTypeLabels } from './assetTypeLabels'

const assetOptions = {
  title: 'Activo',
  children: [
    {
      title: 'Drenaje',
      children: [
        {
          title: 'Arquetas y pozos',
          assetType: TYPES.ARQUETAS_POZOS
        },
        {
          title: 'Cunetas',
          assetType: TYPES.CUNETAS
        },
        {
          title: 'Caz',
          assetType: TYPES.CAZ
        },
        {
          title: 'Bordillos',
          assetType: TYPES.BORDILLOS
        },
        {
          title: 'Bajantes',
          assetType: TYPES.BAJANTES
        }
      ]
    },
    {
      title: 'Cerramiento',
      children: [
        {
          title: 'Valla de cerramiento',
          assetType: TYPES.VALLA_CERRAMIENTO
        }
      ]
    },
    {
      title: 'Contención',
      children: [
        {
          title: 'Barrera metálica',
          assetType: TYPES.BARRERA_METALICA
        },
        {
          title: 'Barrera de hormigón',
          assetType: TYPES.BARRERA_HORMIGON
        },
        {
          title: 'Pretil',
          assetType: TYPES.PRETIL
        }
      ]
    },
    {
      title: 'Pantalla antirruido',
      assetType: TYPES.PANTALLAS_ANTIRRUIDO
    },
    {
      title: 'Señalización horizontal',
      assetType: TYPES.SENALIZACION_HORIZONTAL
    },
    {
      title: 'Señalización vertical',
      assetType: TYPES.SENALIZACION_VERTICAL
    },
    {
      title: 'Balizamiento',
      assetType: TYPES.BALIZAMIENTO
    },
    {
      title: 'Pasos de mediana',
      assetType: TYPES.PASOS_MEDIANA
    },
    {
      title: 'Vías de peaje',
      assetType: TYPES.VIAS_PEAJES
    },
    {
      title: assetTypeLabels[TYPES.FIRMES],
      assetType: TYPES.FIRMES
    },
    {
      title: 'ITS',
      children: [
        {
          title: 'Cámaras',
          assetType: TYPES.CAMARAS
        },
        {
          title: 'Estaciones meteorológicas',
          assetType: TYPES.ESTACION_METEO
        },
        {
          title: 'Panel de Mensajería Variable',
          assetType: TYPES.PMV
        },
        {
          title: 'Poste SOS',
          assetType: TYPES.POSTES_SOS
        }
      ]
    },
    {
      title: 'Alumbrado',
      children: [
        {
          title: 'Báculos',
          assetType: TYPES.BACULOS
        }
      ]
    }
  ]
}

const operationOptions = {
  title: 'Actuación',
  children: [
    {
      title: 'Obra',
      assetType: TYPES.OBRAS
    },
    {
      title: 'Conservación Ordinaria',
      assetType: TYPES.CONSERVACION_ORDINARIA
    },
    {
      title: 'Conservación Extraordinaria',
      assetType: TYPES.CONSERVACION_EXTRAORDINARIA
    }
  ]
}

const defaultPermissions = {
  createAssets: true,
  createOrdinary: true,
  createExtraordinary: true,
  createWork: true
}
export function makeAssetCreatorOptions(permissions = defaultPermissions) {
  const options = []
  if (permissions.createAssets) {
    options.push(assetOptions)
  }
  const opOptions = Object.assign({}, operationOptions)
  //filter based on permissions
  const availableOpItems = opOptions.children.filter(option => {
    switch (option.assetType) {
      case TYPES.OBRAS:
        return !!permissions.createWork
      case TYPES.CONSERVACION_EXTRAORDINARIA:
        return !!permissions.createExtraordinary
      case TYPES.CONSERVACION_ORDINARIA:
        return !!permissions.createOrdinary
      default:
        return true
    }
  })
  if (availableOpItems.length) {
    opOptions.children = availableOpItems
    options.push(opOptions)
  }

  return options
}
