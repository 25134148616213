import { flatten } from 'lodash'

import { matchDateDDMMYYYY } from '../../core/dates/index'
import { getFamilyTypeByLabel } from '../../core/operations/ordinary/partesDeTrabajoFamiliasFormFields'
import ASSET_TYPES from '../../core/assets/assetTypes'

export const toLocal = state => state.queries

export const getMode = state => toLocal(state).mode
export const getQueryType = state => toLocal(state).queryType
export const getSubQueryType = state => toLocal(state).subQueryType
export const getQueryFields = state => toLocal(state).fields
export const getQueryColumns = state => toLocal(state).columns
export const getWorkReportColumnFields = state => toLocal(state).workReportColumnFields
export const getFilters = state => toLocal(state).filters
export const getResultsCount = state => toLocal(state).resultsCount
export const getResult = state => toLocal(state).result
export const getCurrentPage = state => toLocal(state).currentPage
export const getResultsPerPage = state => toLocal(state).resultsPerPage
export const getSearchInputsValues = state => toLocal(state).searchInputsValues
export const getShowReadableColumns = state =>
  toLocal(state).showReadableColumns

export const getSelectedFamilies = state => {
  const familiesFilter = getFilters(state)[`parteTrabajos.familias`]
  const selectedFamilies = familiesFilter ? familiesFilter.$in : []
  return flatten(selectedFamilies.map(getFamilyTypeByLabel))
}

export const areFieldSelected = (state, fields) => {
  return fields.every(field => !!~getQueryFields(state).indexOf(field))
}

export const areCheckboxFiltersSelected = (
  state,
  fieldName,
  selectedFilters
) => {
  const fieldNameFilter = getFilters(state)[fieldName]

  return (
    selectedFilters &&
    selectedFilters.every(
      filter =>
        !!fieldNameFilter &&
        !!fieldNameFilter.$in &&
        !!~fieldNameFilter.$in.indexOf(filter)
    )
  )
}

export const getInputFilterValue = (state, fieldName, filterLabel) => {
  const fieldNameFilter = getFilters(state)[fieldName]

  if (!fieldNameFilter) return ''
  else {
    const filterLabelFilter = fieldNameFilter[filterLabel]
    return filterLabelFilter ? filterLabelFilter : ''
  }
}

export const hasDateError = (state, fieldName, filterLabel) => {
  const inputField = getInputFilterValue(state, fieldName, filterLabel).trim()

  return inputField !== '' && !matchDateDDMMYYYY(inputField)
    ? {
        hasError: true,
        helperText: 'Formato DD/MM/AAAA'
      }
    : {
        hasError: false
      }
}

export const areColumnSelected = (state, columns) => {
  return columns.every(column => !!~getQueryColumns(state).indexOf(column))
}

export const getIsExporting = state => toLocal(state).isExporting
export const getExportType = state => toLocal(state).exportType
export const isFetching = state => toLocal(state).isFetching
export const hasFetchError = state => toLocal(state).hasFetchError

export const getModel = state => {
  if (getQueryType(state) === 'assets') {
    return 'asset'
  } else if (getQueryType(state) === 'workReports') {
    return 'workReport'
  } else {
    switch (getSubQueryType(state)) {
      case ASSET_TYPES.CONSERVACION_ORDINARIA:
        return 'ordinaryOperation'
      case ASSET_TYPES.CONSERVACION_EXTRAORDINARIA:
        return 'extraordinaryOperation'
      case ASSET_TYPES.OBRAS:
        return 'workOperation'
      default:
        return 'ordinaryOperation'
    }
  }
}
