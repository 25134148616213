import { buildReducer } from '../utils'
import * as ActionTypes from './actionTypes'

const initialState = {
  isFetching: false,
  lastFetchBounds: null,
  lastFetchError: null
}

export default buildReducer(initialState, {
  [ActionTypes.FETCH_REQUEST]: state => ({
    ...state,
    isFetching: true,
    lastFetchError: null
  }),
  [ActionTypes.FETCH_FAILED]: (state, action) => ({
    isFetching: false,
    lastFetchError: action.payload
  }),
  [ActionTypes.FETCH_SUCCESS]: (state, action) => {
    const { bounds } = action.payload
    return {
      isFetching: false,
      lastFetchError: null,
      lastFetchBounds: bounds
    }
  }
})
