import app from '../../feathers'
import {
  getFilteredConnections,
  getConnectionById,
  getFavLocations
} from './selectors'
import { filterEquals } from './utils'
import { setMapCenter, setMapZoom } from '../mapview/googleMapMiddleware'
import { getMapZoom } from '../mapview'
import { getUserData } from '../../modules/session/selectors'

import * as api from './api'
import * as ActionTypes from './actionTypes'
import { MIN_ZOOM_LEVEL } from '../assets/constants'
import { saveInCache, Expiration, getFromCache } from '../cache'

export const saveConnections = connections => ({
  type: ActionTypes.SAVE_CONNECTIONS,
  payload: { connections }
})

export const saveFavLocations = favLocations => ({
  type: ActionTypes.SAVE_FAV_LOCATIONS,
  payload: { favLocations }
})

export const setShowDeleteFavLocConfirm = showDeleteFavLocConfirm => ({
  type: ActionTypes.SHOW_DELETE_FAV_LOCATIONS_CONFIRM,
  payload: { showDeleteFavLocConfirm }
})

export const setFavLocToDelete = favLocToDelete => ({
  type: ActionTypes.SET_FAV_LOC_TO_DELETE,
  payload: { favLocToDelete }
})

export const setNewFavLocName = newFavLocName => ({
  type: ActionTypes.SAVE_NEW_FAV_LOC_NAME,
  payload: { newFavLocName }
})

export const clearFilters = () => ({
  type: ActionTypes.CLEAR_FILTERS
})

export const setFilterValue = (filter, value) => ({
  type: ActionTypes.SET_FILTER_VALUE,
  payload: {
    filter,
    value
  }
})

export const setFilters = filters => ({
  type: ActionTypes.SET_FILTERS,
  payload: {
    filters
  }
})

export const savePosition = coordinates => ({
  type: ActionTypes.SAVE_POSITION,
  payload: {
    coordinates
  }
})

export const setModalValues = (show, text) => ({
  type: ActionTypes.SET_MODAL_VALUES,
  payload: {
    show,
    text
  }
})

export const setFiltersFromSelectedConn = connection => {
  const { network, management, highway, _id } = connection
  return {
    type: ActionTypes.SET_FILTERS_FROM_SELECTED_CONN,
    payload: {
      network,
      management,
      highway,
      _id
    }
  }
}

const addLocation = locationData => ({
  type: ActionTypes.LOCATION_ADDED,
  payload: locationData
})

const removeLocation = locationId => ({
  type: ActionTypes.LOCATION_REMOVED,
  payload: {
    id: locationId
  }
})

export const fetchAndSaveConns = () => {
  return async function(dispatch) {
    try {
      const cached = await getFromCache('connections')
      if (cached) {
        dispatch(saveConnections(cached))
        return
      }

      const connectionResults = await app.service(`/connections`).find()
      dispatch(saveConnections(connectionResults))
      saveInCache('connections', connectionResults, Expiration.OneDay)
    } catch (err) {
      console.warn('Error with query', err)
      return []
    }
  }
}

const cacheLocations = data =>
  saveInCache('favLocations', data, Expiration.OneWeek)

export const fetchAndSaveFavLocations = (forceFetch = false) => {
  return async function(dispatch, getState) {
    try {
      if (!forceFetch) {
        const cached = await getFromCache('favLocations')
        if (cached) {
          dispatch(saveFavLocations(cached))
          return
        }
      }
      const state = getState()
      const userData = getUserData(state)
      const favLocations = await app.service(`/user-locations`).find({
        query: {
          userId: userData._id,
          $sort: { name: 1 }
        }
      })
      dispatch(saveFavLocations(favLocations))
      cacheLocations(favLocations)
    } catch (err) {
      console.warn('Error with query', err)
      return []
    }
  }
}

export const deleteFavLocationAndRefetch = favLocId => {
  return async function(dispatch, getState) {
    try {
      await app.service(`/user-locations`).remove(favLocId)
      dispatch(removeLocation(favLocId))
      cacheLocations(getFavLocations(getState()))
    } catch (err) {
      console.warn('Error with query', err)
      return []
    }
  }
}

export const saveNewFavLocationAndRefetch = userLocation => {
  return async function(dispatch, getState) {
    try {
      const state = getState()
      const userData = getUserData(state)
      userLocation.userId = userData._id
      const newLocation = await app
        .service(`/user-locations`)
        .create(userLocation)
      dispatch(addLocation(newLocation))
      cacheLocations(getFavLocations(getState()))
    } catch (err) {
      console.warn('Error with query', err)
      return []
    }
  }
}

export const saveHighwayPKs = (data = []) => ({
  type: ActionTypes.SAVE_HIGHWAY_PKS,
  payload: data
})

export const fetchHighwayPKs = (highway = '') => {
  return async function(dispatch, getState) {
    try {
      const state = getState()
      const conditions = [filterEquals('highway', highway)]
      const filteredConn = getFilteredConnections(state, conditions)

      const axePattern = filteredConn[0].highway
      const results = await api.fetchAxePKs({
        axe: { $regex: `${axePattern}`, $options: 'i' }
      })

      dispatch(saveHighwayPKs(results))

      return results
    } catch (err) {
      console.warn('Error with query', err)
      return []
    }
  }
}

export const selectConnection = connectionId => {
  return async function(dispatch, getState) {
    if (!connectionId) return

    const state = getState()
    const connection = getConnectionById(state, connectionId)
    const { X, Y } = connection

    dispatch(setFiltersFromSelectedConn(connection))
    dispatch(savePosition([X, Y]))
    dispatch(centerAndZoomMap({ lng: X, lat: Y }))
  }
}

export const selectHighwayPK = pkId => {
  return async function(dispatch) {
    const pkResult = await app.service('pks').get(pkId)
    const { coordinates } = pkResult.geometry
    const [lng, lat] = coordinates
    dispatch(savePosition(coordinates))
    dispatch(centerAndZoomMap({ lng, lat }))
  }
}

export function centerAndZoomMap(center) {
  return function(dispatch, getState) {
    const currentZoom = getMapZoom(getState())
    dispatch(setMapCenter(center))
    if (currentZoom <= MIN_ZOOM_LEVEL) dispatch(setMapZoom(18))
  }
}
