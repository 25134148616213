import app from '../../feathers'

export async function fetchById(id, autopopulate = true) {
  return app
    .service('/operations/work')
    .get(id, { query: { $autopopulate: autopopulate } })
}

export async function deleteById(id) {
  return app.service('operations/work').remove(id)
}
