import { FIELD_CARD_TYPES } from '../../modules/queries/constants'
import {
  REGISTRO_GENERAL,
  INDICADORES,
  getCommonGeneralInfo,
  getAutopistasGeneral,
  getConceptoGeneral,
  getEmpresaAdjudicatariaGeneral,
  getIAOPEXCAPEX,
  getContrato,
  getPresupuestoSAP,
  getMaterialesSeguEconomico,
  getPrevSAPySegSidebarFields,
  getPrevSAPySegColumnsFields
} from './commonOperationFields'

export const SEG_PRESUPUESTARIO = 'seguimientoPresupuestario'
export const SEG_OBRA = 'seguimientoObra'

const ACTAS = 'actas'
const ACTIVOS = 'activos'

/* In the query section we need different fields if we are creating the sidebar or the select
 ** columns section that's why we have different methods. If the fields are the same in both
 ** cases, only appear 'Fields' in the method's names */

export const getExtraordinaryOpSidebarFields = () => ({
  [REGISTRO_GENERAL]: [
    ...getDatosGenSidebarFields(),
    ...getIAOPEXCAPEX(),
    ...getContrato(),
    ...getActasFields()
  ],
  [SEG_PRESUPUESTARIO]: [
    ...getSeguPresupuestarioFields(),
    ...getPrevSAPySegSidebarFields(SEG_PRESUPUESTARIO)
  ],
  [SEG_OBRA]: [...getSeguimientoObraFields(), ...getActivosSidebarFields()],
  [INDICADORES]: getIndicadoresFields()
})

export const getExtraordinaryOpColumnsFields = () => ({
  [REGISTRO_GENERAL]: [
    ...getDatosGenColumnsFields(),
    ...getIAOPEXCAPEX(),
    ...getContrato(),
    ...getActasFields()
  ],
  [SEG_PRESUPUESTARIO]: [
    ...getSeguPresupuestarioFields(),
    ...getPrevSAPySegColumnsFields(SEG_PRESUPUESTARIO)
  ],
  [SEG_OBRA]: [...getSeguimientoObraFields(), ...getActivosColumnsFields()],
  [INDICADORES]: getIndicadoresFields()
})

// REGISTRO GENERAL
const getDatosGenSidebarFields = () => [
  ...getCommonGeneralInfo(),
  ...getAutopistasGeneral(),
  ...getConceptoGeneral(),
  ...getEmpresaAdjudicatariaGeneral()
]
const getDatosGenColumnsFields = () => [
  ...getCommonGeneralInfo(),
  ...getAutopistasGeneral(),
  ...getConceptoGeneral(),
  {
    label: 'Descripción',
    name: `${REGISTRO_GENERAL}.descripcion`
  },
  {
    label: 'Pep',
    name: `${REGISTRO_GENERAL}.pep`
  },
  ...getEmpresaAdjudicatariaGeneral()
]
const getActasFields = () => [
  {
    label: 'Fecha acta de replanteo',
    type: FIELD_CARD_TYPES.Date,
    path: [`${REGISTRO_GENERAL}`, `${ACTAS}`, 'fechaActaReplanteo'],
    name: `${REGISTRO_GENERAL}.${ACTAS}.fechaActaReplanteo`
  },
  {
    label: 'Fecha acta de recepción',
    type: FIELD_CARD_TYPES.Date,
    path: [`${REGISTRO_GENERAL}`, `${ACTAS}`, 'fechaActaRecepcion'],
    name: `${REGISTRO_GENERAL}.${ACTAS}.fechaActaRecepcion`
  }
]

// SEGUIMIENTO PRESUPUESTARIO
const getSeguPresupuestarioFields = () => [
  ...getPresupuestoSAP(SEG_PRESUPUESTARIO),
  ...getMaterialesSeguEconomico(SEG_PRESUPUESTARIO),
  {
    label: 'Presupuesto Licitado',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${SEG_PRESUPUESTARIO}`, 'presupuestoLicitado'],
    name: `${SEG_PRESUPUESTARIO}.presupuestoLicitado`
  },
  {
    label: 'Presupuesto Adjudicado',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${SEG_PRESUPUESTARIO}`, 'presupuestoAdjudicado'],
    name: `${SEG_PRESUPUESTARIO}.presupuestoAdjudicado`
  },
  {
    label: 'Baja (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [`${SEG_PRESUPUESTARIO}`, 'baja'],
    name: `${SEG_PRESUPUESTARIO}.baja`
  },
  {
    label: 'Plazo Contractual (meses)',
    type: FIELD_CARD_TYPES.Number,
    path: [`${SEG_PRESUPUESTARIO}`, 'plazoContractualMeses'],
    name: `${SEG_PRESUPUESTARIO}.plazoContractualMeses`
  },
  {
    label: 'Obra Finalizada',
    type: FIELD_CARD_TYPES.Boolean,
    path: [`${SEG_PRESUPUESTARIO}`, 'obraFinalizada'],
    name: `${SEG_PRESUPUESTARIO}.obraFinalizada`
  },
  {
    label: 'Presupuesto Liquidación',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${SEG_PRESUPUESTARIO}`, 'presupuestoLiquidacion'],
    name: `${SEG_PRESUPUESTARIO}.presupuestoLiquidacion`
  },
  {
    label:
      'Avance eco. sobre importe adjudicación (Imp. Certificado/Imp. Ejecución)',
    type: FIELD_CARD_TYPES.Number,
    path: [`${SEG_PRESUPUESTARIO}`, 'avanceEcoSobreImporteAdjudicacion'],
    name: `${SEG_PRESUPUESTARIO}.avanceEcoSobreImporteAdjudicacion`
  },
  {
    label: 'Grado avance ejecución (Meses ejecución / Plazo contractual)',
    type: FIELD_CARD_TYPES.Number,
    path: [`${SEG_PRESUPUESTARIO}`, 'gradoAvanceEjecucion'],
    name: `${SEG_PRESUPUESTARIO}.gradoAvanceEjecucion`
  }
]

// SEGUIMIENTO DE OBRA
const getSeguimientoObraFields = () => [
  {
    label: 'Director Obra',
    type: FIELD_CARD_TYPES.String,
    path: [`${SEG_OBRA}`, 'directorObra'],
    name: `${SEG_OBRA}.directorObra`
  },
  {
    label: 'Coordinador',
    type: FIELD_CARD_TYPES.String,
    path: [`${SEG_OBRA}`, 'coordinador'],
    name: `${SEG_OBRA}.coordinador`
  },
  {
    label: 'Asistencia Técnica',
    type: FIELD_CARD_TYPES.String,
    path: [`${SEG_OBRA}`, 'asistenciaTecnica'],
    name: `${SEG_OBRA}.asistenciaTecnica`
  },
  {
    label: 'Empresa Contratista',
    type: FIELD_CARD_TYPES.String,
    path: [`${SEG_OBRA}`, 'empresaContratista'],
    name: `${SEG_OBRA}.empresaContratista`
  },
  {
    label: 'Jefe Obra',
    type: FIELD_CARD_TYPES.String,
    path: [`${SEG_OBRA}`, 'jefeObra'],
    name: `${SEG_OBRA}.jefeObra`
  },
  {
    label: 'Presupuesto Liquidación',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${SEG_OBRA}`, 'presupuestoLiquidacion'],
    name: `${SEG_OBRA}.presupuestoLiquidacion`
  },
  {
    label: 'Retrasos',
    type: FIELD_CARD_TYPES.Boolean,
    path: [`${SEG_OBRA}`, 'retrasos'],
    name: `${SEG_OBRA}.retrasos`
  },
  {
    label: 'Penalizaciones',
    type: FIELD_CARD_TYPES.Boolean,
    path: [`${SEG_OBRA}`, 'penalizaciones'],
    name: `${SEG_OBRA}.penalizaciones`
  },
  {
    label: 'Incidencias',
    type: FIELD_CARD_TYPES.Boolean,
    path: [`${SEG_OBRA}`, 'incidencias'],
    name: `${SEG_OBRA}.incidencias`
  },
  {
    label: 'No Conformidades',
    type: FIELD_CARD_TYPES.Boolean,
    path: [`${SEG_OBRA}`, 'noConformidades'],
    name: `${SEG_OBRA}.noConformidades`
  },
  {
    label: 'Fecha Vencimiento Periodo Garantía',
    type: FIELD_CARD_TYPES.Date,
    path: [`${SEG_OBRA}`, 'fechaVencimientoPeriodoGaratia'],
    name: `${SEG_OBRA}.fechaVencimientoPeriodoGaratia`
  }
]
const getActivosSidebarFields = () => [
  {
    label: 'Autopista',
    type: FIELD_CARD_TYPES.String,
    path: [`${SEG_OBRA}`, `${ACTIVOS}`, 'autopista'],
    name: `${SEG_OBRA}.${ACTIVOS}.autopista`
  },
  {
    label: 'Eje',
    type: FIELD_CARD_TYPES.String,
    path: [`${SEG_OBRA}`, `${ACTIVOS}`, 'eje'],
    name: `${SEG_OBRA}.${ACTIVOS}.eje`
  },
  {
    label: 'PK',
    type: FIELD_CARD_TYPES.Number,
    path: [`${SEG_OBRA}`, `${ACTIVOS}`, 'pk', 'pkNumber'],
    name: `${SEG_OBRA}.${ACTIVOS}.pk.pkNumber`
  },
  {
    label: 'Tipos de activo',
    type: FIELD_CARD_TYPES.String,
    path: [`${SEG_OBRA}`, `${ACTIVOS}`, 'tipos'],
    unwindPath: `${SEG_OBRA}.${ACTIVOS}.tipos`,
    name: `${SEG_OBRA}.${ACTIVOS}.tipos`
  }
]
const getActivosColumnsFields = () => [
  ...getActivosSidebarFields().filter(
    e => e.name !== `${SEG_OBRA}.${ACTIVOS}.pk.pkNumber`
  ),
  {
    label: 'PK',
    name: `${SEG_OBRA}.${ACTIVOS}.pk.formatPKOffset`
  },
  {
    label: 'Radio de Búsqueda',
    name: `${SEG_OBRA}.${ACTIVOS}.radio`
  }
]

// INDICADORES
const getIndicadoresFields = () => [
  {
    label: 'Presupuesto Adjudicado / Presupuesto Total Planificado (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [`${INDICADORES}`, 'presupuestoAdjudicadoEntreTotalPlanificado'],
    name: `${INDICADORES}.presupuestoAdjudicadoEntreTotalPlanificado`
  },
  {
    label: 'Presupuesto Licitado / Presupuesto Total Planificado (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [`${INDICADORES}`, 'presupuestoLicitadoEntreTotalPlanificado'],
    name: `${INDICADORES}.presupuestoLicitadoEntreTotalPlanificado`
  },
  {
    label: 'Presupuesto Certificado a Origen / Presupuesto Adjudicado (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [
      `${INDICADORES}`,
      'presupuestoCertificadoAOrigenEntrePresupuestoAdjudicado'
    ],
    name: `${INDICADORES}.presupuestoCertificadoAOrigenEntrePresupuestoAdjudicado`
  },
  {
    label: 'Presupuesto Liquidación / Presupuesto Adjudicado (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [
      `${INDICADORES}`,
      'presupuestoLiquidacionEntrePresupuestoAdjudicado'
    ],
    name: `${INDICADORES}.presupuestoLiquidacionEntrePresupuestoAdjudicado`
  },
  {
    label:
      'Presupuesto Certificado a Origen / Presupuesto Total Planificado (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [
      `${INDICADORES}`,
      'presupuestoCertificadoAOrigenEntrePresupuestoPlanificado'
    ],
    name: `${INDICADORES}.presupuestoCertificadoAOrigenEntrePresupuestoPlanificado`
  }
]
