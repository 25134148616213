import SenalesVerticales, {
  getSignalAttributeByCode
} from './data/codigos_senal_vertical'
import * as validators from '../autoform/validators'
import {
  getHighways,
  getStates,
  getProvinces
} from './data/concesionarias_autopistas'
import { ALL_PROVINCES, ALL_STATES } from './data/constants'
import { getMunicipality } from './data/municipality'
import { FormSignal, SignalingGroups } from './constants'

export const ID_FIELDS = 'idFields'
export const DATA_FIELDS = 'dataFields'
export const AUSCUL_FIELDS = 'auscultFields'

// Define all possible fields to mix and match
// for specific assets

const idFields = {
  codigo: {
    label: 'Código',
    type: 'String',
    name: 'codigo',
    path: ['codigo'],
    validators: [validators.requiredString],
    meta: {
      disabled: true
    }
  },
  concesionaria: {
    label: 'Concesionaria',
    type: 'SimpleSelect',
    name: 'concesionaria',
    path: ['concesionaria'],
    meta: {
      options: [
        {
          value: 'ACESA',
          text: 'ACESA'
        },
        {
          value: 'AUMAR',
          text: 'AUMAR'
        },
        {
          value: 'CASTELLANA',
          text: 'CASTELLANA'
        },
        {
          value: 'AVASA',
          text: 'AVASA'
        },
        {
          value: 'AULESA',
          text: 'AULESA'
        },
        {
          value: 'AUCAT',
          text: 'AUCAT'
        },
        {
          value: 'INVICAT',
          text: 'INVICAT'
        },
        {
          value: 'TÚNELS',
          text: 'TÚNELS'
        },
        {
          value: 'TRADOS45',
          text: 'TRADOS45'
        }
      ]
    }
  },
  autopista: {
    label: 'Autopista',
    type: 'SimpleList',
    name: 'autopista',
    path: ['autopista'],
    meta: getField => ({
      //options: autopistas,
      filterBy: (item = {}) => {
        const highwaysByCompany = getHighways({
          company: getField(['concesionaria'])
        })
        return highwaysByCompany.includes(item.value)
      },
      listName: 'autopistas',
      placeholder: 'Seleccione autopista',
      concesionaria: getField(['concesionaria'])
    })
  },
  eje: {
    label: 'Eje',
    type: 'AxisSelect',
    name: 'eje',
    path: ['eje'],
    meta: getField => ({
      placeholder: 'Seleccione eje',
      filter: x => {
        const selectedHighway = getField(['autopista'])
        return (
          x.highway === getField(['autopista']) ||
          (x.value || '').startsWith(selectedHighway)
        )
      }

      //onChange: console.log
      //options: axisNames.filter(x => x.startsWith(getField(['autopista']))),
      //allowSearch: true // use a component that allows filtering/type ahead
    })
  },
  sentido: {
    label: 'Sentido',
    type: 'SimpleSelect',
    name: 'sentido',
    path: ['sentido'],
    meta: {
      listName: 'Sentido',
      options: [
        { text: 'Creciente', value: 'CRE' },
        { text: 'Decreciente', value: 'DEC' },
        { text: 'Mediana', value: 'MED' },
        { text: 'Reversible', value: 'REV' }
      ],
      placeholder: 'Seleccione sentido'
    }
  },
  pk: {
    label: 'PK',
    type: 'Number',
    name: 'pkInicial',
    path: ['pkInicial'],
    meta: {
      disabled: true
    }
  },
  pkInicial: {
    label: 'PK Inicial',
    type: 'Number',
    name: 'pkInicial',
    path: ['pkInicial'],
    meta: {
      disabled: true
    }
  },
  pkFinal: {
    label: 'PK Final',
    type: 'Number',
    name: 'pkFinal',
    path: ['pkFinal']
  },
  lado: {
    label: 'Lado',
    type: 'SimpleSelect',
    name: 'lado',
    path: ['lado'],
    meta: {
      options: [
        { text: 'Exterior', value: 'EXT' },
        { text: 'Interior', value: 'INT' }
      ],
      placeholder: 'Seleccione lado'
    }
  },
  distanciaEje: {
    label: 'Distancia a eje (m)',
    type: 'Number',
    name: 'distanciaEje',
    path: ['distanciaEje']
  },
  latitud: {
    label: 'Latitud',
    type: 'Number',
    name: 'Y',
    path: ['Y'],
    meta: {
      hasLabel: true,
      disabled: true
    }
  },
  longitud: {
    label: 'Longitud',
    type: 'Number',
    name: 'X',
    path: ['X'],
    meta: {
      hasLabel: true,
      disabled: true
    }
  },
  X: {
    label: 'Longitud',
    type: 'Number',
    name: 'X',
    path: ['X'],
    meta: {
      hasLabel: true,
      disabled: true
    }
  },
  ccaa: {
    label: 'Comunidad Autónoma',
    type: 'Select',
    name: 'ccaa',
    path: ['ccaa'],
    meta: getField => {
      const filteredStates = getStates({
        company: getField(['concesionaria']),
        highway: getField(['autopista'])
      })
      return {
        options: filteredStates.length ? filteredStates : ALL_STATES,
        placeholder: 'Seleccione CCAA'
      }
    }
  },
  provincia: {
    label: 'Provincia',
    type: 'Select',
    name: 'provincia',
    path: ['provincia'],
    meta: getField => {
      const provincesByState = getProvinces({
        company: getField(['concesionaria']),
        highway: getField(['autopista']),
        state: getField(['ccaa'])
      })
      return {
        options: provincesByState.length ? provincesByState : ALL_PROVINCES,
        placeholder: 'Seleccione provincia'
      }
    }
  },

  municipioSelect: {
    label: 'Municipio',
    type: 'Select',
    name: 'municipioSelect',
    path: ['municipioSelect'],

    meta: getField => {
      const municipalityByState = getMunicipality({
        company: getField(['concesionaria']),
        highway: getField(['autopista']),
        province: getField(['provincia']),
        state: getField(['ccaa'])
      })
      return {
        options: municipalityByState.length ? municipalityByState : [],
        placeholder: 'Seleccione un municipio'
      }
    }
  },
  municipio: {
    label: 'Municipio (BBDD Origen)',
    type: 'String',
    name: 'municipio',
    path: ['municipio']
  },
  fechaAlta: {
    label: 'Fecha de alta',
    type: 'Date',
    name: 'fechaAlta',
    path: ['fechaAlta']
  },
  fechaBaja: {
    label: 'Fecha de baja',
    type: 'Date',
    name: 'fechaBaja',
    path: ['fechaBaja']
  },
  codigoi3met: {
    label: 'Código i3met',
    name: 'codigoi3met'
  },
  nombre: {
    label: 'Nombre',
    name: 'nombre'
  }
}

const dataFields = {
  descripcion: {
    label: 'Descripción',
    type: 'String',
    name: 'descripcion',
    path: ['descripcion']
  },
  equipo: {
    label: 'Equipo',
    type: 'String',
    name: 'equipo',
    path: ['equipo']
  },
  numEquipo: {
    label: 'Número de equipo',
    type: 'String',
    name: 'numEquipo',
    path: ['numEquipo']
  },
  emplazamiento: {
    label: 'Emplazamiento',
    type: 'String',
    name: 'emplazamiento',
    path: ['emplazamiento']
  },
  ubicacionTecnica: {
    label: 'Ubicación técnica',
    type: 'String',
    name: 'ubicacionTecnica',
    path: ['ubicacionTecnica']
  },
  denominacion: {
    label: 'Denominación',
    type: 'String',
    name: 'denominacion',
    path: ['denominacion']
  },
  longitud: {
    label: 'Longitud (m)',
    type: 'Number',
    name: 'longitud',
    path: ['longitud']
  },
  altura: {
    label: 'Altura (m)',
    type: 'Number',
    name: 'altura',
    path: ['altura'],
    hidden: getField => !(getField(['formaSenal']) !== FormSignal.Circulo)
  },
  diametro: {
    label: 'Diámetro (m)',
    type: 'Number',
    name: 'diametro',
    path: ['diametro'],
    hidden: getField => !(getField(['formaSenal']) === FormSignal.Circulo)
  },
  tipologiaElementoArquetas: {
    label: 'Tipología de elemento',
    type: 'SimpleSelect',
    name: 'tipologiaElemento',
    path: ['tipologiaElemento'],
    meta: {
      options: [
        'Arqueta',
        'Arqueta - arenero',
        'Arqueta - sumidero',
        'Drenaje',
        'Sifón',
        'Otros'
      ],
      placeholder: 'Seleccione tipo'
    }
  },
  tipologiaElementoCunetasCaz: {
    label: 'Tipología de elemento',
    type: 'SimpleSelect',
    name: 'tipologiaElemento',
    path: ['tipologiaElemento'],
    meta: {
      options: [
        { value: 'PRE', text: 'Prefabricada' },
        { value: 'SIT', text: 'In situ' }
      ],
      placeholder: 'Seleccione tipo'
    }
  },
  tipologiaElementoTexto: {
    label: 'Tipología de elemento',
    type: 'String',
    name: 'tipologiaElemento',
    path: ['tipologiaElemento']
  },
  codigoElemento: {
    label: 'Código de elemento',
    type: 'String',
    name: 'codigoElemento',
    path: ['codigoElemento']
  },
  dimensionesPlanta: {
    label: 'Dimensiones en planta',
    type: 'String',
    name: 'dimensionesPlanta',
    path: ['dimensionesPlanta']
  },

  materialElemento: {
    label: 'Material del elemento',
    type: 'SimpleSelect',
    name: 'materialElemento',
    path: ['materialElemento'],
    meta: {
      options: [
        { value: 'HOR', text: 'Hormigón' },
        { value: 'MET', text: 'Metálico' },
        { value: 'OTR', text: 'Metálico (acero galvanizado)' },
        { value: 'OTR', text: 'Metálico (aluminio)' },
        { value: 'OTR', text: 'Metálico (lamas de aluminio)' },
        { value: 'OTR', text: 'Otros' }
      ],
      placeholder: 'Seleccione material'
    }
  },

  anoColocacion: {
    label: 'Año de colocación',
    type: 'Number',
    name: 'anoColocacion',
    path: ['anoColocacion']
  },
  elementosProtegidos: {
    label: 'Elementos protegidos',
    type: 'String',
    name: 'elementosProtegidos',
    path: ['elementosProtegidos']
  },
  fabricanteElemento: {
    label: 'Fabricante del elemento',
    type: 'String',
    name: 'fabricanteElemento',
    path: ['fabricanteElemento']
  },
  nivelContencion: {
    label: 'Nivel de contención',
    type: 'SimpleSelect',
    name: 'nivelContencion',
    path: ['nivelContencion'],
    meta: {
      options: [
        'H1',
        'H2',
        'H2 (trionda)',
        'H3',
        'H4',
        'H4b',
        'N1',
        'N2',
        'N2/H1',
        'N2/H2'
      ],
      placeholder: 'Seleccione nivel de contención'
    }
  },
  cronoConstruccion: {
    label: 'Cronología (años) construcción',
    type: 'String',
    name: 'cronoConstruccion',
    path: ['cronoConstruccion']
  },
  tipologiaGeneral: {
    label: 'Tipología general',
    type: 'String',
    name: 'tipologiaGeneral',
    path: ['tipologiaGeneral']
  },
  longitudTotal: {
    label: 'Longitud total (m)',
    type: 'Number',
    name: 'longitudTotal',
    path: ['longitudTotal']
  },
  anchuraTotal: {
    label: 'Anchura total (m)',
    type: 'Number',
    name: 'anchuraTotal',
    path: ['anchuraTotal']
  },
  superficieTotal: {
    label: 'Superficie total (m2)',
    type: 'Number',
    name: 'superficieTotal',
    path: ['superficieTotal']
  },
  numVanos: {
    label: 'Número de vanos / tubos',
    type: 'Number',
    name: 'numVanos',
    path: ['numVanos']
  },
  ultimInspeccionPpal: {
    label: 'Ultima IP',
    type: 'Date',
    name: 'ultimInspeccionPpal',
    path: ['ultimInspeccionPpal']
  },
  califUltimaInspeccionPpal: {
    label: 'Calificación última IP',
    type: 'String',
    name: 'califUltimaInspeccionPpal',
    path: ['califUltimaInspeccionPpal']
  },
  ultimInspeccionBasica: {
    label: 'Última IB',
    type: 'Date',
    name: 'ultimInspeccionBasica',
    path: ['ultimInspeccionBasica']
  },
  pendiente: {
    label: 'Pendiente (%)',
    type: 'Number',
    name: 'pendiente',
    path: ['pendiente'],
    meta: {
      // eslint-disable-next-line
      pattern: '\\d{1,2}%'
    }
  },
  ubicacion: {
    label: 'Ubicación',
    type: 'SimpleSelect',
    name: 'ubicacion',
    path: ['ubicacion'],
    meta: {
      options: [
        'Coronación terraplén',
        'Pie terraplén',
        'Coronación desmonte',
        'Pie desmonte'
      ],
      placeholder: 'Seleccione ubicación'
    }
  },
  soporteBarrera: {
    label: 'Soporte barrera',
    type: 'SimpleSelect',
    name: 'soporteBarrera',
    path: ['soporteBarrera'],
    meta: {
      options: [
        'Poste C',
        'Poste C cada 2 m',
        'Poste C cada 4 m',
        'Poste C con tirante',
        'Poste IPN',
        'Poste IPN cada 2 m',
        'Poste IPN cada 4 m',
        'Poste IPN con tirante',
        'Otros',
        'Varios tipos'
      ],
      placeholder: 'Seleccione soporte'
    }
  },
  tipoBarreraMetalica: {
    label: 'Tipo de barrera',
    type: 'SimpleSelect',
    name: 'tipoBarrera',
    path: ['tipoBarrera'],
    meta: {
      options: [
        'Doble',
        'Doble (2/100)',
        'Doble (2/120)',
        'Doble (2/120) / Móvil',
        'Doble (4/120)',
        'Doble (4/120) + protección moto',
        'Doble + protección moto',
        'Móvil',
        'Simple',
        'Simple (2 m)',
        'Simple (2 m) + simple (b)',
        'Simple (2/120)',
        'Simple (2/120) / doble (4/120)',
        'Simple (2/120) / simple (4/120)',
        'Simple (2/120) / superpuesta',
        'Simple (2/120) + protección moto',
        'Simple (4/100)',
        'Simple (4/120)',
        'Simple (4/120) / superpuesta',
        'Simple (4/120) + protección moto',
        'Simple + protección moto',
        'Superpuesta',
        'Superpuesta + protección moto',
        'Superpuesta (4/120)',
        'Otros',
        'Varios tipos'
      ],
      placeholder: 'Seleccione tipo'
    }
  },
  tipoBarreraHormigon: {
    label: 'Tipo de barrera',
    type: 'SimpleSelect',
    name: 'tipoBarrera',
    path: ['tipoBarrera'],
    meta: {
      options: [
        'Doble',
        'Doble-in_situ',
        'Doble-prefabricada',
        'Hombre sentado',
        'Prefabricada',
        'Simple',
        'Simple-in_situ',
        'Simple-prefabricada',
        'Otros',
        'Varios tipos'
      ],
      placeholder: 'Seleccione tipo'
    }
  },
  tipoPretil: {
    label: 'Tipo Pretil',
    type: 'String',
    name: 'tipoPretil',
    path: ['tipoPretil']
  },
  tipoPantalla: {
    label: 'Tipo pantalla',
    type: 'SimpleSelect',
    name: 'tipoPantalla',
    path: ['tipoPantalla'],
    meta: {
      options: [
        'Chapa perforada',
        'Hormigón',
        'Hormigón + Metacrilato',
        'Madera',
        'Metacrilato',
        'Metálica',
        'Muro hormigón',
        'Otros',
        'Varios tipos'
      ],
      placeholder: 'Seleccione tipo'
    }
  },
  cimentacion: {
    label: 'Cimentación',
    type: 'SimpleSelect',
    name: 'cimentacion',
    path: ['cimentacion'],
    meta: {
      options: ['Pilotes', 'Zapata', 'Otros', 'Varios tipos', 'No observable'],
      placeholder: 'Seleccione cimentación'
    }
  },
  longitudCm: {
    label: 'Longitud (cm)',
    type: 'Number',
    name: 'longitudCm',
    path: ['longitudCm']
  },
  anchura: {
    label: 'Anchura (cm)',
    type: 'Number',
    name: 'anchura',
    path: ['anchura'],
    hidden: getField => !(getField(['formaSenal']) !== FormSignal.Circulo)
  },
  anchuraCm: {
    label: 'Anchura (cm)',
    type: 'Number',
    name: 'anchuraCm',
    path: ['anchuraCm']
  },
  discontinuidad: {
    label: 'Discontinuidad',
    type: 'String',
    name: 'discontinuidad',
    path: ['discontinuidad']
  },
  discontinuidadExterior: {
    label: 'Discontinuidad Exterior',
    type: 'SimpleSelect',
    name: 'discontinuidadExterior',
    path: ['discontinuidadExterior'],
    meta: {
      options: ['SI', 'NO'],
      placeholder: 'Seleccione...'
    }
  },
  discontinuidadInterior: {
    label: 'Discontinuidad Interior',
    type: 'SimpleSelect',
    name: 'discontinuidadInterior',
    path: ['discontinuidadInterior'],
    meta: {
      options: ['SI', 'NO'],
      placeholder: 'Seleccione...'
    }
  },
  discontinuidadEje: {
    label: 'Discontinuidad Eje',
    type: 'SimpleSelect',
    name: 'discontinuidadEje',
    path: ['discontinuidadEje'],
    meta: {
      options: ['SI', 'NO'],
      placeholder: 'Seleccione...'
    }
  },
  tipoPintura: {
    label: 'Tipo pintura',
    type: 'SimpleSelect',
    name: 'tipoPintura',
    path: ['tipoPintura'],
    meta: {
      options: ['Acrílica', 'Termoplástica', 'Otros', 'Varios tipos'],
      placeholder: 'Seleccione tipo de pintura'
    }
  },
  resalte: {
    label: 'Resalte',
    type: 'SimpleSelect',
    name: 'resalte',
    path: ['resalte'],
    meta: {
      options: [
        'No',
        'Struder',
        'Spotflex',
        'Huella sonora',
        'Otros',
        'Varios tipos'
      ],
      placeholder: 'Seleccione tipo de resalte'
    }
  },
  fechaRepintadoEje: {
    label: 'Fecha repintado eje',
    type: 'Date',
    name: 'fechaRepintadoEje',
    path: ['fechaRepintadoEje']
  },
  fechaRepintadoInterior: {
    label: 'Fecha repintado interior',
    type: 'Date',
    name: 'fechaRepintadoInterior',
    path: ['fechaRepintadoInterior']
  },
  fechaRepintadoExterior: {
    label: 'Fecha repintado exterior',
    type: 'Date',
    name: 'fechaRepintadoExterior',
    path: ['fechaRepintadoExterior']
  },
  alturaMm: {
    label: 'Altura (mm)',
    type: 'Number',
    name: 'alturaMm',
    path: ['alturaMm']
  },
  anchuraMm: {
    label: 'Anchura (mm)',
    type: 'Number',
    name: 'anchuraMm',
    path: ['anchuraMm']
  },
  superficie: {
    label: 'Superficie (m2)',
    type: 'Number',
    name: 'superficie',
    path: ['superficie']
  },
  codigoSenal: {
    label: 'Código de Señal',
    type: 'Select',
    name: 'codigoSenal',
    path: ['codigoSenal'],
    meta: {
      options: SenalesVerticales.map(senal => senal.codigo),
      placeholder: 'Seleccione tipo de señal'
    }
  },
  imgSenal: {
    label: 'Señal',
    type: 'Image',
    path: [],
    meta: getField => {
      const codigoSenal = getField(['codigoSenal'])
      return {
        src: codigoSenal ? `/images/${codigoSenal}.png` : '',
        altText: codigoSenal,
        title: codigoSenal
      }
    }
  },
  formaSenal: {
    label: 'Forma de Señal',
    type: 'AutoSelect',
    name: 'formaSenal',
    path: ['formaSenal'],
    meta: getField => {
      return {
        dependency: getField(['codigoSenal']),
        findValue: code => getSignalAttributeByCode({ code, attr: 'formato' }),
        options: Object.values(FormSignal),
        placeholder: 'Seleccione forma'
      }
    }
  },
  grupoSenalizacion: {
    label: 'Grupo de señalización',
    type: 'AutoSelect',
    name: 'grupoSenalizacion',
    path: ['grupoSenalizacion'],
    meta: getField => {
      return {
        dependency: getField(['codigoSenal']),
        findValue: code => getSignalAttributeByCode({ code, attr: 'grupo' }),
        options: Object.values(SignalingGroups),
        placeholder: 'Seleccione grupo'
      }
    }
  },
  leyenda: {
    label: 'Leyenda',
    type: 'String',
    name: 'leyenda',
    path: ['leyenda'],
    meta: {
      hasLabel: true
    }
  },
  soporte: {
    label: 'Soporte',
    type: 'SimpleSelect',
    name: 'soporte',
    path: ['soporte'],
    meta: {
      options: ['Poste', 'Banderola', 'Pórtico', 'PMV', 'PMVT'],
      placeholder: 'Seleccione soporte'
    }
  },
  reflectancia: {
    label: 'Reflectancia',
    type: 'String',
    name: 'reflectancia',
    path: ['reflectancia']
  },
  codigoBalizamiento: {
    label: 'Código de Elemento',
    type: 'SimpleSelect',
    name: 'codigoElemento',
    path: ['codigoElemento'],
    meta: { options: ['B2B', 'Otros'] }
  },
  tipoSoporteBalizamiento: {
    label: 'Tipo de Soporte',
    type: 'SimpleSelect',
    name: 'tipoSoporte',
    path: ['tipoSoporte'],
    meta: {
      options: ['Poste', 'Otros'],
      placeholder: 'Seleccione tipo de soporte'
    }
  },
  tipoSenalBalizamiento: {
    label: 'Tipo de señal',
    type: 'SimpleSelect',
    name: 'tipoSenal',
    path: ['tipoSenal'],
    meta: {
      options: ['Paneles direccionales', 'Otros'],
      placeholder: 'Seleccione tipo'
    }
  },
  geometria: {
    label: 'Geometría',
    type: 'String',
    name: 'geometria',
    path: ['geometria']
  },
  sistemaViasPeaje: {
    label: 'Sistema',
    type: 'SimpleSelect',
    name: 'sistema',
    path: ['sistema'],
    meta: {
      options: ['Abierto', 'Cerrado', 'Semicerrado', 'Abierto / Cerrado']
    }
  },
  numViasEntrada: {
    label: 'Nº de vías de entrada',
    type: 'Number',
    name: 'numViasEntrada',
    path: ['numViasEntrada']
  },
  numViasSalida: {
    label: 'Nº de vías de salida',
    type: 'Number',
    name: 'numViasSalida',
    path: ['numViasSalida']
  },
  numViasReversibles: {
    label: 'Nº de vías reversibles',
    type: 'Number',
    name: 'numViasReversibles',
    path: ['numViasReversibles']
  },
  ancho: {
    label: 'Ancho (m)',
    type: 'Number',
    name: 'ancho',
    path: ['ancho']
  },
  anchoVias: {
    label: 'Ancho de vías (m)',
    type: 'Number',
    name: 'anchoVias',
    path: ['anchoVias']
  },
  anchoViasMixtas: {
    label: 'Ancho de vías mixtas (m)',
    type: 'Number',
    name: 'anchoViasMixtas',
    path: ['anchoViasMixtas']
  },
  dimensiones: {
    label: 'Dimensiones',
    type: 'String',
    name: 'dimensiones',
    path: ['dimensiones']
  },
  superficieSeccionTunel: {
    label: 'Superficie sección del túnel',
    type: 'Number',
    name: 'superficieSeccionTunel',
    path: ['superficieSeccionTunel']
  },
  tipoEquipo: {
    label: 'Tipo de equipo',
    type: 'SimpleSelect',
    name: 'tipoEquipo',
    path: ['tipoEquipo'],
    meta: {
      options: ['H', 'T'],
      placeholder: 'Seleccione tipo'
    }
  },
  tipoObjeto: {
    label: 'Tipo de objeto',
    type: 'SimpleSelect',
    name: 'tipoObjeto',
    path: ['tipoObjeto'],
    meta: {
      options: ['BACULO', 'BARRERA', 'OTROSINF'],
      placeholder: 'Seleccione tipo objeto'
    }
  },
  elementoPEP: {
    label: 'Elemento PEP',
    type: 'String',
    name: 'elementoPEP',
    path: ['elementoPEP']
  },
  cuadroMandoReferencia: {
    label: 'Cuadro de mando / Punto de suministro',
    type: 'String',
    name: 'cuadroMandoReferencia',
    path: ['cuadroMandoReferencia']
  },
  tipoSoporteAlumbrado: {
    label: 'Tipo de soporte',
    type: 'SimpleSelect',
    name: 'tipoSoporteAlumbrado',
    path: ['tipoSoporteAlumbrado'],
    meta: {
      options: ['Pórtico', 'Banderola', 'Báculo/Columna', 'Bastidor', 'Otros'],
      placeholder: 'Seleccione tipo soporte'
    }
  },
  elementoSustentado: {
    label: 'Elemento sustentado',
    type: 'Select',
    name: 'elementoSustentado',
    path: ['elementoSustentado'],
    meta: {
      multi: true,
      options: [
        'Señales fijas',
        'Señales variables',
        'Panel solar',
        'Paneles variables',
        'Iluminación',
        'Cámara',
        'Otros'
      ],
      placeholder: 'Seleccione elemento sustentado'
    }
  }
}

const auscultFields = {
  ultimInspeccionPpal: {
    label: 'Última IP',
    type: 'Date',
    name: 'ultimInspeccionPpal',
    path: ['ultimInspeccionPpal']
  },
  califUltimInspeccionPpal: {
    label: 'Calificación última IP',
    type: 'Number',
    name: 'califUltimInspeccionPpal',
    path: ['califUltimInspeccionPpal']
  },
  ultimInspeccionBasica: {
    label: 'Última IB',
    type: 'Date',
    name: 'ultimInspeccionBasica',
    path: ['ultimInspeccionBasica']
  }
}
// const fields = { ID_Fields, Data_Fields, Auscul_Fields }
const fields = { idFields, dataFields, auscultFields }

export const ID_Fields = idFields
export const Data_Fields = dataFields
export const Auscult_Fields = auscultFields

export function getAssetFieldLabel(dataBlock, fieldName) {
  if (!fields[dataBlock][fieldName]) return fieldName

  const fieldLabel = fields[dataBlock][fieldName].label
  return fieldLabel ? fieldLabel : fieldName
}
