import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withClickOutside from 'react-click-outside'
import cx from 'classnames'
import UpperMenu from './UpperMenu'
import NavItem from './NavItem'
import { redirectToLogin } from '../../modules/session'
import { logout } from '../../modules/session/api'
import { SAML_LOGOUT_URL } from '../../modules/session/constants'
import app from '../../feathers'

const noop = () => {}
class MainNavigation extends Component {
  handleClickOutside() {
    if (!this.props.mini) {
      this.props.onToggle()
    }
  }
  async handleLogout() {
    const hasSAML= SAML_LOGOUT_URL
    if (hasSAML) {
      const samlUrl = await app.service(SAML_LOGOUT_URL).find()
      logout().then(() => {
        window.location.replace(samlUrl)
      })
    } else {
      logout().then(() => {
        redirectToLogin()
      })
    }
  }
  render() {
    const { navItems, mini, onToggle } = this.props
    const className = cx('main-navigation', {
      mini: mini
    })
    return (
      <div className={className}>
        <UpperMenu icon={mini ? 'menu' : 'hide'} onClick={onToggle} />
        <nav className='nav-items-container'>
          <ul className='nav-items'>
            {navItems.map((s, i) => (
              <NavItem
                key={i}
                url={s.url}
                aliasURLs={s.aliasURLs}
                text={s.text}
                icon={s.icon}
                afterClick={mini ? noop : onToggle}
              />
            ))}
          </ul>
        </nav>
        <div className='logout'>
          <NavItem
            url='#'
            text='Cerrar sesión'
            icon='logout'
            afterClick={this.handleLogout}
          />
        </div>
      </div>
    )
  }
}

MainNavigation.defaultProps = {
  icon: 'hide',
  mini: false
}

MainNavigation.propTypes = {
  icon: PropTypes.string,
  navItems: PropTypes.array,
  mini: PropTypes.bool.isRequired,
  onToggle: PropTypes.func
}

export default withClickOutside(MainNavigation)
