import ability from './ability'

export const ROLES = {
  ADMIN: 'admin',
  USER: 'user',
  COLLABORATOR: 'collaborator',
  READ_ONLY: 'read_only'
}

export const RESOURCES = {
  ASSETS: 'assets',
  OP_WORK: 'work',
  OP_ORDINARY: 'ord',
  OP_EXTRAORDINARY: 'extra',
  EXT_GEFIREX: 'gef',
  EXT_I3MET: 'i3met',
  EXT_PAT: 'pat',
  EXT_TALUD: 'talud',
  ADMIN: 'admin',
  //queries, not used right now
  QUERY_ASSETS: 'query_assets',
  QUERY_OPERATIONS: 'query_operations'
}

export const Ability = ability
