import * as ActionTypes from './actionTypes'

export const updateMapPosition = ({ center, zoom, bounds }) => {
  return {
    type: ActionTypes.MAP_POSITION_UPDATED,
    center,
    zoom,
    bounds
  }
}

export const setBottomLabel = (title, label) => ({
  type: ActionTypes.SET_BOTTOM_LABEL,
  payload: {
    title,
    label
  }
})

export const removeBottomLabel = () => ({
  type: ActionTypes.REMOVE_BOTTOM_LABEL
})

export const resetMap = () => ({
  type: ActionTypes.MAP_RESET
})
