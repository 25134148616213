import {
  getPKFamily,
  getStartEndPKExecFields,
  getWayField,
  getTraceField,
  getOpField,
  getMedicUnidExecFields,
  getCommonInspectionFields,
  getCommonExecFields,
  getCommonValidationFields
} from './commonFields'

const reparacionesFirmesEstructurasFields = [
  ...getPKFamily('reparacionesFirmesEstructuras'),
  ...getCommonInspectionFields('reparacionesFirmesEstructuras'),
  ...getStartEndPKExecFields('reparacionesFirmesEstructuras'),
  ...getWayField('reparacionesFirmesEstructuras'),
  ...getTraceField('reparacionesFirmesEstructuras'),
  ...getOpField('reparacionesFirmesEstructuras'),
  ...getMedicUnidExecFields('reparacionesFirmesEstructuras'),
  ...getCommonExecFields('reparacionesFirmesEstructuras'),
  ...getCommonValidationFields('reparacionesFirmesEstructuras')
]

export const getReparacionFirmesFields = () =>
  reparacionesFirmesEstructurasFields
