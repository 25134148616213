import { FIELD_CARD_TYPES } from '../../modules/queries/constants'
import {
  REGISTRO_GENERAL,
  getCommonGeneralInfo,
  getAutopistasGeneral,
  getEmpresaAdjudicatariaGeneral,
  getIAOPEXCAPEX,
  getContrato,
  getPresupuestoSAP,
  getMaterialesSeguEconomico,
  getPrevSAPySegSidebarFields,
  getPrevSAPySegColumnsFields
} from './commonOperationFields'

export const SEGUIMIENTO_ECONOMICO = 'seguimientoEconomico'

/* In the query section we need different fields if we are creating the sidebar or the select
 ** columns section that's why we have different methods. If the fields are the same in both
 ** cases, only appear 'Fields' in the method's names */

export const getOrdinaryOpSidebarFields = () => ({
  [REGISTRO_GENERAL]: [
    ...getDatosGenFields(),
    ...getIAOPEXCAPEX(),
    ...getContrato()
  ],
  [SEGUIMIENTO_ECONOMICO]: [
    ...getSegEconomicoFields(),
    ...getPrevSAPySegSidebarFields(SEGUIMIENTO_ECONOMICO)
  ]
})

export const getOrdinaryOpColumnsFields = () => ({
  [REGISTRO_GENERAL]: [
    ...getDatosGenFields(),
    ...getIAOPEXCAPEX(),
    ...getContrato()
  ],
  [SEGUIMIENTO_ECONOMICO]: [
    ...getSegEconomicoFields(),
    ...getPrevSAPySegColumnsFields(SEGUIMIENTO_ECONOMICO)
  ]
})

// REGISTRO GENERAL
const getDatosGenFields = () => [
  ...getCommonGeneralInfo(),
  ...getAutopistasGeneral(),
  ...getGeneralFields(),
  ...getEmpresaAdjudicatariaGeneral()
]
const getGeneralFields = () => [
  {
    label: 'Importe anual licitación',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${REGISTRO_GENERAL}`, 'importeLicitacion'],
    name: `${REGISTRO_GENERAL}.importeLicitacion`
  },
  {
    label: 'Importe anual adjudicación',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${REGISTRO_GENERAL}`, 'importeAdjudicacion'],
    name: `${REGISTRO_GENERAL}.importeAdjudicacion`
  },
  {
    label: 'Baja (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [`${REGISTRO_GENERAL}`, 'baja'],
    name: `${REGISTRO_GENERAL}.baja`
  },
  {
    label: 'Fecha inicio contrato',
    type: FIELD_CARD_TYPES.Date,
    path: [`${REGISTRO_GENERAL}`, 'inicioContrato'],
    name: `${REGISTRO_GENERAL}.inicioContrato`
  },
  {
    label: 'Fecha fin contrato',
    type: FIELD_CARD_TYPES.Date,
    path: [`${REGISTRO_GENERAL}`, 'finalContrato'],
    name: `${REGISTRO_GENERAL}.finalContrato`
  }
]

// SEGUIMIENTO ECONOMICO
const getSegEconomicoFields = () => [
  ...getPresupuestoSAP(SEGUIMIENTO_ECONOMICO),
  ...getMaterialesSeguEconomico(SEGUIMIENTO_ECONOMICO)
]
