import {
  SI_NO,
  INSPECCION_BACULO_COLUMNA,
  INSPECCION_TAPAS_INTERIOR_BACULO,
  INSPECCION_CIMENTACION,
  INSPECCION_ESPACIO_CIMIENTO,
  INSPECCION_PLACAS_ANCLAJE_Y_CARTELAS_EN_BASES,
  EJECUCION_CIMENTACION,
  EJECUCION_ESPACIO_CIMIENTO,
  EJECUCION_BACULO_COLUMNA,
  EJECUCION_TAPAS_INTERIOR_BACULO
} from './selectOptions'
import TYPES from '../../../assets/assetTypes'
import comunIdentificacionFormFields from './comunIdentificacionFormFields'

export const identificacion = (workOrderFragment, familyFragment) => [
  ...comunIdentificacionFormFields(
    workOrderFragment,
    'alumbrado',
    familyFragment,
    [TYPES.BACULOS]
  ),

  {
    label: 'Comentarios',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'identificacion',
      'comentarios'
    ]
  }
]

export const inspeccion = (workOrderFragment, familyFragment) => [
  {
    label: 'Cimentación',
    type: 'Select',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'inspeccion',
      'cimentacion'
    ],
    meta: {
      options: INSPECCION_CIMENTACION,
      multi: true
    }
  },

  {
    label: 'Fotos cimentación',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'inspeccion',
      'cimentacionFotos'
    ],
    meta: { isPhotos: true }
  },

  {
    label: 'Espacio cimiento - placa',
    type: 'Select',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'inspeccion',
      'espacioCimientoPlaca'
    ],
    meta: {
      options: INSPECCION_ESPACIO_CIMIENTO,
      multi: true
    }
  },

  {
    label: 'Fotos espacio cimiento - placa',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'inspeccion',
      'espacioCimientoPlacaFotos'
    ],
    meta: { isPhotos: true }
  },

  {
    label: 'Placas de anclaje y cartelas en bases',
    type: 'Select',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'inspeccion',
      'anclajeCartelas'
    ],
    meta: {
      options: INSPECCION_PLACAS_ANCLAJE_Y_CARTELAS_EN_BASES,
      multi: true
    }
  },

  {
    label: 'Fotos placas anclaje y cartelas en bases',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'inspeccion',
      'anclajeCartelasFotos'
    ],
    meta: { isPhotos: true }
  },

  {
    label: 'Báculo o columna',
    type: 'Select',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'inspeccion',
      'baculoColumna'
    ],
    meta: {
      options: INSPECCION_BACULO_COLUMNA,
      multi: true
    }
  },

  {
    label: 'Fotos báculo o columna',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'inspeccion',
      'baculoColumnaFotos'
    ],
    meta: { isPhotos: true }
  },

  {
    label: 'Tapas e interior báculo',
    type: 'Select',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'inspeccion',
      'tapasInterior'
    ],
    meta: {
      options: INSPECCION_TAPAS_INTERIOR_BACULO,
      multi: true
    }
  },

  {
    label: 'Fotos tapas e interior',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'inspeccion',
      'tapasInteriorFotos'
    ],
    meta: { isPhotos: true }
  },

  {
    label: 'Observaciones',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'inspeccion',
      'observaciones'
    ]
  }
]

export const ejecucion = (workOrderFragment, familyFragment) => [
  {
    label: 'Cimentación',
    type: 'Select',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'ejecucion',
      'cimentacion'
    ],
    meta: {
      options: EJECUCION_CIMENTACION,
      multi: true
    }
  },

  {
    label: 'Espacio cimiento - placa',
    type: 'Select',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'ejecucion',
      'espacioCimientoPlaca'
    ],
    meta: {
      options: EJECUCION_ESPACIO_CIMIENTO,
      multi: true
    }
  },

  {
    label: 'Báculo o columna',
    type: 'Select',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'ejecucion',
      'baculoColumna'
    ],
    meta: {
      options: EJECUCION_BACULO_COLUMNA,
      multi: true
    }
  },

  {
    label: 'Tapas e interior báculo',
    type: 'Select',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'ejecucion',
      'tapasInterior'
    ],
    meta: {
      options: EJECUCION_TAPAS_INTERIOR_BACULO,
      multi: true
    }
  },

  {
    label: 'Observaciones',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'ejecucion',
      'observaciones'
    ]
  }
]

export const validacion = (workOrderFragment, familyFragment) => [
  {
    label: 'Responsable Contratista',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'validacion',
      'responsableContratista'
    ]
  },

  {
    label: 'Fecha',
    type: 'Date',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'validacion',
      'fecha'
    ]
  },

  {
    label: 'Necesidad de inspección especial',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'validacion',
      'necesidadInspeccionEspecial'
    ],
    meta: {
      placeholder: 'Sin especificar',
      options: SI_NO
    }
  },

  {
    label: 'Urgencia para intervención inmediata',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'validacion',
      'urgenciaIntervencionInmediata'
    ],
    meta: {
      placeholder: 'Sin especificar',
      options: SI_NO
    }
  },

  {
    label: 'Mantenimiento extraordinario',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'validacion',
      'mantenimientoExtraordinario'
    ],
    meta: {
      placeholder: 'Sin especificar',
      options: SI_NO
    }
  },

  {
    label: 'Observaciones',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'validacion',
      'observaciones'
    ]
  },

  {
    label: 'Auditado',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'validacion',
      'auditado'
    ],
    meta: {
      placeholder: 'Sin especificar',
      options: SI_NO
    }
  },

  {
    label: 'Fotos antes ejecución',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'validacion',
      'antesFotos'
    ],
    meta: { isPhotos: true }
  },

  {
    label: 'Fotos después ejecución',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'alumbrado',
      familyFragment,
      'validacion',
      'despuesFotos'
    ],
    meta: { isPhotos: true }
  }
]
