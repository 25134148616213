const toLocal = state => state.ordinary_operation_details

export function getById(state, id) {
  return toLocal(state).byId[id]
}

export function isFetching(state) {
  return toLocal(state).isFetching
}

export function getFetchingId(state) {
  return toLocal(state).fetchingId
}

export function hasError(state) {
  return Boolean(toLocal(state).lastError)
}

export function getRelatedAssetsIsFetching(state) {
  return toLocal(state).relatedAssets.isFetching
}

export function getRelatedAssetsError(state) {
  return toLocal(state).relatedAssets.error
}

export function getRelatedAssets(state) {
  return toLocal(state).relatedAssets.assets
}
