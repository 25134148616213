import { FIELD_CARD_TYPES } from '../../modules/queries/constants'

export const REGISTRO_GENERAL = 'registroGeneral'
export const INDICADORES = 'indicadores'

export const getCommonGeneralInfo = () => [
  {
    label: 'Clave proyecto',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'claveProyecto'],
    name: `${REGISTRO_GENERAL}.claveProyecto`
  },
  {
    label: 'Ceco',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'ceco'],
    name: `${REGISTRO_GENERAL}.ceco`
  },
  {
    label: 'Cebes',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'cebes'],
    unwindPath: `${REGISTRO_GENERAL}.cebes`,
    name: `${REGISTRO_GENERAL}.cebes`
  }
]

export const getIAOPEXCAPEX = () => [
  {
    label: 'Nº I.A.',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'iaOpexCapex', 'numero'],
    name: `${REGISTRO_GENERAL}.iaOpexCapex.numero`
  },

  {
    label: 'Fecha I.A.',
    type: FIELD_CARD_TYPES.Date,
    path: [`${REGISTRO_GENERAL}`, 'iaOpexCapex', 'fecha'],
    name: `${REGISTRO_GENERAL}.iaOpexCapex.fecha`
  }
]

export const getContrato = () => [
  {
    label: 'Nº Pedido',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'contrato', 'numPedido'],
    name: `${REGISTRO_GENERAL}.contrato.numPedido`
  },
  {
    label: 'Nº Contrato',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'contrato', 'numContrato'],
    name: `${REGISTRO_GENERAL}.contrato.numContrato`
  }
]

export const getAutopistasGeneral = () => [
  {
    label: 'Autopistas',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'autopistas'],
    unwindPath: `${REGISTRO_GENERAL}.autopistas`,
    name: `${REGISTRO_GENERAL}.autopistas`
  }
]

export const getEmpresaAdjudicatariaGeneral = () => [
  {
    label: 'Empresa Adjudicataria',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'adjudicataria'],
    name: `${REGISTRO_GENERAL}.adjudicataria`
  }
]

export const getConceptoGeneral = () => [
  {
    label: 'Concepto',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'concepto'],
    name: `${REGISTRO_GENERAL}.concepto`
  }
]

export const getPrevSAPySegSidebarFields = path => [
  {
    label: 'Mes Previsión SAP',
    type: FIELD_CARD_TYPES.MonthYear,
    path: [...`${path}`.split('.'), 'prevision', 'mes', 'formatYYYYMM'],
    unwindPath: `${path}.prevision`,
    name: `${path}.prevision.mes.formatYYYYMM`
  },
  {
    label: 'Presupuesto Previsión SAP',
    type: FIELD_CARD_TYPES.Monetary,
    path: [...`${path}`.split('.'), 'prevision', 'presupuestoMes'],
    unwindPath: `${path}.prevision`,
    name: `${path}.prevision.presupuestoMes`
  },
  {
    label: 'Presupuesto Previsión SAP origen',
    type: FIELD_CARD_TYPES.Monetary,
    path: [...`${path}`.split('.'), 'prevision', 'presupuestoOrigen'],
    unwindPath: `${path}.prevision`,
    name: `${path}.prevision.presupuestoOrigen`
  },
  {
    label: 'Mes seguimiento',
    type: FIELD_CARD_TYPES.MonthYear,
    path: [...`${path}`.split('.'), 'seguimiento', 'mes', 'formatYYYYMM'],
    unwindPath: `${path}.seguimiento`,
    name: `${path}.seguimiento.mes.formatYYYYMM`
  },
  {
    label: 'Presupuesto cert. mes',
    type: FIELD_CARD_TYPES.Monetary,
    path: [...`${path}`.split('.'), 'seguimiento', 'presupuestoMes'],
    unwindPath: `${path}.seguimiento`,
    name: `${path}.seguimiento.presupuestoMes`
  },
  {
    label: 'Presupuesto cert. origen',
    type: FIELD_CARD_TYPES.Monetary,
    path: [...`${path}`.split('.'), 'seguimiento', 'presupuestoOrigen'],
    unwindPath: `${path}.seguimiento`,
    name: `${path}.seguimiento.presupuestoOrigen`
  },
  {
    label: 'Desvío económico mensual (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [...`${path}`.split('.'), 'seguimiento', 'desvio'],
    unwindPath: `${path}.seguimiento`,
    name: `${path}.seguimiento.desvio`
  },
  {
    label: 'Avance mensual origen (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [...`${path}`.split('.'), 'seguimiento', 'avance'],
    unwindPath: `${path}.seguimiento`,
    name: `${path}.seguimiento.avance`
  }
]

export const getPrevSAPySegColumnsFields = path => [
  ...getPrevSAPySegSidebarFields(path),
  {
    label: 'Observaciones seguimiento mensual',
    name: `${path}.seguimiento.comentarios`
  }
]

export const getPresupuestoSAP = path => [
  {
    label: 'Presupuesto planificado SAP',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${path}`, 'presupuesto'],
    name: `${path}.presupuesto`
  }
]

export const getMaterialesSeguEconomico = path => [
  {
    label: 'Materiales',
    type: FIELD_CARD_TYPES.String,
    path: [`${path}`, 'materiales'],
    unwindPath: `${path}.materiales`,
    name: `${path}.materiales`
  }
]
