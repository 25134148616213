import XLSX from 'xlsx'
import { flatten } from 'lodash'
import { getOr } from 'lodash/fp'

import { getAssetFieldLabel } from '../../core/assets/assetFields'
import {
  parseDateToForInput,
  matchDateYYYYMM,
  matchDateDDMMYYYY,
  formatMonthYear
} from '../../core/dates/index'
import { isOnI3Met } from '../../core/assets/assetTypes'
import { getFamilyTypeByLabel } from '../../core/operations/ordinary/partesDeTrabajoFamiliasFormFields/index'
import { getOpFieldLabel } from '../../core/queries/operationFields'
import { getWorkReportsFieldLabel } from '../../core/queries/workReportFields'

import { FIELD_CARD_TYPES, GEOJSON_VALUE } from './constants'

export const formatValue = (value, fileFormat) => {
  try {
    switch (typeof value) {
      case 'boolean':
        return value.toString()
      case 'number':
        return Number(value)
      case 'string':
        if (matchDateYYYYMM(value)) return formatMonthYear(value)
        if (
          matchDateDDMMYYYY(value) ||
          (value && value.match(/^(\d{4})-(\d{2})-(\d{2})/))
        )
          return parseDateToForInput(value, 'DD/MM/YYYY')

        return value.replace(/"/g, '""')
      case 'object':
        return null
      default:
        return value
    }
  } catch (err) {
    console.log('Error in formatValue', { value, fileFormat, err })
    return String(value)
  }
}

const getSheetHeaders = ({
  headers,
  workReportColumnFields,
  queryType,
  subQueryType,
  showReadableColumns
}) =>
  headers.map(field => {
    if (queryType === 'assets') {
      if (field === 'codigo' && isOnI3Met(subQueryType)) return 'Código i3met'
      else return showReadableColumns ? getAssetFieldLabel(field) : field
    } else if (queryType === 'workReports') {
      return showReadableColumns
        ? getWorkReportsFieldLabel(workReportColumnFields, field)
        : field
    } else
      return showReadableColumns ? getOpFieldLabel(subQueryType, field) : field
  })

export const convertResult = ({
  headers,
  workReportColumnFields,
  result,
  queryType,
  subQueryType,
  filename,
  showReadableColumns,
  exportType
}) => {
  const fileHeaders = getSheetHeaders({
    headers,
    workReportColumnFields,
    queryType,
    subQueryType,
    showReadableColumns
  })

  const fileResults = result.map(e =>
    headers.map(field => `${formatValue(getOr('', field.split('.'), e))}`)
  )

  const worksheet = XLSX.utils.aoa_to_sheet([fileHeaders, ...fileResults])
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, subQueryType)
  XLSX.writeFile(workbook, filename, { bookType: exportType })
}

export const convertResultToGeoJSON = (
  headers,
  result,
  fn,
  subQueryType,
  showReadableColumns
) => {
  const features = result.map(e => {
    const geometry = fn ? fn(e.geometry) : e.geometry
    delete e.geometry

    headers.forEach(field => {
      const value = formatValue(getOr('', field.split('.'), e), GEOJSON_VALUE)

      if (field === 'codigo' && isOnI3Met(subQueryType))
        e['Código i3met'] = value
      else if (e[field]) {
        const label = showReadableColumns ? getAssetFieldLabel(field) : field
        e[label] = value
      }

      if (showReadableColumns) delete e[field]
    })

    return `{
      "type": "Feature",
        "geometry": ${JSON.stringify(geometry)},
        "properties": ${JSON.stringify(e)}
    }`
  })

  return `{
    "type": "FeatureCollection",
    "features": [${features}]
  }`
}

const execDownload = element => {
  element.style.display = 'none'
  document.body.appendChild(element)
  console.log(element)
  element.click()

  document.body.removeChild(element)
}

export const downloadTxtFile = (filename, text) => {
  let element = document.createElement('a')
  const encodeText = encodeURIComponent(text)

  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeText)
  element.setAttribute('download', filename)

  execDownload(element)
}

export const downloadFile = ({
  filename,
  queryColumns,
  workReportColumnFields,
  result,
  queryType,
  subQueryType,
  showReadableColumns,
  exportType
}) => {
  let element = document.createElement('input')
  element.setAttribute('type', 'submit')
  element.setAttribute(
    'onclick',
    convertResult({
      headers: queryColumns,
      workReportColumnFields,
      result,
      queryType,
      subQueryType,
      filename,
      showReadableColumns,
      exportType
    })
  )

  execDownload(element)
}

export const trasformToPoint = geometry =>
  geometry.type !== 'Point'
    ? {
        type: 'Point',
        coordinates: geometry.coordinates[0]
      }
    : geometry

export const buildStringQuery = (fieldName, filter) => {
  const { includeNulls, unwindPath } = filter

  const buildQuery = fieldName =>
    includeNulls && fieldName !== 'ordinaryOperationId'
      ? {
          $or: [
            {
              [fieldName]: { $exists: false }
            },
            { [fieldName]: { $in: filter.$in } }
          ]
        }
      : { [fieldName]: { $in: filter.$in } }

  const unwindFieldName = fieldName.split(`${unwindPath}.`)[1]

  const queryFieldFilter = buildQuery(unwindFieldName || fieldName)

  const queryFilters = {
    beforeUnwind: unwindFieldName
      ? { [unwindPath]: { $elemMatch: queryFieldFilter } }
      : queryFieldFilter,
    afterUnwind: buildQuery(fieldName)
  }

  return queryFilters
}

export const buildIntervalQuery = (fieldName, filter) => {
  const { includeNulls, $gte, $lte, unwindPath } = filter

  const unwindFieldName = fieldName.split(`${unwindPath}.`)[1]

  const buildQuery = fieldName => {
    let query = {}
    if (includeNulls) {
      query = {
        $or: [{ [fieldName]: { $exists: false } }, { [fieldName]: null }]
      }

      if ($gte && $lte) {
        query.$or.push({
          $and: [{ [fieldName]: { $lte } }, { [fieldName]: { $gte } }]
        })
      } else {
        if ($gte) query.$or.push({ [fieldName]: { $gte } })
        if ($lte) query.$or.push({ [fieldName]: { $lte } })
      }
    } else {
      query = { [fieldName]: {} }
      if ($gte) query[fieldName].$gte = $gte
      if ($lte) query[fieldName].$lte = $lte
    }
    return query
  }

  const queryFieldFilter = buildQuery(unwindFieldName || fieldName)
  const queryFilters = {
    beforeUnwind: unwindFieldName
      ? { [unwindPath]: { $elemMatch: queryFieldFilter } }
      : queryFieldFilter,
    afterUnwind: buildQuery(fieldName)
  }

  return queryFilters
}

/**
 * Converts a boolean filter data to query data
 * @param {String} fieldName
 * @param {Object} filter
 * @returns {Object} query data
 */
export const buildBooleanQuery = (fieldName, filter) => {
  const { value, unwindPath } = filter

  const unwindFieldName = fieldName.split(`${unwindPath}.`)[1]

  const buildQuery = fieldName =>
    value ? { [fieldName]: { $eq: true } } : { [fieldName]: { $ne: true } }

  const queryFieldFilter = buildQuery(unwindFieldName || fieldName)

  const queryFilters = {
    beforeUnwind: unwindFieldName
      ? { [unwindPath]: { $elemMatch: queryFieldFilter } }
      : queryFieldFilter,
    afterUnwind: buildQuery(fieldName)
  }

  return queryFilters
}

/**
 * Converts a families filter data to query data
 * @param {Object} filter
 * @returns {Object} query data
 */
export const buildFamiliesQuery = (_, filter) => {
  const { $in, unwindPath } = filter

  const selectedFamilies = flatten($in.map(e => getFamilyTypeByLabel(e)))

  const buildQuery = beforeUnwind =>
    selectedFamilies.reduce(
      (acc, family) => [
        ...acc,
        {
          [beforeUnwind
            ? `${unwindPath}.${family}.0`
            : `${unwindPath}.${family}`]: { $exists: true }
        }
      ],
      []
    )

  return {
    beforeUnwind: { $or: buildQuery(true) },
    afterUnwind: { $or: buildQuery(false) }
  }
}

export const buildQueryFilters = (filters, model, subQueryType) => {
  let queryFilters = {
    beforeUnwind: {},
    afterUnwind: {}
  }

  if (model === 'asset') {
    queryFilters.beforeUnwind.assetType = subQueryType
    queryFilters.afterUnwind.assetType = subQueryType
  }

  for (const filter in filters) {
    const { buildQueryFn, formatFn } = filters[filter]
    const { $gte, $lte } = filters[filter]
    const { includeTrue, includeFalse } = filters[filter]

    switch (filters[filter].type) {
      case FIELD_CARD_TYPES.Date:
      case FIELD_CARD_TYPES.MonthYear:
      case FIELD_CARD_TYPES.Number:
      case FIELD_CARD_TYPES.Monetary:
        if ($gte) filters[filter].$gte = formatFn($gte)
        if ($lte) filters[filter].$lte = formatFn($lte)
        break
      case FIELD_CARD_TYPES.Boolean:
        //If both are selected, not use in query
        if (!(includeTrue && includeFalse)) {
          if (includeTrue) filters[filter].value = true
          if (includeFalse) filters[filter].value = false
        }
        break
      case FIELD_CARD_TYPES.String:
      case FIELD_CARD_TYPES.Families:
      default:
        break
    }

    if (buildQueryFn) {
      const { beforeUnwind, afterUnwind } = buildQueryFn(
        filter,
        filters[filter]
      )
      queryFilters = {
        beforeUnwind: { ...queryFilters.beforeUnwind, ...beforeUnwind },
        afterUnwind: { ...queryFilters.afterUnwind, ...afterUnwind }
      }
    }
  }
  return queryFilters
}
