import app from '../../feathers'

export async function searchPKCoord(options) {
  const svc = app.service(`/searchPKCoord`)

  const data = { conditions: { ...options } }
  return svc.create(data)
}

export async function fetchAxePKs(conditions) {
  const svc = await app.service(`/searchDistinctPKs`)

  const data = {
    conditions,
    model: 'pk'
  }

  return svc.create(data)
}
