const MODULE = 'Documents'

export const FETCH_DOCUMENTS_REQUEST = `${MODULE}/FETCH_DOCUMENTS_REQUEST`
export const FETCH_DOCUMENTS_SUCCESS = `${MODULE}/FETCH_DOCUMENTS_SUCCESS`
export const FETCH_DOCUMENTS_FAILED = `${MODULE}/FETCH_DOCUMENTS_FAILED`
export const SET_EDITING = `${MODULE}/SET_EDITING`
export const SET_CREATING = `${MODULE}/SET_CREATING`
export const SET_SEARCH_VALUE = `${MODULE}/SET_SEARCH_VALUE`
export const SET_CURRENT_PAGE = `${MODULE}/SET_CURRENT_PAGE`
export const SET_DOC_TYPE = `${MODULE}/SET_DOC_TYPE`
export const SET_SELECTED_CECO = `${MODULE}/SET_SELECTED_CECO`
export const SET_RESULTS_PER_PAGE = `${MODULE}/SET_RESULTS_PER_PAGE`
export const SET_MODAL_VALUES = `${MODULE}/SET_MODAL_VALUES`
