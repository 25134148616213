import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Icon from '../icons/Icon'

const Button = props => {
  const {
    onClick,
    iconName,
    hasText,
    text,
    variant,
    fullWidth,
    disabled,
    type
  } = props
  const className = cx('button', `button-${variant}`, {
    'full-width': fullWidth
  })
  return (
    <button
      className={className}
      onClick={onClick}
      onTouchStart={onClick}
      disabled={disabled}
      type={type}
    >
      {iconName ? <Icon name={iconName} /> : null}
      <span className={hasText ? null : 'reader-only'}>{text}</span>
    </button>
  )
}

Button.defaultProps = {
  onClick: function() {},
  hasText: true,
  fullWidth: false,
  disabled: false,
  text: 'Button',
  variant: 'primary',
  type: 'button'
}

Button.propTypes = {
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'quaternary',
    'mobile'
  ]).isRequired,
  fullWidth: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  iconName: PropTypes.string,
  hasText: PropTypes.bool,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export default Button
