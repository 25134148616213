import { buildReducer } from '../utils'
import * as ActionTypes from './actionTypes'
export * from './actions'
export * from './selectors'
export * from './constants'

const initialState = {
  isLoggedIn: false,
  hasForbiddenError: false,
  token: {},
  data: {}
}

const sessionReducer = buildReducer(initialState, {
  [ActionTypes.LOGGED_IN_CHANGED]: (state, action) => {
    return {
      ...state,
      isLoggedIn: Boolean(action.payload)
    }
  },
  [ActionTypes.STORE_USER_DATA]: (state, action) => {
    return {
      ...state,
      isLoggedIn: true,
      data: { ...action.payload }
    }
  },
  [ActionTypes.STORE_TOKEN]: (state, action) => {
    return {
      ...state,
      token: { ...action.payload }
    }
  },
  [ActionTypes.STORE_FORBIDDEN_ERROR]: (state, action) => {
    const {
      payload: { isLoginRequired }
    } = action
    return {
      ...state,
      isLoggedIn: !isLoginRequired,
      hasForbiddenError: true
    }
  },
  [ActionTypes.CLEAR_FORBIDDEN_ERROR]: state => ({
    ...state,
    hasForbiddenError: false
  })
})

export default sessionReducer
