import { flatten, size } from 'lodash/fp'
import * as ActionTypes from './actionTypes'
import { getById, getMyLocationData } from './selectors'
import app from '../../feathers'
import {
  changeLabelsIfNotExitsFinalPK,
  changeCodeIfIsAnStructure
} from './utils'
import { setMapCenter, setMapZoom } from '../mapview/googleMapMiddleware'
import {
  setAssetFocus,
  setCheckedTo,
  getSelectedAssetTypes,
  addAsset,
  endAssetMove
} from '../assets'
import { getMapZoom } from '../mapview'
import { MIN_ZOOM_LEVEL } from '../assets/constants'
import { getLatLngFromAsset } from '../../core/gmaps'
import TYPES from '../../core/assets/assetTypes'
import ability from '../../core/auth/ability'
import { RESOURCES } from '../../core/auth'

export function fetchDetailsRequest(id) {
  return {
    type: ActionTypes.FETCH_DETAILS_REQUEST,
    id
  }
}

export function fetchDetailsSuccess(id, data) {
  return {
    type: ActionTypes.FETCH_DETAILS_SUCCESS,
    id,
    payload: data
  }
}

export function fetchDetailsFailed(id, error) {
  return {
    type: ActionTypes.FETCH_DETAILS_FAILED,
    id,
    collection: 'assets',
    error: error.message || error.toString()
  }
}

async function fetchById(id) {
  return app.service('/assets').get(id, { query: { $autopopulate: true } })
}

export function fetchDetails(id, force = false) {
  return async function(dispatch, getState) {
    const existingAsset = getById(getState(), id)
    if (existingAsset && !force) {
      return existingAsset
    }
    dispatch(fetchDetailsRequest(id))
    try {
      const asset = await fetchById(id)
      let assetFormatted = changeLabelsIfNotExitsFinalPK(asset)
      assetFormatted = changeCodeIfIsAnStructure(asset)
      dispatch(fetchDetailsSuccess(id, assetFormatted))
      return assetFormatted
    } catch (err) {
      console.warn('Error fetching details', err)
      dispatch(fetchDetailsFailed(id, err))
      return null
    }
  }
}

export function fetchDetailsAndFocusMap(id) {
  return async function(dispatch, getState) {
    try {
      const asset = await dispatch(fetchDetails(id))
      if (!asset || size(asset) === 0) {
        console.log('Fetch details and focus map no asset', asset)
        return
      }
      const assetCenter = getLatLngFromAsset(asset)
      const currentZoom = getMapZoom(getState())
      const isAssetTypeSelected = getSelectedAssetTypes(getState()).includes(
        asset.assetType
      )
      if (!isAssetTypeSelected) {
        dispatch(setCheckedTo([asset.assetType], true))
      }
      //save asset in data layer so it actually appears in the map
      dispatch(addAsset(asset))
      dispatch(setAssetFocus(id))
      dispatch(setMapCenter(assetCenter))
      if (currentZoom <= MIN_ZOOM_LEVEL) {
        dispatch(setMapZoom(18))
      }
      return asset
    } catch (err) {
      console.warn('Error fetching details', err)
      dispatch(fetchDetailsFailed(id, err))
      return null
    }
  }
}

export const addFileToDetails = attachedFile => ({
  type: ActionTypes.ADD_FILE_TO_DETAILS,
  payload: {
    collection: 'assets',
    attachedFile
  }
})

export const removeFileFromDetails = (assetId, attachedFileId, fileType) => ({
  type: ActionTypes.REMOVE_FILE_FROM_DETAILS,
  payload: {
    collection: 'assets',
    assetId,
    attachedFileId,
    fileType
  }
})

export const createImage = (assetId, { name, size, type }, dataURL) => {
  const [, resource] = dataURL.split(',')
  return createMedia({ assetId, name, size, type, resource })
}

export const createMedia = rawMedia =>
  async function(dispatch) {
    try {
      const attachedFile = await app.service('attachedFiles').create(rawMedia)
      dispatch(addFileToDetails(attachedFile))
    } catch (error) {
      console.warn('Error creating media', error)
    }
  }

export const deleteFile = (attachedFileId, assetId, fileType) =>
  async function(dispatch) {
    try {
      await app.service('attachedFiles').remove(attachedFileId)
      dispatch(removeFileFromDetails(assetId, attachedFileId, fileType))
    } catch (error) {
      console.warn('Error deleting attached file', error)
    }
  }

export const showImgCarouselModal = imageIndex => ({
  type: ActionTypes.IMG_CAROUSEL_SHOWN,
  payload: imageIndex
})

export const hideImgCarouselModal = () => ({
  type: ActionTypes.IMG_CAROUSEL_HIDDEN
})

export const showReferenceImageCarouselModal = imageIndex => ({
  type: ActionTypes.REFERENCE_IMAGE_CAROUSEL_SHOWN
})

export const hideReferenceImageCarouselModal = () => ({
  type: ActionTypes.REFERENCE_IMAGE_CAROUSEL_HIDDEN
})

export const fetchRelatedOperationsRequest = () => ({
  type: ActionTypes.FETCH_RELATED_OPERATIONS_REQUEST
})

export const fetchRelatedOperationsError = error => ({
  type: ActionTypes.FETCH_RELATED_OPERATIONS_FAILED,
  error
})

export const fetchRelatedOperationsSuccess = operations => ({
  type: ActionTypes.FETCH_RELATED_OPERATIONS_SUCCESS,
  operations
})

export const clearRelatedOperations = () => ({
  type: ActionTypes.CLEAR_RELATED_OPERATIONS
})

export const fetchRelatedOperations = assetId => async dispatch => {
  dispatch(fetchRelatedOperationsRequest())

  try {
    const workReports = await fetchRelatedWorkReportsByAssetId(assetId)
    const relatedOrdinaryOperations = workReports.map(workReport => ({
      type: TYPES.CONSERVACION_ORDINARIA,
      operation: workReport
    }))

    const relatedExtraordinaryOperation = fetchRelatedExtraordinaryOperationsByAssetId(
      assetId
    ).then(operations =>
      operations.map(operation => ({
        type: TYPES.CONSERVACION_EXTRAORDINARIA,
        operation
      }))
    )

    const relatedOperations = flatten(
      await Promise.all([
        relatedOrdinaryOperations,
        relatedExtraordinaryOperation
      ])
    )

    dispatch(fetchRelatedOperationsSuccess(relatedOperations))
  } catch (e) {
    dispatch(fetchRelatedOperationsError(e.message))
  }
}

async function fetchRelatedWorkReportsByAssetId(assetId) {
  // if user cannot read ordinary ops, return an empty array
  // for the Promise.all above in fetchRelatedOperations
  if (!ability.can('read', RESOURCES.OP_ORDINARY)) {
    return Promise.resolve([])
  }
  return app.service('operations/work-report').find({
    query: {
      isDeleted: { $ne: true },
      $or: [
        { 'drenajesTransversales.identificacion.activos': assetId },
        { 'drenajesSuperficiales.identificacion.activos': assetId },
        { 'drenajesProfundos.identificacion.activos': assetId },
        { 'intervencionesVallaCierre.identificacion.activos': assetId },
        { 'limpiezasDesbrocesODs.identificacion.activos': assetId },
        { 'desbrocesTaludes.identificacion.activos': assetId },
        { 'limpiezasPista.identificacion.activos': assetId },
        { 'reparacionesFirmesEstructuras.identificacion.activos': assetId },
        { 'elementosSeñalizacionBalizamiento.identificacion.activos': assetId },
        { 'elementosContencion.identificacion.activos': assetId },
        { 'alumbrado.identificacion.activos': assetId }
      ],
      $autopopulate: ['ordinaryOperationId'],
      $select: [
        'creador',
        'fecha',
        'horaEntrada',
        'horaSalida',
        'ordinaryOperationId'
      ]
    }
  })
}

async function fetchRelatedExtraordinaryOperationsByAssetId(assetId) {
  if (!ability.can('read', RESOURCES.OP_EXTRAORDINARY)) {
    return Promise.resolve([])
  }
  return app.service('operations/extraordinary').find({
    query: {
      isDeleted: { $ne: true },
      'seguimientoObra.activos.activos': assetId,
      $select: ['registroGeneral.claveProyecto', 'createdAt']
    }
  })
}

export function moveAssetToMyLocation(
  assetId,
  { latitude = 0, longitude = 0 }
) {
  if (!assetId) throw new Error('moveAssetToMyLocation expects assetId!')
  return {
    type: ActionTypes.MOVED_TO_MY_LOCATION,
    payload: {
      assetId,
      latitude,
      longitude
    }
  }
}

export function cancelMoveAssetToMyLocation() {
  return {
    type: ActionTypes.MOVE_CANCELED
  }
}

export function saveAssetLocation() {
  return function(dispatch, getState) {
    const { assetId, latitude, longitude } = getMyLocationData(getState())
    const newGeometry = {
      type: 'Point',
      coordinates: [longitude, latitude]
    }
    return app
      .service('assets')
      .patch(assetId, {
        geometry: newGeometry,
        X: longitude,
        Y: latitude
      })
      .then(data => {
        dispatch(fetchDetailsSuccess(assetId, data))
        dispatch(cancelMoveAssetToMyLocation())
        dispatch(
          endAssetMove(assetId, {
            newGeometry
          })
        )
        dispatch(setMapCenter({ lat: latitude, lng: longitude }))
        return true
      })
  }
}
