const toLocal = state => state.asset_details

export function getById(state, id) {
  return toLocal(state).byId[id]
}

export function isFetching(state) {
  return toLocal(state).isFetching
}

export function getFetchingId(state) {
  return toLocal(state).fetchingId
}

export function hasError(state) {
  return Boolean(toLocal(state).lastError)
}

export function isImgCarouselOpen(state) {
  return toLocal(state).imgCarousel.isVisible
}

export function getCarouselCurrentIndex(state) {
  return toLocal(state).imgCarousel.currentIndex
}

export function isReferenceImageCarouselOpen(state) {
  return toLocal(state).referenceImageCarousel.isVisible
}

export function getReferenceImageCarouselCurrentIndex(state) {
  return toLocal(state).referenceImageCarousel.currentIndex
}

export function getRelatedOperationsIsFetching(state) {
  return toLocal(state).relatedOperations.isFetching
}

export function getRelatedOperationsError(state) {
  return toLocal(state).relatedOperations.error
}

export function getRelatedOperations(state) {
  return toLocal(state).relatedOperations.operations
}

export function getMyLocationData(state) {
  const { latitude, longitude, assetId } = toLocal(state).myLocation

  return {
    latitude,
    longitude,
    assetId
  }
}
