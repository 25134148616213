import { uniq, allPass, eq, propOr, pipe, filter, pluck, prop } from 'lodash/fp'

const objArrToOptionList = (arr = [], filter) =>
  arr
    .filter(e => e[filter] !== '')
    .map(e => ({ label: e[filter], value: e._id }))

const elemArrToOptionList = (arr = []) =>
  arr.filter(e => e !== '').map(e => ({ label: String(e), value: e }))

export const arrToOptionList = (arr = [], filter) => {
  if (filter) return objArrToOptionList(arr, filter)
  else return elemArrToOptionList(arr)
}

export const filterByConditions = (conditions = [], arr = []) =>
  arr.filter(allPass(conditions))

export const filterEquals = (filter, value) =>
  pipe(
    propOr(null, [`${filter}`]),
    eq(value)
  )

/**
 * @param String
 * @param [Function]
 * @param [Object]
 *
 */
export const filterAndPluck = (propName, conditions = [], arr = []) =>
  pipe(
    filter(allPass(conditions)),
    pluck(prop(propName)),
    uniq
  )(arr)
