import { getOr, find, mergeWith, isArray } from 'lodash/fp'

import * as FamilyFields from './families'
import {
  getOrdinaryOpSidebarFields,
  getOrdinaryOpColumnsFields,
  SEGUIMIENTO_ECONOMICO
} from './ordinaryOpFields'
import {
  getExtraordinaryOpSidebarFields,
  getExtraordinaryOpColumnsFields,
  SEG_PRESUPUESTARIO,
  SEG_OBRA
} from './extraordinaryOpFields'
import {
  getWorkOpSidebarFields,
  getWorkOpColumnsFields,
  SEGUIMIENTO,
  FINALIZACION
} from './workOpFields'

import TYPES from '../assets/assetTypes'
import { FAMILY_TYPES } from '../operations/ordinary/partesDeTrabajoFamiliasFormFields'
import { REGISTRO_GENERAL, INDICADORES } from './commonOperationFields'

export const DATA_BLOCK_LABELS_OPERATION = {
  [REGISTRO_GENERAL]: 'Registro General',
  [SEGUIMIENTO_ECONOMICO]: 'Seguimiento Económico',
  [SEG_PRESUPUESTARIO]: 'Seguimiento Presupuestario',
  [SEG_OBRA]: 'Seguimiento de Obra',
  [INDICADORES]: 'Indicadores',
  [SEGUIMIENTO]: 'Seguimiento',
  [FINALIZACION]: 'Finalización'
}

const sidebarOpFields = {
  [TYPES.CONSERVACION_ORDINARIA]: getOrdinaryOpSidebarFields(),
  [TYPES.CONSERVACION_EXTRAORDINARIA]: getExtraordinaryOpSidebarFields(),
  [TYPES.OBRAS]: getWorkOpSidebarFields()
}

const columnsOpFields = {
  [TYPES.CONSERVACION_ORDINARIA]: getOrdinaryOpColumnsFields(),
  [TYPES.FAMILIAS]: {
    [FAMILY_TYPES.DRENAJES_TRANSVERSALES]: FamilyFields.getDrenajeTransversalFields(),
    [FAMILY_TYPES.DRENAJES_SUPERFICIALES]: FamilyFields.getDrenajeSuperficialFields(),
    [FAMILY_TYPES.DRENAJES_PROFUNDOS]: FamilyFields.getDrenajeProfundoFields(),
    [FAMILY_TYPES.INTERV_VALLA_CIERRE]: FamilyFields.getVallaCierreFields(),
    [FAMILY_TYPES.LIMPIEZAS_DESBROCES_ODS]: FamilyFields.getLimpiezaDesbroceODFields(),
    [FAMILY_TYPES.JARDINERIAS_TRONCO]: FamilyFields.getJardineriaTroncoFields(),
    [FAMILY_TYPES.JARDINERIAS_ENLACE]: FamilyFields.getJardineriaEnlacesFields(),
    [FAMILY_TYPES.DESBROCES_TALUDES]: FamilyFields.getDesbroceTaludesFields(),
    [FAMILY_TYPES.LIMPIEZAS_PISTA]: FamilyFields.getLimpiezaPistaFields(),
    [FAMILY_TYPES.REPARAC_FIRMES_ESTRUCTURAS]: FamilyFields.getReparacionFirmesFields(),
    [FAMILY_TYPES.ELEM_SENALIZ_BALIZAMIENTO]: FamilyFields.getElemenSenalBalizamientoFields(),
    [FAMILY_TYPES.ELEM_CONTENCION]: FamilyFields.getElemContencion()
  },
  [TYPES.CONSERVACION_EXTRAORDINARIA]: getExtraordinaryOpColumnsFields(),
  [TYPES.OBRAS]: getWorkOpColumnsFields()
}

export const getOpSidebarFieldsByType = opType => sidebarOpFields[opType] || {}
export const getOpColumnsFieldsByType = opType => columnsOpFields[opType] || {}
export const getFamiliesColumnsFields = families =>
  families.reduce(
    (acc, family) => ({
      ...acc,
      ...{ [family]: columnsOpFields[TYPES.FAMILIAS][family] }
    }),
    {}
  )

const customizer = (objValue, srcValue) => {
  if (isArray(objValue)) return objValue.concat(srcValue)
}

/**
 * Returns a field config data from an operation type
 * and field name (field name contains "path")
 * @param {String} opType
 * @param {String} fieldName  "registroGeneral.claveProyecto"
 * @returns Object field data
 */
export function getOperationField(opType, fieldName) {
  const fieldsWithoutFamilies = mergeWith(
    customizer,
    getOpSidebarFieldsByType(opType),
    getOpColumnsFieldsByType(opType)
  )

  const fieldList = getOr([], fieldName.split('.')[0], fieldsWithoutFamilies)
  return find(x => x.name === fieldName, fieldList)
}

export const getOpFieldLabel = (opType, fieldName) => {
  const field = getOperationField(opType, fieldName)
  return getOr(fieldName, 'label', field)
}
