import PropTypes from 'prop-types'
import { isUndefined } from 'lodash'

export function getValueWithPath(object, path = []) {
  return path.reduce((v, fragment) => {
    // On path not found, return undefined indefinitely
    if (v == null) {
      return undefined
    }

    return findItemFromFragment(v, fragment)
  }, object)
}

export function setValueWithPath(object, path = '', value) {
  // First we walk to the second-to-last sub-path (find parent of value)
  const parent = path.slice(0, -1).reduce((v, fragment, i) => {
    const indexOrKey = findIndexOrKeyFromFragment(v, fragment)

    if (indexOrKey < 0) {
      throw new Error('Could not find fragment')
    }

    // If we are trying to set value into non-existent sub-path, create it
    if (v[indexOrKey] === undefined) {
      const nextFragment = path[i + 1]

      v[indexOrKey] =
        // If fragment is number (array index) or has `_id`/`_temp_id`...
        Number.isFinite(nextFragment) ||
        nextFragment._id ||
        nextFragment._temp_id
          ? [] // ...we are implicitly setting a value inside an array, create it
          : {} // ...otherwise create object
    }

    return v[indexOrKey]
  }, object)

  // We are on second-to-last sub-path, create value
  const lastFragment = path[path.length - 1]

  const indexOrKey = findIndexOrKeyFromFragment(parent, lastFragment)
  parent[indexOrKey] = value

  return object
}

// Turns a dataPath into a '-' separated string (useful to use as unique id or
// as a React key)
export function dataPathAsString(dataPath) {
  return dataPath
    .map(fragment => {
      if (fragment._id) return `$[id${fragment._id}]`
      if (fragment._temp_id) return `$[temp_id${fragment._temp_id}]`

      return fragment.toString()
    })
    .join('-')
}

export function extractPathFragment(item, i) {
  return item._id
    ? { _id: item._id }
    : item._temp_id
    ? { _temp_id: item._temp_id }
    : i
}

// Useful to use as `key` in React
export function extractPathFragmentValue(item, i) {
  return item._id || item._temp_id || i
}

export const findItemFromFragment = (items = [], fragment) => {
  // If path fragment is an array index, return indexed
  try{
    if (Number.isFinite(fragment)) {
      return items[fragment]
    }
  
    // If path fragment has `_id`, find subdocument
    if (fragment._id && Array.isArray(items)) {
      return items.find(x => x._id === fragment._id)
    }
  
    // Same for `_temp_id`
    if (fragment._temp_id && Array.isArray(items)) {
      return items.find(x => x._temp_id === fragment._temp_id)
    }
  
    // Return object sub-path
    return items[fragment]
  }
  catch(err){
    console.warn('Error in impossible function', err)
    return null
  }
  
}

export const findIndexOrKeyFromFragment = (items, fragment) => {
  // If path fragment has `_id` return subdocument index
  if (fragment._id) {
    return items.findIndex(x => x._id === fragment._id)
  }

  // Same for `_temp_id`
  if (fragment._temp_id) {
    return items.findIndex(x => x._temp_id === fragment._temp_id)
  }

  // Fragment is assumed to be an index (or key)
  return fragment
}

// Useful to get an array index form a React `key` generated using `extractPathFragmentValue`
export const findArrayIndexFromFragmentValue = (items, key) => {
  if (Number.isFinite(key)) {
    return key
  }

  const fromId = items.findIndex(item => item._id === key)

  if (fromId >= 0) {
    return fromId
  }

  const fromTempId = items.findIndex(item => item._temp_id === key)

  if (fromTempId >= 0) {
    return fromTempId
  }

  return -1
}

// Useful to replace temp ids in paths before sending to mongo
export const getPathWithTempIdsAsIndices = (object, path) => {
  return path.map((fragment, i) => {
    if (isUndefined(fragment._temp_id)) {
      return fragment
    }

    const parent = getValueWithPath(object, path.slice(0, i))
    const index = findArrayIndexFromFragmentValue(parent, fragment._temp_id)

    if (index < 0) {
      throw new Error('Could not find temp id in parent')
    }

    return index
  })
}

export const ArrayFragmentPropType = PropTypes.oneOfType([
  PropTypes.shape({ _id: PropTypes.string }),
  PropTypes.shape({ _temp_id: PropTypes.string }),
  PropTypes.number
])

export const FragmentPropType = PropTypes.oneOfType([
  ArrayFragmentPropType,
  PropTypes.string
])

export const PropType = PropTypes.arrayOf(FragmentPropType)
