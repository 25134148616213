import { buildReducer } from "../utils";
import { TOGGLE_ITEM_COLLAPSE } from './actionTypes'
export * from './actions'
export * from './selectors'

const initialState = {
}

export default buildReducer(initialState, {
  [TOGGLE_ITEM_COLLAPSE]: (state, action) => {
    const currentValue = state[action.name] === undefined ? true : state[action.name]
    return {
      ...state,
      [action.name]: !currentValue
    }
  }
})