import difference from 'lodash/difference'
import omit from 'lodash/omit'
import { buildReducer } from '../utils'
import { buildBooleanQuery } from './utils'
import * as ActionTypes from './actionTypes'
import { MODE_FILTERS, FIELD_CARD_TYPES } from './constants'
export * from './actions'
export * from './selectors'

const defaultFilters = {
  isDeleted: {
    type: FIELD_CARD_TYPES.Boolean,
    includeFalse: true,
    buildQueryFn: buildBooleanQuery
  }
}

const initialState = {
  queryType: '',
  subQueryType: '',
  fields: [],
  mode: MODE_FILTERS,
  filters: defaultFilters,
  columns: [],
  resultsCount: 0,
  result: [],
  currentPage: 1,
  resultsPerPage: 25,
  isExporting: false,
  exportType: '',
  isFetching: false,
  hasFetchError: false,
  showReadableColumns: true
}

export default buildReducer(initialState, {
  [ActionTypes.SET_QUERY_TYPE]: (state, action) => {
    return {
      ...state,
      queryType: action.queryType,
      subQueryType: '',
      fields: [],
      resultsCount: 0,
      result: []
    }
  },
  [ActionTypes.SET_SUB_QUERY_TYPE]: (state, action) => {
    return {
      ...state,
      subQueryType: action.subQueryType,
      fields: [],
      filters: { ...defaultFilters, subQueryType: action.subQueryType },
      columns: [],
      currentPage: 1,
      resultsPerPage: 25
    }
  },
  [ActionTypes.ADD_FIELDS]: (state, action) => {
    return {
      ...state,
      fields: [...state.fields, ...difference(action.fields, state.fields)]
    }
  },
  [ActionTypes.REMOVE_FIELDS]: (state, action) => {
    const { fields } = action

    fields.forEach(field => {
      const cleanField = field.split('_')[1]
      delete state.filters[cleanField]
    })

    return {
      ...state,
      fields: difference(state.fields, fields),
      filters: { ...state.filters }
    }
  },
  [ActionTypes.CLEAR_FIELDS]: state => {
    return {
      ...state,
      fields: [],
      filters: defaultFilters
    }
  },
  [ActionTypes.SET_MODE]: (state, action) => {
    return {
      ...state,
      mode: action.mode
    }
  },
  [ActionTypes.UPDATE_FILTERS]: (state, action) => {
    const { fieldName, filter } = action.payload

    return {
      ...state,
      filters: {
        ...state.filters,
        [fieldName]: filter
      }
    }
  },
  [ActionTypes.REMOVE_FILTER]: (state, action) => {
    const { fieldName } = action.payload

    return {
      ...state,
      filters: omit(state.filters, fieldName)
    }
  },
  [ActionTypes.CLEAR_FILTERS]: state => {
    return {
      ...state,
      filters: defaultFilters
    }
  },
  [ActionTypes.SET_RESULTS_COUNT]: (state, action) => {
    return {
      ...state,
      resultsCount: action.count
    }
  },
  [ActionTypes.SET_WORK_REPORT_COLUMN_FIELDS]: (state, action) => {
    return {
      ...state,
      workReportColumnFields: action.columnFields
    }
  },
  [ActionTypes.ADD_COLUMNS]: (state, action) => {
    return {
      ...state,
      columns: [...state.columns, ...difference(action.columns, state.columns)]
    }
  },
  [ActionTypes.REMOVE_COLUMNS]: (state, action) => {
    return {
      ...state,
      columns: difference(state.columns, action.columns)
    }
  },
  [ActionTypes.CLEAR_COLUMNS]: state => {
    return {
      ...state,
      columns: []
    }
  },
  [ActionTypes.SET_RESULT]: (state, action) => {
    return {
      ...state,
      result: action.result
    }
  },
  [ActionTypes.SET_CURRENT_PAGE]: (state, action) => {
    return {
      ...state,
      currentPage: action.currentPage
    }
  },
  [ActionTypes.SET_RESULTS_PER_PAGE]: (state, action) => {
    return {
      ...state,
      resultsPerPage: action.resultsPerPage
    }
  },
  [ActionTypes.HAS_EXPORTED]: (state, action) => {
    return {
      ...state,
      isExporting: action.isExporting
    }
  },
  [ActionTypes.SET_EXPORT_TYPE]: (state, action) => {
    return {
      ...state,
      exportType: action.exportType
    }
  },
  [ActionTypes.FETCH_ASSETS_REQUEST]: state => {
    return {
      ...state,
      isFetching: true,
      hasFetchError: false
    }
  },
  [ActionTypes.FETCH_ASSETS_FAILED]: state => {
    return {
      ...state,
      isFetching: false,
      hasFetchError: true
    }
  },
  [ActionTypes.FETCH_ASSETS_SUCCESS]: state => {
    return {
      ...state,
      isFetching: false,
      hasFetchError: false
    }
  },
  [ActionTypes.SET_SHOW_READABLE_COLUMNS]: (state, action) => {
    return {
      ...state,
      showReadableColumns: action.showReadableColumns
    }
  }
})
