const MODULE = 'ASSET_DETAILS'
export const FETCH_DETAILS_REQUEST = `${MODULE}/FETCH_DETAILS_REQUEST`
export const FETCH_DETAILS_SUCCESS = `${MODULE}/FETCH_DETAILS_SUCCESS`
export const FETCH_DETAILS_FAILED = `${MODULE}/FETCH_DETAILS_FAILED`
export const ADD_FILE_TO_DETAILS = `${MODULE}/ADD_FILE_TO_DETAILS`
export const REMOVE_FILE_FROM_DETAILS = `${MODULE}/REMOVE_FILE_FROM_DETAILS`
export const IMG_CAROUSEL_SHOWN = `${MODULE}/IMG_CAROUSEL_SHOWN`
export const IMG_CAROUSEL_HIDDEN = `${MODULE}/IMG_CAROUSEL_HIDDEN`
export const REFERENCE_IMAGE_CAROUSEL_SHOWN = `${MODULE}/REFERENCE_IMAGE_CAROUSEL_SHOWN`
export const REFERENCE_IMAGE_CAROUSEL_HIDDEN = `${MODULE}/REFERENCE_IMAGE_CAROUSEL_HIDDEN`
export const FETCH_RELATED_OPERATIONS_REQUEST = `${MODULE}/FETCH_RELATED_OPERATIONS_REQUEST`
export const FETCH_RELATED_OPERATIONS_FAILED = `${MODULE}/FETCH_RELATED_OPERATIONS_FAILED`
export const FETCH_RELATED_OPERATIONS_SUCCESS = `${MODULE}/FETCH_RELATED_OPERATIONS_SUCCESS`
export const CLEAR_RELATED_OPERATIONS = `${MODULE}/CLEAR_RELATED_OPERATIONS`
export const MOVED_TO_MY_LOCATION = `${MODULE}/MOVED_TO_MY_LOCATION`
export const MOVE_CANCELED = `${MODULE}/MOVE_CANCELED`
