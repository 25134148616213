import { pkStringToNumber } from '../../core/autoform/util'
import { pkToString } from '../../core/autoform/formFieldDataFromDB'
import TYPES from '../../core/assets/assetTypes'

const toLocal = state => state.asset_list

export const getSelectedAssetType = state => toLocal(state).selectedAssetType

export const isLoadingAssets = state => toLocal(state).isLoading
export const didLoadFailed = state => toLocal(state).hasError
export const getAssetsList = state => toLocal(state).assetList

export const getFilterValue = (state, field) => toLocal(state).filter[field]

export const getCodeFilter = state => getFilterValue(state, 'code')
export const getInitialPKFilter = state => getFilterValue(state, 'initialPK')
export const getFinalPKFilter = state => getFilterValue(state, 'finalPK')
export const getHighwayFilter = state => getFilterValue(state, 'highway')
export const getDenominationFilter = state =>
  getFilterValue(state, 'denomination')

export const getAssetsFiltered = state => {
  const filters = {
    code: getCodeFilter(state).toUpperCase(),
    highway: getHighwayFilter(state).toUpperCase(),
    startPk: getInitialPKFilter(state),
    endPk: getFinalPKFilter(state),
    denomination: getDenominationFilter(state)
  }

  if (
    !filters.code &&
    !filters.highway &&
    !filters.startPk &&
    !filters.endPk &&
    !filters.denomination
  ) {
    return getAssetsList(state)
  }

  return getAssetsList(state).filter(e => {
    switch (getSelectedAssetType(state)) {
      case TYPES.CONSERVACION_ORDINARIA: {
        return matchesFiltersOrdinaryOperation(e, filters)
      }

      case TYPES.CONSERVACION_EXTRAORDINARIA: {
        return matchesFiltersExtraordinaryOperation(e, filters)
      }

      case TYPES.OBRAS: {
        return matchesFiltersWorkOperation(e, filters)
      }

      default: {
        return matchesFiltersAsset(e, filters)
      }
    }
  })
}

//

const matchesFiltersAsset = (item, filters) => {
  const { code, highway, startPk, endPk, denomination } = filters

  const matchesCode =
    !code || (item.codigo && item.codigo.toUpperCase().includes(code))
  const matchesHighway =
    !highway || (item.autopista && item.autopista.includes(highway))
  const matchesStartPk =
    !startPk || (item.pkInicial && item.pkInicial >= startPk)
  const matchesEndPk = !endPk || (item.pkInicial && item.pkInicial <= endPk)
  const matchesDenomination =
    !denomination ||
    (item.denominacion &&
      item.denominacion.toLowerCase().includes(denomination.toLowerCase()))

  return (
    matchesCode &&
    matchesHighway &&
    matchesStartPk &&
    matchesEndPk &&
    matchesDenomination
  )
}

const matchesFiltersOrdinaryOperation = (item, filters) => {
  const { code, highway, startPk, endPk } = filters

  const matchesCode =
    !code ||
    (item.registroGeneral &&
      item.registroGeneral.claveProyecto &&
      item.registroGeneral.claveProyecto.toUpperCase().includes(code))

  const matchesHighway =
    !highway ||
    (item.registroGeneral &&
      item.registroGeneral.autopistas &&
      item.registroGeneral.autopistas.find(autopista =>
        autopista.includes(highway)
      ))

  const matchesStartPk =
    !startPk ||
    (item.parteTrabajos &&
      item.parteTrabajos.some(parte => {
        const pkInicio = pkStringToNumber(pkToString(parte.pkInicio))
        return pkInicio >= startPk
      }))

  const matchesEndPk =
    !endPk ||
    (item.parteTrabajos &&
      item.parteTrabajos.some(parte => {
        const pkInicio = pkStringToNumber(pkToString(parte.pkInicio))
        return pkInicio <= endPk
      }))

  return matchesCode && matchesHighway && matchesStartPk && matchesEndPk
}

const matchesFiltersExtraordinaryOperation = (item, filters) => {
  const { code, highway, startPk, endPk } = filters

  const matchesCode =
    !code ||
    (item.registroGeneral &&
      item.registroGeneral.claveProyecto &&
      item.registroGeneral.claveProyecto.toUpperCase().includes(code))

  const matchesHighway =
    !highway ||
    (item.registroGeneral &&
      item.registroGeneral.autopistas &&
      item.registroGeneral.autopistas.find(autopista =>
        autopista.includes(highway)
      ))

  const matchesStartPk =
    !startPk ||
    (item.seguimientoObra &&
      item.seguimientoObra.activos &&
      pkStringToNumber(pkToString(item.seguimientoObra.activos.pk)) >= startPk)

  const matchesEndPk =
    !endPk ||
    (item.seguimientoObra &&
      item.seguimientoObra.activos &&
      pkStringToNumber(pkToString(item.seguimientoObra.activos.pk)) <= endPk)

  return matchesCode && matchesHighway && matchesStartPk && matchesEndPk
}

const matchesFiltersWorkOperation = (item, filters) => {
  const { code, highway, startPk, endPk } = filters

  const matchesCode =
    !code ||
    (item.registroGeneral &&
      item.registroGeneral.claveProyecto &&
      item.registroGeneral.claveProyecto.toUpperCase().includes(code))

  const matchesHighway =
    !highway ||
    (item.registroGeneral &&
      item.registroGeneral.ubicacion &&
      item.registroGeneral.ubicacion.autopistas &&
      item.registroGeneral.ubicacion.autopistas.find(autopista =>
        autopista.includes(highway)
      ))

  const matchesStartPk =
    !startPk ||
    (item.registroGeneral &&
      item.registroGeneral.ubicacion &&
      item.registroGeneral.ubicacion.pkInicio &&
      pkStringToNumber(pkToString(item.registroGeneral.ubicacion.pkInicio)) >=
        startPk)

  const matchesEndPk =
    !endPk ||
    (item.registroGeneral &&
      item.registroGeneral.ubicacion &&
      item.registroGeneral.ubicacion.pkInicio &&
      pkStringToNumber(pkToString(item.registroGeneral.ubicacion.pkInicio)) <=
        endPk)

  return matchesCode && matchesHighway && matchesStartPk && matchesEndPk
}
