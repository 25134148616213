import { pipe } from 'lodash/fp'
import * as ActionTypes from './actionTypes'
import { buildReducer } from '../utils'
import { polygon } from '@turf/helpers'
import { boundsToPolygon } from '../assets/utils'
import { DEFAULT_MAP_CENTER, DEFAULT_ZOOM } from './constants'
export * from './selectors'
export * from './actions'

const mapBoundsToGeoJSON = pipe(
  boundsToPolygon,
  polygon
)

const initialState = {
  mapCenter: DEFAULT_MAP_CENTER,
  mapBounds: {},
  mapBoundsGeoJSON: null,
  mapZoom: DEFAULT_ZOOM,
  bottomMapTitle: 'Eje',
  bottomMapLabel: ''
}

const mapViewReducer = buildReducer(initialState, {
  [ActionTypes.MAP_POSITION_UPDATED]: (state, action) => {
    return {
      ...state,
      mapCenter: action.center,
      mapBounds: action.bounds,
      mapBoundsGeoJSON: action.bounds
        ? mapBoundsToGeoJSON(action.bounds)
        : null,
      mapZoom: action.zoom
    }
  },
  [ActionTypes.SET_BOTTOM_LABEL]: (state, action) => {
    return {
      ...state,
      bottomMapTitle: action.payload.title,
      bottomMapLabel: action.payload.label
    }
  },
  [ActionTypes.REMOVE_BOTTOM_LABEL]: state => {
    return {
      ...state,
      bottomMapLabel: ''
    }
  },
  [ActionTypes.MAP_RESET]: () => {
    return initialState
  }
})

export default mapViewReducer
