import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import appReducer from './modules'
import gmapMiddleware from './modules/mapview/googleMapMiddleware'

const middleware = [thunkMiddleware, gmapMiddleware]

export default function configureStore() {
  const composeEnhancers =
    process.env.NODE_ENV === 'development'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose
  const store = createStore(
    appReducer,
    composeEnhancers(applyMiddleware(...middleware))
  )
  if (module.hot) {
    module.hot.accept('./modules', () => {
      const nextReducer = require('./modules').default
      store.replaceReducer(nextReducer)
    })
  }
  return store
}
