import { compose, eq, propOr, anyPass } from 'lodash/fp'
// errorCodeEquals :: a -> Object -> bool
export const errorCodeEquals = code =>
  compose(
    eq(code),
    propOr(null, ['error', 'code'])
  )

// isAuthError :: {Context} -> bool
export const isAuthError = anyPass([errorCodeEquals(401), errorCodeEquals(403)])

export const isConflictError = errorCodeEquals(409)

export const isService = service =>
  compose(
    eq(service),
    propOr(null, ['path'])
  )
