import moment from 'moment'

export const exceptIf = options => (value, getField) => {
  const except_value =  options["value"]
  const current_value = getField(options["path"]|| [])

  if (except_value === current_value) {
    return {}
  }

  return options["validator"](value, getField)
}

export const required = value => {
  const isValid = value !== undefined

  if (!isValid) {
    return {
      hasError: true,
      helperText: 'Requerido',
      required: true
    }
  }

  return {
    required: true
  }
}

export const requiredString = value => {
  const isValid = value !== undefined && value.length && value.trim().length > 0

  if (!isValid) {
    return {
      hasError: true,
      helperText: 'Requerido',
      required: true
    }
  }

  return {
    required: true
  }
}

export const dateDDMMAAAA = value => {
  if (!value || !value.length || value.length === 0) {
    return {}
  }

  const parsed = moment.utc(value, 'DD/MM/YYYY', true)

  if (!parsed.isValid()) {
    return {
      hasError: true,
      helperText: 'Formato DD/MM/AAAA'
    }
  }

  return {}
}

export const dateMMAAAA = value => {
  if (!value || !value.length || value.length === 0) {
    return {}
  }

  const parsed = moment(value, 'MM/YYYY', true)

  if (!parsed.isValid()) {
    return {
      hasError: true,
      helperText: 'Formato MM/AAAA'
    }
  }

  return {}
}

export const timeHHMM = value => {
  if (!value || !value.length || value.length === 0) {
    return {}
  }

  const parsed = moment(value, 'kk:mm', true)

  if (!parsed.isValid()) {
    return {
      hasError: true,
      helperText: 'Formato HH:MM'
    }
  }

  return {}
}

const PK_REGEX = /^\d{3}\+\d{4}$/

export const PK = value => {
  if (!value || !value.length || value.length === 0) {
    return {}
  }

  const isValid = PK_REGEX.test(value)

  if (!isValid) {
    return {
      hasError: true,
      helperText: 'Formato 000+0000'
    }
  }

  return {}
}
