// A collection of helper functions to detail with Gmaps API
// https://developers.google.com/maps/documentation/javascript/reference/3.exp/

// extracts geometry data from a `setgeometry` event
// emitted by a Data feature being dragged around the map
// https://developers.google.com/maps/documentation/javascript/reference/3.exp/data#Data.SetGeometryEvent
export function extractDragGeometry(event) {
  const { newGeometry } = event
  const geoType = newGeometry.getType() //'Point', most of the time
  const coords = []
  // Point and LineString
  newGeometry.forEachLatLng(latlng => coords.push([latlng.lng(), latlng.lat()]))
  let finalCoords
  if (geoType === 'Polygon') {
    // an array of polygon lines, with the last point equal to the first (to close the line)
    finalCoords = [coords.concat([coords[0]])]
  }
  if (geoType === 'LineString') {
    finalCoords = coords
  }
  if (geoType === 'Point') {
    finalCoords = coords[0]
  }
  return {
    type: geoType,
    coordinates: finalCoords
  }
}

// Extracts a google Map compatible LatLng object from an application asset document
export const getLatLngFromAsset = asset => {
  if (asset.geometry.type === 'Point') {
    return {
      lng: asset.geometry.coordinates[0],
      lat: asset.geometry.coordinates[1]
    }
  }
  if (asset.geometry.type === 'LineString') {
    return {
      lng: asset.geometry.coordinates[0][0],
      lat: asset.geometry.coordinates[0][1]
    }
  }
  if (asset.geometry.type === 'Polygon') {
    return {
      lng: asset.geometry.coordinates[0][0][0],
      lat: asset.geometry.coordinates[0][0][1]
    }
  }
  return {
    lng: asset.X || 0,
    lat: asset.Y || 0
  }
}

// Creates a FeatureCollection from an array of entities
// that have a `geometry` property, and id and some other properties
export function makeFeatureCollection(data = [], idField = '_id') {
  return {
    type: 'FeatureCollection',
    features: data.map(d => {
      const { geometry, ...rest } = d
      return {
        type: 'Feature',
        geometry,
        id: d[idField],
        properties: rest
      }
    })
  }
}

// Google -> Mongo
const GEOMETRY_TYPES = {
  marker: 'Point',
  polyline: 'LineString',
  polygon: 'Polygon'
}

export function gmapOverlayToGeoJSON(overlayType) {
  return GEOMETRY_TYPES[overlayType]
}
export function geoJSONtoGmapOverlay(geoJSONtype) {
  return Object.keys(GEOMETRY_TYPES).find(
    x => GEOMETRY_TYPES[x] === geoJSONtype
  )
}

export function makeGeoJSONGeometryFromGmaps(gmapsOverlayType, overlay) {
  const geometry = {
    coordinates: []
  }
  geometry.type = gmapOverlayToGeoJSON(gmapsOverlayType)
  // convert gmaps coordinates to geoJSON / Mongo type
  // docs here:
  switch (gmapsOverlayType) {
    case 'marker':
      const position = overlay.getPosition()
      geometry.coordinates = [position.lng(), position.lat()]
      break
    case 'polyline':
      const gmapCoords = overlay.getPath() // array of LatLng
      gmapCoords.forEach(latLng => {
        geometry.coordinates.push([latLng.lng(), latLng.lat()])
      })
      break
    case 'polygon':
      const gmapPaths = overlay.getPaths() // array of arrays of LatLng with first and last point the same
      gmapPaths.forEach(path => {
        //geometry.coordinates.push([])
        const pathCoords = []
        path.forEach(latLng => {
          pathCoords.push([latLng.lng(), latLng.lat()])
        })
        // insert the first one again as last
        pathCoords.push([].concat(pathCoords[0]))
        geometry.coordinates.push(pathCoords)
      })
      break
    default:
      throw new Error('Unknown Google Map overlay type - ' + gmapsOverlayType)
  }
  return geometry
}

// from a google map "dblclick" mouse event, grab X and Y
// and displace a little bit
export function getXYFromMapMouseEvent(e) {
  return {
    x: e.pixel.x - 160,
    y: e.pixel.y - 16
  }
}

// extend a google LatLngBounds object with all features of a layer
// mutates the bounds object
export function mutablyExtendBoundsWithLayerFeatures(layer, bounds) {
  layer.forEach(feature =>
    feature.getGeometry().forEachLatLng(latlng => {
      if (!bounds.contains(latlng)) bounds.extend(latlng)
    })
  )
}
