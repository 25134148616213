import { TIPO_INSPECCION_VALLA_CIERRE, SENTIDOS } from './selectOptions'
import TYPES from '../../../../core/assets/assetTypes'
import comunIdentificacionFormFields from './comunIdentificacionFormFields'
import comunValidacionFormFields from './comunValidacionFormFields'

export const identificacion = (workOrderFragment, familyFragment) => [
  ...comunIdentificacionFormFields(
    workOrderFragment,
    'intervencionesVallaCierre',
    familyFragment,
    [TYPES.VALLA_CERRAMIENTO]
  ),

  {
    label: 'Tipo Aviso',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'identificacion',
      'tipoAviso'
    ]
  },

  {
    label: 'Comentarios',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'identificacion',
      'comentarios'
    ]
  }
]

export const inspeccion = (workOrderFragment, familyFragment) => [
  {
    label: 'PK Inicio',
    type: 'PK',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'inspeccion',
      'pkInicio'
    ]
  },

  {
    label: 'PK Fin',
    type: 'PK',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'inspeccion',
      'pkFin'
    ]
  },

  {
    label: 'Km Inspeccionados',
    type: 'Number',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'inspeccion',
      'kmInspeccionados'
    ]
  },

  {
    label: 'Tipo Inspección',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'inspeccion',
      'tipoInspeccion'
    ],
    meta: {
      options: TIPO_INSPECCION_VALLA_CIERRE
    }
  },

  {
    label: 'Trabajos programados',
    type: 'Checkbox',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'inspeccion',
      'trabajosProgramados'
    ]
  },

  {
    label: 'Observaciones',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'inspeccion',
      'observaciones'
    ]
  }
]

export const ejecucion = (workOrderFragment, familyFragment) => [
  {
    label: 'Nº Reparación',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'ejecucion',
      'numReparacion'
    ]
  },

  {
    label: 'Coordenadas PK Inicio',
    type: 'Coordinates',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'ejecucion',
      'coordenadasPkInicio'
    ]
  },

  {
    label: 'Sentido',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'ejecucion',
      'sentido'
    ],
    meta: {
      options: SENTIDOS
    }
  },

  {
    label: 'Ud. Poste Tensor',
    type: 'Number',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'ejecucion',
      'udPosteTensor'
    ]
  },

  {
    label: 'Ud. Poste Intermedio',
    type: 'Number',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'ejecucion',
      'udPosteIntermedio'
    ]
  },

  {
    label: 'ML Valla',
    type: 'Number',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'ejecucion',
      'mlValla'
    ]
  },

  {
    label: 'Sup. Desbroce',
    type: 'Number',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'ejecucion',
      'supDesbroce'
    ]
  },

  {
    label: 'Ud. Compuertas',
    type: 'Number',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'ejecucion',
      'udCompuertas'
    ]
  },

  {
    label: 'Trabajos programados',
    type: 'Checkbox',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'ejecucion',
      'trabajosProgramados'
    ]
  },

  {
    label: 'Observaciones',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'ejecucion',
      'observaciones'
    ]
  },

  {
    label: 'Fotos antes',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'ejecucion',
      'antesFotos'
    ],
    meta: { isPhotos: true }
  },

  {
    label: 'Fotos despues',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'intervencionesVallaCierre',
      familyFragment,
      'ejecucion',
      'despuesFotos'
    ],
    meta: { isPhotos: true }
  }
]

export const validacion = comunValidacionFormFields('intervencionesVallaCierre')
