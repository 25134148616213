import { buildReducer } from '../utils'
import * as ActionTypes from './actionTypes'
export * from './actions'
export * from './selectors'

const initialState = {
  isLoading: false,
  hasError: false,
  axes: []
}

export default buildReducer(initialState, {
  [ActionTypes.FETCH_AXES_SUCCESS]: (state, action) => {
    return {
      isLoading: false,
      hasError: false,
      axes: action.payload
    }
  },
  [ActionTypes.FETCH_AXES_REQUEST]: (state, action) => {
    return {
      ...state,
      hasError: false,
      isLoading: true
    }
  },
  [ActionTypes.FETCH_AXES_FAILED]: (state, action) => {
    return {
      ...state,
      isLoading: false,
      hasError: true
    }
  }
})
