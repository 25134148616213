import {
  getPKFamily,
  getPKField,
  getAvgHeightGrassField,
  getStartEndPKExecFields,
  // getStartEndCoordExecFields,
  getWayField,
  getMedicUnidExecFields,
  getCommonInspectionFields,
  getCommonExecFields,
  getCommonValidationFields
} from './commonFields'

const desbrocesTaludesFields = [
  ...getPKFamily('desbrocesTaludes'),
  ...getPKField('desbrocesTaludes'),
  ...getAvgHeightGrassField('desbrocesTaludes'),
  ...getCommonInspectionFields('desbrocesTaludes'),
  ...getStartEndPKExecFields('desbrocesTaludes'),
  // ...getStartEndCoordExecFields('desbrocesTaludes'),
  ...getWayField('desbrocesTaludes'),
  ...getMedicUnidExecFields('desbrocesTaludes'),
  ...getCommonExecFields('desbrocesTaludes'),
  ...getCommonValidationFields('desbrocesTaludes')
]

export const getDesbroceTaludesFields = () => desbrocesTaludesFields
