import {
  TIPOLOGIA_ELEMENTO_HIDRAULICO_DRENAJE_PROFUNDO,
  UNIDADES,
  SITUACIONES,
  SI_NO
} from './selectOptions'
import TYPES from '../../../../core/assets/assetTypes'
import comunIdentificacionFormFields from './comunIdentificacionFormFields'

export const identificacion = (workOrderFragment, familyFragment) => [
  ...comunIdentificacionFormFields(
    workOrderFragment,
    'drenajesProfundos',
    familyFragment,
    [TYPES.COLECTORES_LONG]
  ),

  {
    label: 'Tipología del elemento hidráulico',
    type: 'Select',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'identificacion',
      'tipologiaElementoHidraulico'
    ],
    meta: {
      options: TIPOLOGIA_ELEMENTO_HIDRAULICO_DRENAJE_PROFUNDO
    }
  },

  {
    label: 'Comentarios',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'identificacion',
      'comentarios'
    ]
  }
]

export const inspeccion = (workOrderFragment, familyFragment) => [
  {
    label: 'Medición',
    type: 'Number',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'inspeccion',
      'medicion'
    ]
  },

  {
    label: 'Unidad',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'inspeccion',
      'unidad'
    ],
    meta: {
      options: UNIDADES
    }
  },

  {
    label: 'Aspecto',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'inspeccion',
      'aspecto'
    ],
    meta: {
      options: SITUACIONES
    }
  },

  {
    label: 'Trabajos programados',
    type: 'Checkbox',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'inspeccion',
      'trabajosProgramados'
    ]
  },

  {
    label: 'Observaciones',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'inspeccion',
      'observaciones'
    ]
  },

  {
    label: 'Fotos antes',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'inspeccion',
      'antesFotos'
    ],
    meta: { isPhotos: true }
  }
]

export const ejecucion = (workOrderFragment, familyFragment) => [
  {
    label: 'Medición Desbroce',
    type: 'Number',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'ejecucion',
      'medicionDesbroce'
    ]
  },

  {
    label: 'Unidad Desbroce',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'ejecucion',
      'unidadDesbroce'
    ],
    meta: {
      options: UNIDADES
    }
  },

  {
    label: 'Limpieza',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'ejecucion',
      'limpieza'
    ],
    meta: {
      placeholder: 'Sin especificar',
      options: SI_NO
    }
  },

  {
    label: 'Limpieza PK Inicio',
    type: 'PK',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'ejecucion',
      'limpiezaPkInicio'
    ]
  },

  {
    label: 'Limpieza PK Fin',
    type: 'PK',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'ejecucion',
      'limpiezaPkFin'
    ]
  },

  {
    label: 'Trabajos programados',
    type: 'Checkbox',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'ejecucion',
      'trabajosProgramados'
    ]
  },

  {
    label: 'Observaciones',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'ejecucion',
      'observaciones'
    ]
  }
]

export const validacion = (workOrderFragment, familyFragment) => [
  {
    label: 'Responsable Contratista',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'validacion',
      'responsableContratista'
    ]
  },

  {
    label: 'Fecha',
    type: 'Date',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'validacion',
      'fecha'
    ]
  },

  {
    label: 'Situación de la entrada',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'validacion',
      'situacionEntrada'
    ],
    meta: {
      options: SITUACIONES
    }
  },

  {
    label: 'Auditado',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'validacion',
      'auditado'
    ],
    meta: {
      placeholder: 'Sin especificar',
      options: SI_NO
    }
  },

  {
    label: 'Comentarios',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'validacion',
      'comentarios'
    ]
  },

  {
    label: 'Observaciones',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'validacion',
      'observaciones'
    ]
  },

  {
    label: 'Fotos entrada',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'validacion',
      'fotosEntrada'
    ],
    meta: { isPhotos: true }
  },

  {
    label: 'Fotos salida',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'validacion',
      'fotosSalida'
    ],
    meta: { isPhotos: true }
  },

  {
    label: 'Fotos',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'drenajesProfundos',
      familyFragment,
      'validacion',
      'fotos'
    ],
    meta: { isPhotos: true }
  }
]
