import React from 'react'
// import PropTypes from 'prop-types'

const Overlay = props => {
  return <div className="overlay" />
}

Overlay.defaultProps = {}

Overlay.propTypes = {}

export default Overlay
