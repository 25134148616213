import * as validators from '../../../autoform/validators'
import { isUndefined, isNil } from 'lodash'

const ASSET_DISTANCE_RADIUS_DEFAULT = 100

const getPKMiddleWorkOrder = (workOrderFragment, getField) => {
  const pkInicioWorkOrder = getField([
    'parteTrabajos',
    workOrderFragment,
    'pkInicio'
  ])
  const pkFinWorkOrder = getField(['parteTrabajos', workOrderFragment, 'pkFin'])

  if (
    pkInicioWorkOrder &&
    pkFinWorkOrder &&
    !validators.PK(pkInicioWorkOrder).hasError &&
    !validators.PK(pkFinWorkOrder).hasError
  ) {
    const pkPkInicio = parseInt(pkInicioWorkOrder.slice(0, 3), 10)
    const pkPkFin = parseInt(pkFinWorkOrder.slice(0, 3), 10)
    const pkPkMiddle = Math.floor((pkPkInicio + pkPkFin) / 2)
      .toString()
      .padStart(3, '0')

    return `${pkPkMiddle}+0000`
  }
}

export default (workOrderFragment, familyType, familyFragment, assetTypes) => [
  {
    label: 'PK Familia',
    type: 'PK',
    path: [
      'parteTrabajos',
      workOrderFragment,
      familyType,
      familyFragment,
      'identificacion',
      'pk'
    ],
    meta: (getField, setField) => {
      const pkDefault = getPKMiddleWorkOrder(workOrderFragment, getField)

      return {
        helperText: `Si se omite se usará el PK del parte de trabajo (${
          !isUndefined(pkDefault) && !validators.PK(pkDefault).hasError
            ? `PK ${pkDefault}`
            : 'actualmente sin definir'
        }).`
      }
    }
  },

  {
    label: 'Radio de búsqueda (metros)',
    type: 'Number',
    path: [
      'parteTrabajos',
      workOrderFragment,
      familyType,
      familyFragment,
      'identificacion',
      'radio'
    ],
    meta: {
      placeholder: ASSET_DISTANCE_RADIUS_DEFAULT.toString()
    }
  },

  {
    label: 'Activos',
    type: 'Assets',
    path: [
      'parteTrabajos',
      workOrderFragment,
      familyType,
      familyFragment,
      'identificacion',
      'activos'
    ],
    meta: (getField, setField) => {
      const autopista = getField([
        'parteTrabajos',
        workOrderFragment,
        'autopista'
      ])
      const eje = getField(['parteTrabajos', workOrderFragment, 'eje'])
      const pkDefault = getPKMiddleWorkOrder(workOrderFragment, getField)

      const pkFamilia = getField([
        'parteTrabajos',
        workOrderFragment,
        familyType,
        familyFragment,
        'identificacion',
        'pk'
      ])

      const pk =
        (!isUndefined(pkFamilia) &&
          !validators.PK(pkFamilia).hasError &&
          pkFamilia) ||
        (!isUndefined(pkDefault) &&
          !validators.PK(pkDefault).hasError &&
          pkDefault) ||
        undefined

      const assetDistanceMeters = getField([
        'parteTrabajos',
        workOrderFragment,
        familyType,
        familyFragment,
        'identificacion',
        'radio'
      ])

      return {
        assetTypes,
        autopista,
        eje,
        pk,
        assetDistanceMeters: isNil(assetDistanceMeters)
          ? ASSET_DISTANCE_RADIUS_DEFAULT
          : assetDistanceMeters,
        disabled:
          autopista === undefined || eje === undefined || pk === undefined
      }
    }
  }
]
