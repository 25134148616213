const MODULE = 'QUERIES'

export const SET_QUERY_TYPE = `${MODULE}/SET_QUERY_TYPE`
export const SET_SUB_QUERY_TYPE = `${MODULE}/SET_SUB_QUERY_TYPE`
export const ADD_FIELDS = `${MODULE}/ADD_FIELDS`
export const REMOVE_FIELDS = `${MODULE}/REMOVE_FIELDS`
export const CLEAR_FIELDS = `${MODULE}/CLEAR_FIELDS`
export const SET_MODE = `${MODULE}/SET_MODE`
export const UPDATE_FILTERS = `${MODULE}/UPDATE_FILTERS`
export const REMOVE_FILTER = `${MODULE}/REMOVE_FILTER`
export const CLEAR_FILTERS = `${MODULE}/CLEAR_FILTERS`
export const SET_RESULTS_COUNT = `${MODULE}/SET_RESULTS_COUNT`
export const ADD_COLUMNS = `${MODULE}/ADD_COLUMNS`
export const REMOVE_COLUMNS = `${MODULE}/REMOVE_COLUMNS`
export const CLEAR_COLUMNS = `${MODULE}/CLEAR_COLUMNS`
export const SET_RESULT = `${MODULE}/SET_RESULT`
export const SET_CURRENT_PAGE = `${MODULE}/SET_CURRENT_PAGE`
export const SET_RESULTS_PER_PAGE = `${MODULE}/SET_RESULTS_PER_PAGE`
export const HAS_EXPORTED = `${MODULE}/HAS_EXPORTED`
export const SET_EXPORT_TYPE = `${MODULE}/SET_EXPORT_TYPE`
export const FETCH_ASSETS_REQUEST = `${MODULE}/FETCH_ASSETS_REQUEST`
export const FETCH_ASSETS_SUCCESS = `${MODULE}/FETCH_ASSETS_SUCCESS`
export const FETCH_ASSETS_FAILED = `${MODULE}/FETCH_ASSETS_FAILED`
export const SET_SHOW_READABLE_COLUMNS = `${MODULE}/SET_SHOW_READABLE_COLUMNS`
export const SET_WORK_REPORT_COLUMN_FIELDS = `${MODULE}/SET_WORK_REPORT_COLUMN_FIELDS`
