import { Component } from 'react'
import { createPortal } from 'react-dom'
import { string } from 'prop-types'

// This renders a modal into the div#modal-root (see AppLayout)
export default class ModalPortal extends Component {
  render() {
    const { modalRootId = 'modal-root' } = this.props
    const target = document.getElementById(modalRootId)
    return createPortal(
      this.props.children,
      target
    )
  }
}

ModalPortal.propTypes = {
  modalRootId: string
}