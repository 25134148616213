import {
  getPKFamily,
  getStartEndPKExecFields,
  getWayField,
  getTraceField,
  getOpAndElementsFields,
  getMedicUnidExecFields,
  getCommonInspectionFields,
  getCommonExecFields,
  getCommonValidationFields
} from './commonFields'

const elemContencionFields = [
  ...getPKFamily('elementosContencion'),
  ...getCommonInspectionFields('elementosContencion'),
  ...getStartEndPKExecFields('elementosContencion'),
  ...getWayField('elementosContencion'),
  ...getTraceField('elementosContencion'),
  ...getOpAndElementsFields('elementosContencion'),
  ...getMedicUnidExecFields('elementosContencion'),
  ...getCommonExecFields('elementosContencion'),
  ...getCommonValidationFields('elementosContencion')
]

export const getElemContencion = () => elemContencionFields
