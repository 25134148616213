import omit from 'lodash/omit'

/**
 * Omits properties from an asset object so that the user
 * can't accidentally change fields he's not supposed to :)
 * @param {object} asset
 */
export function blackListProps(asset) {
  return omit(asset, ['geometry', 'isDeleted'])
}
