import React from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers, withProps } from 'recompose'
import * as Session from '../index'
import Modal from '../../../ui/modals/Modal'
import ModalPortal from '../../../ui/modals/ModalPortal'
import ConfirmationAlert from '../../../ui/alerts/ConfirmationAlert'

export const AuthErrorModal = props => {
  const { displayModal = false, message, buttonLabel, onConfirm } = props
  if (!displayModal) return null
  return (
    <ModalPortal modalRootId='modal-error'>
      <Modal>
        <ConfirmationAlert
          text={message}
          primaryAction={buttonLabel}
          onClickPrimary={onConfirm}
        />
      </Modal>
    </ModalPortal>
  )
}
AuthErrorModal.defaultProps = {
  message: 'No se ha podido verificar su identidad',
  buttonLabel: 'Continuar'
}

// State -> auth info
const mapStateToProps = state => ({
  displayModal: Session.hasForbiddenError(state),
  isLoginRequired: !Session.isUserLoggedIn(state)
})
const mapDispatchToProps = {
  clearForbiddenError: Session.clearForbiddenError
}
const withAuthErrorState = connect(
  mapStateToProps,
  mapDispatchToProps
)

// Generate a specific message depending on the the error
const withErrorMessage = withProps(props => {
  const message = props.isLoginRequired
    ? 'Su sesión ha caducado, debe volver a iniciar sesión'
    : 'No tiene permiso para realizar esa acción'
  return {
    message
  }
})

// Handles the Modal primary action, either forcing login or clearing the error
const withModalAction = withHandlers({
  onConfirm: props => () => {
    if (!!props.isLoginRequired) {
      Session.redirectToLogin()
    } else {
      props.clearForbiddenError()
    }
  }
})

const decorate = compose(
  withAuthErrorState,
  withErrorMessage,
  withModalAction
)

export default decorate(AuthErrorModal)
