import { combineReducers } from 'redux'
import { buildReducer } from '../utils'
import * as ActionTypes from './actionTypes'
export * from './actions'
export * from './selectors'

const byId = buildReducer(
  {},
  {
    [ActionTypes.ADD]: (state, action) => {
      const { id, payload } = action
      return { ...state, [id]: payload }
    },

    [ActionTypes.FETCH_DETAILS_SUCCESS]: (state, action) => {
      const { id, payload } = action
      return { ...state, [id]: payload }
    }
  }
)

const isFetching = buildReducer(false, {
  [ActionTypes.FETCH_DETAILS_REQUEST]: () => true,
  [ActionTypes.FETCH_DETAILS_FAILED]: () => false,
  [ActionTypes.FETCH_DETAILS_SUCCESS]: () => false
})

const fetchingId = buildReducer(null, {
  [ActionTypes.FETCH_DETAILS_REQUEST]: (_, action) => action.id || null
})

const lastError = buildReducer(null, {
  [ActionTypes.FETCH_DETAILS_SUCCESS]: state => null,
  [ActionTypes.FETCH_DETAILS_FAILED]: (state, action) => action.error
})

const relatedAssets = buildReducer(
  {
    isFetching: false,
    assets: [],
    error: null
  },
  {
    [ActionTypes.FETCH_RELATED_ASSETS_REQUEST]: () => ({
      isFetching: true,
      assets: [],
      error: null
    }),
    [ActionTypes.FETCH_RELATED_ASSETS_FAILED]: (state, { error }) => ({
      isFetching: false,
      assets: [],
      error
    }),
    [ActionTypes.FETCH_RELATED_ASSETS_SUCCESS]: (state, { assets }) => ({
      isFetching: false,
      assets,
      error: null
    }),
    [ActionTypes.CLEAR_RELATED_ASSETS]: () => ({
      isFetching: false,
      assets: [],
      error: null
    })
  }
)

export default combineReducers({
  byId,
  isFetching,
  fetchingId,
  lastError,
  relatedAssets
})
