import { compose, map, sortBy, propOr, first } from 'lodash/fp'
import app from '../../feathers'

const createListItem = item => ({
  value: item.value,
  label: item.label || item.value,
  displayOrder: item.displayOrder || 0
})

const createItems = compose(
  sortBy(['displayOrder']),
  map(createListItem),
  propOr([], 'items'),
  first
)

export function getListItems(listName, feathers = app) {
  if (!feathers || !listName) {
    throw new Error('getListItems requires a Feathers app and a list name')
  }
  const service = feathers.service('simple-list')
  return service
    .find({
      query: {
        name: listName
      },
      $select: ['name', 'items']
    })
    .then(createItems)
}
