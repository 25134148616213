import { SignalingGroups, FormSignal } from '../constants'
const {
  Balizamiento,
  Carriles,
  Fluorescentes,
  Genericas,
  IdentificacionCarreteras,
  Iluminadas,
  IndicacionesGenerales,
  Obligacion,
  FinProhibicionRestriccion,
  Obras,
  Otras,
  OtrasSenalesProhibicionRestriccion,
  PanelesComplementarios,
  Peligro,
  ProhibicionEntrada,
  RestriccionDePaso,
  ReglamentacionPrioridad,
  Servicios,
  OrientacionPresenalizacion,
  OrientacionDireccion,
  OrientacionLocalizacion,
  OrientacionConfirmacion,
  OrientacionPoblado
} = SignalingGroups

const {
  Circulo,
  Cuadrado,
  Octogono,
  Panel,
  Rectangulo,
  Triangulo,
  Rombo
} = FormSignal

/**
 *  @typedef {object} SenalesVerticales
 * @property {string} codigo
 * @property {string} formato
 * @property {string} grupo
 * @property {string} descripción
 */

/** @type {SenalesVerticales[]} */
const SenalesVerticales = [
  {
    codigo: 'Aimpe-X',
    formato: Rectangulo,
    grupo: Balizamiento,
    descripción: 'Aimpe no diseñado'
  },
  {
    codigo: 'B1A',
    formato: Panel,
    grupo: Balizamiento,
    descripción: 'Panel Direccional Corto Izquierda'
  },
  {
    codigo: 'B1B',
    formato: Panel,
    grupo: Balizamiento,
    descripción: 'Panel Direccional Corto Derecha'
  },
  {
    codigo: 'B2A',
    formato: Panel,
    grupo: Balizamiento,
    descripción: 'Panel Direccional Largo Izquierda'
  },
  {
    codigo: 'B2A_Rojo',
    formato: Panel,
    grupo: Balizamiento,
    descripción: 'Panel Direccional Largo Izquierda Rojo'
  },
  {
    codigo: 'B2B',
    formato: Panel,
    grupo: Balizamiento,
    descripción: 'Panel Direccional Largo Derecha'
  },
  {
    codigo: 'B2B_Rojo',
    formato: Panel,
    grupo: Balizamiento,
    descripción: 'Panel Direccional Largo Derecha'
  },
  {
    codigo: 'B2C',
    formato: Panel,
    grupo: Balizamiento,
    descripción: 'Panel Direccional Corto Izquierda'
  },
  {
    codigo: 'B2D',
    formato: Panel,
    grupo: Balizamiento,
    descripción: 'Panel Direccional Corto Derecha'
  },
  {
    codigo: 'B2E',
    formato: Panel,
    grupo: Balizamiento,
    descripción: 'Panel Dirección'
  },
  {
    codigo: 'B3a',
    formato: Panel,
    grupo: Balizamiento,
    descripción: 'Panel Direccional Largo Izquierda'
  },
  {
    codigo: 'B3b',
    formato: Panel,
    grupo: Balizamiento,
    descripción: 'Panel Direccional Largo Derecha'
  },
  {
    codigo: 'B3c',
    formato: Panel,
    grupo: Balizamiento,
    descripción: 'Panel Direccional Corto Izquierda'
  },
  {
    codigo: 'B3d',
    formato: Panel,
    grupo: Balizamiento,
    descripción: 'Panel Direccional Corto Derecha'
  },
  {
    codigo: 'Banderola_A-X',
    formato: Panel,
    grupo: Genericas,
    descripción: 'Banderola en autopista no diseñado'
  },
  {
    codigo: 'Banderola_B-X',
    formato: Panel,
    grupo: Genericas,
    descripción: 'Banderola no diseñada'
  },
  {
    codigo: 'Caj',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Cajetin Generico'
  },
  {
    codigo: 'Caj30min',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Cajetin con limitacion temporal'
  },
  {
    codigo: 'CajA_BS',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Cajetín Atención Bandas Sonoras'
  },
  {
    codigo: 'CajBaden',
    formato: Cuadrado,
    grupo: PanelesComplementarios,
    descripción: 'Cajetín Badén'
  },
  {
    codigo: 'CajBS',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Cajetín Bandas Sonoras'
  },
  {
    codigo: 'CajCyD',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Cajetin Carga y Descarga'
  },
  {
    codigo: 'CajFD',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Cajetin con Flecha Derecha'
  },
  {
    codigo: 'CajFI',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Cajetin con Flecha Izquierda'
  },
  {
    codigo: 'CajFL',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Cajetin con Flecha Doble'
  },
  {
    codigo: 'CajGrua',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Cajetin Grua'
  },
  {
    codigo: 'CajLAB',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Cajetin SOLO dias laborables'
  },
  {
    codigo: 'CajMoto',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Cajetin SOLO Motos'
  },
  {
    codigo: 'CajMyT',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Cajetin con Horario de Mañana y Tarde'
  },
  {
    codigo: 'CAJ_NIEVE',
    formato: Rectangulo,
    grupo: Obras,
    descripción: 'Cajetín Nieve'
  },
  {
    codigo: 'CAJ_OBRA',
    formato: Rectangulo,
    grupo: Obras,
    descripción: 'Cajetín Genérico de Obra'
  },
  {
    codigo: 'Cartel_A-X',
    formato: Cuadrado,
    grupo: Genericas,
    descripción: 'Cartel Lateral en autopista no diseñado'
  },
  {
    codigo: 'Cartel_B-X',
    formato: Cuadrado,
    grupo: Genericas,
    descripción: 'Cartel lateral no diseñado'
  },
  {
    codigo: 'Cartel-X',
    formato: Cuadrado,
    grupo: Genericas,
    descripción: 'Cartel NO DISEÑADO'
  },
  {
    codigo: 'ESP',
    formato: Circulo,
    grupo: IndicacionesGenerales,
    descripción: 'Espejo'
  },
  {
    codigo: 'Europeo-X',
    formato: Rectangulo,
    grupo: Genericas,
    descripción: 'Europeo no diseñado'
  },
  {
    codigo: 'FCB',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Fin carril bici'
  },
  {
    codigo: 'FCP',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Fin carril peatón'
  },
  {
    codigo: 'Flecha_A-X',
    formato: Panel,
    grupo: Genericas,
    descripción: 'Flecha de destino en autopista no diseñada'
  },
  {
    codigo: 'Flecha_B-X',
    formato: Panel,
    grupo: Genericas,
    descripción: 'Flecha de destino no diseñada'
  },
  {
    codigo: 'F_P21',
    formato: Rectangulo,
    grupo: Fluorescentes,
    descripción: 'Cartel fluorescente P-21'
  },
  {
    codigo: 'F_P4',
    formato: Rectangulo,
    grupo: Fluorescentes,
    descripción: 'Cartel fluorescente P-4'
  },
  {
    codigo: 'F_R301_genAT',
    formato: Rectangulo,
    grupo: Fluorescentes,
    descripción: 'Cartel fluorescente R301'
  },
  {
    codigo: 'M70',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'No Bloquear Cruce'
  },
  {
    codigo: 'NBC1',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'No bloquear cruce'
  },
  {
    codigo: 'NBC2',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'No bloquear cruce'
  },
  {
    codigo: 'P-1',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Intersección con prioridad'
  },
  {
    codigo: 'P-10a',
    formato: Rectangulo,
    grupo: Peligro,
    descripción: 'Proximidad paso a nivel o puente movil (lado izquierdo)'
  },
  {
    codigo: 'P-10b',
    formato: Rectangulo,
    grupo: Peligro,
    descripción:
      'Aproximación a un paso a nivel o puente movil (lado izquierdo)'
  },
  {
    codigo: 'P-10c',
    formato: Rectangulo,
    grupo: Peligro,
    descripción: 'Cercanía de un paso a nivel o puente móvil (lado izquierdo)'
  },
  {
    codigo: 'P-11',
    formato: Rectangulo,
    grupo: Peligro,
    descripción: 'Situación de un paso a nivel sin barreras'
  },
  {
    codigo: 'P-11a',
    formato: Rectangulo,
    grupo: Peligro,
    descripción:
      'Situación de un paso a nivel sin barreras de mas de una vía férrea'
  },
  {
    codigo: 'P-12',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Aeropuerto'
  },
  {
    codigo: 'P-13a',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Curva peligrosa hacia la derecha'
  },
  {
    codigo: 'P-13b',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Curva peligrosa hacia la izquierda'
  },
  {
    codigo: 'P-14a',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Curvas peligrosas hacia la derecha'
  },
  {
    codigo: 'P-14a_luz',
    formato: Cuadrado,
    grupo: Iluminadas,
    descripción: 'P-14a iluminada'
  },
  {
    codigo: 'P-14b',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Curvas peligrosas hacia la izquierda'
  },
  {
    codigo: 'P-15',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Perfil irregular'
  },
  {
    codigo: 'P-15a',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Resalto'
  },
  {
    codigo: 'P-15b',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Badén'
  },
  {
    codigo: 'P-16a_10',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16a_11',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16a_12',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16a_13',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16a_14',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16a_15',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16a_16',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16a_17',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16a_18',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16a_19',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16a_20',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16a_4',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16a_5',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16a_6',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16a_7',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16a_8',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16a_9',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bajada con fuerte pendiente'
  },
  {
    codigo: 'P-16b_10',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-16b_11',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-16b_12',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-16b_13',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-16b_14',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-16b_15',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-16b_16',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-16b_17',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-16b_18',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-16b_19',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-16b_20',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-16b_4',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-16b_5',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-16b_6',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-16b_7',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-16b_8',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-16b_9',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Subida con fuerte pendiente'
  },
  {
    codigo: 'P-17',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Estrechamiento de la calzada'
  },
  {
    codigo: 'P-17a',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Estrechamiento de la calzada por la derecha'
  },
  {
    codigo: 'P-17b',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Estrechamiento de la calzada por la izquierda'
  },
  {
    codigo: 'P-18',
    formato: Triangulo,
    grupo: Peligro,
    descripción: Obras
  },
  {
    codigo: 'P-19',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Pavimento deslizante'
  },
  {
    codigo: 'P-1a',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Intersección con prioridad'
  },
  {
    codigo: 'P-1b',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Intersección con prioridad sobre vía a la izquierda'
  },
  {
    codigo: 'P-1c',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Intersección con prioridad sobre incorporación a la derecha'
  },
  {
    codigo: 'P-1d',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Intersección con prioridad sobre incorporación a la izquierda'
  },
  {
    codigo: 'P-2',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Intersección con prioridad a la derecha'
  },
  {
    codigo: 'P-20',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Peatones'
  },
  {
    codigo: 'P-20a',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Peatones'
  },
  {
    codigo: 'P-20a_P',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Peatones'
  },
  {
    codigo: 'P-20_P',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Peatones'
  },
  {
    codigo: 'P-21',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Niños'
  },
  {
    codigo: 'P-22',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Bicicletas'
  },
  {
    codigo: 'P-23',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Paso de animales domésticos'
  },
  {
    codigo: 'P-24',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Paso de animales en libertad'
  },
  {
    codigo: 'P-25',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Circulación en los dos sentidos'
  },
  {
    codigo: 'P-26',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Desprendimiento'
  },
  {
    codigo: 'P-27',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Muelle'
  },
  {
    codigo: 'P-28',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Proyección de gravilla'
  },
  {
    codigo: 'P-29',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Viento transversal'
  },
  {
    codigo: 'P-3',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Semáforos'
  },
  {
    codigo: 'P-30',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Escalón lateral'
  },
  {
    codigo: 'P-31',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Congestión'
  },
  {
    codigo: 'P-32',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Obstrucción en la Calzada'
  },
  {
    codigo: 'P-33',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Visibilidad reducida'
  },
  {
    codigo: 'P-34',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Pavimento deslizante por hielo o nieve'
  },
  {
    codigo: 'P-4',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Intersección con circulación giratoria'
  },
  {
    codigo: 'P-5',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Puente Movil'
  },
  {
    codigo: 'P-50',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Otros peligros'
  },
  {
    codigo: 'P-50o',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Otros Peligros'
  },
  {
    codigo: 'P-6',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Cruce de Tranvía'
  },
  {
    codigo: 'P-7',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Paso a nivel con barrera'
  },
  {
    codigo: 'P-8',
    formato: Triangulo,
    grupo: Peligro,
    descripción: 'Paso a nivel sin barrera'
  },
  {
    codigo: 'P-9a',
    formato: Rectangulo,
    grupo: Peligro,
    descripción: 'Proximidad paso a nivel o puente movil (lado derecho)'
  },
  {
    codigo: 'P-9b',
    formato: Rectangulo,
    grupo: Peligro,
    descripción: 'Aproximación a un paso a nivel o puente movil (lado derecho)'
  },
  {
    codigo: 'P-9c',
    formato: Rectangulo,
    grupo: Peligro,
    descripción: 'Cercanía de un paso a nivel o puente móvil (lado derecho)'
  },
  {
    codigo: 'PlacaSolar',
    formato: Rectangulo,
    grupo: Otras,
    descripción: 'Placa Solar'
  },
  {
    codigo: 'Placa-X1',
    formato: Circulo,
    grupo: Genericas,
    descripción: 'Placa genérica circular'
  },
  {
    codigo: 'Placa-X2',
    formato: Triangulo,
    grupo: Genericas,
    descripción: 'Placa genérica triangular'
  },
  {
    codigo: 'Placa-X3',
    formato: Cuadrado,
    grupo: Genericas,
    descripción: 'Placa genérica cuadrada'
  },
  {
    codigo: 'Placa-X4',
    formato: Rectangulo,
    grupo: Genericas,
    descripción: 'Placa genérica rectangular'
  },
  {
    codigo: 'Placa-X5',
    formato: Rectangulo,
    grupo: Genericas,
    descripción: 'Placa genérica rectangular'
  },
  {
    codigo: 'Pórtico_A-X',
    formato: Panel,
    grupo: Genericas,
    descripción: 'Pórtico  en autopista sin diseñar'
  },
  {
    codigo: 'Pórtico_B-X',
    formato: Panel,
    grupo: Genericas,
    descripción: 'Pórtico sin diseñar'
  },
  {
    codigo: 'R-1',
    formato: Triangulo,
    grupo: ReglamentacionPrioridad,
    descripción: 'Ceda el paso'
  },
  {
    codigo: 'R-100',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Circulación prohibida'
  },
  {
    codigo: 'R-101',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Entrada prohibida'
  },
  {
    codigo: 'R-102',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Entrada prohibida a vehículos de motor'
  },
  {
    codigo: 'R-102A',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Entrada prohibida a motocicletas y peatones'
  },
  {
    codigo: 'R-102B',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Entrada prohibida a peatones y ciclos'
  },
  {
    codigo: 'R-102C',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos agrícolas a motor y a ciclomotores'
  },
  {
    codigo: 'R-102D',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos agrícolas y vehículos de tracción animal.'
  },
  {
    codigo: 'R-102E',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos de tracción animal y vehículos agrícolas.'
  },
  {
    codigo: 'R-102F',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Entrada prohibida a ciclos y peatones'
  },
  {
    codigo: 'R-102G',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Entrada prohibida a motocicletas y a ciclomotores'
  },
  {
    codigo: 'R-102H',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a animales de montura y vehículos de tracción animal'
  },
  {
    codigo: 'R-102I',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Entrada prohibida a peatones y a ciclomotores'
  },
  {
    codigo: 'R-102J',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Entrada prohibida a vehículos agrícolas a motor y ciclos'
  },
  {
    codigo: 'R-103',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehiculos de motor, excepto motocicletas de dos ruedas sin sidecar'
  },
  {
    codigo: 'R-104',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Entrada prohibida a motocicletas'
  },
  {
    codigo: 'R-105',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Entrada prohibida a ciclomotores'
  },
  {
    codigo: 'R-106',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías.'
  },
  {
    codigo: 'R-107_10',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías con mayor masa autorizada que la indicada.'
  },
  {
    codigo: 'R-107_10.5',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías con mayor masa autorizada que la indicada.'
  },
  {
    codigo: 'R-107_11',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancias'
  },
  {
    codigo: 'R-107_11.5',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancias'
  },
  {
    codigo: 'R-107_12',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías con mayor masa autorizada que la indicada.'
  },
  {
    codigo: 'R-107_16',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías con mayor masa autorizada que la indicada.'
  },
  {
    codigo: 'R-107_18',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías con mayor masa autorizada que la indicada.'
  },
  {
    codigo: 'R-107_2',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías con mayor masa autorizada que la indicada.'
  },
  {
    codigo: 'R-107_2.5',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancias'
  },
  {
    codigo: 'R-107_3',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías con mayor masa autorizada que la indicada.'
  },
  {
    codigo: 'R-107_3.5',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías con mayor masa autorizada que la indicada.'
  },
  {
    codigo: 'R-107_38',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancias'
  },
  {
    codigo: 'R-107_4',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancias'
  },
  {
    codigo: 'R-107_4.5',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancias'
  },
  {
    codigo: 'R-107_5',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías con mayor masa autorizada que la indicada.'
  },
  {
    codigo: 'R-107_5.5',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías con mayor masa autorizada que la indicada.'
  },
  {
    codigo: 'R-107_6',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías con mayor masa autorizada que la indicada.'
  },
  {
    codigo: 'R-107_6.5',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías con mayor masa autorizada que la indicada.'
  },
  {
    codigo: 'R-107_7',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías con mayor masa autorizada que la indicada.'
  },
  {
    codigo: 'R-107_7.5',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías con mayor masa autorizada que la indicada.'
  },
  {
    codigo: 'R-107_8',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancias'
  },
  {
    codigo: 'R-107_8.5',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancias'
  },
  {
    codigo: 'R-107_9',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancias'
  },
  {
    codigo: 'R-107_9.5',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancias'
  },
  {
    codigo: 'R-108',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos que transporten mercancías peligrosas'
  },
  {
    codigo: 'R-109',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos que transporten mercancías explosivas o inflamables'
  },
  {
    codigo: 'R-110',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos que transporten productos contaminantes del agua'
  },
  {
    codigo: 'R-111',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Entrada prohibida a vehículos agrícolas de motor'
  },
  {
    codigo: 'R-112',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a vehículos de motor conremolque, que no sea un semirremolque o un remolque de un solo eje'
  },
  {
    codigo: 'R-113',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Entrada prohibida a vehículos de tracción animal'
  },
  {
    codigo: 'R-114',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Entrada prohibida a ciclos'
  },
  {
    codigo: 'R-115',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Entrada prohibida a carros de mano'
  },
  {
    codigo: 'R-116',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Entrada prohibida a peatones'
  },
  {
    codigo: 'R-117',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción: 'Entrada prohibida a animales de montura'
  },
  {
    codigo: 'R-2',
    formato: Octogono,
    grupo: ReglamentacionPrioridad,
    descripción: 'Detención obligatoria'
  },
  {
    codigo: 'R-200',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Prohibición de pasar sin detenerse'
  },
  {
    codigo: 'R-200_CC',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Prohibición de pasar sin detenerse'
  },
  {
    codigo: 'R-200_CI',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Prohibición de pasar sin detenerse'
  },
  {
    codigo: 'R-201_10',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_11',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_12',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_14',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_15',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_1.5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_16',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_17',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_20',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_24',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_29',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_3',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_3.5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_38',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_4',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_4.5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_5.5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_6',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_6.5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_7',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_7.5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_8',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_8.5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_9',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-201_9.5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-202_10',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-202_2',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-202_2.4',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-202_5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-202_7',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-202c',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-202E2',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-202E3',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de masa'
  },
  {
    codigo: 'R-203_10',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de longitud'
  },
  {
    codigo: 'R-203_7',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de longitud'
  },
  {
    codigo: 'R-204_10',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_2',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_2.0',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_2.1',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de Anchura'
  },
  {
    codigo: 'R-204_2.2',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_2.3',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_2.4',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_2.5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_2.6',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_2.7',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_2.8',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_2.9',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_3',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_3.1',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_3.2',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_3.3',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_3.4',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_3.5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_3.6',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_3.7',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_3.8',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_3.9',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_4',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_4.1',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_4.2',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_4.3',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_4.4',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-204_4.5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de anchura'
  },
  {
    codigo: 'R-205_2',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_2.1',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_2.2',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_2.25',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_2.3',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_2.4',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_2.5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_2.6',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_2.7',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_2.8',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_2.9',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_3',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_3.1',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_3.2',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_3.3',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_3.4',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_3.5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_3.6',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_3.7',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_3.8',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_3.9',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_4',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_4.1',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_4.2',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_4.3',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_4.4',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_4.5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_4.6',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_4.7',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_4.75',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_4.8',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_4.9',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_5.1',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_5.2',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_5.3',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_5.4',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_5.5',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_5.6',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_5.7',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_5.8',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_5.9',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R-205_6',
    formato: Circulo,
    grupo: RestriccionDePaso,
    descripción: 'Limitación de altura'
  },
  {
    codigo: 'R2_luz',
    formato: Cuadrado,
    grupo: Iluminadas,
    descripción: 'R-2 Iluminada'
  },
  {
    codigo: 'R-2o',
    formato: Circulo,
    grupo: ReglamentacionPrioridad,
    descripción: 'Detención obligatoria antigua'
  },
  {
    codigo: 'R-3',
    formato: Cuadrado,
    grupo: ReglamentacionPrioridad,
    descripción: 'Calzada con prioridad'
  },
  {
    codigo: 'R-300_100',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Separación minima'
  },
  {
    codigo: 'R-300_150',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Serparación minima'
  },
  {
    codigo: 'R-300_60',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Separación minima 60m'
  },
  {
    codigo: 'R-300_70',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Separación minima 70m'
  },
  {
    codigo: 'R-300_80',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Separación minima 80m'
  },
  {
    codigo: 'R-301_10',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'R-301_100',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Velocidad Máxima'
  },
  {
    codigo: 'R-301_110',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Velocidad Máxima'
  },
  {
    codigo: 'R-301_120',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'R-301_20',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'R-301_30',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'R-301_40',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'R-301_5',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'R-301_50',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'R-301_60',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'R-301_70',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'R-301_80',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'R-301_90',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'R-302',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Giro a la derecha prohibido'
  },
  {
    codigo: 'R-303',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Giro a la izquierda prohibido'
  },
  {
    codigo: 'R-304',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Media vuelta prohibida'
  },
  {
    codigo: 'R-305',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Adelantamiento prohibido'
  },
  {
    codigo: 'R-306',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Adelantamiento prohibido para camiones'
  },
  {
    codigo: 'R-307',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Parada y estacionamiento prohibidos'
  },
  {
    codigo: 'R-307C',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Parada y estacionamiento prohibidos'
  },
  {
    codigo: 'R-307D',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Parada y estacionamiento prohibidos'
  },
  {
    codigo: 'R-307Da',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Parada y estacionamiento prohibido'
  },
  {
    codigo: 'R-307I',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Parada y estacionamiento prohibidos'
  },
  {
    codigo: 'R-307Ia',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Parada y estacionamiento prohibido'
  },
  {
    codigo: 'R-307L',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Parada y estacionamiento prohibidos'
  },
  {
    codigo: 'R-307La',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Parada y estacionamiento prohibidos'
  },
  {
    codigo: 'R-307P',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Parada y estacionamiento prohibidos'
  },
  {
    codigo: 'R-307PD',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Parada y estacionamiento prohibidos'
  },
  {
    codigo: 'R-307PI',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Parada y estacionamiento prohibidos'
  },
  {
    codigo: 'R-307PL',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Parada y estacionamiento prohibidos'
  },
  {
    codigo: 'R-308',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Estacionamiento prohibido'
  },
  {
    codigo: 'R-308a',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Estacionamiento prohibido los días impares'
  },
  {
    codigo: 'R-308b',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Estacionamiento prohibido los días pares'
  },
  {
    codigo: 'R-308BC',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Estacionamiento prohibido autobuses y camiones'
  },
  {
    codigo: 'R-308c',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Estacionamiento prohibido la primera quincena'
  },
  {
    codigo: 'R-308d',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Estacionamiento prohibido la segunda quincena'
  },
  {
    codigo: 'R-308D2',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Estacionamiento prohibido'
  },
  {
    codigo: 'R-308I',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Estacionamiento prohibido'
  },
  {
    codigo: 'R-308L',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Estacionamiento prohibido'
  },
  {
    codigo: 'R-308M',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Estacionamiento prohibido minusválidos'
  },
  {
    codigo: 'R-308MID',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Estacionamiento prohibido minusválidos izquierda derecha'
  },
  {
    codigo: 'R-308P',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Estacionamiento prohibido'
  },
  {
    codigo: 'R-308PD',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Estacionamiento prohibido'
  },
  {
    codigo: 'R-308PI',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Estacionamiento prohibido'
  },
  {
    codigo: 'R-308PL',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Estacionamiento prohibido'
  },
  {
    codigo: 'R-309',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Zona de estacionamiento limitado'
  },
  {
    codigo: 'R-310',
    formato: Circulo,
    grupo: OtrasSenalesProhibicionRestriccion,
    descripción: 'Estacionamiento prohibido'
  },
  {
    codigo: 'R-4',
    formato: Cuadrado,
    grupo: ReglamentacionPrioridad,
    descripción: 'Fin de Prioridad'
  },
  {
    codigo: 'R-400a',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Sentido Obligatorio'
  },
  {
    codigo: 'R-400b',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Sentido Obligatorio'
  },
  {
    codigo: 'R-400c',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Sentido Obligatorio'
  },
  {
    codigo: 'R-400d',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Sentido Obligatorio'
  },
  {
    codigo: 'R-400e',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Sentido Obligatorio'
  },
  {
    codigo: 'R-401a',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Paso Obligatorio'
  },
  {
    codigo: 'R-401b',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Paso Obligatorio'
  },
  {
    codigo: 'R-401c',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Paso Obligatorio'
  },
  {
    codigo: 'R-401d',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Paso Obligatorio'
  },
  {
    codigo: 'R-402',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Intersección de sentido giratorio obligatorio'
  },
  {
    codigo: 'R-403a',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Sentido obligatorio frente derecha'
  },
  {
    codigo: 'R-403b',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Sentido obligatorio frente izquierda'
  },
  {
    codigo: 'R-403c',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Sentido Obligatorio'
  },
  {
    codigo: 'R-404',
    formato: Circulo,
    grupo: Obligacion,
    descripción:
      'Calzada para automóviles, excepto motocicletas de dos ruedas sin sidecar'
  },
  {
    codigo: 'R-405',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Calzada pra motocicletas de dos ruedas sin sidecar'
  },
  {
    codigo: 'R-406',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Calzada para camiones y furgones'
  },
  {
    codigo: 'R-407',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Via Reservada'
  },
  {
    codigo: 'R-407a',
    formato: Rectangulo,
    grupo: Obligacion,
    descripción: 'Via Reservada'
  },
  {
    codigo: 'R-408',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Camino reservado para vehículos de tracción animal'
  },
  {
    codigo: 'R-409',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Camino reservado para animales de montura'
  },
  {
    codigo: 'R-410',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Obligatorio Peatones'
  },
  {
    codigo: 'R-411_100',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Velocidad mínima'
  },
  {
    codigo: 'R-411_20',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Velocidad mínima'
  },
  {
    codigo: 'R-411_30',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Velocidad mínima'
  },
  {
    codigo: 'R-411_40',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Velocidad mínima'
  },
  {
    codigo: 'R-411_50',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Velocidad mínima'
  },
  {
    codigo: 'R-411_60',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Velocidad mínima'
  },
  {
    codigo: 'R-411_70',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Velocidad mínima'
  },
  {
    codigo: 'R-411_80',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Velocidad mínima'
  },
  {
    codigo: 'R-411_90',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Velocidad mínima'
  },
  {
    codigo: 'R-412',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Cadenas para nieve'
  },
  {
    codigo: 'R-413',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Alumbrado de corto alcance'
  },
  {
    codigo: 'R-414',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Calzada para vehículos que transporten mercancías peligrosas'
  },
  {
    codigo: 'R-415',
    formato: Circulo,
    grupo: Obligacion,
    descripción:
      'Calzada para vehículos que transporten productos contaminantes del agua'
  },
  {
    codigo: 'R-416',
    formato: Circulo,
    grupo: Obligacion,
    descripción:
      'Calzada para vehículos que transporten mercancias explosivas o inflamables'
  },
  {
    codigo: 'R-417',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Uso obligatorio del cinturón de seguridad'
  },
  {
    codigo: 'R-418',
    formato: Circulo,
    grupo: Obligacion,
    descripción: 'Via exclusiva vehiculos con telepeaje'
  },
  {
    codigo: 'R-5',
    formato: Circulo,
    grupo: ReglamentacionPrioridad,
    descripción: 'Prioridad en sentido contrario'
  },
  {
    codigo: 'R-500',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de prohibiciones'
  },
  {
    codigo: 'R-501_100',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de limitación de velocidad'
  },
  {
    codigo: 'R-501_20',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de limitación de velocidad'
  },
  {
    codigo: 'R-501_30',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de la limitación de velocidad'
  },
  {
    codigo: 'R-501_40',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de limitación de velocidad'
  },
  {
    codigo: 'R-501_50',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de la limitación de velocidad'
  },
  {
    codigo: 'R-501_60',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de limitación de velocidad'
  },
  {
    codigo: 'R-501_70',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de limitación de velocidad'
  },
  {
    codigo: 'R-501_80',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de limitación de velocidad'
  },
  {
    codigo: 'R-501_90',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de limitación de velocidad'
  },
  {
    codigo: 'R-502',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de la prohibición de adelantamiento'
  },
  {
    codigo: 'R-503',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de prohibición de adelantamiento para camiones'
  },
  {
    codigo: 'R-504',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de zona de estacionamiento limitado'
  },
  {
    codigo: 'R-505',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de la prohibición de advertencias acústicas'
  },
  {
    codigo: 'R-506_20',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de velocidad mínima'
  },
  {
    codigo: 'R-506_30',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de velocidad mínima'
  },
  {
    codigo: 'R-506_40',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de velocidad mínima'
  },
  {
    codigo: 'R-506_50',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de velocidad mínima'
  },
  {
    codigo: 'R-506_60',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de velocidad mínima'
  },
  {
    codigo: 'R-506_70',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de velocidad mínima'
  },
  {
    codigo: 'R-506_90',
    formato: Circulo,
    grupo: FinProhibicionRestriccion,
    descripción: 'Fin de velocidad mínima'
  },
  {
    codigo: 'R-6',
    formato: Cuadrado,
    grupo: ReglamentacionPrioridad,
    descripción: 'Prioridad respecto al sentido contrario'
  },
  {
    codigo: 'RED',
    formato: Rombo,
    grupo: IndicacionesGenerales,
    descripción: 'Red prioritaria'
  },
  {
    codigo: 'R_PBCT',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a peatones,ciclos, vehículos de tracción animal  y vehículos agrícolas'
  },
  {
    codigo: 'R_PCTM',
    formato: Circulo,
    grupo: ProhibicionEntrada,
    descripción:
      'Entrada prohibida a peatones, vehículos de tracción animal, vehículos agrícolas y ciclos.'
  },
  {
    codigo: 'S-1',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Autopista'
  },
  {
    codigo: 'S-10',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Fin de intervalo aconsejado de velocidades'
  },
  {
    codigo: 'S-100',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Puesto de socorro'
  },
  {
    codigo: 'S-100_250',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Puesto de socorro'
  },
  {
    codigo: 'S-100_300',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Puesto de socorro'
  },
  {
    codigo: 'S-100_400',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Puesto de socorro'
  },
  {
    codigo: 'S-100_500',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Puesto de socorro'
  },
  {
    codigo: 'S-101',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Base de ambulancia'
  },
  {
    codigo: 'S-102',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Servicio de inspección técnica de vehículos'
  },
  {
    codigo: 'S-103',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Taller de reparación'
  },
  {
    codigo: 'S-104a',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Teléfono'
  },
  {
    codigo: 'S-104b',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Teléfono de Socorro'
  },
  {
    codigo: 'S-105',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante'
  },
  {
    codigo: 'S-105_1000',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante'
  },
  {
    codigo: 'S-105_200',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante'
  },
  {
    codigo: 'S-105_300',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante'
  },
  {
    codigo: 'S-105_400',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante'
  },
  {
    codigo: 'S-105_500',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante'
  },
  {
    codigo: 'S-105a',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante con plomo y sin plomo'
  },
  {
    codigo: 'S-105a_100',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante con plomo y sin plomo'
  },
  {
    codigo: 'S-105a_1000',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante con plomo y sin plomo'
  },
  {
    codigo: 'S-105a_200',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante con plomo y sin plomo'
  },
  {
    codigo: 'S-105a_250',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante con plomo y sin plomo'
  },
  {
    codigo: 'S-105a_300',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante con plomo y sin plomo'
  },
  {
    codigo: 'S-105a_400',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante con plomo y sin plomo'
  },
  {
    codigo: 'S-105a_500',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante con plomo y sin plomo'
  },
  {
    codigo: 'S-105ad',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante con plomo y sin plomo'
  },
  {
    codigo: 'S-105d',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante'
  },
  {
    codigo: 'S-106',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Taller de reparación y surtidor de carburante'
  },
  {
    codigo: 'S-106a',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Surtidor de carburante y lavadero de coches'
  },
  {
    codigo: 'S-107',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Campamento'
  },
  {
    codigo: 'S-108',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Agua'
  },
  {
    codigo: 'S-109',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Lugar pintoresco'
  },
  {
    codigo: 'S-11',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Calzada de sentido único'
  },
  {
    codigo: 'S-110',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Hotel o motel'
  },
  {
    codigo: 'S-110_1000',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Hotel o motel'
  },
  {
    codigo: 'S-110_200',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Hotel o motel'
  },
  {
    codigo: 'S-110_300',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Hotel o motel'
  },
  {
    codigo: 'S-110_500',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Hotel o motel'
  },
  {
    codigo: 'S-110H',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Hotel'
  },
  {
    codigo: 'S-111',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Restaurante'
  },
  {
    codigo: 'S-111_100',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Restaurante'
  },
  {
    codigo: 'S-111_1000',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Restaurante'
  },
  {
    codigo: 'S-111_200',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Restaurante'
  },
  {
    codigo: 'S-111_250',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Restaurante'
  },
  {
    codigo: 'S-111_300',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Restaurante'
  },
  {
    codigo: 'S-111_500',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Restaurante'
  },
  {
    codigo: 'S-112',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Cafetería'
  },
  {
    codigo: 'S-113',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Terreno para remolques-vivienda'
  },
  {
    codigo: 'S-114',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Merendero'
  },
  {
    codigo: 'S-115',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Punto de partida para excursiones a pie'
  },
  {
    codigo: 'S-116',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Campamento y terreno para remolques-vivienda'
  },
  {
    codigo: 'S-117',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Albergue juvenil'
  },
  {
    codigo: 'S-118',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Información turística'
  },
  {
    codigo: 'S-118_100',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Información turística'
  },
  {
    codigo: 'S-118_1000',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Información turística'
  },
  {
    codigo: 'S-118_200',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Información turística'
  },
  {
    codigo: 'S-118_250',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Información turística'
  },
  {
    codigo: 'S-118_300',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Información turística'
  },
  {
    codigo: 'S-118_400',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Información turística'
  },
  {
    codigo: 'S-118_500',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Información turística'
  },
  {
    codigo: 'S-118_600',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Información turística'
  },
  {
    codigo: 'S-118_700',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Información turística'
  },
  {
    codigo: 'S-118_800',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Información turística'
  },
  {
    codigo: 'S-118_900',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Información turística'
  },
  {
    codigo: 'S-119',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Coto de Pesca'
  },
  {
    codigo: 'S-11a',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Calzada de sentido único'
  },
  {
    codigo: 'S-11b',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Calzada de sentido único'
  },
  {
    codigo: 'S11C',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Calzada de doble sentido'
  },
  {
    codigo: 'S-12',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Tramo de calzada de sentido único'
  },
  {
    codigo: 'S-120',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Parque Nacional'
  },
  {
    codigo: 'S-121',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Monumento nacional'
  },
  {
    codigo: 'S-122',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Otros servicios'
  },
  {
    codigo: 'S-123',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Area de descanso'
  },
  {
    codigo: 'S-124',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Estacionamiento para usuarios del ferrocarril'
  },
  {
    codigo: 'S-125',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Estacionamiento para usuarios del ferrocarril subterraneo'
  },
  {
    codigo: 'S-126',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Estacionamiento para usuarios de autobus'
  },
  {
    codigo: 'S-127',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Servicio de autobuses'
  },
  {
    codigo: 'S-128',
    formato: Rectangulo,
    grupo: Servicios,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías.'
  },
  {
    codigo: 'S-129',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Servicio de taxis'
  },
  {
    codigo: 'S-12I',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Tramo de calzada de sentido único'
  },
  {
    codigo: 'S-13',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Situación de un paso de peatones'
  },
  {
    codigo: 'S130',
    formato: Rectangulo,
    grupo: Servicios,
    descripción: 'Área industrial'
  },
  {
    codigo: 'S-14a',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Paso superior para peatones'
  },
  {
    codigo: 'S-14b',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Paso inferior para peatones'
  },
  {
    codigo: 'S-15a',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Preseñalización de calzada sin salida'
  },
  {
    codigo: 'S-15b',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Preseñalización de calzada sin salida'
  },
  {
    codigo: 'S-15c',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Preseñalización de calzada sin salida'
  },
  {
    codigo: 'S-15d',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Preseñalización de calzada sin salida'
  },
  {
    codigo: 'S-16',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Zona de frenado de emergencia'
  },
  {
    codigo: 'S-17',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Estacionamiento'
  },
  {
    codigo: 'S-17_500',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Estacionamiento'
  },
  {
    codigo: 'S-17A',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Estacionamiento fin'
  },
  {
    codigo: 'S-17D',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Estacionamiento'
  },
  {
    codigo: 'S-17F',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Estacionamiento'
  },
  {
    codigo: 'S-17I',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Estacionamiento'
  },
  {
    codigo: 'S-17L',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Estacionamiento'
  },
  {
    codigo: 'S-17M',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Estacionamiento'
  },
  {
    codigo: 'S-17MD',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Estacionamiento'
  },
  {
    codigo: 'S-17MI',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Estacionamiento'
  },
  {
    codigo: 'S-17Mo',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Estacionamiento Motos'
  },
  {
    codigo: 'S-17Mo2',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Estacionamiento motos texto'
  },
  {
    codigo: 'S-18',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Taxi'
  },
  {
    codigo: 'S-18D',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Taxi'
  },
  {
    codigo: 'S-18D_4',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Taxi'
  },
  {
    codigo: 'S-18I',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Taxi'
  },
  {
    codigo: 'S-18I_4',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Taxi'
  },
  {
    codigo: 'S-18L',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Taxi'
  },
  {
    codigo: 'S-18o',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Taxi'
  },
  {
    codigo: 'S-18oD',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Taxi'
  },
  {
    codigo: 'S-18oI',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Taxi'
  },
  {
    codigo: 'S-18oL',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Taxi'
  },
  {
    codigo: 'S-19',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Parada de autobuses'
  },
  {
    codigo: 'S-1a',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Autovía'
  },
  {
    codigo: 'S-1b',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Vía Rápida'
  },
  {
    codigo: 'S-2',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Fin de autopista'
  },
  {
    codigo: 'S-20',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Parada de Tranvías'
  },
  {
    codigo: 'S-21_1a',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Transitabilidad en tramo o puerto de montaña'
  },
  {
    codigo: 'S-21_1b',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Transitabilidad en tramo o puerto de montaña'
  },
  {
    codigo: 'S-21_2b',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Transitabilidad en tramo o puerto de montaña'
  },
  {
    codigo: 'S-21_2c',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Transitabilidad en tramo o puerto de montaña'
  },
  {
    codigo: 'S-21_3b',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Transitabilidad en tramo o puerto de montaña'
  },
  {
    codigo: 'S-21_gen',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Transitabilidad en tramo o puerto de montaña'
  },
  {
    codigo: 'S-22',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_100',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_1000',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_1100',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_1200',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_1300',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_1400',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_150',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_1500',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_200',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_2000',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_250',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_300',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_400',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_50',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_500',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_600',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_700',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_800',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22_900',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-22a',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido'
  },
  {
    codigo: 'S-23',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Hospital'
  },
  {
    codigo: 'S-23o',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Hospital'
  },
  {
    codigo: 'S-23o2',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Hospital'
  },
  {
    codigo: 'S-24',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Fin de obligación de alumbrado de corto alcance'
  },
  {
    codigo: 'S-25',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Cambio de sentido a distinto nivel'
  },
  {
    codigo: 'S-26a',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Panel de aproximación salida (100 m)'
  },
  {
    codigo: 'S-26ai',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Panel de aproximación salida (100 m)'
  },
  {
    codigo: 'S-26b',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Panel de aproximación salida (200 m)'
  },
  {
    codigo: 'S-26bi',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Panel de aproximación salida (200 m)'
  },
  {
    codigo: 'S-26c',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Panel de aproximación salida (300 m)'
  },
  {
    codigo: 'S-26ci',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Panel de aproximación salida (300 m)'
  },
  {
    codigo: 'S-26Td',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Panel Aproximación Túnel'
  },
  {
    codigo: 'S26Td_N',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Panel Aproximación Túnel'
  },
  {
    codigo: 'S-26Ti',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Panel Aproximación Túnel'
  },
  {
    codigo: 'S26Ti_N',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Panel Aproximación Túnel'
  },
  {
    codigo: 'S-27',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Auxilio en carretera'
  },
  {
    codigo: 'S-271',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Área de Servicios'
  },
  {
    codigo: 'S-27D',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Auxilio en carretera'
  },
  {
    codigo: 'S-28',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Zona Residencial'
  },
  {
    codigo: 'S-29',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Fin Zona Residencial'
  },
  {
    codigo: 'S-2a',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Fin de autovía'
  },
  {
    codigo: 'S-2b',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Fin vía rápida'
  },
  {
    codigo: 'S-200',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: 'Preseñalización de glorieta'
  },
  {
    codigo: 'S-220',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción:
      'Preseñalización de direcciones hacia una carreter convencional'
  },
  {
    codigo: 'S-221',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: 'Preseñalización de direcciones hacia una vía rápida'
  },
  {
    codigo: 'S-221a',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción:
      'Preseñalización de direcciones hacia una vía rápida y dirección propia'
  },
  {
    codigo: 'S-222',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción:
      'Preseñalización de direcciones hacia una autopista o una autovía'
  },
  {
    codigo: 'S-222a',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción:
      'Preseñalización de direcciones hacia una autopista o una autovía y dirección propia'
  },
  {
    codigo: 'S-223',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: 'Preseñalización de direcciones en una vía rápida'
  },
  {
    codigo: 'S-224',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción:
      'Preseñalización de direcciones en una vía rápida hacia autopista o autovía'
  },
  {
    codigo: 'S-225',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción:
      'Preseñalización de direcciones en una vía rápida hacia autopista o autovía'
  },
  {
    codigo: 'S-230',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-230a',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-231',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-231a',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-232',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-232a',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-233',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-233a',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-234',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-234a',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-235',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-235a',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-240',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-240a',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-241',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-241a',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-242',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-242a',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-250',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-260',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-261',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-262',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-262a',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-263',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-263a',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-264',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-265',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-265a',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-266',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-266a',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-270',
    formato: Panel,
    grupo: OrientacionPresenalizacion,
    descripción: ''
  },
  {
    codigo: 'S-3',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Vía para automóviles'
  },
  {
    codigo: 'S-34',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Apartadero en túneles'
  },
  {
    codigo: 'S-34a',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Apartadero en túneles'
  },
  {
    codigo: 'S-300',
    formato: Rectangulo,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-301',
    formato: Rectangulo,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-302',
    formato: Rectangulo,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-310',
    formato: Rectangulo,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-320',
    formato: Rectangulo,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-321',
    formato: Rectangulo,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-322',
    formato: Rectangulo,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-341',
    formato: Rectangulo,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-342',
    formato: Rectangulo,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-343',
    formato: Rectangulo,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-344',
    formato: Rectangulo,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-345',
    formato: Rectangulo,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-346',
    formato: Rectangulo,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-347',
    formato: Rectangulo,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-348',
    formato: Rectangulo,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-350',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-351',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-352',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-353',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-354',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-355',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-360',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-361',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-362',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-363',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-364',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-365',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-366',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-367',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-368',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-370',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-371',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-372',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-373',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-374',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-375',
    formato: Panel,
    grupo: OrientacionDireccion,
    descripción: ''
  },
  {
    codigo: 'S-4',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Fin de vía para automóviles'
  },
  {
    codigo: 'S-400',
    formato: Rectangulo,
    grupo: IdentificacionCarreteras,
    descripción: 'Cajetín de un itinerario europeo'
  },
  {
    codigo: 'S-400_A_400',
    formato: Panel,
    grupo: IdentificacionCarreteras,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-410',
    formato: Rectangulo,
    grupo: IdentificacionCarreteras,
    descripción: 'Cajetín de una Autopista'
  },
  {
    codigo: 'S-420',
    formato: Rectangulo,
    grupo: IdentificacionCarreteras,
    descripción: 'Cajetín de una Carretera Nacional'
  },
  {
    codigo: 'S-420_N-IV',
    formato: Rectangulo,
    grupo: IdentificacionCarreteras,
    descripción: 'Cajetín de la N-IV'
  },
  {
    codigo: 'S-430',
    formato: Rectangulo,
    grupo: IdentificacionCarreteras,
    descripción: 'Cajetín de una carretera autonómica de primer nivel'
  },
  {
    codigo: 'S-440',
    formato: Rectangulo,
    grupo: IdentificacionCarreteras,
    descripción: 'Cajetín de una carretera autonómica de segundo nivel'
  },
  {
    codigo: 'S-450',
    formato: Rectangulo,
    grupo: IdentificacionCarreteras,
    descripción: 'Cajetín de una carretera autonómica de tercer nivel'
  },
  {
    codigo: 'S-460',
    formato: Rectangulo,
    grupo: IdentificacionCarreteras,
    descripción: 'Cajetín de un desvío'
  },
  {
    codigo: 'S-5',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Túnel'
  },
  {
    codigo: 'S-50a_50',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50a_60',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50a_70',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50b_50',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50b_60',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50b_70',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50c',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Carril'
  },
  {
    codigo: 'S-50c_50',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50c_60',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50c_70',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50d_40',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50d_50',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50d_60',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50d_70',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50d_70a',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50e',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: ''
  },
  {
    codigo: 'S-50e_100-90',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50e_50',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50e_60',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-50e_70',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Carriles obligatorios para tráfico lento y reservados para tráfico rápido'
  },
  {
    codigo: 'S-51',
    formato: Cuadrado,
    grupo: Carriles,
    descripción: 'Carril reservado para autobuses'
  },
  {
    codigo: 'S-51a',
    formato: Cuadrado,
    grupo: Carriles,
    descripción: 'Carril reservado para autobuses'
  },
  {
    codigo: 'S-52',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Final de carril destinado a la circulación'
  },
  {
    codigo: 'S-52a',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Final de carril destinado a la circulación'
  },
  {
    codigo: 'S-52b',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Final de carril destinado a la circulación'
  },
  {
    codigo: 'S-52c1',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Final de carril destinado a la circulación'
  },
  {
    codigo: 'S-52c2',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Final de carril destinado a la circulación'
  },
  {
    codigo: 'S-52c3',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Final de carril destinado a la circulación'
  },
  {
    codigo: 'S-52c4',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Final de carril destinado a la circulación'
  },
  {
    codigo: 'S-52c5',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Final de carril destinado a la circulación'
  },
  {
    codigo: 'S-52c6',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Final de carril destinado a la circulación eincorporación por la derecha'
  },
  {
    codigo: 'S-52d1',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Final de carril destinado a la circulación'
  },
  {
    codigo: 'S-52d2',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Final de carril destinado a la circulación'
  },
  {
    codigo: 'S-52d3',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Final de carril destinado a la circulación'
  },
  {
    codigo: 'S-52d4',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Final de carril destinado a la circulación'
  },
  {
    codigo: 'S-53',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Paso de uno a dos carriles de circulación'
  },
  {
    codigo: 'S-53_70',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Paso de uno a dos carriles'
  },
  {
    codigo: 'S-53a',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Paso de uno a dos carriles de circulación con limitación maxima en cada carril'
  },
  {
    codigo: 'S-53b',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Paso de dos a tres carriles de circulación'
  },
  {
    codigo: 'S-53c',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Paso de dos a tres carriles de circulación con limitación maxima en cada carril'
  },
  {
    codigo: 'S-53d',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Paso de dos a tres carriles de circulación'
  },
  {
    codigo: 'S-53e',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Paso de uno a dos carriles de circulación'
  },
  {
    codigo: 'S-53f',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Paso de uno a dos carriles de circulación en uno de los sentidos'
  },
  {
    codigo: 'S-53G',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Paso de tres a cuatro carriles'
  },
  {
    codigo: 'S-53H',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Paso de tres a cuatro carriles'
  },
  {
    codigo: 'S-500',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción: ''
  },
  {
    codigo: 'S-510',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción: ''
  },
  {
    codigo: 'S-520',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción: ''
  },
  {
    codigo: 'S-530',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción: ''
  },
  {
    codigo: 'S-540',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción: ''
  },
  {
    codigo: 'S-550',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción: ''
  },
  {
    codigo: 'S-560',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción: ''
  },
  {
    codigo: 'S-570',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción: 'Hito kilométrico en autopista'
  },
  {
    codigo: 'S-570a',
    formato: Cuadrado,
    grupo: OrientacionLocalizacion,
    descripción: 'Hito kilométrico'
  },
  {
    codigo: 'S-571',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción: 'Hito kilométrico en autopista e itinerario europeo'
  },
  {
    codigo: 'S-572',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción:
      'Hito kilométrico en autovía, vía rápida o carretera convencional'
  },
  {
    codigo: 'S-572B',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción:
      'Hito kilométrico en autovía, vía rápida o carretera convencional amarillo'
  },
  {
    codigo: 'S-573',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción: 'Hito kilométrico en itinerario europeo'
  },
  {
    codigo: 'S-574',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción: 'Hito miriamétrico'
  },
  {
    codigo: 'S-574a',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción: 'Hito miriametrico en autopista'
  },
  {
    codigo: 'S-575',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción: 'Hito miriamétrico'
  },
  {
    codigo: 'S-575A',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción: 'Hito miriamétrico en autopista'
  },
  {
    codigo: 'S-576',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción: 'Hito miriamétrico'
  },
  {
    codigo: 'S-576A',
    formato: Rectangulo,
    grupo: OrientacionLocalizacion,
    descripción: 'Hito miriametrico en autopista'
  },
  {
    codigo: 'S-6',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Fin de Tunel'
  },
  {
    codigo: 'S-60a',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la izquierda en calzada de dos carriles'
  },
  {
    codigo: 'S-60b',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la derecha en calzada de dos carriles'
  },
  {
    codigo: 'S-60b22',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la derecha en calzada de cuatro carriles'
  },
  {
    codigo: 'S-60c',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Incorporación por la izquierda en calzada de dos carriles'
  },
  {
    codigo: 'S-60c21',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la derecha en calzada de dos carriles'
  },
  {
    codigo: 'S-60c22',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la derecha en calzada de cuatro carriles'
  },
  {
    codigo: 'S-61a',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la izquierda en calzada de tres carriles'
  },
  {
    codigo: 'S-61b',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la derecha en calzada de tres carriles'
  },
  {
    codigo: 'S-61c',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la izquierda en calzada de tres carriles'
  },
  {
    codigo: 'S-61d',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la derecha en calzada de tres carriles'
  },
  {
    codigo: 'S-61dd',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Incorporación por la derecha en calzada de tres carriles'
  },
  {
    codigo: 'S-61e',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la derecha en calzada de tres carriles'
  },
  {
    codigo: 'S-62',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación en calzada de dos carriles'
  },
  {
    codigo: 'S-62a',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la izquierda en calzada de cuatro carriles'
  },
  {
    codigo: 'S-62a21',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación en calzada de tres carriles'
  },
  {
    codigo: 'S-62a22',
    formato: Rectangulo,
    grupo: Carriles,
    descripción:
      'Paso de dos a un carril por la izquierda y bifurcación hacia la derecha'
  },
  {
    codigo: 'S-62b',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la derecha en calzada de cuatro carriles'
  },
  {
    codigo: 'S-62c',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la izquierda en calzada de cuatro carriles'
  },
  {
    codigo: 'S-62e',
    formato: Cuadrado,
    grupo: Carriles,
    descripción: 'Calzada de tres carriles sin bifurcaciones'
  },
  {
    codigo: 'S-62f',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Calzada de cuatro carriles sin bifurcaciones'
  },
  {
    codigo: 'S-63',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación en calzada de cuatro carriles'
  },
  {
    codigo: 'S-63a',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la izquierda en calzada de cinco carriles'
  },
  {
    codigo: 'S-63a23',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación en calzada de cinco carriles'
  },
  {
    codigo: 'S-63b',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la derecha en calzada de cinco carriles'
  },
  {
    codigo: 'S-63B',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Paso de dos a cuatro carriles'
  },
  {
    codigo: 'S-63c',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la izquierda en calzada de cinco carriles'
  },
  {
    codigo: 'S-63d',
    formato: Rectangulo,
    grupo: Carriles,
    descripción: 'Bifurcación hacia la derecha en calzada de cinco carriles'
  },
  {
    codigo: 'S-600',
    formato: Panel,
    grupo: OrientacionConfirmacion,
    descripción: ''
  },
  {
    codigo: 'S-601',
    formato: Panel,
    grupo: OrientacionConfirmacion,
    descripción: ''
  },
  {
    codigo: 'S-602',
    formato: Panel,
    grupo: OrientacionConfirmacion,
    descripción: ''
  },
  {
    codigo: 'S-610',
    formato: Panel,
    grupo: OrientacionConfirmacion,
    descripción: ''
  },
  {
    codigo: 'S-7_100',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Velocidad máxima aconsejada'
  },
  {
    codigo: 'S-7_20',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Velocidad máxima aconsejada'
  },
  {
    codigo: 'S-7_30',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Velocidad máxima aconsejada'
  },
  {
    codigo: 'S-7_40',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Velocidad máxima aconsejada'
  },
  {
    codigo: 'S-7_50',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Velocidad máxima aconsejada'
  },
  {
    codigo: 'S-7_60',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Velocidad máxima aconsejada'
  },
  {
    codigo: 'S-7_70',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Velocidad máxima aconsejada'
  },
  {
    codigo: 'S-7_80',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Velocidad máxima aconsejada'
  },
  {
    codigo: 'S-7_90',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Velocidad máxima aconsejada'
  },
  {
    codigo: 'S-700',
    formato: Rectangulo,
    grupo: OrientacionPoblado,
    descripción: ''
  },
  {
    codigo: 'S-710',
    formato: Rectangulo,
    grupo: OrientacionPoblado,
    descripción: ''
  },
  {
    codigo: 'S-720',
    formato: Rectangulo,
    grupo: OrientacionPoblado,
    descripción: ''
  },
  {
    codigo: 'S-730',
    formato: Rectangulo,
    grupo: OrientacionPoblado,
    descripción: ''
  },
  {
    codigo: 'S-740',
    formato: Rectangulo,
    grupo: OrientacionPoblado,
    descripción: ''
  },
  {
    codigo: 'S-750',
    formato: Rectangulo,
    grupo: OrientacionPoblado,
    descripción: ''
  },
  {
    codigo: 'S-760',
    formato: Rectangulo,
    grupo: OrientacionPoblado,
    descripción: ''
  },
  {
    codigo: 'S-770',
    formato: Rectangulo,
    grupo: OrientacionPoblado,
    descripción: ''
  },
  {
    codigo: 'S-800',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Panel Complementario Genérico'
  },
  {
    codigo: 'S-800_100',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_1000',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_1300',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_150',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_1500',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_1km',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_200',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_250',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_300',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_350',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_400',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_450',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_50',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_500',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_600',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_700',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_75',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: ''
  },
  {
    codigo: 'S-800_800',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_900',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_A',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Panel Complementario Genérico'
  },
  {
    codigo: 'S-800_A_100',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_A_1000',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_A_1300',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_A_150',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_A_1500',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_A_200',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_A_250',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_A_300',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_A_350',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_A_450',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_A_50',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_A_500',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_A_600',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_A_700',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_A_800',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800_A_900',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Distancia al comienzo del peligro o prescripción'
  },
  {
    codigo: 'S-800d',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Aplicación de prohibición o prescripción'
  },
  {
    codigo: 'S-800i',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Aplicación de prohibición o prescripción'
  },
  {
    codigo: 'S-800_Lluv_1',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Panel Complementario CON LLUVIA 200 x 800'
  },
  {
    codigo: 'S-800_Lluv_2',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Panel Complementario CON LLUVIA 300 x 1250'
  },
  {
    codigo: 'S-800_Rec_1A',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Panel Complementario RECUERDE 200 x 800 NEGRO'
  },
  {
    codigo: 'S-800_Rec_1B',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Panel Complementario RECUERDE 200 x 800 ROJO'
  },
  {
    codigo: 'S-800_Rec_2A',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Panel Complementario RECUERDE 300 x 1250 NEGRO'
  },
  {
    codigo: 'S-800_Rec_2B',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Panel Complementario RECUERDE 300 x 1250 ROJO'
  },
  {
    codigo: 'S-810',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Panel Complementario genérico'
  },
  {
    codigo: 'S-8_100',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Fin de velocidad máxima aconsejada'
  },
  {
    codigo: 'S-810_1',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Longitud del tramo peligroso o sujeto a prescripción'
  },
  {
    codigo: 'S-810_100',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Longitud del tramo peligroso sujeto a prescripción'
  },
  {
    codigo: 'S-810_1.5',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Longitud del tramo peligroso o sujeto a prescripción'
  },
  {
    codigo: 'S-810_2',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Longitud del tramo peligroso o sujeto a prescripción'
  },
  {
    codigo: 'S-810_200',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Longitud del tramo peligroso o sujeto a prescripción'
  },
  {
    codigo: 'S-810_2.5',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Longitud del tramo peligroso o sujeto a prescripción'
  },
  {
    codigo: 'S-810_3',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Longitud del tramo peligroso o sujeto a prescripción'
  },
  {
    codigo: 'S-810_3.5',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Longitud del tramo peligroso o sujeto a prescripción'
  },
  {
    codigo: 'S-810_350',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Longitud del tramo peligroso o sujeto a prescripción'
  },
  {
    codigo: 'S-810_4',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Longitud del tramo peligroso o sujeto a prescripción'
  },
  {
    codigo: 'S-810_4.25',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Longitud del tramo peligroso o sujeto a prescripción'
  },
  {
    codigo: 'S-810_5',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Longitud del tramo peligroso o sujeto a prescripción'
  },
  {
    codigo: 'S-820',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Extensión de la prohibición a un lado'
  },
  {
    codigo: 'S-821',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Extensión de la prohibición a un lado'
  },
  {
    codigo: 'S-830',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Extensiones de la prohibición a ambos lados'
  },
  {
    codigo: 'S-8_30',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Fin de velocidad máxima aconsejada'
  },
  {
    codigo: 'S-8_40',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Fin de velocidad máxima aconsejada'
  },
  {
    codigo: 'S-840_100',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Preseñalización de detención obligatoria'
  },
  {
    codigo: 'S-840_1000',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Preseñalización de detención obligatoria'
  },
  {
    codigo: 'S-840_150',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Preseñalización de detención obligatoria'
  },
  {
    codigo: 'S-840_200',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Preseñalización de detención obligatoria'
  },
  {
    codigo: 'S-840_30',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Preseñalización de detención obligatoria'
  },
  {
    codigo: 'S-840_300',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Preseñalización de detención obligatoria'
  },
  {
    codigo: 'S-840_400',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Preseñalización de detención obligatoria'
  },
  {
    codigo: 'S-840_50',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Preseñalización de detención obligatoria'
  },
  {
    codigo: 'S-840_500',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Preseñalización de detención obligatoria'
  },
  {
    codigo: 'S-840_600',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Preseñalización de detención obligatoria'
  },
  {
    codigo: 'S-840_700',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Preseñalización de detención obligatoria'
  },
  {
    codigo: 'S-840_800',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Preseñalización de detención obligatoria'
  },
  {
    codigo: 'S-840_900',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Preseñalización de detención obligatoria'
  },
  {
    codigo: 'S-850',
    formato: Cuadrado,
    grupo: PanelesComplementarios,
    descripción: 'Itinerario con prioridad'
  },
  {
    codigo: 'S-8_50',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Fin de velocidad máxima aconsejada'
  },
  {
    codigo: 'S-851',
    formato: Cuadrado,
    grupo: PanelesComplementarios,
    descripción: 'Itinerario con prioridad'
  },
  {
    codigo: 'S-852',
    formato: Cuadrado,
    grupo: PanelesComplementarios,
    descripción: 'Itinerario con prioridad'
  },
  {
    codigo: 'S-853',
    formato: Cuadrado,
    grupo: PanelesComplementarios,
    descripción: 'Itinerario con prioridad'
  },
  {
    codigo: 'S-860',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Panel Complementario Genérico'
  },
  {
    codigo: 'S-8_60',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Fin de velocidad máxima aconsejada'
  },
  {
    codigo: 'S-870',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Aplicación de prohibición o prescripción'
  },
  {
    codigo: 'S-8_70',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Fin de velocidad máxima aconsejada'
  },
  {
    codigo: 'S-870a',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Aplicación de prohibición o prescripción'
  },
  {
    codigo: 'S-870b',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Aplicación de prohibición o prescripción'
  },
  {
    codigo: 'S-870c',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Aplicación de prohibición o prescripción'
  },
  {
    codigo: 'S-870d',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Aplicación de prohibición o prescripción'
  },
  {
    codigo: 'S-880',
    formato: Panel,
    grupo: PanelesComplementarios,
    descripción: ''
  },
  {
    codigo: 'S-8_80',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Fin de velocidad máxima aconsejada'
  },
  {
    codigo: 'S-8_90',
    formato: Cuadrado,
    grupo: IndicacionesGenerales,
    descripción: 'Fin de velocidad máxima aconsejada'
  },
  {
    codigo: 'S-890a',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Panel complementario nieve'
  },
  {
    codigo: 'S-890b',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Panel complementario lluvia'
  },
  {
    codigo: 'S-890c',
    formato: Rectangulo,
    grupo: PanelesComplementarios,
    descripción: 'Panel complementario niebla'
  },
  {
    codigo: 'S-9',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Intervalo aconsejado de velocidades'
  },
  {
    codigo: 'S-900',
    formato: Rectangulo,
    grupo: Otras,
    descripción: 'Peligro Incendios'
  },
  {
    codigo: 'S-910',
    formato: Rectangulo,
    grupo: Otras,
    descripción: 'Horario de Servicios Religiosos'
  },
  {
    codigo: 'S-920',
    formato: Cuadrado,
    grupo: Otras,
    descripción: 'Entrada a España'
  },
  {
    codigo: 'S-930_F',
    formato: Rectangulo,
    grupo: Otras,
    descripción: 'Confirmación de País'
  },
  {
    codigo: 'S-930_P',
    formato: Rectangulo,
    grupo: Otras,
    descripción: 'Confirmación de País'
  },
  {
    codigo: 'S-940',
    formato: Rectangulo,
    grupo: Otras,
    descripción: 'Limitaciones de velocidad en España'
  },
  {
    codigo: 'S-950',
    formato: Rectangulo,
    grupo: Otras,
    descripción: 'Emisora de Radio'
  },
  {
    codigo: 'SANTIAGO1',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Camino de Santiago'
  },
  {
    codigo: 'SANTIAGO2',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Camino de Santiago'
  },
  {
    codigo: 'SANTIAGO2_B',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Camino de Santiago'
  },
  {
    codigo: 'SS-15bi',
    formato: Rectangulo,
    grupo: IndicacionesGenerales,
    descripción: 'Preseñalización de calzada sin salida'
  },
  {
    codigo: 'Tapada-X1',
    formato: Circulo,
    grupo: Genericas,
    descripción: 'Placa tapada circular'
  },
  {
    codigo: 'Tapada-X2',
    formato: Triangulo,
    grupo: Genericas,
    descripción: 'Placa tapada triangular'
  },
  {
    codigo: 'Tapada-X3',
    formato: Cuadrado,
    grupo: Genericas,
    descripción: 'Placa tapada cuadrada'
  },
  {
    codigo: 'Tapada-X4',
    formato: Rectangulo,
    grupo: Genericas,
    descripción: 'Placa tapada rectangular'
  },
  {
    codigo: 'Tapada-X5',
    formato: Rectangulo,
    grupo: Genericas,
    descripción: 'Placa tapada rectangular'
  },
  {
    codigo: 'TapadoAimpe',
    formato: Rectangulo,
    grupo: Genericas,
    descripción: 'Cartel urbano Aimpe tapado'
  },
  {
    codigo: 'TapadoBanderola',
    formato: Panel,
    grupo: Genericas,
    descripción: 'Banderola Tapada'
  },
  {
    codigo: 'TapadoCartelLat',
    formato: Cuadrado,
    grupo: Genericas,
    descripción: 'Cartel Lateral tapado'
  },
  {
    codigo: 'TapadoEuropeo',
    formato: Rectangulo,
    grupo: Genericas,
    descripción: 'Cartel urbano europeo tapado'
  },
  {
    codigo: 'TapadoFlecha',
    formato: Panel,
    grupo: Genericas,
    descripción: 'Flecha de destino tapada'
  },
  {
    codigo: 'TapadoPórtico',
    formato: Rectangulo,
    grupo: Genericas,
    descripción: 'Pórtico tapado'
  },
  {
    codigo: 'TP-13a',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Curva peligrosa hacia la derecha'
  },
  {
    codigo: 'TP-13b',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Curva peligrosa hacia la izquierda'
  },
  {
    codigo: 'TP-14a',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Curvas peligrosas hacia la derecha'
  },
  {
    codigo: 'TP-14b',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Curvas peligrosas hacia la izquierda'
  },
  {
    codigo: 'TP-15',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Perfil irregular'
  },
  {
    codigo: 'TP-15a',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Resalto'
  },
  {
    codigo: 'TP-15b',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Badén'
  },
  {
    codigo: 'TP-17',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Estrechamiento de calzada'
  },
  {
    codigo: 'TP-17a',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Estrechamiento de calzada por la derecha'
  },
  {
    codigo: 'TP-17b',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Estrechamiento de calzada por la izquierda'
  },
  {
    codigo: 'TP-18',
    formato: Triangulo,
    grupo: Obras,
    descripción: Obras
  },
  {
    codigo: 'TP-19',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Pavimento deslizante'
  },
  {
    codigo: 'TP-25',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Circulación en dos sentidos'
  },
  {
    codigo: 'TP-26',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Desprendimiento'
  },
  {
    codigo: 'TP-28',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Proyección de gravilla'
  },
  {
    codigo: 'TP-3',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Semarofo'
  },
  {
    codigo: 'TP-30',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Escalón lateral'
  },
  {
    codigo: 'TP-31',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Congestión de Obra'
  },
  {
    codigo: 'TP-50',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Otros peligros'
  },
  {
    codigo: 'TR-1',
    formato: Triangulo,
    grupo: Obras,
    descripción: 'Ceda de Obra'
  },
  {
    codigo: 'TR-100',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Circulación prohibida'
  },
  {
    codigo: 'TR-106',
    formato: Circulo,
    grupo: Obras,
    descripción:
      'Entrada prohibida a vehículos destinados al transporte de mercancías.'
  },
  {
    codigo: 'TR-201',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Limitación de Peso'
  },
  {
    codigo: 'TR-204',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Limitación de Anchura'
  },
  {
    codigo: 'TR-205',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Limitación de Altura'
  },
  {
    codigo: 'TR-301_100',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Velocidad max 100 en Obras'
  },
  {
    codigo: 'TR-301_30',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'TR-301_40',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'TR-301_50',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'TR-301_60',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'TR-301_70',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'TR-301_80',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'TR-301_90',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Velocidad máxima'
  },
  {
    codigo: 'TR-302',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Giro a la derecha prohibido'
  },
  {
    codigo: 'TR-303',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Giro a la izquierda prohibido'
  },
  {
    codigo: 'TR-305',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Adelantamiento prohibido'
  },
  {
    codigo: 'TR-306',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Adelantamiento prohibido para camiones'
  },
  {
    codigo: 'TR-5',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Prioridad en sentido contrario'
  },
  {
    codigo: 'TR-500',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Fin de prohibiciones'
  },
  {
    codigo: 'TR-501_30',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Fin de limitación de velocidad'
  },
  {
    codigo: 'TR-501_40',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Fin de limitación de velocidad'
  },
  {
    codigo: 'TR-501_50',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Fin de limitación de velocidad'
  },
  {
    codigo: 'TR-501_60',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Fin de limitación de velocidad'
  },
  {
    codigo: 'TR-501_70',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Fin de limitación de velocidad'
  },
  {
    codigo: 'TR-501_80',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Fin de limitación de velocidad'
  },
  {
    codigo: 'TR-501_90',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Fin de la limitación de velocidad'
  },
  {
    codigo: 'TR-502',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Fin de la prohibición de adelantamiento'
  },
  {
    codigo: 'TR-503',
    formato: Circulo,
    grupo: Obras,
    descripción: 'Fin de la prohibición de adelantamiento para camiones'
  },
  {
    codigo: 'TS-22',
    formato: Cuadrado,
    grupo: Obras,
    descripción: 'Cambio de Sentido de Obra'
  },
  {
    codigo: 'TS-22_300',
    formato: Cuadrado,
    grupo: Obras,
    descripción: 'Cambio de Sentido de Obra'
  },
  {
    codigo: 'TS-52c1',
    formato: Rectangulo,
    grupo: Obras,
    descripción: 'Reducción de 2 carriles a 1 Obras'
  },
  {
    codigo: 'TS-52c2',
    formato: Rectangulo,
    grupo: Obras,
    descripción: 'Reducción de 3 carriles a 2 Obras'
  },
  {
    codigo: 'TS-52d1',
    formato: Rectangulo,
    grupo: Obras,
    descripción: 'Reducción de 2 carriles a 1 Obras'
  },
  {
    codigo: 'TS-52d2',
    formato: Rectangulo,
    grupo: Obras,
    descripción: 'Reducción de 3 carriles a 2 Obras'
  },
  {
    codigo: 'TS-52d3',
    formato: Rectangulo,
    grupo: Obras,
    descripción: 'Final de carril destinado a la circulación Obras'
  },
  {
    codigo: 'TS-54',
    formato: Rectangulo,
    grupo: Obras,
    descripción: 'Final de carrril destinado a la circulación'
  },
  {
    codigo: 'TS-55',
    formato: Rectangulo,
    grupo: Obras,
    descripción: 'Final de carril destinado a la circulación'
  },
  {
    codigo: 'TS-60',
    formato: Rectangulo,
    grupo: Obras,
    descripción: 'Acceso a otra calzada'
  },
  {
    codigo: 'X',
    formato: Cuadrado,
    grupo: PanelesComplementarios,
    descripción: 'Señal sin Definir'
  }
]

/**
 *
 * @param {object} param
 * @param {string} param.code
 * @param {string} param.attr
 * @returns {string}
 */
export const getSignalAttributeByCode = ({ code, attr }) => {
  const signal = SenalesVerticales.find(s => s.codigo === code)
  if (!signal) return ''
  return signal[attr] ? signal[attr] : ''
}

export default SenalesVerticales
