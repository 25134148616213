import {
  getEnlacesFields,
  // getStartCoordField,
  getWayField,
  getOpTypeField,
  getMedicUnidExecFields,
  getCommonInspectionFields,
  getCommonExecFields,
  getCommonValidationFields
} from './commonFields'

const jardineriasEnlacesFields = [
  ...getEnlacesFields('jardineriasEnlaces'),
  ...getCommonInspectionFields('jardineriasEnlaces'),
  // ...getStartCoordField('jardineriasEnlaces'),
  ...getWayField('jardineriasEnlaces'),
  ...getOpTypeField('jardineriasEnlaces'),
  ...getMedicUnidExecFields('jardineriasEnlaces'),
  ...getCommonExecFields('jardineriasEnlaces'),
  ...getCommonValidationFields('jardineriasEnlaces')
]

export const getJardineriaEnlacesFields = () => jardineriasEnlacesFields
