// Utilities to clean form field data coming from DB

import moment from 'moment'
import { isNil, isUndefined } from 'lodash'
import {
  getValueWithPath,
  setValueWithPath,
  extractPathFragment
} from './paths'
import { recursiveLayoutDataFromDB } from './layoutDataFromDB'

// Transform a date-like (e.g. a ISO 8601 string) and into a DD/MM/YYYY string
// so it can be manipulated by the user in a masked input
export function dateToString(date) {
  if (isNil(date)) {
    return undefined
  }

  /* if (matchDateDDMMYYYY(date)) return date */

  const parsedDate = moment(date, ['DD/MM/YYYY', moment.ISO_8601])

  if (!parsedDate.isValid()) {
    return undefined
  }

  return parsedDate.format('DD/MM/YYYY')
}

// Transform MonthYear entity into a string (so it can be manipulated by the
// user in a masked input)
export function monthYearToString(monthYear) {
  if (
    isNil(monthYear) ||
    isNil(monthYear.month) ||
    isNil(monthYear.year) ||
    !Number.isFinite(monthYear.month) ||
    !Number.isFinite(monthYear.year) ||
    monthYear.month <= 0 ||
    monthYear.month > 12 ||
    monthYear.year < 0
  ) {
    return undefined
  }

  return `${monthYear.month.toString().padStart(2, '0')}/${monthYear.year}`
}

// Transforms a Time entity (`{ hh, mm }`) into an `HH:MM` string, so it can be
// manipulated by the user in a masked input
export function timeToString(time) {
  if (
    isNil(time) ||
    isNil(time.hh) ||
    isNil(time.mm) ||
    !Number.isFinite(time.hh) ||
    !Number.isFinite(time.mm) ||
    time.hh < 0 ||
    time.hh >= 24 ||
    time.mm < 0 ||
    time.mm >= 60
  ) {
    return undefined
  }

  return `${time.hh.toString().padStart(2, '0')}:${time.mm
    .toString()
    .padStart(2, '0')}`
}

// Clean coordinates object (replaces invalid latitude/longitude with `undefined`)
export function cleanCoordinates(coordinates) {
  if (isNil(coordinates)) {
    return undefined
  }

  const maybeLatitude = parseFloat(coordinates.latitude, 10)
  const maybeLongitude = parseFloat(coordinates.longitude, 10)

  const cleanLatitude = !isNaN(maybeLatitude) ? maybeLatitude : undefined
  const cleanLongitude = !isNaN(maybeLongitude) ? maybeLongitude : undefined

  if (isNil(cleanLatitude) && isNil(cleanLongitude)) {
    return undefined
  }

  return {
    latitude: cleanLatitude,
    longitude: cleanLongitude
  }
}

// Transforms a PK entity (`{ pk, offset }`) into a PK string (`"###+####""`)
// (so they can be manipulated by the user in a masked input)
export function pkToString(pk) {
  if (
    isNil(pk) ||
    isNil(pk.pk) ||
    isNil(pk.offset) ||
    !Number.isFinite(pk.pk) ||
    !Number.isFinite(pk.offset) ||
    pk.pk < 0 ||
    pk.pk > 999 || // support up to 3 digits pk
    pk.offset < 0 ||
    pk.offset > 9999 // support up to 4 digits offset
  ) {
    return undefined
  }

  return `${pk.pk.toString().padStart(3, '0')}+${pk.offset
    .toString()
    .padStart(4, '0')}`
}

// here `null` means "this type is recognized, but requires no transformation"
const mapTypeToTransform = {
  Date: dateToString,
  MonthYear: monthYearToString,
  Time: timeToString,
  Coordinates: cleanCoordinates,
  PK: pkToString,

  String: null,
  Number: null,
  Monetary: null,
  Percentage: null,
  Select: null,
  AutoSelect: null,
  SimpleSelect: null,
  SimpleList: null,
  SelectArray: null,
  Checkbox: null,
  Documents: null,
  StandaloneDocuments: null,
  ReferenceImage: null,
  Image: null,
  Assets: null,
  CecoSelect: null,
  CebeByCecoSelect: null,
  EmpresaSelect: null,
  FileUpload: null,
  AxisSelect: null
}

// Clean data from DB given a form field `config`
//
// Mutably changes `data`
//
// `data` is the whole entity coming from DB (actually, a copy of it) but this
// function operates only on this specific field's path
export default function formFieldDataFromDB(config, data, getField, setField) {
  // TODO: PrevisionSeguimiento should actually be a layout, so this special
  // case is avoided
  //
  // This is also the only reason we need the whole `data` object (to pass
  // around for layout cleaning) so, when done, just replace this function for a
  // pure one that returns the clean value
  if (config.type === 'PrevisionSeguimiento') {
    const value = getValueWithPath(data, config.path) || {}

    const prevision = value.prevision || []
    prevision.forEach((mes, i) => {
      recursiveLayoutDataFromDB(
        config.mesPrevisionLayout(config.path, extractPathFragment(mes, i)),
        data,
        getField,
        setField
      )
    })

    const seguimiento = value.seguimiento || []
    seguimiento.forEach((mes, i) => {
      recursiveLayoutDataFromDB(
        config.mesSeguimientoLayout(config.path, extractPathFragment(mes, i)),
        data,
        getField,
        setField
      )
    })

    return
  }

  // Unknown field type: assume typo (or missing implementation) and throw
  if (isUndefined(mapTypeToTransform[config.type])) {
    throw new Error(`Unknown field type ${config.type}`)
  }

  // No transform function
  if (mapTypeToTransform[config.type] === null) {
    return
  }

  // Transform data
  const value = getValueWithPath(data, config.path)
  const cleanValue = mapTypeToTransform[config.type](value)

  setValueWithPath(data, config.path, cleanValue)
}
