import { SI_NO } from './selectOptions'

export default familyType => (workOrderFragment, familyFragment) => [
  {
    label: 'Responsable Contratista',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      familyType,
      familyFragment,
      'validacion',
      'responsableContratista'
    ]
  },

  {
    label: 'Fecha',
    type: 'Date',
    path: [
      'parteTrabajos',
      workOrderFragment,
      familyType,
      familyFragment,
      'validacion',
      'fecha'
    ]
  },

  {
    label: 'Auditado',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      familyType,
      familyFragment,
      'validacion',
      'auditado'
    ],
    meta: {
      placeholder: 'Sin especificar',
      options: SI_NO
    }
  },

  {
    label: 'Comentarios',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      familyType,
      familyFragment,
      'validacion',
      'comentarios'
    ]
  },

  {
    label: 'Observaciones',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      familyType,
      familyFragment,
      'validacion',
      'observaciones'
    ]
  },

  {
    label: 'Fotos final de trabajo',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      familyType,
      familyFragment,
      'validacion',
      'trabajoFinFotos'
    ],
    meta: { isPhotos: true }
  },

  {
    label: 'Fotos',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      familyType,
      familyFragment,
      'validacion',
      'fotos'
    ],
    meta: { isPhotos: true }
  }
]
