import { anyPass, eq, isFunction, propOr } from 'lodash/fp'
import { redirectToLogin, storeForbiddenError } from '../modules/session'
import { errorCodeEquals, isAuthError } from './utils'
// errorRequiresLogin :: {Context} -> bool
export const errorRequiresLogin = anyPass([
  errorCodeEquals(401),
  ctx => eq('authentication', propOr('', ['path'], ctx))
])

export function loginWhenNotAuthorized(context) {
  if (!context.error) return context
  if (!isAuthError(context)) return context

  console.warn('Auth error in service call', context.error.code, {
    servicePath: context.path,
    method: context.method,
    error: context.error.toString()
  })

  const isLoginRequired = errorRequiresLogin(context)
  const store = context.app.get('__reduxStore') || {}
  // If we can actually dispatch an action, let redux display a modal and so on
  // otherwise, we directly redirect to login
  isFunction(store.dispatch)
    ? store.dispatch(storeForbiddenError(isLoginRequired))
    : redirectToLogin()

  return context
}
