import { set, pipe } from 'lodash/fp'
import { buildReducer } from '../utils'
import * as ActionTypes from './actionTypes'
import { REQUEST_STATUS } from '../constants'
export * from './selectors'
export * from './actions'

const initialState = {}

const setFailedState = (listName, error) =>
  pipe(
    set([listName, 'requestStatus'], REQUEST_STATUS.Failed),
    set([listName, 'fetchError'], error)
  )

export default buildReducer(initialState, {
  [ActionTypes.FETCH_REQUESTED]: (state, action) => {
    const { listName } = action.payload
    return set([listName, 'requestStatus'], REQUEST_STATUS.Loading, state)
  },
  [ActionTypes.FETCH_FAILED]: (state, action) => {
    const { listName, error } = action.payload
    return setFailedState(listName, error)(state)
  },
  [ActionTypes.FETCH_SUCCEEDED]: (state, action) => {
    const { listName, data } = action.payload
    return set(
      [listName],
      {
        requestStatus: REQUEST_STATUS.Success,
        lastFetched: Date.now(),
        data
      },
      state
    )
  }
})
