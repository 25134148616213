import { isNil, isFunction, isUndefined } from 'lodash'
import * as validators from './validators'

export const isNormalLayout = config => !isNil(config.items)

export const isListLayout = config =>
  !isNil(config.path) && isFunction(config.makeItemLayout)

export const isTextLayout = config => config.type === 'Text'

export const isLayout = config =>
  isNormalLayout(config) || isListLayout(config) || isTextLayout(config)

export const isFormField = config =>
  (!isNil(config.path) || isFixedField(config)) &&
  !isFunction(config.makeItemLayout)

export const isFixedField = config => !isNil(config.value)

// Used for easy >, <, >=, etc. between textbox PKs
//
// Returns the parsed PK as an (up to) 7 digit number. E.g. 123+4567 is parsed
// as integer 1234567.
//
// Can return `NaN` (enables >, <, etc. operators without checking for undefined)
// becauses for `NaN` they are always false
export const pkStringToNumber = pkString => {
  if (isNil(pkString) || validators.PK(pkString).hasError) {
    return NaN
  }

  return parseInt(`${pkString.slice(0, 3)}${pkString.slice(4)}`, 10)
}

export const resolveMeta = (config, getField, setField, path) =>
  isFunction(config.meta)
    ? config.meta(getField, setField, path)
    : !isUndefined(config.meta)
    ? config.meta
    : {}

export const resolveConfigWithMeta = (config, getField, setField, path) => ({
  ...config,
  ...resolveMeta(config, getField, setField, path)
})
