import { pipe, prop, propOr, filter, allPass } from 'lodash/fp'

import { filterEquals, filterAndPluck } from './utils'
import { REQUEST_STATUS, CACHE_DURATION } from '../constants'

// toLocal :: State -> Object
const toLocal = propOr({}, 'locate')

// getConnections :: State -> [Objects]
export const getConnections = pipe(
  toLocal,
  prop('connections')
)
export const getFilteredConnections = (state, conditions) => {
  const connections = getConnections(state)
  return filter(allPass(conditions))(connections)
}

export const getConnectionById = (state, id) =>
  getFilteredConnections(state, [filterEquals('_id', id)])[0]

export const getConnectionsByHighway = (state, highway) =>
  getFilteredConnections(state, [filterEquals('highway', highway)])

export const getHighwayPKs = pipe(
  toLocal,
  prop('pks')
)
export const getFilters = state => toLocal(state).filters
export const getFilterValue = (state, filter) => prop(filter, getFilters(state))
export const getCoords = state => toLocal(state).coords
export const getCoordValue = (state, field) =>
  toLocal(state).coords[field] || ''

export const getModal = state => toLocal(state).modal
export const getModalInfo = (state, property) => prop(property, getModal(state))

export const getFavLocations = pipe(
  toLocal,
  prop('favLocations')
)

export const getShowDeleteFavLocConfirm = pipe(
  toLocal,
  prop('showDeleteFavLocConfirm')
)

export const getFavLocToDelete = pipe(
  toLocal,
  prop('favLocToDelete')
)

export const getNewFavLocName = pipe(
  toLocal,
  prop('newFavLocName')
)

/**
 * getFilterOptions :: (state, property) -> [String]
 * @param Object Redux state
 * @param String property from which we need the options
 * @return [String] property options
 */
export const getFilterOptions = (state, property) => {
  const conns = getConnections(state)
  const conditions = getConditions(state, property)
  return filterAndPluck(property, conditions, conns)
}

const getConditions = (state, property) => {
  let prevProperties = []
  switch (property) {
    case 'management':
      prevProperties = ['network']
      break
    case 'highway':
      prevProperties = ['network', 'management']
      break
    case 'connectionCode':
    case 'connectionName':
      prevProperties = ['network', 'management', 'highway']
      break
    default:
      prevProperties = []
      break
  }

  return prevProperties.reduce((acc, property) => {
    const propValue = getFilterValue(state, property)
    return propValue && propValue !== ''
      ? [...acc, filterEquals(property, propValue)]
      : acc
  }, [])
}

export const getConnectionsRequestStatus = pipe(
  toLocal,
  propOr(REQUEST_STATUS.NotRequested, 'connectionsRequest')
)

export const getFavLocationsRequestStatus = pipe(
  toLocal,
  propOr(REQUEST_STATUS.NotRequested, 'favLocationsRequest')
)

export const shouldFetchConnections = state => {
  const reqStatus = getConnectionsRequestStatus(state)
  const lastFetched = prop('connectionsLastFeched', toLocal(state))
  // connections are cached in Redux for one day
  return (
    reqStatus === REQUEST_STATUS.NotRequested ||
    lastFetched + CACHE_DURATION.OneDay <= Date.now()
  )
}

export const shouldFetchFavLocations = state => {
  const reqStatus = getFavLocationsRequestStatus(state)
  const lastFetched = prop('favLocationsLastFetched', toLocal(state))
  // fav locations are cached in Redux for 1 day
  // if user adds a new one they're refreshed automatically
  return (
    reqStatus === REQUEST_STATUS.NotRequested ||
    lastFetched + CACHE_DURATION.OneDay <= Date.now()
  )
}
