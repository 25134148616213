import { UNIDADES, SENTIDOS } from './selectOptions'
import TYPES from '../../../../core/assets/assetTypes'
import comunIdentificacionFormFields from './comunIdentificacionFormFields'
import comunValidacionFormFields from './comunValidacionFormFields'

export const identificacion = (workOrderFragment, familyFragment) => [
  ...comunIdentificacionFormFields(
    workOrderFragment,
    'limpiezasDesbrocesODs',
    familyFragment,
    [TYPES.ODT]
  ),
  {
    label: 'Comentarios',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'identificacion',
      'comentarios'
    ]
  }
]

export const inspeccion = (workOrderFragment, familyFragment) => [
  {
    label: 'PK Inicio',
    type: 'PK',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'inspeccion',
      'pkInicio'
    ]
  },

  {
    label: 'PK Fin',
    type: 'PK',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'inspeccion',
      'pkFin'
    ]
  },

  {
    label: 'Altura media hierba',
    type: 'Number',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'inspeccion',
      'alturaMediaHierba'
    ]
  },

  {
    label: 'Trabajos programados',
    type: 'Checkbox',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'inspeccion',
      'trabajosProgramados'
    ]
  },

  {
    label: 'Observaciones',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'inspeccion',
      'observaciones'
    ]
  },

  {
    label: 'Fotos antes',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'inspeccion',
      'antesFotos'
    ],
    meta: { isPhotos: true }
  }
]

export const ejecucion = (workOrderFragment, familyFragment) => [
  {
    label: 'PK Inicio',
    type: 'PK',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'ejecucion',
      'pkInicio'
    ]
  },

  {
    label: 'PK Fin',
    type: 'PK',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'ejecucion',
      'pkFin'
    ]
  },

  {
    label: 'Coordenadas PK Inicio',
    type: 'Coordinates',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'ejecucion',
      'coordenadasPkInicio'
    ]
  },

  {
    label: 'Coordenadas PK Fin',
    type: 'Coordinates',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'ejecucion',
      'coordenadasPkFin'
    ]
  },

  {
    label: 'Sentido',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'ejecucion',
      'sentido'
    ],
    meta: {
      options: SENTIDOS
    }
  },

  {
    label: 'Medición',
    type: 'Number',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'ejecucion',
      'medicion'
    ]
  },

  {
    label: 'Unidad',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'ejecucion',
      'unidad'
    ],
    meta: {
      options: UNIDADES
    }
  },

  {
    label: 'Trabajos programados',
    type: 'Checkbox',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'ejecucion',
      'trabajosProgramados'
    ]
  },

  {
    label: 'Observaciones',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'ejecucion',
      'observaciones'
    ]
  },

  {
    label: 'Fotos despues',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'limpiezasDesbrocesODs',
      familyFragment,
      'ejecucion',
      'despuesFotos'
    ],
    meta: { isPhotos: true }
  }
]

export const validacion = comunValidacionFormFields('limpiezasDesbrocesODs')
