import TYPES from './assetTypes'
const assetFlatCheckedTree = {
  activos: [
    TYPES.VPP,
    TYPES.ODT,
    TYPES.POP,
    TYPES.PORTICOS,
    TYPES.BANDEROLAS,
    TYPES.MUROS,
    TYPES.ARQUETAS_POZOS,
    TYPES.CUNETAS,
    TYPES.CAZ,
    TYPES.BORDILLOS,
    TYPES.BAJANTES,
    TYPES.VALLA_CERRAMIENTO,
    TYPES.BARRERA_METALICA,
    TYPES.BARRERA_HORMIGON,
    TYPES.PRETIL,
    TYPES.PANTALLAS_ANTIRRUIDO,
    TYPES.SENALIZACION_HORIZONTAL,
    TYPES.SENALIZACION_VERTICAL,
    TYPES.BALIZAMIENTO,
    TYPES.PASOS_MEDIANA,
    TYPES.VIAS_PEAJES,
    TYPES.FIRMES,
    TYPES.CAMARAS,
    TYPES.ESTACION_METEO,
    TYPES.PMV,
    TYPES.POSTES_SOS,
    TYPES.DESMONTES,
    TYPES.TERRAPLENES,
    TYPES.TUNELES,
    TYPES.BACULOS
  ],
  estructurasyodt: [
    TYPES.VPP,
    TYPES.ODT,
    TYPES.POP,
    TYPES.PORTICOS,
    TYPES.BANDEROLAS,
    TYPES.MUROS
  ],
  elementosdedrenaje: [
    TYPES.ARQUETAS_POZOS,
    TYPES.CUNETAS,
    TYPES.CAZ,
    TYPES.BORDILLOS,
    TYPES.BAJANTES
  ],
  elementoscerramiento: [TYPES.VALLA_CERRAMIENTO],
  elementoscontencion: [
    TYPES.BARRERA_METALICA,
    TYPES.BARRERA_HORMIGON,
    TYPES.PRETIL
  ],
  its: [TYPES.CAMARAS, TYPES.ESTACION_METEO, TYPES.PMV, TYPES.POSTES_SOS],
  taludes: [TYPES.DESMONTES, TYPES.TERRAPLENES],
  tuneles: [TYPES.TUNELES],
  alumbrado: [TYPES.BACULOS]
}

export const isAnI3metAsset = assetType =>
  assetFlatCheckedTree.estructurasyodt.includes(assetType) ||
  assetFlatCheckedTree.taludes.includes(assetType) ||
  assetFlatCheckedTree.tuneles.includes(assetType)

export default assetFlatCheckedTree
