import {
  LOGGED_IN_CHANGED,
  STORE_USER_DATA,
  STORE_TOKEN,
  STORE_FORBIDDEN_ERROR,
  CLEAR_FORBIDDEN_ERROR
} from './actionTypes'
import { LOGIN_URL } from './constants'
import app from '../../feathers'
import * as API from './api'
import ability from '../../core/auth/ability'
import { fetchCECOs } from '../cecos'

export const tryAuthenticate = (feathers = app) => dispatch => {
  return API.authenticateWithExistingToken(feathers)
    .then(payload => {
      dispatch(storeAccessToken(payload))
      return dispatch(fetchMe(payload.userId, feathers))
    })
    .catch(err => {
      console.warn('Error trying to authenticate with existing token', err)
      dispatch(logOut())
      return false
    })
}

export const logIn = (token, feathers = app) => dispatch => {
  return API.authenticateWithToken(token, feathers)
    .then(payload => {
      dispatch(storeAccessToken(payload))
      return dispatch(fetchMe(payload.userId, feathers))
    })
    .catch(err => {
      console.warn('Error trying to authenticate with provided token', err)
      dispatch(logOut())
      return false
    })
}

export const fetchMe = (userId, feathers = app) => dispatch => {
  return API.fetchUserData(userId, feathers)
    .then(userData => {
      dispatch(storeUserData(userData))
      ability.update(userData.permissions.rules || [])
    })
    .then(() => {
      return dispatch(fetchCECOs())
    })
    .then(() => true)
    .catch(err => {
      console.error('Error fetching user data', err)
      dispatch(logOut())
      return false
    })
}

export const setLoggedIn = () => ({
  type: LOGGED_IN_CHANGED,
  payload: true
})

export const storeUserData = data => ({
  type: STORE_USER_DATA,
  payload: data
})

export const storeAccessToken = payload => ({
  type: STORE_TOKEN,
  payload
})

export const logOut = () => ({
  type: LOGGED_IN_CHANGED,
  payload: false
})

export const storeForbiddenError = (isLoginRequired = false) => ({
  type: STORE_FORBIDDEN_ERROR,
  payload: {
    isLoginRequired
  }
})

export const clearForbiddenError = () => ({
  type: CLEAR_FORBIDDEN_ERROR
})

// No Redux action, a simple function with side effects
export const redirectToLogin = () => {
  window.location.replace(LOGIN_URL)
}
