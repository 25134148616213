import TYPES from './assetTypes'
const assetTypeIcons = {
  // Estructuras y ODT
  [TYPES.VPP]: require('../../styles/images/map-icon/icon-estructuras.svg'), //Viaductos puentes pasarelas
  [TYPES.ODT]: require('../../styles/images/map-icon/icon-odt.svg'), //Obras drenaje transversal
  [TYPES.POP]: require('../../styles/images/map-icon/icon-estructuras.svg'), //Pequeñas obras de paso
  [TYPES.PORTICOS]: require('../../styles/images/map-icon/icon-estructuras.svg'),
  [TYPES.BANDEROLAS]: require('../../styles/images/map-icon/icon-estructuras.svg'),
  [TYPES.MUROS]: require('../../styles/images/map-icon/icon-estructuras.svg'),

  // DRENAJE
  [TYPES.ARQUETAS_POZOS]: require('../../styles/images/map-icon/icon-arqueta.svg'),
  [TYPES.CUNETAS]: require('../../styles/images/map-icon/icon-drenaje.svg'),
  [TYPES.CAZ]: require('../../styles/images/map-icon/icon-drenaje.svg'),
  [TYPES.BORDILLOS]: require('../../styles/images/map-icon/icon-drenaje.svg'),
  [TYPES.BAJANTES]: require('../../styles/images/map-icon/icon-drenaje.svg'),
  [TYPES.OTDL]: require('../../styles/images/map-icon/icon-drenaje.svg'),
  [TYPES.COLECTORES_LONG]: require('../../styles/images/map-icon/icon-colector.svg'), // colectores longitudinales

  // CERRAMIENTO
  [TYPES.VALLA_CERRAMIENTO]: require('../../styles/images/map-icon/icon-cerramiento.svg'),

  // CONTENCION
  [TYPES.BARRERA_METALICA]: require('../../styles/images/map-icon/icon-contencion.svg'),
  [TYPES.BARRERA_HORMIGON]: require('../../styles/images/map-icon/icon-contencion.svg'),
  [TYPES.PRETIL]: require('../../styles/images/map-icon/icon-contencion.svg'),

  // PANTALLAS
  [TYPES.PANTALLAS_ANTIRRUIDO]: require('../../styles/images/map-icon/icon-pantallas.svg'),

  // HORIZONTAL
  [TYPES.SENALIZACION_HORIZONTAL]: require('../../styles/images/map-icon/icon-vertical-horizontal.svg'),

  // VERTICAL
  [TYPES.SENALIZACION_VERTICAL]: require('../../styles/images/map-icon/icon-vertical-horizontal.svg'),

  // BALIZAMIENTO
  [TYPES.BALIZAMIENTO]: require('../../styles/images/map-icon/icon-balizamiento.svg'),

  // Pasos de mediana
  [TYPES.PASOS_MEDIANA]: require('../../styles/images/map-icon/icon-mediana.svg'),

  // Peajes
  [TYPES.EDIFICIOS_PEAJES]: require('../../styles/images/map-icon/icon-peaje.svg'),
  [TYPES.VIAS_PEAJES]: require('../../styles/images/map-icon/icon-peaje.svg'),

  // Firmes
  [TYPES.FIRMES]: require('../../styles/images/map-icon/icon-firmes.svg'),

  // ITS
  [TYPES.CAMARAS]: require('../../styles/images/map-icon/icon-camara.svg'),
  [TYPES.ESTACION_METEO]: require('../../styles/images/map-icon/icon-meteo.svg'),
  [TYPES.PMV]: require('../../styles/images/map-icon/icon-pmv.svg'), //Panel Mensajería Variable
  [TYPES.POSTES_SOS]: require('../../styles/images/map-icon/icon-sos.svg'),
  [TYPES.CANALIZACION_FO]: require('../../styles/images/map-icon/icon-otros.svg'),
  [TYPES.ARQUETAS_FO]: require('../../styles/images/map-icon/icon-otros.svg'),

  // Taludes
  [TYPES.DESMONTES]: require('../../styles/images/map-icon/icon-otros.svg'),
  [TYPES.TERRAPLENES]: require('../../styles/images/map-icon/icon-otros.svg'),

  // Túneles
  [TYPES.TUNELES]: require('../../styles/images/map-icon/icon-otros.svg'),

  // Actuaciones
  [TYPES.OBRAS]: require('../../styles/images/map-icon/icon-otros.svg'),
  [TYPES.CONSERVACION_ORDINARIA]: require('../../styles/images/map-icon/icon-otros.svg'),
  [TYPES.CONSERVACION_EXTRAORDINARIA]: require('../../styles/images/map-icon/icon-otros.svg'),

  // Alumbrado
  [TYPES.BACULOS]: require('../../styles/images/map-icon/icon-baculo.svg')
}

const genericIcons = {
  default: require('../../styles/images/map-icon/icon-otros.svg'),
  focus: require('../../styles/images/map-icon/icon-selected.svg')
}

export function getIconForAssetType(assetType, isSelected = false) {
  if (!TYPES[assetType]) {
    throw new Error('getIconForAssetType - Unknown asset type', assetType)
  }
  if (isSelected) {
    return genericIcons.focus
  }
  return (
    assetTypeIcons[assetType] || genericIcons[assetType] || genericIcons.default
  )
}
