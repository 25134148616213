import { UNIDADES, SENTIDOS, TIPO_ACTUACION_JARDINERIA } from './selectOptions'

import comunValidacionFormFields from './comunValidacionFormFields'

export const identificacion = (workOrderFragment, familyFragment) => [
  {
    label: 'Nº Enlace / Área Peaj. / Mant.',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'jardineriasEnlaces',
      familyFragment,
      'identificacion',
      'numEnlacePeajMant'
    ]
  },

  {
    label: 'Comentarios',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'jardineriasEnlaces',
      familyFragment,
      'identificacion',
      'comentarios'
    ]
  }
]

export const inspeccion = (workOrderFragment, familyFragment) => [
  {
    label: 'Trabajos programados',
    type: 'Checkbox',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'jardineriasEnlaces',
      familyFragment,
      'inspeccion',
      'trabajosProgramados'
    ]
  },

  {
    label: 'Observaciones',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'jardineriasEnlaces',
      familyFragment,
      'inspeccion',
      'observaciones'
    ]
  },

  {
    label: 'Fotos antes',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'jardineriasEnlaces',
      familyFragment,
      'inspeccion',
      'antesFotos'
    ],
    meta: { isPhotos: true }
  }
]

export const ejecucion = (workOrderFragment, familyFragment) => [
  {
    label: 'Nº Enlace',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'jardineriasEnlaces',
      familyFragment,
      'ejecucion',
      'numEnlace'
    ]
  },

  {
    label: 'Coordenadas PK Inicio',
    type: 'Coordinates',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'jardineriasEnlaces',
      familyFragment,
      'ejecucion',
      'coordenadasPkInicio'
    ]
  },

  {
    label: 'Sentido',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'jardineriasEnlaces',
      familyFragment,
      'ejecucion',
      'sentido'
    ],
    meta: {
      options: SENTIDOS
    }
  },

  {
    label: 'Tipo de actuación',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'jardineriasEnlaces',
      familyFragment,
      'ejecucion',
      'tipoActuacion'
    ],
    meta: {
      options: TIPO_ACTUACION_JARDINERIA
    }
  },

  {
    label: 'Medición',
    type: 'Number',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'jardineriasEnlaces',
      familyFragment,
      'ejecucion',
      'medicion'
    ]
  },

  {
    label: 'Unidad',
    type: 'SimpleSelect',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'jardineriasEnlaces',
      familyFragment,
      'ejecucion',
      'unidad'
    ],
    meta: {
      options: UNIDADES
    }
  },

  {
    label: 'Trabajos programados',
    type: 'Checkbox',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'jardineriasEnlaces',
      familyFragment,
      'ejecucion',
      'trabajosProgramados'
    ]
  },

  {
    label: 'Observaciones',
    type: 'String',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'jardineriasEnlaces',
      familyFragment,
      'ejecucion',
      'observaciones'
    ]
  },

  {
    label: 'Fotos despues',
    type: 'Documents',
    path: [
      'parteTrabajos',
      workOrderFragment,
      'jardineriasEnlaces',
      familyFragment,
      'ejecucion',
      'despuesFotos'
    ],
    meta: { isPhotos: true }
  }
]

export const validacion = comunValidacionFormFields('jardineriasEnlaces')
