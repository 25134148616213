import React from 'react'
import PropTypes from 'prop-types'
import MainNavigation from '../navigation/MainNavigation'
import Overlay from '../overlay/Overlay'
import * as AppRoutes from '../../core/routes'

const defaultMenuItems = [
  //{ text: 'Mis búsquedas', url: '#', icon: 'search' },
  {
    text: 'Inicio',
    url: AppRoutes.ROOT,
    aliasURLs: [
      AppRoutes.makeFilterByType(),
      // HACK! We cannot build these URLs for no `id` (or it throws) so we set
      // a fake `id` and replace it, since we're only interested in the parent
      // path
      AppRoutes.makeAssetDetails('HACK').replace('HACK', ''),
      AppRoutes.makeAssetEdit('HACK').replace('HACK', ''),
      AppRoutes.makeOrdinaryOperationDetails('HACK').replace('HACK', ''),
      AppRoutes.makeOrdinaryOperationEdit('HACK').replace('HACK', ''),
      AppRoutes.makeExtraordinaryOperationDetails('HACK').replace('HACK', ''),
      AppRoutes.makeExtraordinaryOperationEdit('HACK').replace('HACK', ''),
      AppRoutes.makeWorkOperationDetails('HACK').replace('HACK', ''),
      AppRoutes.makeWorkOperationEdit('HACK').replace('HACK', '')
    ],
    icon: 'home'
  },
  { text: 'Consultas', url: AppRoutes.QUERIES, icon: 'data' },
  { text: 'Ejes', url: '#', icon: 'road' },
  { text: 'Contratos', url: AppRoutes.DOCS, icon: 'contracts' },
  { text: 'Configuración', url: '#', icon: 'configuration' }
]

const AppLayout = props => {
  const { isMenuOpen, onMenuToggle, menuItems } = props

  return (
    <div className='app-wrapper'>
      <div className='nav-container'>
        <MainNavigation
          navItems={menuItems}
          mini={!isMenuOpen}
          onToggle={onMenuToggle}
        />
      </div>
      <main className='main-container'>{props.children}</main>
      {isMenuOpen && <Overlay />}
    </div>
  )
}

AppLayout.defaultProps = {
  isMenuOpen: false,
  menuItems: defaultMenuItems
}
AppLayout.propTypes = {
  onMenuToggle: PropTypes.func,
  isMenuOpen: PropTypes.bool
}

export default AppLayout
