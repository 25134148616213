import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../icons/Icon'
import logo from '../../styles/images/logo_es_autopistas.svg'
import * as ROUTES from '../../core/routes'
import { Link } from 'react-router-dom'

const logoUrl = process.env.REACT_APP_CUSTOM_LOGO || logo
const appTitle = process.env.REACT_APP_CUSTOM_TITLE || 'Autopistas'

const UpperMenu = props => {
  const { icon, onClick } = props
  return (
    <div className='upper-menu'>
      <Link to={ROUTES.ROOT} className='logo'>
        <img src={logoUrl} alt={appTitle} />
      </Link>
      <button type='button' className='action-menu' onClick={onClick}>
        <Icon name={icon} />
      </button>
    </div>
  )
}

UpperMenu.defaultProps = {
  icon: 'hide'
}

UpperMenu.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func
}

export default UpperMenu
