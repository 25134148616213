import { get, find } from 'lodash'
import { FIELD_CARD_TYPES } from '../../modules/queries/constants'

export const commonWorkReportsColumnFields = [
  {
    label: 'Creador',
    type: FIELD_CARD_TYPES.String,
    path: ['creador'],
    name: `creador`
  },
  {
    label: 'Responsable',
    type: FIELD_CARD_TYPES.String,
    path: ['responsable'],
    name: `responsable`
  },
  {
    label: 'Fecha',
    type: FIELD_CARD_TYPES.Date,
    path: ['fecha'],
    name: `fecha`
  },
  {
    label: 'Autopista',
    type: FIELD_CARD_TYPES.String,
    path: ['autopista'],
    name: `autopista`
  },
  {
    label: 'Trabajos con Recursos Propios',
    type: FIELD_CARD_TYPES.Boolean,
    path: ['trabajosRecursosPropios'],
    name: `trabajosRecursosPropios`
  },
  {
    label: 'PK Inicio',
    name: `pkInicio.formatPKOffset`
  },
  {
    label: 'PK Fin',
    name: `pkFin.formatPKOffset`
  },
  {
    label: 'Hora de entrada',
    name: `horaEntrada.formatHHMM`
  },
  {
    label: 'Hora de salida',
    name: `horaSalida.formatHHMM`
  },
  {
    label: 'Eje',
    name: `eje`
  },
  {
    label: 'Observaciones',
    name: `observaciones`
  },
  {
    label: 'RG - Adjudicataria',
    name: `ordinaryOperationRegistroGeneral.adjudicataria`
  },
  {
    label: 'RG - Inicio contrato',
    name: `ordinaryOperationRegistroGeneral.inicioContrato`
  },
  {
    label: 'RG - Final contrato',
    name: `ordinaryOperationRegistroGeneral.finalContrato`
  },
  {
    label: 'RG - Baja',
    name: `ordinaryOperationRegistroGeneral.baja`
  },
  {
    label: 'RG - Clave proyecto',
    name: `ordinaryOperationRegistroGeneral.claveProyecto`
  },
  {
    label: 'RG - Familias',
    name: `ordinaryOperationRegistroGeneral.familias`
  },
  {
    label: 'RG - Importe adjudicación',
    name: `ordinaryOperationRegistroGeneral.importeAdjudicacion`
  },
  {
    label: 'RG - Importe licitación',
    name: `ordinaryOperationRegistroGeneral.importeLicitacion`
  },
  {
    label: 'RG - Autopistas',
    name: `ordinaryOperationRegistroGeneral.autopistas`
  },
  {
    label: 'RG - Cebes',
    name: `ordinaryOperationRegistroGeneral.cebes`
  },
  {
    label: 'RG - Ceco',
    name: `ordinaryOperationRegistroGeneral.ceco`
  }
]
export const workReportFilterfields = {
  fecha: {
    label: 'Fecha',
    type: FIELD_CARD_TYPES.Date,
    path: ['fecha'],
    name: `fecha`
  },
  ordinaryOperationId: {
    label: 'Contrato',
    type: FIELD_CARD_TYPES.String,
    path: ['ordinaryOperationId'],
    name: `ordinaryOperationId`
  }
}
export const workReportFilters = {
  Filtros: [
    workReportFilterfields.fecha,
    workReportFilterfields.ordinaryOperationId
  ]
}

export const getWorkReportsFieldLabel = (fields, field) =>
  get(find(fields, { name: field }), 'label')
