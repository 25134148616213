import app from '../../feathers'

export const fetchManyById = async (
  assetIds = [],
  $select = ['_id', 'codigo', 'assetType'],
  $sort = { codigo: 1 }
) => {
  return assetIds.length > 0
    ? app.service('assets').find({
        query: {
          _id: { $in: assetIds },
          isDeleted: { $ne: true },
          $sort,
          $select
        }
      })
    : []
}
