import { loginWhenNotAuthorized } from './auth'
import { logToSentry } from './logToSentry'
import { handleUpdateError } from './assets'
export default function configureHooks(app) {
  app.hooks({
    error: {
      all: [logToSentry, loginWhenNotAuthorized],
      patch: [handleUpdateError],
      update: [handleUpdateError]
    }
  })
}
