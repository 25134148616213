import React from 'react'
import PropTypes from 'prop-types'
import Button from '../buttons/Button'

const ConfirmationAlert = props => {
  const {
    text,
    primaryAction,
    onClickPrimary,
    secondaryAction,
    onClickSecondary
  } = props
  return (
    <div className="confirmation-alert">
      <p className="text">{text}</p>
      {primaryAction || secondaryAction ? (
        <div className="actions">
          {secondaryAction && (
            <Button
              variant="secondary"
              text={secondaryAction}
              onClick={onClickSecondary}
            />
          )}
          {primaryAction && (
            <Button
              variant="primary"
              text={primaryAction}
              onClick={onClickPrimary}
            />
          )}
        </div>
      ) : null}
    </div>
  )
}

ConfirmationAlert.defaultProps = {}

ConfirmationAlert.propTypes = {
  text: PropTypes.string.isRequired,
  primaryAction: PropTypes.string,
  secondaryAction: PropTypes.string,
  onClickPrimary: PropTypes.func,
  onClickSecondary: PropTypes.func
}

export default ConfirmationAlert
