import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import Icon from '../icons/Icon'

// aliasURLs are alternative URLs to mark the link as active
// e.g. useful for home navigation: home has to match '/', '/filter' and '/details'
//
// if there are aliasURLs: url is matched as exact and aliasURLs as startsWith
// if there is only url: url is matched as startsWith
//
// care! aliases must still match url in react router (i.e. be children in the path)
const isActive = aliasURLs => (match, location) => {
  if (!match) return false

  if (aliasURLs && aliasURLs.length > 0) {
    if (match.isExact) return true

    const isBelowAliasURL =
      aliasURLs &&
      aliasURLs.find(url => location.pathname.startsWith(url)) !== undefined

    if (isBelowAliasURL) return true

    return false
  }

  return true
}

const NavItem = props => {
  const { url, aliasURLs, icon, text, afterClick } = props

  return (
    <NavLink
      to={url}
      className="nav-item"
      onClick={afterClick}
      activeClassName="active"
      isActive={isActive(aliasURLs)}
    >
      {icon && <Icon name={icon} />}
      <span className="text">{text}</span>
    </NavLink>
  )
}

NavItem.defaultProps = {
  url: '#',
  text: 'Section',
  active: false
}

NavItem.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  afterClick: PropTypes.func
}

export default NavItem
