import pathToRegexp from 'path-to-regexp'
import ASSET_TYPES from '../core/assets/assetTypes'

export const ROOT = '/'

//

export const QUERIES = '/queries'
export const DOCS = '/documents'

//

export const LOCATE = '/locate'

export const FILTER_BY_TYPE = '/filter/:assetType?'

const FILTER_BY_TYPE_COMPILED = pathToRegexp.compile(FILTER_BY_TYPE)

export const makeFilterByType = assetType =>
  FILTER_BY_TYPE_COMPILED({ assetType })

/* ASSETS */

export const ASSET_DETAILS = '/details/asset/:assetId'

const ASSET_DETAILS_COMPILED = pathToRegexp.compile(ASSET_DETAILS)

export const makeAssetDetails = assetId => ASSET_DETAILS_COMPILED({ assetId })

export const ASSET_MOVE_TO_LOCATION = '/movetolocation/:assetId'
export const makeAssetMoveToLocation = assetId => `/movetolocation/${assetId}`

//

export const ASSET_EDIT = '/details/asset/:assetId/edit'

const ASSET_EDIT_COMPILED = pathToRegexp.compile(ASSET_EDIT)

export const makeAssetEdit = assetId => ASSET_EDIT_COMPILED({ assetId })

/* ORDINARY OPERATIONS */

export const ORDINARY_OPERATION_DETAILS =
  '/details/ordinary-operation/:operationId'

const ORDINARY_OPERATION_DETAILS_COMPILED = pathToRegexp.compile(
  ORDINARY_OPERATION_DETAILS
)

export const makeOrdinaryOperationDetails = operationId =>
  ORDINARY_OPERATION_DETAILS_COMPILED({ operationId })

//

export const ORDINARY_OPERATION_CREATE = '/create/ordinary-operation'

//

export const ORDINARY_OPERATION_EDIT =
  '/details/ordinary-operation/:operationId/edit'

const ORDINARY_OPERATION_EDIT_COMPILED = pathToRegexp.compile(
  ORDINARY_OPERATION_EDIT
)

export const makeOrdinaryOperationEdit = operationId =>
  ORDINARY_OPERATION_EDIT_COMPILED({ operationId })

/* EXTRAORDINARY OPERATIONS */

export const EXTRAORDINARY_OPERATION_DETAILS =
  '/details/extraordinary-operation/:operationId'

const EXTRAORDINARY_OPERATION_DETAILS_COMPILED = pathToRegexp.compile(
  EXTRAORDINARY_OPERATION_DETAILS
)

export const makeExtraordinaryOperationDetails = operationId =>
  EXTRAORDINARY_OPERATION_DETAILS_COMPILED({ operationId })

//

export const EXTRAORDINARY_OPERATION_CREATE = '/create/extraordinary-operation'

//

export const EXTRAORDINARY_OPERATION_EDIT =
  '/details/extraordinary-operation/:operationId/edit'

const EXTRAORDINARY_OPERATION_EDIT_COMPILED = pathToRegexp.compile(
  EXTRAORDINARY_OPERATION_EDIT
)

export const makeExtraordinaryOperationEdit = operationId =>
  EXTRAORDINARY_OPERATION_EDIT_COMPILED({ operationId })

/* WORK OPERATIONS */

export const WORK_OPERATION_DETAILS = '/details/work-operation/:operationId'

const WORK_OPERATION_DETAILS_COMPILED = pathToRegexp.compile(
  WORK_OPERATION_DETAILS
)

export const makeWorkOperationDetails = operationId =>
  WORK_OPERATION_DETAILS_COMPILED({ operationId })

//

export const WORK_OPERATION_CREATE = '/create/work-operation'

//

export const WORK_OPERATION_EDIT = '/details/work-operation/:operationId/edit'

const WORK_OPERATION_EDIT_COMPILED = pathToRegexp.compile(WORK_OPERATION_EDIT)

export const makeWorkOperationEdit = operationId =>
  WORK_OPERATION_EDIT_COMPILED({ operationId })

export const getDetailRoute = (queryType, subQueryType) => {
  if (queryType === 'assets') return ASSET_DETAILS.split(':')[0]
  else {
    switch (subQueryType) {
      case ASSET_TYPES.CONSERVACION_ORDINARIA:
        return ORDINARY_OPERATION_DETAILS.split(':')[0]
      case ASSET_TYPES.CONSERVACION_EXTRAORDINARIA:
        return EXTRAORDINARY_OPERATION_DETAILS.split(':')[0]
      case ASSET_TYPES.OBRAS:
        return WORK_OPERATION_DETAILS.split(':')[0]
      default:
        return ORDINARY_OPERATION_DETAILS.split(':')[0]
    }
  }
}
