import app from '../../feathers'
import { getValueWithPath } from '../../core/autoform/paths'

export function readFileAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = resolve
    reader.readAsDataURL(file)
  })
}

const makeUploadDocPromiseFactory = (entityIdKey, entity) => ({
  doc,
  dataPath
}) => {
  const { name, size, type } = doc.file

  return readFileAsDataURL(doc.file)
    .then(e => {
      const [, resource] = e.target.result.split(',')
      return resource
    })
    .then(resource => {
      // Replace positional fragments in dataPath width database `_id`s
      dataPath = dataPath.map((fragment, i) => {
        if (!Number.isFinite(fragment)) return fragment

        const dataInPositionalFragment = getValueWithPath(
          entity,
          dataPath.slice(0, i + 1)
        )

        if (!dataInPositionalFragment._id) return fragment

        return { _id: dataInPositionalFragment._id }
      })

      const datum = {
        name,
        size,
        type,
        resource,
        [entityIdKey]: entity._id,
        dataPath
      }

      const serviceName = dataPath.includes('referenceImage')
        ? 'referenceImage'
        : 'attachedFiles'

      return app.service(serviceName).create(datum)

    })
}

export default makeUploadDocPromiseFactory
