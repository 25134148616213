const toLocal = state => state.assets

export const getAssets = state => toLocal(state).data
export const isLoading = state => toLocal(state).isLoading
export const getLastFetchBounds = state => toLocal(state).lastFetchBounds
export const hasError = state => toLocal(state).hasError
export const getLastErrorMessage = state => toLocal(state).lastErrorMessage

export const getSelectedAssetTypes = state => toLocal(state).selectedAssetTypes

export const getAssetCheckedTree = state => toLocal(state).assetTypeGroups

export const getAssetTypeGroup = (state, id) => getAssetCheckedTree(state)[id]

export const getDraggableAssetId = state => toLocal(state).draggableAssetId
export const getDraggableUpdatedGeometry = state =>
  toLocal(state).draggableCurrentGeometry
export const getDraggableOriginalGeometry = state =>
  toLocal(state).draggableOriginalGeometry

export const isAssetTypeGroup = (state, id) =>
  Boolean(getAssetTypeGroup(state, id))

export const isChecked = (state, id) => {
  const assetTypeGroup = getAssetTypeGroup(state, id)
  const selectedAssetTypes = getSelectedAssetTypes(state)

  if (!assetTypeGroup) {
    return selectedAssetTypes.includes(id)
  } else {
    return assetTypeGroup.some(assetId => selectedAssetTypes.includes(assetId))
  }
}

export const getFocusedAssetId = state => toLocal(state).focusedAssetId
