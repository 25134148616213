import feathers from '@feathersjs/client'
import auth from '@feathersjs/authentication-client'
import configureHooks from './hooks'

//console.log('Configure Feathers client to', process.env.REACT_APP_BACKEND_URL)
const app = feathers()
const restClient = feathers.rest(process.env.REACT_APP_BACKEND_URL)
app.configure(restClient.fetch(global.fetch))
configureHooks(app)
app.configure(
  auth({
    storage: window.localStorage,
    header: 'Authorization'
  })
)

export default app
