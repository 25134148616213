import * as Sentry from '@sentry/browser'
import { isAuthError } from './utils'

// we don't want to log to sentry Duplicate/409 errors for these services
const servicesToSkipConflicts = ['pks']

export const logToSentry = ctx => {
  // auth errors are handled by another hook
  if (isAuthError(ctx)) return ctx

  const { error } = ctx
  if (error.code === 409 && servicesToSkipConflicts.includes(ctx.path)) {
    return ctx
  }
  if (process.env.NODE_ENV !== 'production') {
    return ctx
  }
  Sentry.withScope(scope => {
    scope.setTag('Feathers Error', ctx.className || '' + ctx.message || '')
    scope.setExtra('Service path', ctx.path)
    scope.setExtra('Service method', ctx.method)
    scope.setExtra('Service params', ctx.params)
    if (
      ctx.method === 'create' ||
      ctx.method === 'patch' ||
      ctx.method === 'update'
    ) {
      scope.setExtra('Data', ctx.data)
    }
    Sentry.captureException(error)
  })
}
