import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Icon from '../icons/Icon'

const Modal = props => {
  const {
    children,
    closeModalAction,
    hasCloseButton,
    dark,
    typeContent
  } = props
  const className = cx('overlay modal', { 'dark-modal': dark })
  const contentClass = cx('modal-content', typeContent)
  return (
    <div className={className}>
      <div className={contentClass}>
        {children}
        {hasCloseButton ? (
          <div className="close-modal">
            <button
              type="button"
              className="clean-button"
              onClick={closeModalAction}>
              <Icon name="close" />
            </button>
          </div>
        ) : null}
      </div>
    </div>
  )
}

Modal.defaultProps = {
  typeContent: 'modal-generic'
}

Modal.propTypes = {
  closeModalAction: PropTypes.func,
  dark: PropTypes.bool,
  hasCloseButton: PropTypes.bool,
  typeContent: PropTypes.oneOf([
    'modal-generic',
    'modal-edit',
    'modal-carousel',
    'modal-actions',
    'modal-format'
  ])
}

export default Modal
