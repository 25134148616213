import {
  getPKFamily,
  getStartEndPKFields,
  getOpField,
  getStartEndPKExecFields,
  // getStartEndCoordExecFields,
  getWayField,
  getTraceField,
  getMedicUnidExecFields,
  getCommonInspectionFields,
  getCommonExecFields,
  getCommonValidationFields
} from './commonFields'

const limpiezasPistaFields = [
  ...getPKFamily('limpiezasPista'),
  ...getStartEndPKFields('limpiezasPista'),
  ...getCommonInspectionFields('limpiezasPista'),
  ...getOpField('limpiezasPista'),
  ...getStartEndPKExecFields('limpiezasPista'),
  // ...getStartEndCoordExecFields('limpiezasPista'),
  ...getWayField('limpiezasPista'),
  ...getTraceField('limpiezasPista'),
  ...getMedicUnidExecFields('limpiezasPista'),
  ...getCommonExecFields('limpiezasPista'),
  ...getCommonValidationFields('limpiezasPista')
]

export const getLimpiezaPistaFields = () => limpiezasPistaFields
