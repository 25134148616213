import { propOr, prop, path } from 'lodash/fp'

// toLocal :: State -> Object
const toLocal = propOr({}, 'cecos')

export const getCECOs = state => {
  const { ids, byId } = toLocal(state)
  return ids.map(id => byId[id])
}
//export const getNumCECOs = state => toLocal(state).cecos.length
export const getIsCreating = state => toLocal(state).isCreating
export const getCECOToEdit = state => toLocal(state).cecoIdToEdit
export const getCurrentPage = state => toLocal(state).currentPage
export const getSearchValue = state => toLocal(state).searchValue
export const getModal = state => toLocal(state).modal
export const getModalInfo = (state, property) => prop(property, getModal(state))
export const isFetching = path(['cecos', 'isFetching'])
export const getCecoById = (id, state) => path(['cecos', 'byId', id], state)
export const getCecoIds = path(['cecos', 'ids'])
