export const getCommonInspectionFields = family => [
  {
    label: 'Comentarios',
    name: `parteTrabajos.${family}.identificacion.comentarios`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Trabajos programados en inspección',
    name: `parteTrabajos.${family}.inspeccion.trabajosProgramados`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Observaciones en inspección',
    name: `parteTrabajos.${family}.inspeccion.observaciones`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getCommonExecFields = family => [
  {
    label: 'Trabajos programados en ejecución',
    name: `parteTrabajos.${family}.ejecucion.trabajosProgramados`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Observaciones en ejecución',
    name: `parteTrabajos.${family}.ejecucion.observaciones`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getCommonValidationFields = family => [
  {
    label: 'Fecha validación',
    name: `parteTrabajos.${family}.validacion.fecha`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Responsable Contratista',
    name: `parteTrabajos.${family}.validacion.responsableContratista`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Auditado',
    name: `parteTrabajos.${family}.validacion.auditado`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Comentarios en validación',
    name: `parteTrabajos.${family}.validacion.comentarios`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Observaciones en validación',
    name: `parteTrabajos.${family}.validacion.observaciones`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getCommonDrenajeFields = family => [
  {
    label: 'Tipología del elemento hidráulico',
    name: `parteTrabajos.${family}.identificacion.tipologiaElementoHidraulico`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Medición',
    name: `parteTrabajos.${family}.inspeccion.medicion`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Unidad',
    name: `parteTrabajos.${family}.inspeccion.unidad`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getStartEndPKFields = family => [
  {
    label: 'PK Inicio',
    name: `parteTrabajos.${family}.inspeccion.pkInicio.formatPKOffset`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'PK Fin',
    name: `parteTrabajos.${family}.inspeccion.pkFin.formatPKOffset`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getStartEndPKExecFields = family => [
  {
    label: 'PK Inicio en ejecución',
    name: `parteTrabajos.${family}.ejecucion.pkInicio.formatPKOffset`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'PK Fin en ejecución',
    name: `parteTrabajos.${family}.ejecucion.pkFin.formatPKOffset`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getStartCoordField = family => [
  {
    label: 'Coordenadas PK Inicio',
    name: `parteTrabajos.${family}.ejecucion.coordenadasPkInicio`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getStartEndCoordExecFields = family => [
  ...getStartCoordField(family),
  {
    label: 'Coordenadas PK Fin',
    name: `parteTrabajos.${family}.ejecucion.coordenadasPkFin`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getMedicUnidExecFields = family => [
  {
    label: 'Medición',
    name: `parteTrabajos.${family}.ejecucion.medicion`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Unidad',
    name: `parteTrabajos.${family}.ejecucion.unidad`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getAvgHeightGrassField = family => [
  {
    label: 'Altura media hierba',
    name: `parteTrabajos.${family}.inspeccion.alturaMediaHierba`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getOpTypeField = family => [
  {
    label: 'Tipo de actuación',
    name: `parteTrabajos.${family}.ejecucion.tipoActuacion`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getWayField = family => [
  {
    label: 'Sentido',
    name: `parteTrabajos.${family}.ejecucion.sentido`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getTraceField = family => [
  {
    label: 'Traza',
    name: `parteTrabajos.${family}.ejecucion.traza`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getOpField = family => [
  {
    label: 'Actuación',
    name: `parteTrabajos.${family}.ejecucion.actuacion`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getOpAndElementsFields = family => [
  ...getOpField(family),
  {
    label: 'Elementos',
    name: `parteTrabajos.${family}.ejecucion.elementos`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getCleaningField = family => [
  {
    label: 'Limpieza',
    name: `parteTrabajos.${family}.ejecucion.limpieza`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getCleanStartEndPKFields = family => [
  ...getCleaningField(family),
  {
    label: 'Limpieza PK Inicio',
    name: `parteTrabajos.${family}.ejecucion.limpiezaPkInicio.formatPKOffset`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Limpieza PK Fin',
    name: `parteTrabajos.${family}.ejecucion.limpiezaPkFin.formatPKOffset`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getMedUnidCleaningFields = family => [
  {
    label: 'Medición Limpieza',
    name: `parteTrabajos.${family}.ejecucion.medicionLimpieza`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Unidad Limpieza',
    name: `parteTrabajos.${family}.ejecucion.unidadLimpieza`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getClearingField = family => [
  {
    label: 'Desbroce',
    name: `parteTrabajos.${family}.ejecucion.desbroce`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getClearStartEndPKFields = family => [
  ...getClearingField(family),
  {
    label: 'Desbroce PK Inicio',
    name: `parteTrabajos.${family}.ejecucion.desbrocePkInicio.formatPKOffset`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Desbroce PK Fin',
    name: `parteTrabajos.${family}.ejecucion.desbrocePkFin.formatPKOffset`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getMedUnidClearingFields = family => [
  {
    label: 'Medición Desbroce',
    name: `parteTrabajos.${family}.ejecucion.medicionDesbroce`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Unidad Desbroce',
    name: `parteTrabajos.${family}.ejecucion.unidadDesbroce`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getEntryValStatusField = family => [
  {
    label: 'Situación de la entrada en validación',
    name: `parteTrabajos.${family}.validacion.situacionEntrada`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getEntryOutputValStatusField = family => [
  ...getEntryValStatusField(family),
  {
    label: 'Situación de la salida en validación',
    name: `parteTrabajos.${family}.validacion.situacionSalida`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getAppearanceField = family => [
  {
    label: 'Aspecto',
    name: `parteTrabajos.${family}.inspeccion.aspecto`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getVallaCierreInspectionFields = family => [
  {
    label: 'Tipo Aviso',
    name: `parteTrabajos.${family}.identificacion.tipoAviso`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Km Inspeccionados',
    name: `parteTrabajos.${family}.inspeccion.kmInspeccionados`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Tipo Inspección',
    name: `parteTrabajos.${family}.inspeccion.tipoInspeccion`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getVallaCierreExecFields = family => [
  {
    label: 'Nº Reparación',
    name: `parteTrabajos.${family}.ejecucion.numReparacion`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Ud. Poste Tensor',
    name: `parteTrabajos.${family}.ejecucion.udPosteTensor`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Ud. Poste Intermedio',
    name: `parteTrabajos.${family}.ejecucion.udPosteIntermedio`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'ML Valla',
    name: `parteTrabajos.${family}.ejecucion.mlValla`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Sup. Desbroce',
    name: `parteTrabajos.${family}.ejecucion.supDesbroce`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Ud. Compuertas',
    name: `parteTrabajos.${family}.ejecucion.udCompuertas`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getLocationField = family => [
  {
    label: 'Localización',
    name: `parteTrabajos.${family}.ejecucion.localizacion`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getEnlacesFields = family => [
  {
    label: 'Nº Enlace',
    name: `parteTrabajos.${family}.ejecucion.numEnlace`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Localización',
    name: `parteTrabajos.${family}.ejecucion.localizacion`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getNumOFfield = family => [
  {
    label: 'Nº O.F.',
    name: `parteTrabajos.${family}.identificacion.numOF`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getEntryOutputInpecStatusField = family => [
  {
    label: 'Situación de la entrada',
    name: `parteTrabajos.${family}.inspeccion.situacionEntrada`,
    unwindPath: `parteTrabajos.${family}`
  },
  {
    label: 'Situación de la salida',
    name: `parteTrabajos.${family}.inspeccion.situacionSalida`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getPKField = family => [
  {
    label: 'PK Inspección',
    name: `parteTrabajos.${family}.inspeccion.pk.formatPKOffset`,
    unwindPath: `parteTrabajos.${family}`
  }
]

export const getPKFamily = family => [
  {
    label: 'PK Familia',
    name: `parteTrabajos.${family}.identificacion.pk.formatPKOffset`,
    unwindPath: `parteTrabajos.${family}`
  }
]
