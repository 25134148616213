import { head, assocPath } from 'lodash/fp'
import * as ActionTypes from './actionTypes'
import { getById } from './selectors'
import { fetchById, fetchAssetsInOperation } from './api'
import { fetchCecoByCode } from '../cecos/api'

export function fetchDetailsRequest(id) {
  return {
    type: ActionTypes.FETCH_DETAILS_REQUEST,
    id
  }
}

export function fetchDetailsSuccess(id, data) {
  return {
    type: ActionTypes.FETCH_DETAILS_SUCCESS,
    id,
    payload: data
  }
}

export function fetchDetailsFailed(id, error) {
  return {
    type: ActionTypes.FETCH_DETAILS_FAILED,
    id,
    error: error.message || error.toString()
  }
}

export function fetchDetails(id, force = false) {
  return async function(dispatch, getState) {
    const existingOperation = getById(getState(), id)
    if (existingOperation && !force) {
      return existingOperation
    }
    dispatch(fetchDetailsRequest(id))
    try {
      const operation = await fetchById(id)
      const ceco = await fetchCecoByCode(operation.registroGeneral.ceco)
      const operationWithCeco = assocPath(
        ['registroGeneral', 'cecoData'],
        head(ceco),
        operation
      )
      dispatch(fetchDetailsSuccess(id, operationWithCeco))
      return operationWithCeco
    } catch (err) {
      console.warn('Error fetching details', err)
      dispatch(fetchDetailsFailed(id, err))
      return null
    }
  }
}

export const fetchRelatedAssetsRequest = () => ({
  type: ActionTypes.FETCH_RELATED_ASSETS_REQUEST
})

export const fetchRelatedAssetsError = error => ({
  type: ActionTypes.FETCH_RELATED_ASSETS_FAILED,
  error
})

export const fetchRelatedAssetsSuccess = assets => ({
  type: ActionTypes.FETCH_RELATED_ASSETS_SUCCESS,
  assets
})

export const clearRelatedAssets = () => ({
  type: ActionTypes.CLEAR_RELATED_ASSETS
})

export const fetchRelatedAssets = operation => async dispatch => {
  dispatch(fetchRelatedAssetsRequest())
  const operationId = operation._id
  try {
    const relatedAssets = await fetchAssetsInOperation(operationId)
    dispatch(fetchRelatedAssetsSuccess(relatedAssets))
  } catch (e) {
    dispatch(fetchRelatedAssetsError(e.message))
  }
}
