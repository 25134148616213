import { combineReducers } from 'redux'
import { pipe, set } from 'lodash/fp'
import { buildReducer } from '../utils'
import * as ActionTypes from './actionTypes'
import { ASSET_DRAG_END } from '../assets/actionTypes'
export * from './actions'
export * from './selectors'

const byId = buildReducer(
  {},
  {
    [ActionTypes.FETCH_DETAILS_SUCCESS]: (state, action) => {
      const { id, payload } = action
      return { ...state, [id]: payload }
    },
    [ActionTypes.ADD_FILE_TO_DETAILS]: (state, action) => {
      const { attachedFile } = action.payload
      const { fileType, assetId } = attachedFile

      const updatedAsset = {
        ...state[assetId],
        [fileType]: [
          action.payload.attachedFile,
          ...(state[assetId][fileType] || [])
        ]
      }

      return {
        ...state,
        [assetId]: updatedAsset
      }
    },
    [ActionTypes.REMOVE_FILE_FROM_DETAILS]: (state, action) => {
      let { assetId, attachedFileId, fileType } = action.payload

      const updatedAsset = {
        ...state[assetId],
        [fileType]: state[assetId][fileType].filter(
          file => file._id !== attachedFileId
        )
      }

      return {
        ...state,
        [assetId]: updatedAsset
      }
    },
    // update X, Y when asset is dragged
    [ASSET_DRAG_END]: (state, action) => {
      const { assetId, X, Y } = action.payload
      return pipe(
        set([assetId, 'X'], X),
        set([assetId, 'Y'], Y)
      )(state)
    }
  }
)

const isFetching = buildReducer(false, {
  [ActionTypes.FETCH_DETAILS_REQUEST]: () => true,
  [ActionTypes.FETCH_DETAILS_FAILED]: () => false,
  [ActionTypes.FETCH_DETAILS_SUCCESS]: () => false
})

const fetchingId = buildReducer(null, {
  [ActionTypes.FETCH_DETAILS_REQUEST]: (_, action) => action.id || null
})

const lastError = buildReducer(null, {
  [ActionTypes.FETCH_DETAILS_SUCCESS]: state => null,
  [ActionTypes.FETCH_DETAILS_FAILED]: (state, action) => action.error
})

const imgCarousel = buildReducer(
  {
    isVisible: false,
    currentIndex: 0
  },
  {
    [ActionTypes.IMG_CAROUSEL_SHOWN]: (state, action) => {
      return {
        isVisible: true,
        currentIndex: action.payload
      }
    },
    [ActionTypes.IMG_CAROUSEL_HIDDEN]: state => {
      return {
        isVisible: false,
        currentIndex: 0
      }
    }
  }
)

const referenceImageCarousel = buildReducer(
  {
    isVisible: false,
    currentIndex: 0
  },
  {
    [ActionTypes.REFERENCE_IMAGE_CAROUSEL_SHOWN]: state => {
      return {
        isVisible: true,
        currentIndex: 0
      }
    },
    [ActionTypes.REFERENCE_IMAGE_CAROUSEL_HIDDEN]: state => {
      return {
        isVisible: false,
        currentIndex: 0
      }
    }
  }
)

const relatedOperations = buildReducer(
  {
    isFetching: false,
    operations: [],
    error: null
  },
  {
    [ActionTypes.FETCH_RELATED_OPERATIONS_REQUEST]: () => ({
      isFetching: true,
      operations: [],
      error: null
    }),
    [ActionTypes.FETCH_RELATED_OPERATIONS_FAILED]: (state, { error }) => ({
      isFetching: false,
      operations: [],
      error
    }),
    [ActionTypes.FETCH_RELATED_OPERATIONS_SUCCESS]: (
      state,
      { operations }
    ) => ({
      isFetching: false,
      operations,
      error: null
    }),
    [ActionTypes.CLEAR_RELATED_OPERATIONS]: () => ({
      isFetching: false,
      operations: [],
      error: null
    })
  }
)

const myLocation = buildReducer(
  {
    assetId: null,
    latitude: null,
    longitude: null
  },
  {
    [ActionTypes.MOVED_TO_MY_LOCATION]: (_, action) => {
      return {
        assetId: action.payload.assetId,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude
      }
    },
    [ActionTypes.MOVE_CANCELED]: () => {
      return {
        assetId: null,
        latitude: null,
        longitude: null
      }
    }
  }
)

export default combineReducers({
  byId,
  isFetching,
  fetchingId,
  lastError,
  imgCarousel,
  referenceImageCarousel,
  relatedOperations,
  myLocation
})
