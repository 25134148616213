import {
  getStartEndPKFields,
  getAvgHeightGrassField,
  getStartEndPKExecFields,
  // getStartEndCoordExecFields,
  getWayField,
  getLocationField,
  getMedicUnidExecFields,
  getOpTypeField,
  getCommonInspectionFields,
  getCommonExecFields,
  getCommonValidationFields
} from './commonFields'

const jardineriasTroncoFields = [
  ...getStartEndPKFields('jardineriasTronco'),
  ...getAvgHeightGrassField('jardineriasTronco'),
  ...getCommonInspectionFields('jardineriasTronco'),
  ...getOpTypeField('jardineriasTronco'),
  ...getStartEndPKExecFields('jardineriasTronco'),
  // ...getStartEndCoordExecFields('jardineriasTronco'),
  ...getWayField('jardineriasTronco'),
  ...getLocationField('jardineriasTronco'),
  ...getMedicUnidExecFields('jardineriasTronco'),
  ...getCommonExecFields('jardineriasTronco'),
  ...getCommonValidationFields('jardineriasTronco')
]

export const getJardineriaTroncoFields = () => jardineriasTroncoFields
