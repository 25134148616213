import * as ActionTypes from './actionTypes'
import * as API from './api'
import { map, omit } from 'lodash/fp'

//const CACHE_KEY = 'axes'

export const fetchAxesSuccess = axesData => ({
  type: ActionTypes.FETCH_AXES_SUCCESS,
  payload: axesData
})

export const fetchAxesRequest = () => {
  return { type: ActionTypes.FETCH_AXES_REQUEST }
}

export const fetchAxesFailed = () => ({ type: ActionTypes.FETCH_AXES_FAILED })

export const fetchAxes = query => {
  return async function(dispatch) {
    dispatch(fetchAxesRequest())
    /* const cached = await getFromCache(CACHE_KEY)
    if (cached && cached.length) {
      dispatch(fetchAxesSuccess(cached))
      return cached
    } */

    /* return app
      .service('axes')
      .find({ query: { $select: ['geometry', 'name', 'direction'] } }) */

    const queryParams = {
      $select: ['geometry', 'name', 'direction', 'highway', 'isDeleted'],
      //$sort: { name: 1 },
      ...query
    }
    return API.fetchAxes({
      query: queryParams
    })
      .then(async axesData => {
        //console.log(axesData)
        dispatch(fetchAxesSuccess(map(omit(['geometry']), axesData)))
        // resolve with data for calling code
        // await saveInCache(CACHE_KEY, axesData, Expiration.OneDay)
        return axesData
      })
      .catch(err => {
        console.log('Error fetching axis', err)
        dispatch(fetchAxesFailed())
        // resolve with null for calling code
        return null
      })
  }
}
