import { buildReducer } from '../utils'
import { TOGGLE_NAVIGATION } from './actionTypes'
export * from './actions'
export * from './selectors'

const initialState = {
  isNavOpen: false
}

export default buildReducer(initialState, {
  [TOGGLE_NAVIGATION]: (state, _) => {
    return {
      ...state,
      isNavOpen: !state.isNavOpen
    }
  }
})
