import {
  getPKFamily,
  getStartEndPKExecFields,
  getWayField,
  getTraceField,
  getOpAndElementsFields,
  getMedicUnidExecFields,
  getCommonInspectionFields,
  getCommonExecFields,
  getCommonValidationFields
} from './commonFields'

const elemenSenalBalizamientoFields = [
  ...getPKFamily('elementosSeñalizacionBalizamiento'),
  ...getCommonInspectionFields('elementosSeñalizacionBalizamiento'),
  ...getStartEndPKExecFields('elementosSeñalizacionBalizamiento'),
  ...getWayField('elementosSeñalizacionBalizamiento'),
  ...getTraceField('elementosSeñalizacionBalizamiento'),
  ...getOpAndElementsFields('elementosSeñalizacionBalizamiento'),
  ...getMedicUnidExecFields('elementosSeñalizacionBalizamiento'),
  ...getCommonExecFields('elementosSeñalizacionBalizamiento'),
  ...getCommonValidationFields('elementosSeñalizacionBalizamiento')
]

export const getElemenSenalBalizamientoFields = () =>
  elemenSenalBalizamientoFields
