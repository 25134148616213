import app from '../../feathers'
import { set, uniq, compact } from 'lodash/fp'

export async function searchFieldValues(
  model,
  fieldName,
  queryFilters,
  unwindPath
) {
  if (fieldName === 'codigoi3met') fieldName = 'codigo'

  const svc = app.service(`searchFieldValues/${model}`)

  const data = { conditions: queryFilters.beforeUnwind, fieldName, unwindPath }

  return svc.create(data)
}

export async function searchNumFamilies(
  model,
  families,
  queryFilters,
  unwindPath
) {
  const svc = app.service(`searchNumFamilies/${model}`)

  const data = { conditions: queryFilters.afterUnwind, families, unwindPath }

  return svc.create(data)
}

// [x, y] -> {x:1, y:1}
const arrToObj = arr => arr.reduce((acc, e) => ({ ...acc, [e]: 1 }), {})

/**
 * Get the unwindPath for each selected column
 * @param {Array} selectedColumns
 */
const getUnwindPathsFromCols = selectedColumns =>
  uniq(
    compact(selectedColumns).reduce(
      (acc, col) => [...acc, ...getFieldNameUnwindPath(col)],
      []
    )
  )

/**
 * "a.b.c" -> ["a", "a.b", "a.b.c"]
 * @param {String} fieldName
 */
const getFieldNameUnwindPath = fieldName => {
  const arr = fieldName.split('.')
  return arr.reduce((acc, _, i) => [...acc, arr.slice(0, i + 1).join('.')], [])
}

export async function search(model, queryFilters, columns, onlyCount = true) {
  const svc = app.service(`search/${model}`)
  const data = {
    conditions: queryFilters.beforeUnwind,
    projections: arrToObj(columns)
  }

  if (model !== 'assets') {
    data.unwindPaths = getUnwindPathsFromCols(columns)
    data.condAfterUnwind = queryFilters.afterUnwind
  }

  // if (queryFilters.unwindPaths.length)
  //   data.unwindPaths = queryFilters.unwindPaths
  let params = {}
  if (onlyCount) params = set('query.count', true, params)

  return svc.create(data, params)
}

export async function searchWorkReportsByFamily(
  family = '',
  filters = {},
  count = false
) {
  return app
    .service('searchWorkReports')
    .find({ query: { family, filters, count } })
}

export async function searchWRFieldValues(fieldName, conditions = {}) {
  return app
    .service('searchWRFieldValues/workReport')
    .create({ conditions, fieldName })
}
