import app from '../../feathers'

export const fetchById = async (id, autopopulate = true) => {
  const ordinaryOp = await app
    .service('/operations/ordinary')
    .get(id, { query: { $autopopulate: autopopulate } })

  return ordinaryOp
}

export async function deleteById(id) {
  return app.service('operations/ordinary').remove(id)
}

export function fetchAssetsInOperation(id) {
  return app.service(`/operations/ordinary/${id}/assets`).find()
}
