export const MODE_FILTERS = 'filters'
export const MODE_COLUMNS = 'columns'
export const MODE_RESULT = 'result'
export const MODE_MAP = 'map'
export const FIELDS = 'fields'

export const NUM_PAGE_NEIGHBOURS = 2

export const GEOJSON_VALUE = 'geojson'
export const CSV_VALUE = 'csv'
export const EXCEL_VALUE = 'xlsx'
export const GEOJSON_LABEL = 'GeoJSON'
export const CSV_LABEL = 'CSV'
export const EXCEL_LABEL = 'Excel'

export const CONTINUAR = 'Continuar'
export const VER_RESULTADOS = 'Ver Resultados'
export const VER_EN_MAPA = 'Ver en mapa'
export const VISTA_LISTADO = 'Vista listado'
export const VOLVER = 'Volver'
export const EXPORTAR = 'Exportar'

export const FIELD_CARD_TYPES = {
  Date: 'Date',
  MonthYear: 'MonthYear',
  Number: 'Number',
  Monetary: 'Monetary',
  String: 'String',
  Boolean: 'Boolean',
  Families: 'Families'
}
