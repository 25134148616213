import { combineReducers } from 'redux'
import { buildReducer } from '../utils'
import * as ActionTypes from './actionTypes'
export * from './actions'
export * from './selectors'

const byId = buildReducer(
  {},
  {
    [ActionTypes.ADD]: (state, action) => {
      const { id, payload } = action
      return { ...state, [id]: payload }
    },

    [ActionTypes.FETCH_DETAILS_SUCCESS]: (state, action) => {
      const { id, payload } = action
      return { ...state, [id]: payload }
    }
  }
)

const isFetching = buildReducer(false, {
  [ActionTypes.FETCH_DETAILS_REQUEST]: () => true,
  [ActionTypes.FETCH_DETAILS_FAILED]: () => false,
  [ActionTypes.FETCH_DETAILS_SUCCESS]: () => false
})

const fetchingId = buildReducer(null, {
  [ActionTypes.FETCH_DETAILS_REQUEST]: (_, action) => action.id || null
})

const lastError = buildReducer(null, {
  [ActionTypes.FETCH_DETAILS_SUCCESS]: state => null,
  [ActionTypes.FETCH_DETAILS_FAILED]: (state, action) => action.error
})

export default combineReducers({
  byId,
  isFetching,
  fetchingId,
  lastError
})
