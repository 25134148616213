// An object defining constants for all asset types in the application
// This is used in labels, icons, fields and so on to avoid "magic strings"

import { flattenDeep, flow, uniq } from 'lodash/fp'

// If a new asset type is needed, it should be defined FIRST here
const ASSET_TYPES = {
  VPP: 'VPP',
  ODT: 'ODT',
  POP: 'POP',
  PORTICOS: 'PORTICOS',
  BANDEROLAS: 'BANDEROLAS',
  MUROS: 'MUROS',

  // DRENAJE
  ARQUETAS_POZOS: 'ARQUETAS_POZOS',
  CUNETAS: 'CUNETAS',
  CAZ: 'CAZ',
  BORDILLOS: 'BORDILLOS',
  BAJANTES: 'BAJANTES',
  OTDL: 'OTDL',
  COLECTORES_LONG: 'COLECTORES_LONG',

  // CERRAMIENTO
  VALLA_CERRAMIENTO: 'VALLA_CERRAMIENTO',

  // CONTENCION
  BARRERA_METALICA: 'BARRERA_METALICA',
  BARRERA_HORMIGON: 'BARRERA_HORMIGON',
  PRETIL: 'PRETIL',

  // PANTALLAS
  PANTALLAS_ANTIRRUIDO: 'PANTALLAS_ANTIRRUIDO',

  // HORIZONTAL
  SENALIZACION_HORIZONTAL: 'SENALIZACION_HORIZONTAL',

  // VERTICAL
  SENALIZACION_VERTICAL: 'SENALIZACION_VERTICAL',

  // BALIZAMIENTO
  BALIZAMIENTO: 'BALIZAMIENTO',

  // Pasos de mediana
  PASOS_MEDIANA: 'PASOS_MEDIANA',

  // Peajes
  EDIFICIOS_PEAJES: 'EDIFICIOS_PEAJES',
  VIAS_PEAJES: 'VIAS_PEAJES',

  // Firmes
  FIRMES: 'FIRMES',

  // ITS
  CAMARAS: 'CAMARAS',
  ESTACION_METEO: 'ESTACION_METEO',
  PMV: 'PMV',
  POSTES_SOS: 'POSTES_SOS',
  CANALIZACION_FO: 'CANALIZACION_FO',
  ARQUETAS_FO: 'ARQUETAS_FO',

  //Taludes
  DESMONTES: 'DESMONTES',
  TERRAPLENES: 'TERRAPLENES',

  //Túneles
  TUNELES: 'TUNELES',

  // Actuaciones
  OBRAS: 'OBRAS',
  CONSERVACION_ORDINARIA: 'CONSERVACION_ORDINARIA',
  FAMILIAS: 'FAMILIAS',
  CONSERVACION_EXTRAORDINARIA: 'CONSERVACION_EXTRAORDINARIA',

  // Alumbrado
  BACULOS: 'BACULOS'
}

const I3MET_ASSETS = [
  ASSET_TYPES.VPP,
  ASSET_TYPES.ODT,
  ASSET_TYPES.POP,
  ASSET_TYPES.PORTICOS,
  ASSET_TYPES.BANDEROLAS,
  ASSET_TYPES.MUROS
]

// Jardinerias don't have associate asset types
export const relationWorkReportFamilyAssetType = {
  desbrocesTaludes: [ASSET_TYPES.TUNELES, ASSET_TYPES.TERRAPLENES],
  drenajesProfundos: ASSET_TYPES.COLECTORES_LONG,
  drenajesSuperficiales: [
    ASSET_TYPES.ARQUETAS_POZOS,
    ASSET_TYPES.CUNETAS,
    ASSET_TYPES.CAZ,
    ASSET_TYPES.BORDILLOS,
    ASSET_TYPES.BAJANTES
  ],
  drenajesTransversales: ASSET_TYPES.OTDL,
  elementosContencion: [
    ASSET_TYPES.BARRERA_METALICA,
    ASSET_TYPES.BARRERA_HORMIGON,
    ASSET_TYPES.PRETIL
  ],
  elementosSeñalizacionBalizamiento: [
    ASSET_TYPES.SENALIZACION_HORIZONTAL,
    ASSET_TYPES.SENALIZACION_VERTICAL,
    ASSET_TYPES.BALIZAMIENTO
  ],
  intervencionesVallaCierre: ASSET_TYPES.VALLA_CERRAMIENTO,
  limpiezasDesbrocesODs: ASSET_TYPES.ODT,
  limpiezasPista: ASSET_TYPES.FIRMES,
  reparacionesFirmesEstructuras: [
    ASSET_TYPES.VPP,
    ASSET_TYPES.POP,
    ASSET_TYPES.MUROS,
    ASSET_TYPES.FIRMES,
    ASSET_TYPES.PORTICOS,
    ASSET_TYPES.BANDEROLAS
  ],
  alumbrado: ASSET_TYPES.BACULOS
}

export const LIST_OF_ASSET_TYPES_WITH_WR = flow(
  flattenDeep,
  uniq
)(Object.values(relationWorkReportFamilyAssetType))

export const getWorkReportFamilyByAssetType = assetType => {
  for (const familyByAssetTypes in relationWorkReportFamilyAssetType) {
    const objectValue = relationWorkReportFamilyAssetType[familyByAssetTypes]
    if (Array.isArray(objectValue) && objectValue.includes(assetType))
      return familyByAssetTypes
    if (objectValue === assetType) return familyByAssetTypes
  }
  return null
}

export const isOnI3Met = assetType => I3MET_ASSETS.includes(assetType)

export default ASSET_TYPES
