import { set } from 'lodash/fp'

import { buildReducer } from '../utils'
import * as ActionTypes from './actionTypes'
export * from './actions'
export * from './selectors'

const initialState = {
  ids: [],
  byId: {},
  isFetching: false,
  hasFetchError: false,
  cecoIdToEdit: false,
  isCreating: false,
  currentPage: 1,
  searchValue: '',
  modal: {
    show: false,
    text: ''
  }
}
const normalizeCecos = arrayCecos =>
  arrayCecos.reduce(
    (acc, ceco) => {
      acc.ids.push(ceco._id)
      acc.byId[ceco._id] = ceco
      return acc
    },
    { ids: [], byId: {} }
  )

export default buildReducer(initialState, {
  [ActionTypes.SET_EDITING]: (state, action) => {
    return {
      ...state,
      cecoIdToEdit: action.payload
    }
  },
  [ActionTypes.SET_CREATING]: (state, action) => {
    return {
      ...state,
      isCreating: action.payload
    }
  },
  [ActionTypes.FETCH_CECOS_SUCCESS]: (state, action) => {
    const { ids, byId } = normalizeCecos(action.payload)

    return {
      ...state,
      ids,
      byId,
      isFetching: false,
      hasFetchError: false
    }
  },
  [ActionTypes.FETCH_CECOS_REQUEST]: state => {
    return {
      ...state,
      isFetching: true,
      hasFetchError: false
    }
  },
  [ActionTypes.FETCH_CECOS_FAILED]: state => {
    return {
      ...state,
      isFetching: false,
      hasFetchError: true
    }
  },

  [ActionTypes.SET_CURRENT_PAGE]: (state, action) => {
    return {
      ...state,
      currentPage: action.currentPage
    }
  },
  [ActionTypes.SET_SEARCH_VALUE]: (state, action) => {
    return {
      ...state,
      searchValue: action.searchValue
    }
  },
  [ActionTypes.SET_MODAL_VALUES]: (state, action) => {
    const { show, text } = action.payload
    return set('modal', { show, text }, state)
  }
})
