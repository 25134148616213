import { isString, isObject } from 'lodash/fp'
import { Ability } from '@casl/ability'

export const customModelName = '__modelName'

export default new Ability([], {
  subjectName(subject) {
    if (isString(subject)) return subject
    if (isObject(subject) && subject[customModelName])
      return subject[customModelName]

    const Type = typeof subject === 'object' ? subject.constructor : subject
    return Type.modelName || Type.name
  }
})
