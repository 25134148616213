// Utilities to clean form field data coming from DB, applied recursively
// through a layout/formfield tree

import { getValueWithPath, extractPathFragment } from './paths'

import formFieldDataFromDB from './formFieldDataFromDB'
import { isLayout, isFormField, resolveConfigWithMeta } from './util'

// Mutably changes `data`
function normalLayoutFromDB(config, data, getField, setField) {
  const { items } = resolveConfigWithMeta(config, getField, setField)
  for (let item of items) {
    item = resolveConfigWithMeta(item, getField, setField)
    if (isLayout(item)) {
      recursiveLayoutDataFromDB(item, data, getField, setField)
    } else if (isFormField(item)) {
      formFieldDataFromDB(item, data, getField, setField)
    } else {
      console.warn('Item is neither a layout nor a form field')
      return null
    }
  }
}

// Mutably changes `data`
function listLayoutFromDB(config, data, getField, setField) {
  const value = getValueWithPath(data, config.path) || []

  value.forEach((item, i) => {
    const itemLayout = config.makeItemLayout(
      [...config.path, extractPathFragment(item, i)],
      item
    )
    recursiveLayoutDataFromDB(itemLayout, data, getField, setField)
  })
}

// Mutably changes `data`
export function recursiveLayoutDataFromDB(config, data, getField, setField) {
  switch (config.type) {
    case 'Concatenate':
    case 'Fragment':
    case 'Linear':
    case 'Tabs':
    case 'SidebarNavigation':
    case 'SidebarNavigationGroup':
    case 'Collapsable': {
      normalLayoutFromDB(config, data, getField, setField)
      break
    }

    case 'FilterableList':
    case 'SidebarSearch': {
      listLayoutFromDB(config, data, getField, setField)
      break
    }
    case 'Text':
      break
    default: {
      throw new Error(`Unknown layout type ${config.type}`)
    }
  }
}

// Given a layout `config` and the `initialData` we are getting from DB, return
// a clean copy of it
export default function layoutDataFromDB(
  config,
  initialData,
  getField,
  setField
) {
  // Create a JSON-safe deep-copy of initial data
  const data = JSON.parse(JSON.stringify(initialData))

  recursiveLayoutDataFromDB(config, data, getField, setField)

  return data
}
