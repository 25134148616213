import { compose, eq, pathOr, propOr } from 'lodash/fp'
import { ROLES /* ACCESS_TYPE */ } from '../../core/auth'

const toLocal = pathOr({}, 'session')

export const isUserLoggedIn = compose(
  propOr(false, 'isLoggedIn'),
  toLocal
)

export const hasForbiddenError = compose(
  propOr(false, 'hasForbiddenError'),
  toLocal
)

export const getUserId = compose(
  propOr('', ['token', 'userId']),
  toLocal
)

export const getUserName = compose(
  propOr('', ['token', 'login']),
  toLocal
)

export const getUserData = compose(
  propOr({}, 'data'),
  toLocal
)

const getUserPermissions = compose(
  propOr({}, ['data', 'permissions']),
  toLocal
)

export const getUserRole = compose(
  propOr('read_only', ['role']),
  getUserPermissions
)

export const isUserAdmin = compose(
  eq(ROLES.ADMIN),
  getUserRole
)

export const getUserOpsCecos = compose(
  propOr([], 'operationCecos'),
  getUserPermissions
)
