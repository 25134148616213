import { buildReducer } from '../utils'
import * as ActionTypes from './actionTypes'
import { set } from 'lodash/fp'

const initialState = {
  documents: [],
  totalDocs: 0,
  isFetching: false,
  hasFetchError: false,
  documentIdToEdit: false,
  isCreating: false,
  documentTypeFilter: '',
  cecoFilter: '',
  currentPage: 1,
  searchValue: '',
  resultsPerPage: 10,
  modal: {
    show: false,
    text: ''
  }
}

export default buildReducer(initialState, {
  [ActionTypes.SET_EDITING]: (state, action) => {
    return {
      ...state,
      documentIdToEdit: action.payload
    }
  },
  [ActionTypes.SET_CREATING]: (state, action) => {
    return {
      ...state,
      isCreating: action.payload
    }
  },

  [ActionTypes.FETCH_DOCUMENTS_REQUEST]: state => {
    return {
      ...state,
      isFetching: true,
      hasFetchError: false
    }
  },
  [ActionTypes.FETCH_DOCUMENTS_FAILED]: state => {
    return {
      ...state,
      isFetching: false,
      hasFetchError: true
    }
  },
  [ActionTypes.FETCH_DOCUMENTS_SUCCESS]: (state, action) => {
    const { data, total } = action.payload
    return {
      ...state,
      //payload.data, .total
      documents: data,
      totalDocs: total,
      isFetching: false,
      hasFetchError: false
    }
  },
  [ActionTypes.SET_CURRENT_PAGE]: (state, action) => {
    return {
      ...state,
      currentPage: action.payload
    }
  },
  [ActionTypes.SET_RESULTS_PER_PAGE]: (state, action) => {
    return {
      ...state,
      resultsPerPage: action.payload
    }
  },
  [ActionTypes.SET_SEARCH_VALUE]: (state, action) => {
    return {
      ...state,
      searchValue: action.payload
    }
  },
  [ActionTypes.SET_DOC_TYPE]: (state, action) => {
    return {
      ...state,
      cecoFilter: '',
      documentTypeFilter: action.payload
    }
  },
  [ActionTypes.SET_SELECTED_CECO]: (state, action) => {
    return {
      ...state,
      cecoFilter: action.payload
    }
  },
  [ActionTypes.SET_MODAL_VALUES]: (state, action) => {
    const { show, text } = action.payload
    return set('modal', { show, text }, state)
  }
})
