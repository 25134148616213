import shortid from 'shortid'
import { get, map } from 'lodash/fp'
import { prop, keys, set, size } from 'lodash/fp'
import * as ActionTypes from './actionTypes'
import { getNewAssetType, getNewGeometry } from './selectors'
import { fetchDetailsAndFocusMap } from '../asset_details'
import { fetchDetails as fetchOrdinaryOperationDetails } from '../ordinary_operation_details'
import { fetchDetails as fetchExtraordinaryOperationDetails } from '../extraordinary_operation_details'
import { fetchDetails as fetchWorkOperationDetails } from '../work_operation_details'
import app from '../../feathers'
import { makeGeoJSONGeometryFromGmaps } from '../../core/gmaps'
import { extractXYFromGeometry, blackListProps } from '../../core/assets'
import { fetchDetails } from '../asset_details'
import makeUploadDoc from '../../ui/autoform/makeUploadDocPromiseFactory'
/* Drawing actions */

export function setCreateMode(isUserDrawing, assetType) {
  return {
    type: ActionTypes.SET_CREATE_MODE,
    payload: {
      isUserDrawing: !!isUserDrawing,
      assetType
    }
  }
}

export function setMenuOpen(isOpen) {
  return { type: ActionTypes.SET_MENU_OPEN, payload: { isOpen: !!isOpen } }
}

export function storeGeometry(gmapsOverlayType, gmapsOverlay) {
  return {
    type: ActionTypes.STORE_GEOMETRY,
    payload: makeGeoJSONGeometryFromGmaps(gmapsOverlayType, gmapsOverlay)
  }
}

export function finishCreation() {
  return {
    type: ActionTypes.END_CREATION
  }
}

/* Asset save/update */

export function saveAssetRequest() {
  return {
    type: ActionTypes.SAVE_ASSET_REQUEST
  }
}

export function updateAssetRequest() {
  return {
    type: ActionTypes.UPDATE_ASSET_REQUEST
  }
}

export function saveAssetSuccess(newAsset) {
  return {
    type: ActionTypes.SAVE_ASSET_SUCCESS,
    payload: newAsset
  }
}

export function saveAssetFailed(error) {
  return {
    type: ActionTypes.SAVE_ASSET_FAILED,
    payload: error
  }
}

export function updateAssetSuccess() {
  return {
    type: ActionTypes.UPDATE_ASSET_SUCCESS
  }
}

export function updateAssetFailed(error) {
  return {
    type: ActionTypes.UPDATE_ASSET_FAILED,
    payload: error
  }
}

export function saveAsset() {
  return async function(dispatch, getState) {
    const state = getState()
    const assetType = getNewAssetType(state)
    const geometry = getNewGeometry(state)
    const { X, Y } = extractXYFromGeometry(geometry)

    if (!assetType || !geometry) {
      console.warn('saveAsset() - no assetType or geometry to save')
    }

    const newAssetData = {
      assetType,
      geometry,
      X,
      Y,
      fechaAlta: Date.now(),
      // Carlos - create temporary codigo because otherwise
      // only one asset can be in "creating" mode at a time
      // until the users saves with a new codigo, an asset with codigo=""
      // will block any other creation!
      codigo: shortid.generate()
    }
    dispatch(saveAssetRequest())

    try {
      const newAsset = await app.service('assets').create(newAssetData)

      const id = newAsset._id
      dispatch(saveAssetSuccess(newAsset))
      await dispatch(fetchDetailsAndFocusMap(id))

      return newAsset
    } catch (error) {
      console.warn('Error saving new asset', error)
      dispatch(saveAssetFailed(error))
      throw error
    }
  }
}

export function updateAsset(assetData, refetch = true) {
  return async function(dispatch) {
    const assetId = assetData._id
    const safeData = blackListProps(assetData)

    if (!assetId) {
      console.warn('Trying to save an asset without id')
      return
    }

    try {
      /* const { assetCode, pk, offset } = await generateCodeForAsset(assetData)
      console.log('Generate asset code', assetCode)
      safeData.codigo = assetCode
      safeData.pkInicial = pk * 1000 + offset */
      const updatedAsset = await app.service('assets').patch(assetId, safeData)
      // update details by re-fetching
      if (refetch) {
        dispatch(fetchDetails(assetId, true))
      }
      return updatedAsset
    } catch (err) {
      console.log('Error updating asset', err)
      //return false
      throw err
    }
  }
}

export function updateAssetWithAttachments(
  assetData,
  pendingDocs,
  deletedDocs,
  refetch = true
) {
  return async function(dispatch) {
    dispatch(updateAssetRequest())
    try {
      const asset = await dispatch(updateAsset(assetData, false))
      await Promise.all([
        ...pendingDocs.map(makeUploadDoc('assetId', asset)),
        ...deletedDocs.map(removeAttachedFile)
      ])
      if (refetch) {
        dispatch(fetchDetails(asset._id, true))
      }
      dispatch(updateAssetSuccess())
      return asset
    } catch (error) {
      console.log('Error updating asset with attachments', error)
      dispatch(updateAssetFailed(error))
      throw error
    }
  }
}

/* Ordinary operation save/update */

export function saveOrdinaryOperationRequest() {
  return {
    type: ActionTypes.SAVE_ORDINARY_OPERATION_REQUEST
  }
}

export function updateOrdinaryOperationRequest() {
  return {
    type: ActionTypes.UPDATE_ORDINARY_OPERATION_REQUEST
  }
}

export function saveOrdinaryOperationSuccess(newOrdinaryOperation) {
  return {
    type: ActionTypes.SAVE_ORDINARY_OPERATION_SUCCESS,
    payload: newOrdinaryOperation
  }
}

export function saveOrdinaryOperationFailed(error) {
  return {
    type: ActionTypes.SAVE_ORDINARY_OPERATION_FAILED,
    payload: error
  }
}

export const removeOrdinaryOperationError = () => ({
  type: ActionTypes.REMOVE_ORDINARY_OPERATION_ERROR
})

export function updateOrdinaryOperationSuccess() {
  return {
    type: ActionTypes.UPDATE_ORDINARY_OPERATION_SUCCESS
  }
}

export function updateOrdinaryOperationFailed(error) {
  return {
    type: ActionTypes.UPDATE_ORDINARY_OPERATION_FAILED,
    payload: error
  }
}

export const saveOrdinaryOperation = async ordinaryOperationData =>
  await app.service('operations/ordinary').create(ordinaryOperationData)

export const removeFromDataPath = (path, arr) =>
  arr.map(doc => {
    const indexToDelete = doc.dataPath.indexOf(path)
    doc.dataPath.splice(indexToDelete, 2)
    return doc
  })

export const manageDocs = (pendingDocs, entityIdKey, entity, deletedDocs) =>
  Promise.all([
    ...pendingDocs.map(makeUploadDoc(entityIdKey, entity)),
    ...deletedDocs.map(removeAttachedFile)
  ])

const safeCreateWorkReport = async function*(
  workReports,
  ordinaryOperationId,
  pendingDocs = [],
  deletedDocs = []
) {
  for (const [index, work] of workReports.entries()) {
    try {
      const savedWorkReport = await app
        .service('operations/work-report')
        .create({ ...work, ordinaryOperationId })

      const associatedPendingDocs = pendingDocs.filter(
        doc => doc.dataPath[1] === index
      )
      const associatedDeletedDocs = deletedDocs.filter(
        doc => doc.dataPath[1] === index
      )

      const formatPendingDocs = removeFromDataPath(
        'parteTrabajos',
        associatedPendingDocs
      )
      const formatDeletedDocs = removeFromDataPath(
        'parteTrabajos',
        associatedDeletedDocs
      )

      await manageDocs(
        formatPendingDocs,
        'workReportId',
        savedWorkReport,
        formatDeletedDocs
      )
      yield { _temp_id: work._temp_id, _id: savedWorkReport._id }
    } catch (err) {
      yield work._temp_id
    }
  }
}

export const saveWorkReports = async (
  ordinaryOperationId,
  workReports = [],
  pendingDocs = [],
  deletedDocs = []
) => {
  //
  const result = {
    ok: [],
    failed: []
  }

  const saveResults = safeCreateWorkReport(
    workReports,
    ordinaryOperationId,
    pendingDocs,
    deletedDocs
  )
  for await (const workResult of saveResults) {
    if (workResult._id) {
      result.ok.push(workResult)
    } else {
      result.failed.push(workResult)
    }
  }
  return result
  /*   return await Promise.all(
    workReports.map(async (workReport, i) => {
      const savedWorkReport = await app
        .service('operations/work-report')
        .create({ ...workReport, ordinaryOperationId })

      const associatedPendingDocs = pendingDocs.filter(
        doc => doc.dataPath[1] === i
      )
      const associatedDeletedDocs = deletedDocs.filter(
        doc => doc.dataPath[1] === i
      )

      const formatPendingDocs = removeFromDataPath(
        'parteTrabajos',
        associatedPendingDocs
      )
      const formatDeletedDocs = removeFromDataPath(
        'parteTrabajos',
        associatedDeletedDocs
      )

      await manageDocs(
        formatPendingDocs,
        'workReportId',
        savedWorkReport,
        formatDeletedDocs
      )

      return savedWorkReport
    })
  ) */
}

const safeUpdateWorkReport = async function*(
  workReports,
  pendingDocs = [],
  deletedDocs = []
) {
  for (const work of workReports) {
    try {
      const updatedWorkReport = await app
        .service('operations/work-report')
        .patch(work._id, work)

      const associatedPendingDocs = pendingDocs.filter(
        doc => doc.dataPath[1]._id === work._id
      )
      const associatedDeletedDocs = deletedDocs.filter(
        doc => doc.dataPath[1]._id === work._id
      )

      const formatPendingDocs = removeFromDataPath(
        'parteTrabajos',
        associatedPendingDocs
      )
      const formatDeletedDocs = removeFromDataPath(
        'parteTrabajos',
        associatedDeletedDocs
      )

      await manageDocs(
        formatPendingDocs,
        'workReportId',
        updatedWorkReport,
        formatDeletedDocs
      )
      yield { _id: work._id }
    } catch (err) {
      yield { error: { ...err }, _id: work._id }
    }
  }
}

export const updateWorkReports = async (
  workReports,
  pendingDocs = [],
  deletedDocs = []
) => {
  const result = {
    ok: [],
    failed: []
  }

  const updateResults = safeUpdateWorkReport(
    workReports,
    pendingDocs,
    deletedDocs
  )
  for await (const workResult of updateResults) {
    if (workResult.error) {
      result.failed.push(workResult)
    } else result.ok.push(workResult._id)
  }
  return result
}

export const safeDeleteWorkReports = async function*(workReports) {
  for (const work of workReports) {
    try {
      const id = get('_id', work)
      await app.service('operations/work-report').remove(id)
      yield id
    } catch (err) {
      console.error('Error removing work report', err)
    }
  }
}

export const deleteWorkReports = async workReports => {
  /**@type {string[]} */
  const result = []
  const deletedIds = await safeDeleteWorkReports(workReports)
  for await (const id of deletedIds) {
    result.push(id)
  }
  return result
}

export const getWorkReportDocs = arr =>
  arr.filter(doc => doc.dataPath[0] === 'parteTrabajos')

export const getNotWorkReportDocs = arr =>
  arr.filter(doc => doc.dataPath[0] !== 'parteTrabajos')

export function saveOrdinaryOperationWithAttachments(
  ordinaryOperationData,
  pendingDocs = [],
  deletedDocs = [],
  refetch = true
) {
  return async function(dispatch) {
    dispatch(saveOrdinaryOperationRequest())

    try {
      const ordinaryOp = Object.assign({}, ordinaryOperationData)
      const { parteTrabajos: arrWorkReports } = ordinaryOp
      delete ordinaryOp.parteTrabajos

      const workReportsPendingDocs = getWorkReportDocs(pendingDocs)
      const ordinaryOpPendingDocs = getNotWorkReportDocs(pendingDocs)
      const workReportsDeletedDocs = getWorkReportDocs(deletedDocs)
      const ordinaryOpDeletedDocs = getNotWorkReportDocs(deletedDocs)

      const savedOperation = await saveOrdinaryOperation(ordinaryOp)
      await manageDocs(
        ordinaryOpPendingDocs,
        'ordinaryOperationId',
        savedOperation,
        ordinaryOpDeletedDocs
      )

      const result = await saveWorkReports(
        savedOperation._id,
        arrWorkReports,
        workReportsPendingDocs,
        workReportsDeletedDocs
      )
      console.log('work reports result', result)
      if (refetch && result.failed.length === 0)
        dispatch(fetchOrdinaryOperationDetails(savedOperation._id, true))

      dispatch(saveOrdinaryOperationSuccess())
      return savedOperation
    } catch (error) {
      console.error('Error creating ordinary operation', error)
      if (error.code === 409) dispatch(saveOrdinaryOperationFailed(409))
      else dispatch(saveOrdinaryOperationFailed(error))
      throw error
    }
  }
}

const getPatchData = (data, modifiedPaths) => {
  const patchData = keys(modifiedPaths).reduce((acc, dotPath) => {
    return set(dotPath, prop(dotPath, data), acc)
  }, {})
  const shouldPatch = Boolean(size(patchData))
  return {
    patchData,
    shouldPatch
  }
}

export function updateOrdinaryOperationWithAttachments(
  ordinaryOp, //ordinaryOperationData,
  pendingDocs,
  deletedDocs,
  initialOrdinaryOp = {}, //initialData = {},
  modifiedWorkReportIds = {},
  modifiedPaths = {}
) {
  return async function(dispatch) {
    const {
      _id: ordinaryOperationId,
      parteTrabajos: finalPartesTrabajo,
      deletedItems
    } = ordinaryOp
    const { parteTrabajos: initialPartesTrabajo } = initialOrdinaryOp

    if (!ordinaryOperationId) {
      console.warn('Trying to update an ordinary operation without id')
      return
    }
    //delete ordinaryOp.parteTrabajos
    ordinaryOp.parteTrabajos = map(get('_id'), initialPartesTrabajo)
    const safeData = blackListProps(ordinaryOp)

    const workReportsToCreate = finalPartesTrabajo.filter(
      parteTrabajo => parteTrabajo._temp_id
    )

    const workReportsToUpdate = finalPartesTrabajo.filter(
      parteTrabajo =>
        parteTrabajo._id &&
        parteTrabajo.ordinaryOperationId &&
        modifiedWorkReportIds[parteTrabajo._id]
    )
    const workReportsToDelete = get('parteTrabajos', deletedItems) || []
    const workReportsPendingDocs = getWorkReportDocs(pendingDocs)
    const ordinaryOpPendingDocs = getNotWorkReportDocs(pendingDocs)
    const workReportsDeletedDocs = getWorkReportDocs(deletedDocs)
    const ordinaryOpDeletedDocs = getNotWorkReportDocs(deletedDocs)

    dispatch(updateOrdinaryOperationRequest())
    const { patchData, shouldPatch } = getPatchData(safeData, modifiedPaths)

    try {
      let updatedOperation = shouldPatch
        ? await app
            .service('operations/ordinary')
            .patch(ordinaryOperationId, patchData)
        : initialOrdinaryOp

      await manageDocs(
        ordinaryOpPendingDocs,
        'ordinaryOperationId',
        updatedOperation,
        ordinaryOpDeletedDocs
      )
      const result = await saveWorkReports(
        ordinaryOperationId,
        workReportsToCreate,
        workReportsPendingDocs,
        workReportsDeletedDocs
      )
      if (workReportsToUpdate.length > 0)
        result.updated = await updateWorkReports(
          workReportsToUpdate,
          workReportsPendingDocs,
          workReportsDeletedDocs
        )
      if (workReportsToDelete.length > 0)
        result.deleted = await deleteWorkReports(workReportsToDelete)

      console.log('update ordinary op result', result)
      if (
        result.failed.length === 0 &&
        (workReportsToUpdate.length === 0 ||
          result.updated.failed.length === 0) &&
        (workReportsToDelete.length === 0 ||
          result.deleted.length === workReportsToDelete.length)
      ) {
        console.log('Can refetch!')
        await dispatch(
          fetchOrdinaryOperationDetails(updatedOperation._id, true)
        )
      }

      dispatch(updateOrdinaryOperationSuccess())
      return { updatedOperation, result }
    } catch (error) {
      console.error('Error updating ordinary operation', error)
      if (error.code === 409) dispatch(saveOrdinaryOperationFailed(409))
      else dispatch(updateOrdinaryOperationFailed(error))
      throw error
    }
  }
}

export function createWorkReportFromAssetRequest() {
  return {
    type: ActionTypes.CREATE_WORK_REPORT_FROM_ASSET_REQUEST
  }
}

export function createWorkReportFromAssetSuccess() {
  return {
    type: ActionTypes.CREATE_WORK_REPORT_FROM_ASSET_SUCCESS
  }
}

export function createWorkReportFromAssetFailed(error) {
  return {
    type: ActionTypes.CREATE_WORK_REPORT_FROM_ASSET_FAILED,
    payload: error
  }
}

export function createWorkReportAcknowledgement() {
  return {
    type: ActionTypes.CREATE_WORK_REPORT_FROM_ASSET_ACKNOWLEDGEMENT
  }
}

export function createWorkReportFromAssetSetOptions(options) {
  return {
    type: ActionTypes.CREATE_WORK_REPORT_FROM_ASSET_SET_OPTIONS,
    payload: options
  }
}

export function createWorkReportFromAsset(
  assetId,
  ordinaryOperationId,
  workReportFamily
) {
  return async function(dispatch) {
    dispatch(createWorkReportFromAssetRequest())
    try {
      const result = await app
        .service('/operations/work-report-from-asset-type')
        .create({ assetId, ordinaryOperationId ,workReportFamily})
      if (result.created) {
        dispatch(createWorkReportFromAssetSuccess())
        await dispatch(
          fetchOrdinaryOperationDetails(result.ordinaryOperationId, true)
        )
      }
      if (!result.created && result.ordinaryOperations) {
        dispatch(createWorkReportFromAssetSetOptions(result.ordinaryOperations))
      }
      return result
    } catch (error) {
      console.error('Error creating work report', error)
      dispatch(createWorkReportFromAssetFailed(error))
      throw error
    }
  }
}

/* Extraordinary operation save/update */

export function saveExtraordinaryOperationRequest() {
  return {
    type: ActionTypes.SAVE_EXTRAORDINARY_OPERATION_REQUEST
  }
}

export function updateExtraordinaryOperationRequest() {
  return {
    type: ActionTypes.UPDATE_EXTRAORDINARY_OPERATION_REQUEST
  }
}

export function saveExtraordinaryOperationSuccess(newExtraordinaryOperation) {
  return {
    type: ActionTypes.SAVE_EXTRAORDINARY_OPERATION_SUCCESS,
    payload: newExtraordinaryOperation
  }
}

export function saveExtraordinaryOperationFailed(error) {
  return {
    type: ActionTypes.SAVE_EXTRAORDINARY_OPERATION_FAILED,
    payload: error
  }
}

export const removeExtraordinaryOperationError = () => ({
  type: ActionTypes.REMOVE_EXTRAORDINARY_OPERATION_ERROR
})

export function updateExtraordinaryOperationSuccess() {
  return {
    type: ActionTypes.UPDATE_EXTRAORDINARY_OPERATION_SUCCESS
  }
}

export function updateExtraordinaryOperationFailed(error) {
  return {
    type: ActionTypes.UPDATE_EXTRAORDINARY_OPERATION_FAILED,
    payload: error
  }
}

export function saveExtraordinaryOperation(
  extraordinaryOperationData,
  refetch = true
) {
  return async function(dispatch, getState) {
    const savedOperation = await app
      .service('operations/extraordinary')
      .create(extraordinaryOperationData)

    // update details by re-fetching
    if (refetch) {
      dispatch(fetchExtraordinaryOperationDetails(savedOperation._id, true))
    }

    return savedOperation
  }
}

export function saveExtraordinaryOperationWithAttachments(
  extraordinaryOperationData,
  pendingDocs,
  deletedDocs,
  refetch = true
) {
  return async function(dispatch) {
    dispatch(saveExtraordinaryOperationRequest())

    try {
      const savedOperation = await dispatch(
        saveExtraordinaryOperation(extraordinaryOperationData, false)
      )
      await Promise.all([
        ...pendingDocs.map(
          makeUploadDoc('extraordinaryOperationId', savedOperation)
        ),
        ...deletedDocs.map(removeAttachedFile)
      ])
      if (refetch) {
        dispatch(fetchExtraordinaryOperationDetails(savedOperation._id, true))
      }
      dispatch(saveExtraordinaryOperationSuccess())
      return savedOperation
    } catch (error) {
      console.error('Error creating extraordinary operation', error)
      if (error.code === 409) dispatch(saveExtraordinaryOperationFailed(409))
      else dispatch(saveExtraordinaryOperationFailed(error))
      throw error
    }
  }
}

export function updateExtraordinaryOperation(
  id,
  extraordinaryOperationData,
  refetch = true
) {
  return async function(dispatch) {
    //const extraordinaryOperationId = extraordinaryOperationData._id
    const safeData = blackListProps(extraordinaryOperationData)
    if (!id) {
      console.warn('Trying to update an extraordinary operation without id')
      return
    }

    const updatedOperation = await app
      .service('operations/extraordinary')
      .patch(id, safeData)

    // update details by re-fetching
    if (refetch) {
      dispatch(fetchExtraordinaryOperationDetails(id, true))
    }

    return updatedOperation
  }
}

export function updateExtraordinaryOperationWithAttachments(
  extraordinaryOperationData,
  pendingDocs,
  deletedDocs,
  modifiedPaths,
  refetch = true
) {
  return async function(dispatch) {
    dispatch(updateExtraordinaryOperationRequest())
    const id = extraordinaryOperationData._id
    const { patchData, shouldPatch } = getPatchData(
      extraordinaryOperationData,
      modifiedPaths
    )
    try {
      const updatedOperation = shouldPatch
        ? await dispatch(updateExtraordinaryOperation(id, patchData, false))
        : extraordinaryOperationData

      await Promise.all([
        ...pendingDocs.map(
          makeUploadDoc('extraordinaryOperationId', updatedOperation)
        ),
        ...deletedDocs.map(removeAttachedFile)
      ])
      if (refetch) {
        dispatch(fetchExtraordinaryOperationDetails(updatedOperation._id, true))
      }
      dispatch(updateExtraordinaryOperationSuccess())
      return updatedOperation
    } catch (error) {
      console.error('Error updating ordinary operation', error)
      if (error.code === 409) dispatch(saveExtraordinaryOperationFailed(409))
      else dispatch(updateExtraordinaryOperationFailed(error))
      throw error
    }
  }
}

/* Work operation save/update */

export function saveWorkOperationRequest() {
  return {
    type: ActionTypes.SAVE_WORK_OPERATION_REQUEST
  }
}

export function updateWorkOperationRequest() {
  return {
    type: ActionTypes.UPDATE_WORK_OPERATION_REQUEST
  }
}

export function saveWorkOperationSuccess(newWorkOperation) {
  return {
    type: ActionTypes.SAVE_WORK_OPERATION_SUCCESS,
    payload: newWorkOperation
  }
}

export function saveWorkOperationFailed(error) {
  return {
    type: ActionTypes.SAVE_WORK_OPERATION_FAILED,
    payload: error
  }
}

export const removeWorkOperationError = () => ({
  type: ActionTypes.REMOVE_WORK_OPERATION_ERROR
})

export function updateWorkOperationSuccess() {
  return {
    type: ActionTypes.UPDATE_WORK_OPERATION_SUCCESS
  }
}

export function updateWorkOperationFailed(error) {
  return {
    type: ActionTypes.UPDATE_WORK_OPERATION_FAILED,
    payload: error
  }
}

export function saveWorkOperation(workOperationData, refetch = true) {
  return async function(dispatch, getState) {
    const savedOperation = await app
      .service('operations/work')
      .create(workOperationData)

    // update details by re-fetching
    if (refetch) {
      dispatch(fetchWorkOperationDetails(savedOperation._id, true))
    }

    return savedOperation
  }
}

export function saveWorkOperationWithAttachments(
  workOperationData,
  pendingDocs,
  deletedDocs,
  refetch = true
) {
  return async function(dispatch) {
    dispatch(saveWorkOperationRequest())

    try {
      const savedOperation = await dispatch(
        saveWorkOperation(workOperationData, false)
      )
      await Promise.all([
        ...pendingDocs.map(makeUploadDoc('workOperationId', savedOperation)),
        ...deletedDocs.map(removeAttachedFile)
      ])
      if (refetch) {
        dispatch(fetchWorkOperationDetails(savedOperation._id, true))
      }
      dispatch(saveWorkOperationSuccess())
      return savedOperation
    } catch (error) {
      console.error('Error creating work operation', error)
      if (error.code === 409) dispatch(saveWorkOperationFailed(409))
      else dispatch(saveWorkOperationFailed(error))
      throw error
    }
  }
}

export function updateWorkOperation(id, workOperationData, refetch = true) {
  return async function(dispatch, getState) {
    const safeData = blackListProps(workOperationData)

    if (!id) {
      console.warn('Trying to update an work operation without id')
      return
    }

    const updatedOperation = await app
      .service('operations/work')
      .patch(id, safeData)

    // update details by re-fetching
    if (refetch) {
      dispatch(fetchWorkOperationDetails(id, true))
    }

    return updatedOperation
  }
}

export function updateWorkOperationWithAttachments(
  workOperationData,
  pendingDocs,
  deletedDocs,
  modifiedPaths,
  refetch = true
) {
  return async function(dispatch) {
    dispatch(updateWorkOperationRequest())
    const { patchData, shouldPatch } = getPatchData(
      workOperationData,
      modifiedPaths
    )

    try {
      const updatedOperation = shouldPatch
        ? await dispatch(
            updateWorkOperation(workOperationData._id, patchData, false)
          )
        : workOperationData

      await Promise.all([
        ...pendingDocs.map(makeUploadDoc('workOperationId', updatedOperation)),
        ...deletedDocs.map(removeAttachedFile)
      ])
      if (refetch) {
        dispatch(fetchWorkOperationDetails(updatedOperation._id, true))
      }
      dispatch(updateWorkOperationSuccess())
      return updatedOperation
    } catch (error) {
      console.error('Error updating ordinary operation', error)
      if (error.code === 409) dispatch(saveWorkOperationFailed(409))
      else dispatch(updateWorkOperationFailed(error))
      throw error
    }
  }
}

/* Common */

const removeAttachedFile = ({ _id = 'NEEDS_ID!' }) =>
  app.service('attachedFiles').remove(_id)
