import { buildReducer } from '../utils'
import * as ActionTypes from './actionTypes'
import assetTypeGroupConfig from '../../core/assets/assetTypeGroupConfig'
export * from './actions'
export * from './selectors'

const initialState = {
  data: [],
  isLoading: false,
  lastFetchBounds: null,
  hasError: false,
  lastErrorMessage: '',
  selectedAssetTypes: [],
  focusedAssetId: null,
  assetTypeGroups: assetTypeGroupConfig,
  draggableAssetId: null,
  draggableCurrentGeometry: null,
  draggableOriginalGeometry: null
}

function updateAssetGeometry(assets = [], assetId, { newGeometry }) {
  return assets.map(asset => {
    if (asset._id === assetId) {
      return {
        ...asset,
        geometry: newGeometry
      }
    }
    return asset
  })
}

export default buildReducer(initialState, {
  [ActionTypes.FETCH_ASSETS_REQUEST]: state => {
    return {
      ...state,
      isLoading: true,
      hasError: false
    }
  },
  [ActionTypes.FETCH_ASSETS_FAILED]: (state, action) => {
    return {
      ...state,
      isLoading: false,
      hasError: true,
      lastErrorMessage: action.error
    }
  },
  [ActionTypes.FETCH_ASSETS_SUCCESS]: (state, action) => {
    const { data, bounds } = action.payload
    return {
      ...state,
      lastFetchBounds: bounds,
      hasError: false,
      isLoading: false,
      data: data
    }
  },
  [ActionTypes.ASSET_ADDED]: (state, action) => {
    const isAssetPresent = state.data.find(x => x._id === action.payload._id)
    if (isAssetPresent) return state
    return {
      ...state,
      data: [...state.data, action.payload]
    }
  },
  [ActionTypes.STORE_ASSET_TREE]: (state, action) => {
    return {
      ...state,
      assetTypeGroups: action.payload
    }
  },
  [ActionTypes.CLEAR_SELECTED_TYPES]: state => {
    return {
      ...state,
      selectedAssetTypes: []
    }
  },
  [ActionTypes.SET_ITEM_CHECKED]: (state, action) => {
    const { itemIds, isChecked } = action.payload

    const newSelectedAssetTypes = isChecked
      ? [...state.selectedAssetTypes, ...itemIds]
      : state.selectedAssetTypes.filter(
          assetType => !itemIds.includes(assetType)
        )

    return {
      ...state,
      selectedAssetTypes: newSelectedAssetTypes
    }
  },
  [ActionTypes.SET_ASSET_FOCUS]: (state, action) => {
    const { id } = action
    return {
      ...state,
      focusedAssetId: id
    }
  },
  // move asset
  [ActionTypes.ASSET_DRAG_START]: (state, action) => {
    const { assetId, geometry } = action.payload
    return {
      ...state,
      draggableAssetId: assetId,
      draggableCurrentGeometry: geometry,
      draggableOriginalGeometry: geometry
    }
  },
  [ActionTypes.ASSET_DRAG_UPDATE]: (state, action) => {
    const { assetId, geometry } = action.payload
    return {
      ...state,
      draggableCurrentGeometry:
        assetId === state.draggableAssetId
          ? geometry
          : state.draggableCurrentGeometry
    }
  },
  [ActionTypes.ASSET_DRAG_END]: (state, action) => {
    const { assetId, newGeometry } = action.payload
    return {
      ...state,
      data: updateAssetGeometry(state.data, assetId, { newGeometry }),
      draggableAssetId: null,
      draggableCurrentGeometry: null,
      draggableOriginalGeometry: null
    }
  },
  [ActionTypes.ASSET_REMOVED]: (state, action) => {
    const { assetId } = action
    return {
      ...state,
      data: state.data.filter(x => x._id !== assetId)
    }
  }
})
