import { path, propOr } from 'lodash/fp'
import { defaultListState, MAX_CACHE_AGE } from './constants'
import { REQUEST_STATUS } from '../constants'

const toLocal = path(['simpleList'])

const getListState = (list, state) =>
  propOr(defaultListState, [list], toLocal(state))

export const getRequestStatus = (list, state) =>
  propOr(
    REQUEST_STATUS.NotRequested,
    ['simpleList', list, 'requestStatus'],
    state
  )
export const isListCached = (list, state) => {
  const lastFetched = propOr(0, ['lastFetched'], getListState(list, state))
  return lastFetched + MAX_CACHE_AGE >= Date.now()
}

export const getListItems = (list, state) => {
  const listState = getListState(list, state)
  return propOr([], ['data'], listState)
}
