import { isConflictError, isService } from './utils'

const isAssetService = isService('assets')
const isCodigoError = ctx => /codigo/.test(ctx.error.message)

export const handleUpdateError = ctx => {
  console.log('Error saving asset', ctx.error)
  if (isConflictError(ctx) && isAssetService(ctx)) {
    if (isCodigoError(ctx)) {
      alert('Error al guardar el activo: el código ya existe')
    } else {
      alert('Ocurrió un error al guardar el activo')
    }
  }
  return ctx
}
