//@ts-check
import { pathOr, keys } from 'lodash/fp'
/**
 * Contains the relationship between Concesionaria -> Autopista(s) -> Comunidad Autónoma -> Provincia
 * The top level object contains concesionaria names as keys. Its value are objects with one key per Autopista.
 * Its children are itself a map of Comunidad Autónoma to one or more Provincias.
 *
 * {
 *    CompanyName: {
 *      Highway: {
 *        State: ['Province1', 'Province2']
 *      }
 *    }
 * }
 */
const CompanyMap = {
  ACESA: {
    AP02: {
      Aragón: ['Zaragoza', 'Huesca'],
      Cataluña: ['Lleida', 'Tarragona']
    },
    '0B23': {
      Cataluña: ['Barcelona']
    },
    AP07: {
      Cataluña: ['Barcelona', 'Girona', 'Tarragona', 'Lleida']
    },
    LL12: {
      Cataluña: ['Lleida']
    }
  },
  INVICAT: {
    '0C31': {
      Cataluña: ['Barcelona']
    },
    C32N: {
      Cataluña: ['Barcelona']
    },
    '0C33': {
      Cataluña: ['Barcelona']
    }
  },
  AUCAT: {
    C32A: {
      Cataluña: ['Barcelona', 'Tarragona']
    }
  },
  CASTELLANA: {
    AP51: {
      'Castilla y León': ['Segovia', 'Ávila']
    },
    AP06: {
      'Comunidad de Madrid': ['Madrid'],
      'Castilla y León': ['Segovia', 'Ávila']
    },
    AP61: {
      'Castilla y León': ['Segovia']
    }
  },
  AULESA: {
    AP71: {
      'Castilla y León': ['León']
    }
  },
  AVASA: {
    AP68: {
      'País Vasco': ['Álava', 'Vizcaya'],
      'Castilla y León': ['Burgos'],
      'La Rioja': ['Logroño'],
      Navarra: ['Navarra'],
      Aragón: ['Zaragoza']
    }
  },
  TÚNELS: {
    '0C16': {
      Cataluña: ['Barcelona', 'Lleida', 'Gerona']
    }
  },
  AUMAR: {
    AP7N: {
      'Comunidad Valenciana': ['Castellón', 'Valencia'],
      Cataluña: ['Tarragona']
    },
    AP7S: {
      'Comunidad Valenciana': ['Valencia', 'Alicante']
    },
    V021: {
      'Comunidad Valenciana': ['Valencia']
    },
    AP04: {
      Andalucía: ['Sevilla', 'Cádiz']
    }
  },
  TRADOS45: {
    '0M45': { Madrid: ['Madrid'] }
  }
}

/**
 * @typedef {object} CompanySpec A specification "bag" for properties to be used for searching.
 * @property {string} company
 * @property {string} [highway]
 * @property {string} [state]
 * @property {string} [province]
 * @property {string} [municipality]
 */

/**
 * Returns the list of companies
 * @returns {string[]} List of companies
 */

export function getCompanies() {
  return Object.keys(CompanyMap)
}

/**
 * Returns the list of highways belonging to a company
 * @param {CompanySpec} spec
 */
export function getHighways(spec) {
  const company = CompanyMap[spec.company]
  if (!company) return []
  return Object.keys(company)
}
/**
 * Returns a list of states for company / highway specification
 * @param {CompanySpec} spec
 */
export function getStates(spec) {
  return keys(pathOr({}, [spec.company, spec.highway], CompanyMap))
}
/**
 * Returns a list of provinces given a company / highway / state specification
 * @param {CompanySpec} spec
 */
export function getProvinces(spec) {
  return pathOr([], [spec.company, spec.highway, spec.state], CompanyMap).sort()
}
