import app from '../../feathers'

const decodeToken = (feathers = app) => token =>
  feathers.passport.verifyJWT(token)

export const authenticateWithExistingToken = (feathers = app) => {
  const decoder = decodeToken(feathers)
  // instead of trying to create a new token, try to use a existing one
  // directly
  return feathers.passport.getJWT().then(decoder)
}

export const authenticateWithToken = (token, feathers = app) => {
  return feathers
    .authenticate({ strategy: 'jwt', accessToken: token })
    .then(decodeToken(feathers))
}

export const logout = () => {
  return app.logout()
}

export const fetchUserData = (userId, feathers = app) =>
  feathers.service('users').get(userId)
