const ID = 'GMAP'
// setup and grab handler
const INIT_MAP = `${ID}/INIT`
const DISPOSE_MAP = `${ID}/DISPOSE`
// from redux to map
const SET_ZOOM = `${ID}/SET_ZOOM`
const SET_CENTER = `${ID}/SET_CENTER`
// from map to redux
export const BOUNDS_CHANGED = `${ID}/BOUNDS_CHANGED`
export const ZOOM_CHANGED = `${ID}/ZOOM_CHANGED`

export const initMap = gmap => ({
  type: INIT_MAP,
  payload: gmap
})

export const disposeMap = gmap => ({
  type: DISPOSE_MAP,
  payload: gmap
})

export const setMapZoom = zoom => ({
  type: SET_ZOOM,
  zoom
})

export const setMapCenter = center => ({
  type: SET_CENTER,
  center
})

export const storeMapBounds = bounds => ({
  type: BOUNDS_CHANGED,
  bounds
})

export const storeMapZoom = zoom => ({
  type: ZOOM_CHANGED,
  zoom
})

const gmapMiddleware = store => {
  let map = null
  const queue = []

  function flushQueue() {
    queue.forEach(action => {
      const { fn, args } = action
      map[fn].apply(map, args)
    })
  }

  return next => action => {
    switch (action.type) {
      case INIT_MAP:
        map = action.payload
        flushQueue()
        break
      case DISPOSE_MAP:
        map = null
        break
      case SET_ZOOM:
        map && map.getZoom() !== action.zoom
          ? map.setZoom(action.zoom)
          : queue.push({ fn: 'setZoom', args: [action.zoom] })
        break
      case SET_CENTER:
        map
          ? map.panTo(action.center)
          : queue.push({ fn: 'panTo', args: [action.center] })
        break
      /*  case BOUNDS_CHANGED:
         break
       case ZOOM_CHANGED:
         break */
      default:
        next(action)
    }
  }
}

export default gmapMiddleware
