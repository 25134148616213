export const REQUEST_STATUS = {
  NotRequested: 'NotRequested',
  Loading: 'Loading',
  Success: 'Success',
  Failed: 'Failed'
}

export const CACHE_DURATION = {
  OneHour: 60 * 1000 * 1000,
  TwoHour: 120 * 1000 * 1000,
  OneDay: 24 * 60 * 1000 * 1000,
  OneWeek: 7 * 24 * 60 * 1000 * 1000
}
