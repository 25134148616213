const MODULE = 'LOCATE'

export const SAVE_CONNECTIONS = `${MODULE}/SAVE_CONNECTIONS`
export const SAVE_FAV_LOCATIONS = `${MODULE}/SAVE_FAV_LOCATIONS`
export const SAVE_NEW_FAV_LOC_NAME = `${MODULE}/SAVE_NEW_FAV_LOC_NAME`
export const SHOW_DELETE_FAV_LOCATIONS_CONFIRM = `${MODULE}/SHOW_DELETE_FAV_LOCATIONS_CONFIRM`
export const SET_FAV_LOC_TO_DELETE = `${MODULE}/SET_FAV_LOC_TO_DELETE`
export const SAVE_HIGHWAY_PKS = `${MODULE}/SAVE_HIGHWAY_PKS`
export const CLEAR_FILTERS = `${MODULE}/CLEAR_FILTERS`
export const SET_FILTER_VALUE = `${MODULE}/SET_FILTER_VALUE`
export const SET_MODAL_VALUES = `${MODULE}/SET_MODAL_VALUES`
export const SET_FILTERS_FROM_SELECTED_CONN = `${MODULE}/SET_FILTERS_FROM_SELECTED_CONN`
export const SET_FILTERS = `${MODULE}/SET_FILTERS`
export const SAVE_POSITION = `${MODULE}/SAVE_POSITION`
export const LOCATION_ADDED = `${MODULE}/LOCATION_ADDED`
export const LOCATION_REMOVED = `${MODULE}/LOCATION_REMOVED`
