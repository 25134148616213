import TYPES from './assetTypes'

/*
  Styles for linear or polygon geometries
  Point geometries will be shown as "Markers" with an icon
  but lines or polygons will be represented with shapes
  See https://developers.google.com/maps/documentation/javascript/datalayer#style_options

*/

const commonStyles = {
  strokeWidth: 4
}

const assetStyles = {
  drenaje: {
    assetTypes: [
      TYPES.ARQUETAS_POZOS,
      TYPES.CUNETAS,
      TYPES.CAZ,
      TYPES.BORDILLOS,
      TYPES.BAJANTES,
      TYPES.OTDL,
      TYPES.COLECTORES_LONG
    ],
    style: {
      strokeColor: '#0097F9'
    }
  },
  cerramiento: {
    assetTypes: [TYPES.VALLA_CERRAMIENTO],
    style: {
      strokeColor: '#C72122'
    }
  },
  contencion: {
    assetTypes: [TYPES.BARRERA_METALICA, TYPES.BARRERA_HORMIGON, TYPES.PRETIL],
    style: {
      strokeColor: '#0BC96B'
    }
  },
  pantallas: {
    assetTypes: [TYPES.PANTALLAS_ANTIRRUIDO],
    style: {
      strokeColor: '#693EA7'
    }
  },
  horizontal: {
    assetTypes: [TYPES.SENALIZACION_HORIZONTAL],
    style: {
      strokeColor: '#404040'
    }
  },
  balizamiento: {
    assetTypes: [TYPES.BALIZAMIENTO],
    style: {
      strokeColor: '#8FA4AE'
    }
  },
  pasosMediana: {
    assetTypes: [TYPES.PASOS_MEDIANA],
    style: {
      strokeColor: '#E03A97'
    }
  },
  firmes: {
    assetTypes: [TYPES.FIRMES],
    style: {
      strokeColor: '#E0B517'
    }
  },
  peajes: {
    assetTypes: [TYPES.VIAS_PEAJES],
    style: {
      strokeColor: '#146CB1',
      fillColor: '#146CB1',
      fillOpacity: 0.8
    }
  },
  estructuras_odt: {
    assetTypes: [
      TYPES.VPP,
      TYPES.ODT,
      TYPES.POP,
      TYPES.PORTICOS,
      TYPES.BANDEROLAS,
      TYPES.MUROS,
      TYPES.DESMONTES,
      TYPES.TERRAPLENES,
      TYPES.TUNELES
    ],
    style: {
      strokeColor: '#000',
      fillColor: '#1461A8'
    }
  }
}

// given an asset type, find proper styles for its geometry
export function getStylesForAssetType(assetType) {
  const matchingGroup = Object.keys(assetStyles).find(group => {
    return Boolean(~assetStyles[group].assetTypes.indexOf(assetType))
  })
  return matchingGroup
    ? {
        ...commonStyles,
        ...assetStyles[matchingGroup].style
      }
    : commonStyles
}
