import { combineReducers } from 'redux'
import mapView from './mapview'
import layout from './layout'
import assets from './assets'
import axes from './axes'
import asset_details from './asset_details'
import ordinary_operation_details from './ordinary_operation_details'
import extraordinary_operation_details from './extraordinary_operation_details'
import work_operation_details from './work_operation_details'
import collapsables from './collapsables'
import asset_list from './asset_list'
import asset_create from './asset_create'
import queries from './queries'
import session from './session'
import locate from './locate'
import cecos from './cecos'
import documents from './documents'
import axesAdmin from './admin/axes'
import simpleList from './simple-list'
import pks from './pks'

export default combineReducers({
  axes,
  mapView,
  layout,
  assets,
  asset_details,
  ordinary_operation_details,
  extraordinary_operation_details,
  work_operation_details,
  collapsables,
  asset_list,
  asset_create,
  queries,
  session,
  locate,
  cecos,
  documents,
  axesAdmin,
  simpleList,
  pks
})
