import { compact } from 'lodash/fp'
import * as ActionTypes from './actionTypes'
import { getSubQueryType, getModel } from './selectors'
import { search, searchWorkReportsByFamily } from './api'
import { buildQueryFilters } from './utils'
import { commonWorkReportsColumnFields } from '../../core/queries/workReportFields'

export const setQueryType = queryType => ({
  type: ActionTypes.SET_QUERY_TYPE,
  queryType
})

export const setAssetType = subQueryType => ({
  type: ActionTypes.SET_SUB_QUERY_TYPE,
  subQueryType
})

export const setMode = mode => ({
  type: ActionTypes.SET_MODE,
  mode
})

export const addFields = fields => ({
  type: ActionTypes.ADD_FIELDS,
  fields
})

export const removeFields = fields => ({
  type: ActionTypes.REMOVE_FIELDS,
  fields
})

export const clearFields = () => ({
  type: ActionTypes.CLEAR_FIELDS
})

export const updateFilters = (fieldName, filter) => ({
  type: ActionTypes.UPDATE_FILTERS,
  payload: {
    fieldName,
    filter
  }
})

export const removeFilter = fieldName => ({
  type: ActionTypes.REMOVE_FILTER,
  payload: { fieldName }
})

export const clearFilters = () => ({
  type: ActionTypes.CLEAR_FILTERS
})

export const setSearchCount = count => ({
  type: ActionTypes.SET_RESULTS_COUNT,
  count
})

export const fetchRequest = () => ({
  type: ActionTypes.FETCH_ASSETS_REQUEST
})

export const fetchFailed = () => ({
  type: ActionTypes.FETCH_ASSETS_FAILED
})

export const fetchSuccess = () => ({
  type: ActionTypes.FETCH_ASSETS_SUCCESS
})

export const setWorkReportColumnFields = columnFields => ({
  type: ActionTypes.SET_WORK_REPORT_COLUMN_FIELDS,
  columnFields
})

export const addColumns = columns => ({
  type: ActionTypes.ADD_COLUMNS,
  columns
})

export const removeColumns = columns => ({
  type: ActionTypes.REMOVE_COLUMNS,
  columns
})

export const clearColumns = () => ({
  type: ActionTypes.CLEAR_COLUMNS
})

export const saveSearchResults = result => ({
  type: ActionTypes.SET_RESULT,
  result
})

export const setCurrentPage = currentPage => ({
  type: ActionTypes.SET_CURRENT_PAGE,
  currentPage
})

export const setResultsPerPage = resultsPerPage => ({
  type: ActionTypes.SET_RESULTS_PER_PAGE,
  resultsPerPage
})

export const isExporting = isExporting => ({
  type: ActionTypes.HAS_EXPORTED,
  isExporting
})

export const setExportType = exportType => ({
  type: ActionTypes.SET_EXPORT_TYPE,
  exportType
})

export const setshowReadableColumns = showReadableColumns => ({
  type: ActionTypes.SET_SHOW_READABLE_COLUMNS,
  showReadableColumns
})

/*
  sc = string conditions
  nc = number conditions
  dc = date conditions
*/
export const searchAssetsWithFilters = (
  filters = {},
  columns = [],
  dispatchActions = true
) => {
  return async function(dispatch, getState) {
    dispatch(fetchRequest())
    dispatch(saveSearchResults([]))

    const subQueryType = getSubQueryType(getState())

    const onlyCount = columns.length === 0
    try {
      const model = getModel(getState())
      const queryFilters = buildQueryFilters(filters, model, subQueryType)
      const results = await search(
        model,
        queryFilters,
        compact(columns),
        onlyCount
      )

      if (dispatchActions) {
        if (onlyCount) dispatch(setSearchCount(results || 0))
        else dispatch(saveSearchResults(results))
      }

      dispatch(fetchSuccess())
      return results
    } catch (err) {
      dispatch(fetchFailed())
      console.warn('Error with searchAssetsWithFilters', err)
      return onlyCount ? 0 : []
    }
  }
}


export const searchWorkReports = (filters = {}, count = false) => {
  return async function(dispatch, getState) {
    dispatch(fetchRequest())
    const subQueryType = getSubQueryType(getState())
    try {
      dispatch(setWorkReportColumnFields([]))
      dispatch(addColumns([]))
      dispatch(saveSearchResults([]))
      const { beforeUnwind } = buildQueryFilters(filters, '', subQueryType)
      const {
        results,
        workReportColumnFields,
        numResults
      } = await searchWorkReportsByFamily(subQueryType, beforeUnwind, count)

      if (count) dispatch(setSearchCount(numResults || 0))
      else {
        const columnFields = commonWorkReportsColumnFields.concat(
          ...workReportColumnFields
        )
        dispatch(setWorkReportColumnFields(columnFields))
        dispatch(addColumns(columnFields.map(f => f.name)))
        dispatch(saveSearchResults(results))
      }
      dispatch(fetchSuccess())
    } catch (err) {
      dispatch(setWorkReportColumnFields([]))
      dispatch(saveSearchResults([]))
      dispatch(addColumns([]))
      dispatch(fetchFailed())
      console.warn('Error with searchWorkReports', err)
    }
  }
}
