import feathers from '../../feathers'

export const fetchAxes = (query, app = feathers) => {
  return app.service('axes').find(query)
}

export const fetchAxesList = (app = feathers) => {
  return app.service('axes').find({
    query: {
      $select: ['name', 'direction', 'highway']
    }
  })
}
