const MODULE = 'ASSETS'

export const FETCH_ASSETS_REQUEST = `${MODULE}/FETCH_ASSETS_REQUEST`
export const FETCH_ASSETS_SUCCESS = `${MODULE}/FETCH_ASSETS_SUCCESS`
export const FETCH_ASSETS_FAILED = `${MODULE}/FETCH_ASSETS_FAILED`
export const SINGLE_ASSET_ADDED = `${MODULE}/SINGLE_ASSET_ADDED`

export const STORE_ASSET_TREE = `${MODULE}/STORE_ASSET_TREE`
export const CLEAR_SELECTED_TYPES = `${MODULE}/CLEAR_SELECTED_TYPES`
export const SET_ITEM_CHECKED = `${MODULE}/SET_ITEM_CHECKED`

export const SET_ASSET_FOCUS = `${MODULE}/SET_ASSET_FOCUS`
export const ASSET_ADDED = `${MODULE}/ASSET_ADDED`
export const ASSET_REMOVED = `${MODULE}/ASSET_REMOVED`

export const ASSET_DRAG_START = `${MODULE}/ASSET_DRAG_START`
export const ASSET_DRAG_UPDATE = `${MODULE}/ASSET_DRAG_UPDATE`
export const ASSET_DRAG_END = `${MODULE}/ASSET_DRAG_END`
export const ASSET_DRAG_CANCEL = `${MODULE}/ASSET_DRAG_CANCEL`
