import {
  getPKFamily,
  getAppearanceField,
  getMedUnidClearingFields,
  getCleanStartEndPKFields,
  getEntryValStatusField,
  getCommonDrenajeFields,
  getCommonExecFields,
  getCommonInspectionFields,
  getCommonValidationFields
} from './commonFields'

const drenajesProfundosFields = [
  ...getPKFamily('drenajesProfundos'),
  ...getCommonDrenajeFields('drenajesProfundos'),
  ...getAppearanceField('drenajesProfundos'),
  ...getCommonInspectionFields('drenajesProfundos'),
  ...getMedUnidClearingFields('drenajesProfundos'),
  ...getCleanStartEndPKFields('drenajesProfundos'),
  ...getCommonExecFields('drenajesProfundos'),
  ...getEntryValStatusField('drenajesProfundos'),
  ...getCommonValidationFields('drenajesProfundos')
]

export const getDrenajeProfundoFields = () => drenajesProfundosFields
