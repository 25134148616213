import {
  getPKFamily,
  getStartEndPKFields,
  getVallaCierreInspectionFields,
  // getStartCoordField,
  getWayField,
  getVallaCierreExecFields,
  getCommonInspectionFields,
  getCommonExecFields,
  getCommonValidationFields
} from './commonFields'

const intervencionesVallaCierreFields = [
  ...getPKFamily('intervencionesVallaCierre'),
  ...getStartEndPKFields('intervencionesVallaCierre'),
  ...getVallaCierreInspectionFields('intervencionesVallaCierre'),
  ...getCommonInspectionFields('intervencionesVallaCierre'),
  // ...getStartCoordField('intervencionesVallaCierre'),
  ...getWayField('intervencionesVallaCierre'),
  ...getVallaCierreExecFields('intervencionesVallaCierre'),
  ...getCommonExecFields('intervencionesVallaCierre'),
  ...getCommonValidationFields('desbrocesTaludes')
]

export const getVallaCierreFields = () => intervencionesVallaCierreFields
