import TYPES from './assetTypes'
export const assetTypeLabels = {
  [TYPES.VPP]: 'Viaductos puentes pasarelas',
  [TYPES.ODT]: 'Obras drenaje transversal',
  [TYPES.POP]: 'Pequeñas obras de paso',
  [TYPES.PORTICOS]: 'Pórticos',
  [TYPES.BANDEROLAS]: 'Banderolas',
  [TYPES.MUROS]: 'Muros',
  [TYPES.ARQUETAS_POZOS]: 'Arquetas y pozos',
  [TYPES.CUNETAS]: 'Cuneta',
  [TYPES.CAZ]: 'CAZ',
  [TYPES.BORDILLOS]: 'Bordillo',
  [TYPES.BAJANTES]: 'Bajante',
  [TYPES.VALLA_CERRAMIENTO]: 'Valla cerramiento',
  [TYPES.BARRERA_METALICA]: 'Barrera metálica',
  [TYPES.BARRERA_HORMIGON]: 'Barrera de hormigón',
  [TYPES.PRETIL]: 'Pretil',
  [TYPES.PANTALLAS_ANTIRRUIDO]: 'Pantalla antirruido',
  [TYPES.SENALIZACION_HORIZONTAL]: 'Señalización horizontal',
  [TYPES.SENALIZACION_VERTICAL]: 'Señalización vertical',
  [TYPES.BALIZAMIENTO]: 'Balizamiento',
  [TYPES.PASOS_MEDIANA]: 'Pasos de mediana',
  [TYPES.VIAS_PEAJES]: 'Vías de Peaje',
  [TYPES.FIRMES]: 'Firmes',
  [TYPES.CAMARAS]: 'Cámaras',
  [TYPES.ESTACION_METEO]: 'Estación meteorológica',
  [TYPES.PMV]: 'Paneles de Mensajería Variable',
  [TYPES.POSTES_SOS]: 'Poste SOS',
  [TYPES.DESMONTES]: 'Desmontes',
  [TYPES.TERRAPLENES]: 'Terraplenes',
  [TYPES.TUNELES]: 'Túneles',
  [TYPES.BACULOS]: 'Báculos'

  // Actuaciones
  //   [TYPES.OBRAS]: 'Obras',
  //   [TYPES.CONSERVACION_ORDINARIA]: 'Conservación Ordinaria',
  //   [TYPES.CONSERVACION_EXTRAORDINARIA]: 'Conservación Extraordinaria'
}

export const operationsTypeLabels = {
  [TYPES.OBRAS]: 'Obras',
  [TYPES.CONSERVACION_ORDINARIA]: 'Conservación Ordinaria',
  [TYPES.CONSERVACION_EXTRAORDINARIA]: 'Conservación Extraordinaria'
}

export const allTypesLabels = {
  ...assetTypeLabels,
  ...operationsTypeLabels
}

// export function getLabelForAssetType(assetType) {
//   if (!TYPES[assetType]) {
//     throw new Error('getLabelForAssetType - unknown asset type ' + assetType)
//   }
//   return assetTypeLabels[assetType] || 'Elemento desconocido'
// }

export function getLabelForType(elementType) {
  if (!TYPES[elementType]) {
    throw new Error('getLabelForType - unknown asset type ' + elementType)
  }
  if (!allTypesLabels[elementType]) {
    console.warn('Missing label for AssetType', elementType)
  }
  return allTypesLabels[elementType] || 'Elemento desconocido'
}
