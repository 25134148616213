import { FIELD_CARD_TYPES } from '../../modules/queries/constants'
import {
  REGISTRO_GENERAL,
  INDICADORES,
  getCommonGeneralInfo,
  getConceptoGeneral,
  getPrevSAPySegSidebarFields,
  getPrevSAPySegColumnsFields
} from './commonOperationFields'

export const SEGUIMIENTO = 'seguimiento'
export const FINALIZACION = 'finalizacion'

const TECNICO = 'tecnico'
const CONTROL_MATERIALES = 'controlMateriales'
const INCIDENCIA_OBRA = 'incidenciasObra'
const VISITAS = 'visitas'
const RECEP_OBRA = 'recepcionObra'
const PLAZO_GARANTIA = 'plazoGarantia'
const LIQUIDACIONES = 'liquidaciones'

/* In the query section we need different fields if we are creating the sidebar or the select
 ** columns section that's why we have different methods. If the fields are the same in both
 ** cases, only appear 'Fields' in the method's names */

export const getWorkOpSidebarFields = () => ({
  [REGISTRO_GENERAL]: [
    ...getDatosGenSidebarFields(),
    ...getUbicacionesSidebarFields(),
    ...getContratistaFields(),
    ...getDireccionDeObraFields(),
    ...getAperturaObraSidebarFields()
  ],
  [SEGUIMIENTO]: [
    ...getPlanificacionSidebarFields(),
    ...getAVOsSidebarFields(),
    ...getRevisionDePreciosSidebarFields(),
    ...getPrevSAPySegSidebarFields(`${SEGUIMIENTO}.previsionSeguimiento`),
    ...getSegTecnicoSidebarFields()
  ],
  [FINALIZACION]: [
    ...getRecepcionObraSidebarFields(),
    ...getPlazoDeGarantiaSidebarFields(),
    ...getLiquidacionesFields()
  ],
  [INDICADORES]: getIndicadoresFields()
})

export const getWorkOpColumnsFields = () => ({
  [REGISTRO_GENERAL]: [
    ...getDatosGenColumnsFields(),
    ...getUbicacionesColumnsFields(),
    ...getContratistaFields(),
    ...getDireccionDeObraFields(),
    ...getAperturaObraColumnsFields()
  ],
  [SEGUIMIENTO]: [
    ...getPlanificacionColumnsFields(),
    ...getAVOsColumnsFields(),
    ...getRevisionDePreciosColumnsFields(),
    ...getPrevSAPySegColumnsFields(`${SEGUIMIENTO}.previsionSeguimiento`),
    ...getSegTecnicoColumnsFields()
  ],
  [FINALIZACION]: [
    ...getRecepcionObraColumnsFields(),
    ...getPlazoDeGarantiaColumnsFields(),
    ...getLiquidacionesFields()
  ],
  [INDICADORES]: getIndicadoresFields()
})

// REGISTRO GENERAL
const getDatosGenSidebarFields = () => [
  ...getCommonGeneralInfo(),
  ...getConceptoGeneral(),
  {
    label: 'Título Obra',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'tituloObra'],
    name: `${REGISTRO_GENERAL}.tituloObra`
  },
  {
    label: 'Año',
    type: FIELD_CARD_TYPES.Number,
    path: [`${REGISTRO_GENERAL}`, 'año'],
    name: `${REGISTRO_GENERAL}.año`
  },
  {
    label: 'Importe convenio admin',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${REGISTRO_GENERAL}`, 'importeConvenioAdmin'],
    name: `${REGISTRO_GENERAL}.importeConvenioAdmin`
  },
  {
    label: 'Importe licitación obra',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${REGISTRO_GENERAL}`, 'importeLicitacion'],
    name: `${REGISTRO_GENERAL}.importeLicitacion`
  },
  {
    label: 'Fecha prevista inicio',
    type: FIELD_CARD_TYPES.Date,
    path: [`${REGISTRO_GENERAL}`, 'fechaPrevistaInicio'],
    name: `${REGISTRO_GENERAL}.fechaPrevistaInicio`
  },
  {
    label: 'Plazo obra (meses)',
    type: FIELD_CARD_TYPES.Number,
    path: [`${REGISTRO_GENERAL}`, 'plazoObra'],
    name: `${REGISTRO_GENERAL}.plazoObra`
  },
  {
    label: 'Fecha prevista puesta servicio',
    type: FIELD_CARD_TYPES.Date,
    path: [`${REGISTRO_GENERAL}`, 'fechaPrevistaPuestaServicio'],
    name: `${REGISTRO_GENERAL}.fechaPrevistaPuestaServicio`
  },
  {
    label: 'Fecha real inicio obra',
    type: FIELD_CARD_TYPES.Date,
    path: [`${REGISTRO_GENERAL}`, 'fechaRealInicioObra'],
    name: `${REGISTRO_GENERAL}.fechaRealInicioObra`
  }
]
const getDatosGenColumnsFields = () => [
  ...getDatosGenSidebarFields(),
  {
    label: 'Descripción',
    name: `${REGISTRO_GENERAL}.descripcion`
  }
]
const getUbicacionesSidebarFields = () => [
  {
    label: 'Autopista',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'ubicaciones', 'autopista'],
    unwindPath: `${REGISTRO_GENERAL}.ubicaciones`,
    name: `${REGISTRO_GENERAL}.ubicaciones.autopista`
  },
  {
    label: 'PK Inicio',
    type: FIELD_CARD_TYPES.Number,
    path: [`${REGISTRO_GENERAL}`, 'ubicaciones', 'pkInicio', 'pkNumber'],
    unwindPath: `${REGISTRO_GENERAL}.ubicaciones`,
    name: `${REGISTRO_GENERAL}.ubicaciones.pkInicio.pkNumber`
  },
  {
    label: 'PK Final',
    type: FIELD_CARD_TYPES.Number,
    path: [`${REGISTRO_GENERAL}`, 'ubicaciones', 'pkFinal', 'pkNumber'],
    unwindPath: `${REGISTRO_GENERAL}.ubicaciones`,
    name: `${REGISTRO_GENERAL}.ubicaciones.pkFinal.pkNumber`
  },
  {
    label: 'Zona',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'ubicaciones', 'zona'],
    unwindPath: `${REGISTRO_GENERAL}.ubicaciones`,
    name: `${REGISTRO_GENERAL}.ubicaciones.zona`
  },
  {
    label: 'Sentido',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'ubicaciones', 'sentido'],
    unwindPath: `${REGISTRO_GENERAL}.ubicaciones`,
    name: `${REGISTRO_GENERAL}.ubicaciones.sentido`
  }
]
const getUbicacionesColumnsFields = () => [
  ...getUbicacionesSidebarFields().filter(
    e =>
      e.name !== `${REGISTRO_GENERAL}.ubicaciones.pkInicio.pkNumber` &&
      e.name !== `${REGISTRO_GENERAL}.ubicaciones.pkFinal.pkNumber`
  ),
  {
    label: 'PK Inicio',
    name: `${REGISTRO_GENERAL}.ubicaciones.pkInicio.formatPKOffset`
  },
  {
    label: 'PK Final',
    name: `${REGISTRO_GENERAL}.ubicaciones.pkFinal.formatPKOffset`
  }
]
const getContratistaFields = () => [
  {
    label: 'Contratista',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'contratista', 'contratista'],
    name: `${REGISTRO_GENERAL}.contratista.contratista`
  },
  {
    label: 'Responsable contratista',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'contratista', 'responsable'],
    name: `${REGISTRO_GENERAL}.contratista.responsable`
  },
  {
    label: 'Fecha adjudicación contratista',
    type: FIELD_CARD_TYPES.Date,
    path: [`${REGISTRO_GENERAL}`, 'contratista', 'fechaAdjudicacion'],
    name: `${REGISTRO_GENERAL}.contratista.fechaAdjudicacion`
  },
  {
    label: 'Importe adjudicación constratista',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${REGISTRO_GENERAL}`, 'contratista', 'importeAdjudicacion'],
    name: `${REGISTRO_GENERAL}.contratista.importeAdjudicacion`
  }
]
const getDireccionDeObraFields = () => [
  {
    label: 'Dirección obra',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'direccionObra', 'direccionObra'],
    name: `${REGISTRO_GENERAL}.direccionObra.direccionObra`
  },
  {
    label: 'Responsable dirección de obra',
    type: FIELD_CARD_TYPES.String,
    path: [`${REGISTRO_GENERAL}`, 'direccionObra', 'responsable'],
    name: `${REGISTRO_GENERAL}.direccionObra.responsable`
  },
  {
    label: 'Fecha adjudicación dirección de obra',
    type: FIELD_CARD_TYPES.Date,
    path: [`${REGISTRO_GENERAL}`, 'direccionObra', 'fechaAdjudicacion'],
    name: `${REGISTRO_GENERAL}.direccionObra.fechaAdjudicacion`
  },
  {
    label: 'Importe adjudicación dirección de obra',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${REGISTRO_GENERAL}`, 'direccionObra', 'importeAdjudicacion'],
    name: `${REGISTRO_GENERAL}.direccionObra.importeAdjudicacion`
  }
]
const getAperturaObraSidebarFields = () => [
  {
    label: 'Fecha acta de replanteo',
    type: FIELD_CARD_TYPES.Date,
    path: [`${REGISTRO_GENERAL}`, 'actaReplanteo', 'fecha'],
    name: `${REGISTRO_GENERAL}.actaReplanteo.fecha`
  },
  {
    label: 'Fecha acta reunión prevención',
    type: FIELD_CARD_TYPES.Date,
    path: [`${REGISTRO_GENERAL}`, 'reunionPrevencion', 'fecha'],
    name: `${REGISTRO_GENERAL}.reunionPrevencion.fecha`
  }
]
const getAperturaObraColumnsFields = () => [
  ...getAperturaObraSidebarFields(),
  {
    label: 'Observaciones acta de replanteo',
    name: `${REGISTRO_GENERAL}.actaReplanteo.observaciones`
  },
  {
    label: 'Observaciones reunión prevención',
    name: `${REGISTRO_GENERAL}.reunionPrevencion.observaciones`
  }
]

// SEGUIMIENTO
const getPlanificacionSidebarFields = () => [
  {
    label: 'Presupuesto vigente',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${SEGUIMIENTO}`, 'planificacion', 'presupuestoVigente'],
    name: `${SEGUIMIENTO}.planificacion.presupuestoVigente`
  },
  {
    label: 'Ampliación plazo aprobada (meses)',
    type: FIELD_CARD_TYPES.Number,
    path: [`${SEGUIMIENTO}`, 'planificacion', 'ampliacionPlazoAprobada'],
    name: `${SEGUIMIENTO}.planificacion.ampliacionPlazoAprobada`
  },
  {
    label: 'Fin contrato vigente',
    type: FIELD_CARD_TYPES.Date,
    path: [`${SEGUIMIENTO}`, 'planificacion', 'finContratoVigente'],
    name: `${SEGUIMIENTO}.planificacion.finContratoVigente`
  },
  {
    label: 'Estimación puesta servicio (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [`${SEGUIMIENTO}`, 'planificacion', 'estimacionPuestaServicio'],
    name: `${SEGUIMIENTO}.planificacion.estimacionPuestaServicio`
  },
  {
    label: 'Días retraso obra',
    type: FIELD_CARD_TYPES.Number,
    path: [`${SEGUIMIENTO}`, 'planificacion', 'diasRetrasoObra'],
    name: `${SEGUIMIENTO}.planificacion.diasRetrasoObra`
  }
]
const getPlanificacionColumnsFields = () => [
  ...getPlanificacionSidebarFields(),
  {
    label: 'Observaciones de planificación',
    name: `${SEGUIMIENTO}.planificacion.observaciones`
  }
]
const getAVOsSidebarFields = () => [
  {
    label: 'Importe total AVOs',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${SEGUIMIENTO}`, 'importeTotalAVOs'],
    name: `${SEGUIMIENTO}.importeTotalAVOs`
  },
  {
    label: 'Título AVO',
    type: FIELD_CARD_TYPES.String,
    path: [`${SEGUIMIENTO}`, 'avos', 'titulo'],
    unwindPath: `${SEGUIMIENTO}.avos`,
    name: `${SEGUIMIENTO}.avos.titulo`
  },
  {
    label: 'Importe AVO',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${SEGUIMIENTO}`, 'avos', 'importe'],
    unwindPath: `${SEGUIMIENTO}.avos`,
    name: `${SEGUIMIENTO}.avos.importe`
  },
  {
    label: 'Porcentaje AVO (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [`${SEGUIMIENTO}`, 'avos', 'porcentaje'],
    unwindPath: `${SEGUIMIENTO}.avos`,
    name: `${SEGUIMIENTO}.avos.porcentaje`
  },
  {
    label: 'Fecha adjudicación AVO',
    type: FIELD_CARD_TYPES.Date,
    path: [`${SEGUIMIENTO}`, 'avos', 'fechaAdjudicacion'],
    unwindPath: `${SEGUIMIENTO}.avos`,
    name: `${SEGUIMIENTO}.avos.fechaAdjudicacion`
  },
  {
    label: 'Plazo obra (meses) AVO',
    type: FIELD_CARD_TYPES.Number,
    path: [`${SEGUIMIENTO}`, 'avos', 'plazoObra'],
    unwindPath: `${SEGUIMIENTO}.avos`,
    name: `${SEGUIMIENTO}.avos.plazoObra`
  }
]
const getAVOsColumnsFields = () => [
  ...getAVOsSidebarFields(),
  {
    label: 'Descripción AVO',
    name: `${SEGUIMIENTO}.avos.descripcion`
  }
]
const getRevisionDePreciosSidebarFields = () => [
  {
    label: 'Importe total revisiones de precios',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${SEGUIMIENTO}`, 'importeTotalRevisionesPrecios'],
    name: `${SEGUIMIENTO}.importeTotalRevisionesPrecios`
  },
  {
    label: 'Título Revisión de Precios',
    type: FIELD_CARD_TYPES.String,
    path: [`${SEGUIMIENTO}`, 'revisionesPrecios', 'titulo'],
    unwindPath: `${SEGUIMIENTO}.revisionesPrecios`,
    name: `${SEGUIMIENTO}.revisionesPrecios.titulo`
  },
  {
    label: 'Importe Revisión de Precios',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${SEGUIMIENTO}`, 'revisionesPrecios', 'importe'],
    unwindPath: `${SEGUIMIENTO}.revisionesPrecios`,
    name: `${SEGUIMIENTO}.revisionesPrecios.importe`
  },
  {
    label: 'Porcentaje Revisión de Precios (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [`${SEGUIMIENTO}`, 'revisionesPrecios', 'porcentaje'],
    unwindPath: `${SEGUIMIENTO}.revisionesPrecios`,
    name: `${SEGUIMIENTO}.revisionesPrecios.porcentaje`
  },
  {
    label: 'Fecha adjudicación Revisión de Precios',
    type: FIELD_CARD_TYPES.Date,
    path: [`${SEGUIMIENTO}`, 'revisionesPrecios', 'fechaAdjudicacion'],
    unwindPath: `${SEGUIMIENTO}.revisionesPrecios`,
    name: `${SEGUIMIENTO}.revisionesPrecios.fechaAdjudicacion`
  },
  {
    label: 'Plazo obra (meses) Revisión de Precios',
    type: FIELD_CARD_TYPES.Number,
    path: [`${SEGUIMIENTO}`, 'revisionesPrecios', 'plazoObra'],
    unwindPath: `${SEGUIMIENTO}.revisionesPrecios`,
    name: `${SEGUIMIENTO}.revisionesPrecios.plazoObra`
  }
]
const getRevisionDePreciosColumnsFields = () => [
  ...getRevisionDePreciosSidebarFields(),
  {
    label: 'Descripción Revisiones Precios',
    name: `${SEGUIMIENTO}.revisionesPrecios.descripcion`
  }
]
const getSegTecnicoSidebarFields = () => [
  ...getControlMaterialesFields(),
  ...getIndicenciasObraSidebarFields(),
  ...getVisitasSidebarFields()
]
const getSegTecnicoColumnsFields = () => [
  ...getComunicacionesEmitidasFields(),
  ...getControlMaterialesFields(),
  ...getIndicenciasObraColumnsFields(),
  ...getVisitasColumnsFields()
]
const getComunicacionesEmitidasFields = () => [
  {
    label: 'Cargo comunicación emitida',
    name: `${SEGUIMIENTO}.${TECNICO}.comunicacionesEmitidas.cargo`
  },
  {
    label: 'Nombre comunicación emitida',
    name: `${SEGUIMIENTO}.${TECNICO}.comunicacionesEmitidas.nombre`
  },
  {
    label: 'Remitente comunicación emitida',
    name: `${SEGUIMIENTO}.${TECNICO}.comunicacionesEmitidas.remitentes`
  }
]
const getControlMaterialesFields = () => [
  {
    label: 'Control de materiales realizado',
    type: FIELD_CARD_TYPES.String,
    path: [
      `${SEGUIMIENTO}`,
      `${TECNICO}`,
      `${CONTROL_MATERIALES}`,
      'controlRealizado'
    ],
    unwindPath: `${SEGUIMIENTO}.${TECNICO}.${CONTROL_MATERIALES}`,
    name: `${SEGUIMIENTO}.${TECNICO}.${CONTROL_MATERIALES}.controlRealizado`
  },
  {
    label: 'Empresa del control de materiales',
    type: FIELD_CARD_TYPES.String,
    path: [`${SEGUIMIENTO}`, `${TECNICO}`, `${CONTROL_MATERIALES}`, 'empresa'],
    unwindPath: `${SEGUIMIENTO}.${TECNICO}.${CONTROL_MATERIALES}`,
    name: `${SEGUIMIENTO}.${TECNICO}.${CONTROL_MATERIALES}.empresa`
  },
  {
    label: 'Mes del control de materiales',
    type: FIELD_CARD_TYPES.MonthYear,
    path: [
      `${SEGUIMIENTO}`,
      `${TECNICO}`,
      `${CONTROL_MATERIALES}`,
      'mes',
      'formatYYYYMM'
    ],
    unwindPath: `${SEGUIMIENTO}.${TECNICO}.${CONTROL_MATERIALES}`,
    name: `${SEGUIMIENTO}.${TECNICO}.${CONTROL_MATERIALES}.mes.formatYYYYMM`
  },
  {
    label: 'Resultado del control de materiales',
    type: FIELD_CARD_TYPES.String,
    path: [
      `${SEGUIMIENTO}`,
      `${TECNICO}`,
      `${CONTROL_MATERIALES}`,
      'resultado'
    ],
    unwindPath: `${SEGUIMIENTO}.${TECNICO}.${CONTROL_MATERIALES}`,
    name: `${SEGUIMIENTO}.${TECNICO}.${CONTROL_MATERIALES}.resultado`
  }
]
const getIndicenciasObraSidebarFields = () => [
  {
    label: 'Nombre de Indicencia de Obra',
    type: FIELD_CARD_TYPES.String,
    path: [`${SEGUIMIENTO}`, `${TECNICO}`, `${INCIDENCIA_OBRA}`, 'nombre'],
    unwindPath: `${SEGUIMIENTO}.${TECNICO}.${INCIDENCIA_OBRA}`,
    name: `${SEGUIMIENTO}.${TECNICO}.${INCIDENCIA_OBRA}.nombre`
  },
  {
    label: 'Tajo de Indicencia de Obra',
    type: FIELD_CARD_TYPES.String,
    path: [`${SEGUIMIENTO}`, `${TECNICO}`, `${INCIDENCIA_OBRA}`, 'tajo'],
    unwindPath: `${SEGUIMIENTO}.${TECNICO}.${INCIDENCIA_OBRA}`,
    name: `${SEGUIMIENTO}.${TECNICO}.${INCIDENCIA_OBRA}.tajo`
  },
  {
    label: 'Apertura de Indicencia de Obra',
    type: FIELD_CARD_TYPES.Date,
    path: [`${SEGUIMIENTO}`, `${TECNICO}`, `${INCIDENCIA_OBRA}`, 'apertura'],
    unwindPath: `${SEGUIMIENTO}.${TECNICO}.${INCIDENCIA_OBRA}`,
    name: `${SEGUIMIENTO}.${TECNICO}.${INCIDENCIA_OBRA}.apertura`
  },
  {
    label: 'Cierre de Indicencia de Obra',
    type: FIELD_CARD_TYPES.Date,
    path: [`${SEGUIMIENTO}`, `${TECNICO}`, `${INCIDENCIA_OBRA}`, 'cierre'],
    unwindPath: `${SEGUIMIENTO}.${TECNICO}.${INCIDENCIA_OBRA}`,
    name: `${SEGUIMIENTO}.${TECNICO}.${INCIDENCIA_OBRA}.cierre`
  }
]
const getIndicenciasObraColumnsFields = () => [
  ...getIndicenciasObraSidebarFields(),
  {
    label: 'Observaciones indicendia obra',
    name: `${SEGUIMIENTO}.${TECNICO}.${INCIDENCIA_OBRA}.observaciones`
  }
]
const getVisitasSidebarFields = () => [
  {
    label: 'Responsable de la visita',
    type: FIELD_CARD_TYPES.String,
    path: [`${SEGUIMIENTO}`, `${TECNICO}`, `${VISITAS}`, 'responsable'],
    unwindPath: `${SEGUIMIENTO}.${TECNICO}.${VISITAS}`,
    name: `${SEGUIMIENTO}.${TECNICO}.${VISITAS}.responsable`
  },
  {
    label: 'Fecha de la visita',
    type: FIELD_CARD_TYPES.Date,
    path: [`${SEGUIMIENTO}`, `${TECNICO}`, `${VISITAS}`, 'fecha'],
    unwindPath: `${SEGUIMIENTO}.${TECNICO}.${VISITAS}`,
    name: `${SEGUIMIENTO}.${TECNICO}.${VISITAS}.fecha`
  }
]
const getVisitasColumnsFields = () => [
  ...getVisitasSidebarFields(),
  {
    label: 'Observaciones visita',
    name: `${SEGUIMIENTO}.${TECNICO}.${VISITAS}.observaciones`
  }
]

// FINALIZACIÓN
const getRecepcionObraSidebarFields = () => [
  {
    label: 'Fecha acta recepción de obra',
    type: FIELD_CARD_TYPES.Date,
    path: [`${FINALIZACION}`, `${RECEP_OBRA}`, 'fechaActa'],
    name: `${FINALIZACION}.${RECEP_OBRA}.fechaActa`
  },
  {
    label: 'Fin repaso pendiente',
    type: FIELD_CARD_TYPES.Date,
    path: [`${FINALIZACION}`, `${RECEP_OBRA}`, 'repasosPendientes', 'fin'],
    unwindPath: `${FINALIZACION}.${RECEP_OBRA}.repasosPendientes`,
    name: `${FINALIZACION}.${RECEP_OBRA}.repasosPendientes.fin`
  }
]
const getRecepcionObraColumnsFields = () => [
  ...getRecepcionObraSidebarFields(),
  {
    label: 'Descripción de los repasos pendientes',
    name: `${FINALIZACION}.recepcionObra.repasosPendientes.descripcion`
  }
]
const getPlazoDeGarantiaSidebarFields = () => [
  {
    label: 'Mes plazo de garantía',
    type: FIELD_CARD_TYPES.MonthYear,
    path: [`${FINALIZACION}`, `${PLAZO_GARANTIA}`, 'mes', 'formatYYYYMM'],
    name: `${FINALIZACION}.${PLAZO_GARANTIA}.mes.formatYYYYMM`
  },
  {
    label: 'Fecha fin del plazo de garantía',
    type: FIELD_CARD_TYPES.Date,
    path: [`${FINALIZACION}`, `${PLAZO_GARANTIA}`, 'fechaFin'],
    name: `${FINALIZACION}.${PLAZO_GARANTIA}.fechaFin`
  },
  {
    label: 'Apertura incidencia del plazo de garantía',
    type: FIELD_CARD_TYPES.Date,
    path: [`${FINALIZACION}`, `${PLAZO_GARANTIA}`, 'incidencias', 'apertura'],
    unwindPath: `${FINALIZACION}.${PLAZO_GARANTIA}.incidencias`,
    name: `${FINALIZACION}.${PLAZO_GARANTIA}.incidencias.apertura`
  },
  {
    label: 'Cierre incidencia del plazo de garantía',
    type: FIELD_CARD_TYPES.Date,
    path: [`${FINALIZACION}`, `${PLAZO_GARANTIA}`, 'incidencias', 'cierre'],
    unwindPath: `${FINALIZACION}.${PLAZO_GARANTIA}.incidencias`,
    name: `${FINALIZACION}.${PLAZO_GARANTIA}.incidencias.cierre`
  }
]
const getPlazoDeGarantiaColumnsFields = () => [
  ...getPlazoDeGarantiaSidebarFields(),
  {
    label: 'Descripción de la incidencia',
    name: `${FINALIZACION}.${PLAZO_GARANTIA}.incidencias.descripcion`
  }
]
const getLiquidacionesFields = () => [
  {
    label: 'Importe liquidaciones',
    type: FIELD_CARD_TYPES.Monetary,
    path: [`${FINALIZACION}`, `${LIQUIDACIONES}`, 'importe'],
    name: `${FINALIZACION}.${LIQUIDACIONES}.importe`
  },
  {
    label: 'Desviación respecto contrato liquidaciones (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [`${FINALIZACION}`, `${LIQUIDACIONES}`, 'desviacionRespectoContrato'],
    name: `${FINALIZACION}.${LIQUIDACIONES}.desviacionRespectoContrato`
  },
  {
    label: 'Fecha acta liquidaciones',
    type: FIELD_CARD_TYPES.Date,
    path: [`${FINALIZACION}`, `${LIQUIDACIONES}`, 'fechaActa'],
    name: `${FINALIZACION}.${LIQUIDACIONES}.fechaActa`
  }
]

// INDICADORES
const getIndicadoresFields = () => [
  {
    label: 'Presupuesto Adjudicación / Presupuesto Total Planificado (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [`${INDICADORES}`, 'presupuestoAdjudicacionEntreTotalPlanificado'],
    name: `${INDICADORES}.presupuestoAdjudicacionEntreTotalPlanificado`
  },
  {
    label: 'Presupuesto Licitación / Presupuesto Total Planificado (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [`${INDICADORES}`, 'presupuestoLicitacionEntreTotalPlanificado'],
    name: `${INDICADORES}.presupuestoLicitacionEntreTotalPlanificado`
  },
  {
    label: 'Presupuesto Certificado a Origen / Presupuesto Adjudicación (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [
      `${INDICADORES}`,
      'presupuestoCertificadoAOrigenEntrePresupuestoAdjudicacion'
    ],
    name: `${INDICADORES}.presupuestoCertificadoAOrigenEntrePresupuestoAdjudicacion`
  },
  {
    label: 'Presupuesto Liquidación / Presupuesto Adjudicación (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [
      `${INDICADORES}`,
      'presupuestoLiquidacionEntrePresupuestoAdjudicacion'
    ],
    name: `${INDICADORES}.presupuestoLiquidacionEntrePresupuestoAdjudicacion`
  },
  {
    label: 'Presupuesto Liquidación / Presupuesto Planificado (%)',
    type: FIELD_CARD_TYPES.Number,
    path: [
      `${INDICADORES}`,
      'presupuestoLiquidacionEntrePresupuestoPlanificado'
    ],
    name: `${INDICADORES}.presupuestoLiquidacionEntrePresupuestoPlanificado`
  }
]
