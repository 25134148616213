const toLocal = state => state.asset_create

/* Drawing state */

export function isDrawing(state) {
  return toLocal(state).drawingState.isDrawing
}

export function getNewAssetType(state) {
  return toLocal(state).drawingState.assetType
}

export function getNewGeometry(state) {
  return toLocal(state).drawingState.newGeometry
}

export function isMenuOpen(state) {
  return toLocal(state).drawingState.isMenuOpen
}

/* Asset save/update */

export function isSavingAsset(state) {
  return toLocal(state).asset.isSaving
}

export function getLastAssetSaveError(state) {
  return toLocal(state).asset.lastSaveError
}

/* Ordinary operation save/update */

export function isSavingOrdinaryOperation(state) {
  return toLocal(state).ordinaryOperation.isSaving
}

export function getLastOrdinaryOperationSaveError(state) {
  return toLocal(state).ordinaryOperation.lastSaveError
}

export function getCreateWorkReportFromAssetError(state) {
  return toLocal(state).ordinaryOperation.createFromAssetError
}

export function getCreateWorkReportFromAssetOrdinaryOperationOptions(state) {
  return toLocal(state).ordinaryOperation.ordinaryOperationOptions
}

/* Extraordinary operation save/update */

export function isSavingExtraordinaryOperation(state) {
  return toLocal(state).extraordinaryOperation.isSaving
}

export function getLastExtraordinaryOperationSaveError(state) {
  return toLocal(state).extraordinaryOperation.lastSaveError
}

/* Work operation save/update */

export function isSavingWorkOperation(state) {
  return toLocal(state).workOperation.isSaving
}

export function getLastWorkOperationSaveError(state) {
  return toLocal(state).workOperation.lastSaveError
}
